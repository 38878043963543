import * as flagComponents from 'country-flag-icons/react/3x2';
import React from "react";
import flags from "../../utils/countrySelector/countriesData.json";

interface CountryFlagProps {
    areaCode: string | null;
    size?: number;
}

// Definimos una interfaz para el objeto flagComponents
interface FlagComponents {
    [key: string]: React.ComponentType<any>;
}

const CountryFlag: React.FC<CountryFlagProps> = ({ areaCode, size = 30 }) => {
    // Buscar el país en el JSON basado en el área proporcionada
    const country = flags.find((country) => country.areaCode === areaCode);

    // Verificar si se encontró el país y si tiene un código de bandera válido
    if (!country || !country.flag) {
        return null;
    }

    // Convertimos flagComponents a un tipo seguro
    const flagComponentsSafe = flagComponents as FlagComponents;

    const FlagComponent = flagComponentsSafe[country.flag.toUpperCase()];

    // Devolver el componente de bandera correspondiente si se encontró
    return FlagComponent ? <FlagComponent style={{ marginRight: '8px', width: size, height: size }} /> : null;
};

export default CountryFlag;
