/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Checkbox,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme
} from "@mui/material";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import FancyPaper from "../components/FancyPaper";
import {
  PenIcon,
  PlusIcon,
  TrashIcon
} from "../components/svgicons/SvgIcons";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import FancyTooltip from "../components/utils/FancyTooltip";
import {useApp} from "../hooks/useApp";
import {
  createRequest,
  deleteRequest,
  getRequest,
  updateRequest,
} from "../services/apartmentPending/ApartmentPendingService";

import PaymentIcon from '@mui/icons-material/Payment';
import dayjs from 'dayjs';
import {useTranslation} from "react-i18next";
import ResourceAccess from "../components/security/ResourceAccess";
import AddOrUpdateApartmentPendingModal from "../components/utils/AddOrUpdateApartmentPendingModal";
import DeleteApartmentPending from "../components/utils/DeleteApartmentPending";
import PayApartmentPendingModal from "../components/utils/PayApartmentPendingModal";
import {StatusSearchApartmentPendings} from "../types/ServiceTypes";
import PaymentSuccess from "../components/payments/PaymentSuccess";
import PaymentFailure from "../components/payments/PaymentFailure";
import {
  UpdatePaymentRequest,
  updatePaymentStatus
} from "../services/payment/PaymentService";


const SettingApartmentsPendings = () => {
  const {t} = useTranslation();
  const {
    authInfo,
    company,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const theme = useTheme();
  const [apartmentData, setApartmentData] = useState<any>([]);
  const initForm = {
    id: 0,
    amount: 0,
    apartment: null,
    company: 0,
    expirationDate: new Date(),
    registrationDate: new Date(),
    state: 0,
    title: ''
  };
  const typePending = [
    {title: t("pending"), id: 0},
    {title: t("validating"), id: 1},
    {title: t("applied"), id: 2}
  ]
  const [formData, setFormData] = useState<any>(initForm);
  const {handleSubmit} = useForm();
  const [page, setPage] = useState(0);
  const [preFilter, setPreFilter] = useState<string | undefined>('');

  const queryParameters = new URLSearchParams(window.location.search)
  const query_description = queryParameters.get("description")
  const query_payment = queryParameters.has("payment") ? queryParameters.get("payment") : ""
  const query_session_id = queryParameters.has("session_id") ?
      queryParameters.get("session_id") :
      queryParameters.get("tilopayLinkId")

  /**
   * Efecto inicial para carga de registros
   */

  const handlePaymentStatus = (query_payment: string | null, query_session_id: string | null, query_description: string | null) => {
    const updateCardPaymentStatus = (status: string) => {
      let requestUpdateData: UpdatePaymentRequest = {
        transactionId: query_session_id || "",
        status: status
      }
      updatePaymentStatus(requestUpdateData);
      console.log(`Updating payment status to ${status}`); // Example placeholder
    };

    const openModal = (modalType: string) => {
      setModalData && setModalData({
        ...modalData,
        modalOpen: true,
        modalType,
        modalObject: null,
      });
    };


      if (query_payment) {
        let status;
        if (query_payment === "success") {
          status = "CONFIRMED";
        } else if (query_payment === "tilopay" && query_description && query_description.includes("approved")) {
          status = "CONFIRMED";
        } else if (query_payment === "failure") {
          status = "FAILED";
        } else {
          console.warn(`Unexpected query_payment value: ${query_payment}`);

        }

        if (status) {
          updateCardPaymentStatus(status);
          openModal(status === "CONFIRMED" ? 'paymentSuccess' : 'paymentFailure');
        }
      }

  };

  useEffect(() => {
    // Call handlePaymentStatus on initial render or when relevant props change
    handlePaymentStatus(query_payment, query_session_id, query_description);
  }, [query_payment, query_session_id, query_description]); // Initial call


  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "");
    };
    dataInit();

  }, [company]);

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (
      currentPage?: number,
      filter?: string
  ) => {
    setLoading && setLoading(true);
    setApartmentData([]);
    setSelected([]);
    try {
      //setPage(currentPage);
      const filterString = filter ? filter : '';
      let data = await getRequest(currentPage, company ? company : authInfo.companyId, filterString, StatusSearchApartmentPendings.PENDING);
      if (data) {
        setApartmentData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let createData = await createRequest({
        ...data,
        userCreated: authInfo?.username,
      });

      if (!createData) {
        setErrorMsg && setErrorMsg(t("errorCreatingRecord"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
      setSuccessMsg(t("successfullyCreatedRecord"));
      //call fetch data
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let updateData = await updateRequest({
        ...data,
        userModified: authInfo?.username,

      });

      if (!updateData) {
        setErrorMsg &&
        setErrorMsg(t("errorUpdatingRecord"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
      setSuccessMsg(t("recordUpdatedCorrectly"));
      //call fetch data
      await handleFetchData();
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteRequest(id);
      if (!deleteData) {
        setErrorMsg &&
        setErrorMsg(t("errorDeletingRecord"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
      setSuccessMsg(t("recordDeletedSuccessfully"));

      //reset page and call fetch data
      await handleFetchData();
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
      setModalData({
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");
    if (modalAction === "update") {
      const pending = apartmentData?.content.find((p: any) => p.id === parseInt(id));
      object = {
        company: pending?.company ? pending.company : 0,
        apartment: pending?.apartment ? pending.apartment : {id: 0, name: ''},
        list: [{
          id: pending?.id ? pending.id : 0,
          amount: pending?.amount ? pending.amount : 0,
          expirationDate: pending?.expirationDate ? pending.expirationDate : null,
          registrationDate: pending?.registrationDate ? pending.registrationDate : null,
          title: pending?.title ? pending.title : '',
          currency: pending?.currency ? pending.currency : null
        }]
      }
    }

    if (modalAction === "delete") {
      object = apartmentData?.content.find((p: any) => p.id === parseInt(id));
    }

    if (modalAction === "pay") {
      object = {
        pending: apartmentData?.content.filter((p: any) => p.id === parseInt(id)),
        paymentMethod: 0
      }
    }

    if (modalAction === "paySelect") {
      object = {
        pending: apartmentData?.content.filter((x: any) => selected.some((y: number) => y === x.id)),
        paymentMethod: 0
      }
    }
    //open modal
    setModalData &&
    setModalData({
      ...modalData,
      modalOpen: true,
      modalType: modalAction,
      modalObject: object,
    });
  };


  /**
   * @param event
   */
  const handleCancelModal = () => {
    setFormData(initForm);
    setModalData &&
    setModalData({
      ...modalData,
      modalOpen: false,
      modalType: "",
      modalObject: null,
    });
  };

  const onSubmit = async (data: any) => {
    if (modalData?.modalType !== "delete") {
      if (data?.list && data?.list?.length > 0)
        data?.list.forEach((element: any) => {
          const amount: string = element?.amount ? element.amount.toString().replace(',', '') : '0'
          const object = {
            id: element?.id ? element.id : 0,
            amount: parseFloat(amount),
            apartment: data?.apartment ? data.apartment : null,
            company: company ? company : authInfo.companyId,
            expirationDate: element?.expirationDate ? element.expirationDate : null,
            registrationDate: element?.registrationDate ? element.registrationDate : null,
            state: 0,
            title: element?.title ? element.title : '',
            currency: element?.currency ? element.currency : null
          }

          if (object?.expirationDate)
            object.expirationDate = dayjs(object.expirationDate).format('YYYY-MM-DD') + 'T00:00:00.000Z'

          if (object?.registrationDate)
            object.registrationDate = dayjs(object.registrationDate).format('YYYY-MM-DD') + 'T00:00:00.000Z'

          if (!object?.id || object?.id === 0) {
            handleAdd(object);
          } else {
            handleUpdate(object);
          }
        });

    } else {
      await handleDelete(modalData?.modalObject?.id);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    const customPage = newPage - 1;
    setPage(customPage);
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
  };

  const formatterCurrency = (row: any) => {
    const symbolCurrency = row?.currency?.id && row?.currency?.symbol ? row?.currency?.symbol + ' ' : '';
    return `${formatNumber(row.amount)} ${symbolCurrency}`;
  };

  const formatNumber = (value: string) => {
    // Eliminar cualquier separador de miles antes de aplicar el nuevo valor
    const normalizedValue = (value || "").toString().replace(/,/g, "");
    // Aplicar el formato con separadores de miles
    return normalizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  /**
   * Evento de aplicar filtro de busqueda
   */
  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(0, preFilter);
    }
  };

  /**
   * Seleccionar pendientes de la tabla
   */

  const [selected, setSelected] = useState<any[]>([]);

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = apartmentData?.content.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: any, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name: any) => selected.indexOf(name) !== -1;


  //////////////////////////////// HEADER /////////////////////////

  function EnhancedTableHead(props: any) {
    const {
      onSelectAllClick,
      numSelected,
      rowCount
    } = props;

    return (
        <TableHead>
          <TableRow>
            <TableCell align="center"> {t("id")}</TableCell>
            <TableCell align="center"> {t("apartment")}</TableCell>
            <TableCell align="center"> {t("user")}</TableCell>
            <TableCell align="center"> {t("date")}</TableCell>
            <TableCell align="center"> {t("title")}</TableCell>
            <TableCell align="center"> {t("expirationDate")}</TableCell>
            <TableCell align="center"> {t("state")}</TableCell>
            <TableCell align="center"> {t("amount")}</TableCell>
            <TableCell padding="checkbox">
              <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  color="primary"
                  inputProps={{"aria-label": "select all desserts"}}
              />
            </TableCell>
            <TableCell align="center"> {t("actions")}</TableCell>
          </TableRow>
        </TableHead>
    );
  }

  return (
      <>
        <FancyPaper pagetitle={`${t("finance")} / ${t("pending")}`}>
          <Grid container spacing={8} sx={{pb: 8}}>
            <Grid item md={6} sm={6} xs={12}>
              <ResourceAccess isCode={true} pathOrCode={"ROLE:WRITE"}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenModal}
                    data-name="create"
                >
                  {t("addPending")}
                  <PlusIcon sx={{ml: 1}}/>
                </Button>
              </ResourceAccess>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={2}
              >

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenModal}
                    data-name="paySelect"
                    data-id="select"
                    disabled={selected.length === 0}
                >
                  {t("pay")}
                  <PaymentIcon sx={{ml: 1}}/>
                </Button>

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                  <TextField
                      placeholder={t("searchFilter")}
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-root": {
                          borderRadius: "0.2rem 0 0 0.2rem",
                        },
                        "& fieldset": {
                          borderRightWidth: "0",
                        },
                      }}
                      value={preFilter}
                      onChange={(e: any) => {
                        setPreFilter(e.target.value);
                        if (e.target.value === "") {
                          handleFetchData(0, "");
                        }
                      }}
                      onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
                  />
                  <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleApplyFilter}
                      sx={{
                        borderRadius: "0 0.2rem 0.2rem 0!important",
                        padding: "1.5px 16px!important",
                      }}
                  >
                    {t("search")}
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="simple table">

              <EnhancedTableHead
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={apartmentData?.content?.length}
              />
              <TableBody>
                {apartmentData &&
                    apartmentData.content &&
                    apartmentData.content.map((row: any, i: number) => {

                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${i}`;
                      return (<TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                              sx={{
                                "&:last-child td, &:last-child th": {border: 0},
                              }}
                          >
                            <TableCell align="center" component="th" scope="row">
                              {row.id}
                            </TableCell>
                            <TableCell align="center">{row.apartment.name}</TableCell>
                            <TableCell
                                align="center">{row.apartment?.user ? (row.apartment?.user?.firstName ? row.apartment.user.firstName : '') + ' ' + (row.apartment?.user?.lastName ? row.apartment.user.lastName : '') : ''}</TableCell>
                            <TableCell
                                align="center">{row.registrationDate.substring(0, 10)}</TableCell>
                            <TableCell align="center">{row.title}</TableCell>
                            <TableCell
                                align="center">{row.expirationDate.substring(0, 10)}</TableCell>
                            <TableCell align="center">{typePending[row.state].title}</TableCell>
                            <TableCell align="center">{formatterCurrency(row)}</TableCell>
                            <TableCell padding="checkbox"

                                       onClick={(event) => {
                                         handleClick(event, row.id)
                                       }}
                            >
                              <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{"aria-labelledby": labelId}}
                                  color="primary"
                              />
                            </TableCell>
                            <TableCell align="center">
                              <ResourceAccess isCode={true} pathOrCode={"ROLE:WRITE"}>
                                <FancyTooltip title={t("edit")} placement="top">
                                  <IconButton
                                      aria-label="edit"
                                      component="label"
                                      color="secondary"
                                      sx={{
                                        "&:hover": {
                                          color: theme.palette.secondary.dark,
                                        },
                                      }}
                                      onClick={handleOpenModal}
                                      data-name="update"
                                      data-id={row.id}
                                      disabled={selected.length !== 0}
                                  >
                                    <PenIcon/>
                                  </IconButton>
                                </FancyTooltip>
                              </ResourceAccess>
                              <FancyTooltip title={t("pay")} placement="top">
                                <IconButton
                                    aria-label="edit"
                                    component="label"
                                    color="secondary"
                                    sx={{
                                      "&:hover": {
                                        color: theme.palette.secondary.dark,
                                      },
                                    }}
                                    onClick={handleOpenModal}
                                    data-name="pay"
                                    data-id={row.id}
                                    disabled={selected.length !== 0}
                                >
                                  <PaymentIcon/>
                                </IconButton>
                              </FancyTooltip>
                              <ResourceAccess isCode={true} pathOrCode={"ROLE:WRITE"}>
                                <FancyTooltip title={t("delete")} placement="top">
                                  <IconButton
                                      aria-label="trash"
                                      component="label"
                                      color="secondary"
                                      sx={{
                                        "&:hover": {
                                          color: theme.palette.secondary.dark,
                                        },
                                      }}
                                      data-id={row.id}
                                      onClick={handleOpenModal}
                                      data-name="delete"
                                      disabled={selected.length !== 0}
                                  >
                                    <TrashIcon/>
                                  </IconButton>
                                </FancyTooltip>
                              </ResourceAccess>
                            </TableCell>
                          </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <FancyTablePagination
              count={apartmentData?.content?.length > 0 ? apartmentData?.content?.length : 0}
              rowsPerPage={apartmentData.size}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              totalElements={apartmentData.totalElements}
              totalPages={apartmentData.totalPages}
          />
        </FancyPaper>
        {(modalData?.modalType === "create" ||
            modalData?.modalType === "update") && (
            <Dialog
                open={modalData.modalOpen}
                onClose={handleCancelModal}
                fullWidth
                maxWidth='md'
            >
              <AddOrUpdateApartmentPendingModal
                  data={modalData?.modalObject}
                  onSubmit={onSubmit}
                  cancelModal={handleCancelModal}
                  modalType={modalData?.modalType}
                  company={company ? company : authInfo.companyId}
              />
            </Dialog>
        )}
        {(modalData?.modalType === "pay" || modalData?.modalType === "paySelect") && (
            <Dialog
                open={modalData.modalOpen}
                onClose={handleCancelModal}
                fullWidth
            >
              <PayApartmentPendingModal
                  data={modalData?.modalObject}
                  onSubmit={onSubmit}
                  cancelModal={handleCancelModal}
                  modalType={modalData?.modalType}
                  company={company ? company : authInfo.companyId}
                  handleFetchData={handleFetchData}
              />
            </Dialog>
        )}
        {modalData?.modalType === "delete" && (
            <Dialog
                open={modalData.modalOpen}
                onClose={handleCancelModal}
                fullWidth
            >
              <DeleteApartmentPending
                  //@ts-ignore
                  textChildren={t("thePending")}
                  actionButton={
                    <>
                      {" "}
                      <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          sx={{
                            mt: 2,
                            mr: 2,
                          }}
                          onClick={handleSubmit(onSubmit)}
                      >
                        {t("delete")}
                      </Button>
                      <Button
                          variant="contained"
                          color="secondary"
                          sx={{
                            mt: 2,
                          }}
                          onClick={handleCancelModal}
                          autoFocus
                      >
                        {t("cancel")}
                      </Button>{" "}
                    </>
                  }
              />
            </Dialog>
        )}
        {modalData?.modalType === "paymentSuccess" && (
            <Dialog
                open={modalData.modalOpen}
                onClose={handleCancelModal}
                fullWidth
            >
              <PaymentSuccess cancelModal={handleCancelModal}/>
            </Dialog>
        )}
        {modalData?.modalType === "paymentFailure" && (
            <Dialog
                open={modalData.modalOpen}
                onClose={handleCancelModal}
                fullWidth
            >
              <PaymentFailure cancelModal={handleCancelModal}/>
            </Dialog>
        )}
      </>
  );
};

export default SettingApartmentsPendings;
