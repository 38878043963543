import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Typography,
    styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";

const Title = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: "#e1e8ee",
    color: "#3e627c!important",
    fontFamily: "D-dinExp",
    fontWeight: 400,
    fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
    borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdate {
    id?: number;
    idCompany: number,
    name: string,
    lastName: string,
    mail: string,
    message: string,
    phone: string,
    state: string,

}

interface DialogProps {
    actionButton?: any;
    data: any;
    onSubmit: any;
    cancelModal: any;
    modalType?: any;
}

const AddOrUpdateNotificationsModal = ({
    data,
    onSubmit,
    cancelModal,
    modalType,
}: DialogProps) => {
    const { t } = useTranslation();

    //Validación de datos
    const validation = Yup.object().shape({
        name: Yup.string().required(t("fieldRequired")),
        lastName: Yup.string().required(t("fieldRequired")),
        mail: Yup.string().required(t("fieldRequired")),
        message: Yup.string().required(t("fieldRequired")),
        phone: Yup.string().required(t("fieldRequired")),
        state: Yup.string().required(t("fieldRequired")),
    });

    const defaultValues = {
        id: data ? data.id : 0,
        idCompany: data ? data.idCompany: 0,
        name: data ? data.name : "",
        lastName: data ? data.lastName : "",
        mail: data ? data.mail : "",
        message: data ? data.message : "",
        phone: data ? data.phone : "",
        state: data ? data.state : "",

    };

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        control,
        watch,
    } = useForm<AddOrUpdate>({
        defaultValues,
        resolver: yupResolver(validation),
        mode: "onChange",
    });

    const { isAdmin, currencies, company, authInfo, setLoading, setErrorMsg, setSuccessMsg, modalData } = useApp();


    return (
        <>
            <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
                {t("notifications")}
            </Title>
            <DialogContent sx={{ px: 4, pb: 0 }}>
                <form>
                    <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
                        <Grid item xs={12}>
                            <Controller
                                name={"name"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                        label={t("name")}
                                        type="text"
                                        variant="standard"
                                        value={value}
                                        autoComplete="name"
                                        sx={{ "& input": { pl: "0!important" } }}
                                        {...register("name")}
                                        error={errors.name && Boolean(errors.name)}
                                        helperText={
                                            errors.name && errors.name.message
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name={"lastName"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                        label={t("lastName")}
                                        type="text"
                                        variant="standard"
                                        value={value}
                                        autoComplete="name"
                                        sx={{ "& input": { pl: "0!important" } }}
                                        {...register("lastName")}
                                        error={errors.lastName && Boolean(errors.lastName)}
                                        helperText={
                                            errors.lastName && errors.lastName.message
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name={"mail"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                        label={t("email")}
                                        type="text"
                                        variant="standard"
                                        value={value}
                                        autoComplete="name"
                                        sx={{ "& input": { pl: "0!important" } }}
                                        {...register("mail")}
                                        error={errors.mail && Boolean(errors.mail)}
                                        helperText={
                                            errors.mail && errors.mail.message
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name={"phone"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                        label={t("phone")}
                                        type="text"
                                        variant="standard"
                                        value={value}
                                        autoComplete="name"
                                        sx={{ "& input": { pl: "0!important" } }}
                                        {...register("phone")}
                                        error={errors.phone && Boolean(errors.phone)}
                                        helperText={
                                            errors.phone && errors.phone.message
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                size="small"
                                label={t("description")}
                                type="text"
                                variant="outlined"
                                autoComplete="message"
                                {...register("message")}
                                error={errors.message && Boolean(errors.message)}
                                helperText={errors.message && errors.message.message}
                                InputProps={{ readOnly: isView }}
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            <Controller
                                name={"message"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth
                                        multiline
                                        disabled={true}
                                        size="small"
                                        label={t("message")}
                                        type="text"
                                        variant="outlined"
                                        value={value}
                                        autoComplete="name"
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                paddingLeft: '10px !important' 
                                            }
                                        }}
                                        {...register("message")}
                                        error={errors.message && Boolean(errors.message)}
                                        helperText={
                                            errors.message && errors.message.message
                                        }
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <Actions sx={{ pb: 4, px: 4 }}>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        mt: 2,
                        mr: 2,
                    }}
                    onClick={() => {
                        handleSubmit(onSubmit)();
                    }}
                >
                    {t("ok")}
                </Button>
                {/* <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                        mt: 2,
                        mr: 2,
                    }}
                    onClick={cancelModal}
                    autoFocus
                >
                    {t("cancel")}
                </Button>{" "} */}
            </Actions>
        </>
    );
};

export default AddOrUpdateNotificationsModal;
