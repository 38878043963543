import { AddOrUpdatePostCardType } from "../../types/Post";

export const post:any = [
  {
    id: 1,
    image:
      "https://definicion.de/wp-content/uploads/2009/02/condominio-1.jpg",
    title: "Suspensión Temporal del Suministro de Agua",
    author: "Condominio Oasis de San Jose.",
    date: "Enero 29, 2024",
    description: `Buenas tardes Estimados Vecinos.
    Les informamos que, como parte de las labores de reparación anunciadas previamente, se llevará a cabo una suspensión programada del suministro de agua desde las 6:00 p.m. de hoy hasta las 6:00 a.m. de mañana.              
    
    Instamos a todos las personas a tomar las precauciones necesarias almacenando agua para cubrir sus necesidades durante este período de suspensión. Nos esforzaremos por completar las reparaciones de manera oportuna y restaurar el suministro de agua lo antes posible.
    
    Agradecemos su colaboración y paciencia mientras trabajamos para mejorar nuestros servicios.",
    date="Enero 29, 2024`,
    status: "Borrador",
    tags: null
  },
  {
    id: 2,
    title: "Limpieza del cuarto gris de la Torre A",
    author: "Condominio Oasis de San Jose.",
    date: "Enero 28, 2024",
    description: `Buenos días Estimados Vecinos
    Por este medio queremos informarles sobre las labores de reparación que llevaremos a cabo en el sótano durante la noche, con el objetivo de mejorar las instalaciones y garantizar la seguridad. En este contexto, es necesario realizar una limpieza del cuarto gris de la Torre A, que ha sido utilizado como depósito de basura no tradicional.      
    Para llevar a cabo esta tarea, hemos programado la presencia de un camión de recolección en el sótano de la Torre A, específicamente entre las 9 am y las 10 am. Les solicitamos que, en la medida de lo posible, eviten utilizar el área afectada durante este período y colaboren despejando el acceso al cuarto gris.`,
    tags: null,
    status: "Publicado"
  },
  {
    id: 3,
    title: "Limpieza del cuarto gris de la Torre A",
    author: "Condominio Oasis de San Jose.",
    date: "Enero 28, 2024",
    description: `Buenos días Estimados Vecinos
    Por este medio queremos informarles sobre las labores de reparación que llevaremos a cabo en el sótano durante la noche, con el objetivo de mejorar las instalaciones y garantizar la seguridad. En este contexto, es necesario realizar una limpieza del cuarto gris de la Torre A, que ha sido utilizado como depósito de basura no tradicional.      
    Para llevar a cabo esta tarea, hemos programado la presencia de un camión de recolección en el sótano de la Torre A, específicamente entre las 9 am y las 10 am. Les solicitamos que, en la medida de lo posible, eviten utilizar el área afectada durante este período y colaboren despejando el acceso al cuarto gris.`,
    tags: ["Servicios", "Eventos"],
    status: "Publicado"
  },
  {
    id: 4,
    image:
      "https://definicion.de/wp-content/uploads/2009/02/condominio-1.jpg",
    title: "Suspensión Temporal del Suministro de Agua",
    author: "Condominio Oasis de San Jose.",
    date: "Enero 29, 2024",
    description: `Buenas tardes Estimados Vecinos.
    Les informamos que, como parte de las labores de reparación anunciadas previamente, se llevará a cabo una suspensión programada del suministro de agua desde las 6:00 p.m. de hoy hasta las 6:00 a.m. de mañana.              
    
    Instamos a todos las personas a tomar las precauciones necesarias almacenando agua para cubrir sus necesidades durante este período de suspensión. Nos esforzaremos por completar las reparaciones de manera oportuna y restaurar el suministro de agua lo antes posible.
    
    Agradecemos su colaboración y paciencia mientras trabajamos para mejorar nuestros servicios.",
    date="Enero 29, 2024`,
    tags: null,
    status: "Borrador"
  },
];