import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Grid, Box, Typography, Divider, useTheme } from "@mui/material";

import { useApp } from "../hooks/useApp";
import { useForm } from "react-hook-form";
import FancyPaper from "../components/FancyPaper";


const ValidateClient = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setLoading, setErrorMsg } = useApp();




  return (
    <>
      <FancyPaper pagetitle="Consultas / Validación de cliente">
        <Typography variant="body1">
          Validación de cliente
        </Typography>

      </FancyPaper>
    </>
  );
};

export default ValidateClient;
