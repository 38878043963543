import React from "react";

const FindCategoryPost = ({ id, postCategory }: any) => {
  let findCat: any = "";

  if (postCategory !== null) {
    findCat = postCategory.content?.find((item: any) => item.id === id)?.name;
  }
  return <>{findCat}</>;
};

export default FindCategoryPost;
