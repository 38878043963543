import { properties } from "../../utils/Properties_es";
import React, { useEffect, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Card,
  CardContent,
  Tab,
  Tabs,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  styled,
  Dialog,
  Autocomplete,
  TextField,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import {
  createRequest,
  updateRequest,
  deleteRequest,
} from "../../services/booking/InstallationService";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ThemeProvider from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useForm,
  useFieldArray,
  SubmitHandler,
  FieldValues,
  Controller,
} from "react-hook-form";
import * as Yup from "yup";
import { stringToArrayWithCommas } from "../../utils/UtilsFunctions";
import theme from "../../theme/theme";
import { useApp } from "../../hooks/useApp";
import {
  deleteDocuments,
  findDocuments,
  updateInfoConfRequest,
  uploadDocuments,
} from "../../services/booking/BookingService";
import DeleteModal from "./DeleteModal";
import Swal from "sweetalert2";
import { AnyNsRecord } from "dns";
import { useTranslation } from "react-i18next";

const Actions = DialogActions;

interface Installation {
  id: string;
  idArea: string;
  description: string;
  status: number;
  weekdaysStartTime: string;
  weekdaysEndTime: string;
  weekendsStartTime: string;
  weekendsEndTime: string;
  capacity: number;
  hasCapacity: boolean;
  approval: boolean;
  reservationType: boolean;
  calculatePrice: boolean;
  publicPrice: string;
  colegiadoPrice: string;
  areaDetails: string;
  documents?: File[];
}

interface AddOrUpdateBooking {
  installations: Installation[];
}

interface DialogProps {
  actionButton?: any;
  filteredPhotos: any;
  data: any;
  refreshData: any;
  cancelModal: any;
  modalType?: any;
  areaHasCapacity: any;
  bookingTime: any;
}

const UpdateBookingModal = ({
  actionButton,
  data,
  filteredPhotos,
  cancelModal,
  modalType,
  refreshData,
  areaHasCapacity,
  bookingTime,
}: DialogProps) => {
  const { t } = useTranslation();
  const [showAddInstallationSection, setShowAddInstallationSection] =
    useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const requiredString = Yup.string().required(t("fieldRequired"));
  const validation = Yup.object().shape({
    installations: Yup.array().of(
      Yup.object().shape({
        idArea: Yup.string(),
        description: requiredString,
        status: Yup.number().required(t("fieldRequired")),
        weekdaysStartTime: requiredString,
        weekdaysEndTime: requiredString
          .notOneOf([Yup.ref("weekdaysStartTime")], t("startTimeCannotEqualToEndTime"))
          .test(
            "is-booking-time-valid",
            t("errorBookingTime"),
            function (value) {
              const { weekdaysStartTime } = this.parent;
              const startTime = parseTime(weekdaysStartTime);
              const endTime = parseTime(value);
              const diff = endTime - startTime;

              return diff % bookingTime === 0 && diff >= bookingTime;
            }
          ),
        weekendsStartTime: requiredString,
        weekendsEndTime: requiredString
          .notOneOf([Yup.ref("weekendsStartTime")], t("startTimeCannotEqualToEndTime"))
          .test(
            "is-booking-time-valid",
            t("errorBookingTime"),
            function (value) {
              const { weekendsStartTime } = this.parent;
              const startTime = parseTime(weekendsStartTime);
              const endTime = parseTime(value);
              const diff = endTime - startTime;

              return diff % bookingTime === 0 && diff >= bookingTime;
            }
          ),
        hasCapacity: Yup.boolean().required(t("fieldRequired")),
        approval: Yup.boolean().required(t("fieldRequired")),
        reservationType: Yup.boolean().required(t("fieldRequired")),
        calculatePrice: Yup.boolean().required(t("fieldRequired")),
        publicPrice: requiredString.nullable(),
        colegiadoPrice: requiredString.nullable(),
        areaDetails: Yup.string().max(100, t("areaDetailMustNotExceed100Chars")),
        capacity: Yup.number().when("hasCapacity", (hasCapacity, schema) => {
          // Si areaHasCapacity es true, entonces capacity no es requerido
          if (areaHasCapacity) {
            return schema.nullable();
          }
          // Si areaHasCapacity es false, entonces aplicar la validación según hasCapacity
          return hasCapacity
            ? schema
              .required(t("fieldRequired"))
              .min(0, "No se permiten números negativos")
              .integer("La capacidad debe ser un número entero")
              .nullable()
            : schema.nullable();
        }),
      })
    ),
  });

  // Función para convertir una hora en formato HH:MM a minutos
  function parseTime(time: any) {
    const [hour, minute] = time.split(":").map(Number);
    return hour * 60 + minute;
  }

  const {
    authInfo,
    setLoading,
    company,
    isAdmin,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
    companyInfo,
  } = useApp();

  const [newFiel, setNewField] = useState(0);
  const handleAddInstallation = () => {
    setShowAddInstallationSection(true);
    setFiles([]);
    setCurrentTab(installationsFields.length + 1);
  };
  const defaultValues = {
    installations:
      data && data.areaDetailList
        ? data.areaDetailList
          .filter((ad: any) => ad.status != 6)
          .map((areaDetail: any) => ({
            idArea: areaDetail.idArea ? areaDetail.idArea : 0,
            id: areaDetail.id ? areaDetail.id : 0,
            description: areaDetail.description ? areaDetail.description : "",
            status: areaDetail.status ? areaDetail.status : 0,
            weekdaysStartTime: areaDetail.startTime
              ? areaDetail?.startTime
              : "",
            weekdaysEndTime: areaDetail.endTime ? areaDetail?.endTime : "",
            weekendsStartTime: areaDetail.startTimeWeekend
              ? areaDetail.startTimeWeekend
              : "",
            weekendsEndTime: areaDetail.endTimeWeekend
              ? areaDetail.endTimeWeekend
              : "",
            capacity: areaDetail.capacity ? areaDetail?.capacity : 0,
            hasCapacity: areaDetail.hasCapacity
              ? areaDetail.hasCapacity
              : false,
            approval: areaDetail.approval
              ? areaDetail.approval
              : false,
            reservationType: areaDetail.reservationType
              ? areaDetail.reservationType
              : false,
            calculatePrice: areaDetail.calculatePrice
              ? areaDetail.calculatePrice
              : false,
            publicPrice: areaDetail.publicAmount
              ? areaDetail.publicAmount
              : 0,
            colegiadoPrice: areaDetail.associatedAmount
              ? areaDetail.associatedAmount
              : 0,
            areaDetails: areaDetail.details ? areaDetail.details : "",
            documents: [] as File[],
          }))
        : [],
  };

  const {
    control,
    handleSubmit,
    setValue,
    register,
    watch,
    formState: { errors },
  } = useForm<AddOrUpdateBooking>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const {
    fields: installationsFields,
    append: appendInstallation,
    remove: removeInstallation,
  } = useFieldArray({
    control,
    name: "installations",
    keyName: "key",
  });

  const initForm = {
    id: 0,
    description: "",
    status: 0,
    weekdaysStartTime: "",
    weekdaysEndTime: "",
    weekendsStartTime: "",
    weekendsEndTime: "",
    capacity: null,
    hasCapacity: false,
    approval: false,
    reservationType: false,
    calculatePrice: false,
    publicPrice: "",
    colegiadoPrice: "",
    areaDetails: "",
  };

  const [formData, setFormData] = useState<any>(initForm);
  const [logosByArea, setLogosByArea] = useState<any>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [enlargedImageUrl, setEnlargedImageUrl] = useState<string>("");
  const [isEnlargedImageVisible, setIsEnlargedImageVisible] =
    useState<boolean>(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isSubareaDeleteModalOpen, setSubareaDeleteModalOpen] = useState(false);
  const [isSubareaSelected, setIsSubareaSelected] = useState(false);
  const allowedExtensions = ["jpg", "jpeg", "png", "bmp"];
  const [currency, setCurrency] = useState("CRC");
  const watchHasCapacity = watch("installations");
  const maxPhotos = 4;

  const getPhotos = async (idArea: string, areaDetailId?: string) => {
    const images: any = await findDocuments(
      isAdmin ? company : authInfo.companyId,
      idArea,
      areaDetailId
    );
    return images;
  };

  useEffect(() => {
    fetchLogos();
  }, []);

  const fetchLogos = async () => {
    const logos: any = [];

    for (const area of installationsFields ?? []) {
      const images: any = await getPhotos(area.idArea, area.id);

      const filteredImages = Array.isArray(images)
        ? images.filter((img: any) => img.idDocumentCategory.id === 1)
        : [];

      logos[area.id] = filteredImages;
    }

    setLogosByArea(logos);
  };

  useEffect(() => {
    // Obtener una lista plana de todos los archivos actuales
    const allCurrentFiles = files.map((file) => file);

    // Obtener una lista plana de todos los archivos de logosByArea
    const allLogosByAreaFiles: any = Object.values(logosByArea).reduce(
      (acc: any, val) => acc.concat(val),
      []
    );

    // Verificar si cada archivo de logosByArea ya existe en la lista de archivos actuales
    allLogosByAreaFiles.forEach((logo: any) => {
      if (!allCurrentFiles.some((file: any) => file.id === logo.id)) {
        // Si el archivo no existe, agrégalo a la lista de archivos actuales
        allCurrentFiles.push(logo);
      }
    });

    // Actualizar files con la lista combinada
    setFiles(allCurrentFiles);
  }, [logosByArea]);

  const onSubmit = async (data: any) => {
    //@ts-ignore
    handleInstallationUpdate(data, modalData?.modalObject?.id);
  };

  const handleAcceptInstallation = async () => {
    setLoading && setLoading(true);
    try {
      let createData = await createRequest(
        {
          ...formData,
          userCreated: authInfo.username,
          idArea: data.id,
          status: formData.status,
          capacity: formData.capacity !== 0 ? formData.capacity : null,
          hasCapacity: areaHasCapacity ? true : formData.hasCapacity,
        },
        isAdmin ? company : authInfo.companyId
      );

      const idAreaDetails = createData.id;
      const area = data.id;

      if (!createData) {
        setErrorMsg && setErrorMsg(t("ErrorSaveInstallationProcess"));
        setLoading && setLoading(false);
        return;
      }

      if (!!files.length) {
        const formData = new FormData();
        formData.append("idDocumentCategory", "1");
        formData.append("idCompany", isAdmin ? company : authInfo.companyId);
        formData.append("idArea", area);
        formData.append("idAreaDetail", idAreaDetails);
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i]);
        }
        formData.append("user", authInfo.username);
        await uploadDocuments(formData);
      }

      if (createData.code === "001") {
        setErrorMsg && setErrorMsg(createData.message);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(t("RecordUpdated"));
      refreshData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }

    setShowAddInstallationSection(false);
    cancelModal();
  };

  const handleInputChange = (fieldName: any, value: any) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleRemoveInstallation = async (index: number) => {
    const installationToRemove = installationsFields[index];
    const id = installationToRemove.id;
    //loading
    setLoading && setLoading(true);
    try {
      //call service
      let deleteData = await deleteRequest(
        isAdmin ? company : authInfo.companyId,
        id
      );
      if (!deleteData) {
        setErrorMsg && setErrorMsg(t("ErrorRemovingInstallation"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(t("RecordDeleted"));
      refreshData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
    removeInstallation(index);

    if (installationsFields.length > 1) {
      const newIndex = index === 0 ? index : index - 1;
      setCurrentTab(newIndex);
    } else {
      setShowAddInstallationSection(false);
    }
  };

  //Encargado de actualizar la subInstalación
  const handleInstallationUpdate = async (data: any, id: any) => {
    cancelModal();
    setLoading && setLoading(true);
    try {
      let hasError = false;
      let nameError = "";

      for (let i = 0; i < data.installations.length; i++) {
        let updateData = await updateInfoConfRequest(
          {
            ...data.installations[i],
            userModified: authInfo?.username,
            idArea: id,
            status: data.installations[i].status,
            capacity: data.installations[i].capacity !== 0 ? data.installations[i].capacity : null,
            hasCapacity: areaHasCapacity ? true : data.installations[i].hasCapacity,
          },
          isAdmin ? company : authInfo.companyId
        );

        if (updateData.code === "001") {
          hasError = true;
          nameError += `${data.installations[i].description}; `;
        }
        if (updateData.code === "005") {
          setErrorMsg && setErrorMsg(updateData.message);
          setLoading && setLoading(false);
          return;
        }
      }

      if (hasError) {
        setErrorMsg && setErrorMsg(`${nameError} ${t("couldNotUpd")}`);
        setLoading && setLoading(false);
        return;
      }

      for (const installation of data.installations) {
        const documents = installation.documents;

        if (
          Object.values(logosByArea[installation.id]).every(
            (value) => value !== ""
          )
        ) {
          for (let i = 0; i < documents.length; i++) {
            const document = documents[i];
            if (!!document) {
              const deletePhoto = await deleteDocuments(
                logosByArea[installation.id][i].id,
                authInfo.username
              );
              console.log(deletePhoto);

              const formData = new FormData();
              formData.append("idDocumentCategory", "1");
              formData.append(
                "idCompany",
                isAdmin ? company : authInfo.companyId
              );
              formData.append("idArea", id);
              formData.append("idAreaDetail", installation.id);
              formData.append("files", document);
              formData.append("user", authInfo.username);
              await uploadDocuments(formData);
            }
          }
        } else {
          for (let i = 0; i < documents.length; i++) {
            const document = documents[i];
            if (!!document) {
              const formData = new FormData();
              formData.append("idDocumentCategory", "1");
              formData.append(
                "idCompany",
                isAdmin ? company : authInfo.companyId
              );
              formData.append("idArea", id);
              formData.append("idAreaDetail", installation.id);
              formData.append("files", document);
              formData.append("user", authInfo.username);
              await uploadDocuments(formData);
            }
          }
        }
      }

      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(t("theInformationWasUpdatedCorrectly"));

      //call fetch data
      await refreshData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const hoursArray = Array.from({ length: 24 * 2 }, (_, i) => {
    const hour = Math.floor(i / 2);
    const minute = i % 2 === 0 ? '00' : '30';
    return {
      value: `${hour.toString().padStart(2, "0")}:${minute}`,
      label: `${hour.toString().padStart(2, "0")}:${minute}`,
    };
  });

  const militaryToStandardTime = (time: any) => {
    const [hours, minutes] = time.split(":");
    let formattedHours = parseInt(hours, 10);
    let period = "am";

    if (formattedHours === 0) {
      formattedHours = 12;
    } else if (formattedHours === 12) {
      period = "pm";
    } else if (formattedHours > 12) {
      formattedHours -= 12;
      period = "pm";
    }

    return `${formattedHours.toString().padStart(2, "0")}:${minutes} ${period}`;
  };

  const [installationPhotos, setInstallationPhotos] = useState(
    data.areaDetailList.map((areaDetail: any) =>
      areaDetail.photo ? stringToArrayWithCommas(areaDetail.photo) : []
    )
  );

  const addInstallationImageUrl = (
    installationIndex: number,
    installation: any
  ) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/png, image/jpeg, image/jpg";
    input.onchange = (event) =>
      handleInstallationFileChange(
        installationIndex,
        files.length,
        event,
        installation
      );
    input.click();
  };

  const addImageUrl = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/png, image/jpeg, image/jpg";
    input.onchange = (event) => handleFileChange(files.length, event);
    input.click();
  };

  const removeInstallationImageUrl = async (
    installationIndex: number,
    photoIndex: number,
    installation: any
  ) => {
    //loading
    setLoading && setLoading(true);
    try {
      //call service
      const deletePhoto = await deleteDocuments(
        logosByArea[installation.id][photoIndex].id,
        authInfo.username
      );
      if (!deletePhoto) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_booking_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_delete);
      refreshData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }

    setLogosByArea((prevLogos: any) => {
      const newLogos = { ...prevLogos };
      const installationLogos = newLogos[installation.id] || [];
      newLogos[installation.id] = installationLogos.filter(
        (_: any, index: any) => index !== photoIndex
      );

      return newLogos;
    });
  };

  const removeInstallationImageUrl2 = async (
    installationIndex: number,
    photoIndex: number
  ) => {
    setInstallationPhotos((prevPhotos: any) => {
      const newPhotos = [...prevPhotos];
      newPhotos[installationIndex] = newPhotos[installationIndex].filter(
        (_: any, index: any) => index !== photoIndex
      );
      return newPhotos;
    });

    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(photoIndex, 1);
      return updatedFiles;
    });
  };

  const handleInstallationFileChange = async (
    installationIndex: number,
    photoIndex: number,
    event: any,
    dataInstallation?: any,
    dataImage?: any
  ) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }
    //valida los tipos de ext y size
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        Swal.fire({
          icon: "error",
          title: "Archivo inválido",
          text: "La extensión del archivo no está permitida",
          confirmButtonColor: "#0A3A7A",
        });
        return;
      }

      if (file.size > 2000000) {
        Swal.fire({
          icon: "error",
          title: "Archivo inválido",
          text: "El tamaño del archivo debe ser menor o igual a 2MB",
          confirmButtonColor: "#0A3A7A",
        });
        return;
      }
    }

    if (dataImage !== undefined) {
      setLoading && setLoading(true);
      try {
        const deletePhoto = await deleteDocuments(
          logosByArea[dataInstallation.id][photoIndex].id,
          authInfo.username
        );
        setLoading && setLoading(false);
      } catch (error: any) {
        setErrorMsg && setErrorMsg(error.message);
        setLoading && setLoading(false);
      }
    }
    //Agregado ana, sube documento
    if (!!files.length) {
      const formData = new FormData();
      formData.append("idDocumentCategory", "1");
      formData.append("idCompany", isAdmin ? company : authInfo.companyId);
      formData.append("idArea", dataInstallation.idArea);
      formData.append("idAreaDetail", dataInstallation.id);
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      formData.append("user", authInfo.username);
      await uploadDocuments(formData);
      await fetchLogos();
    }

    //Esto de abajo lo quite ya que el fetchLogos se encarga de volverlo a agregar

    /* setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles[photoIndex] = files[0];
      return updatedFiles;
    });

    setLogosByArea((prevLogos: any) => {
      const newLogos = { ...prevLogos };
      const installationLogos = newLogos[dataInstallation.id] || [];
      newLogos[dataInstallation.id] = [...installationLogos, ""];

      return newLogos;
    });
    setValue(
      `installations.${installationIndex}.documents.${photoIndex}`,
      files[0]
    ); */
  };

  const handleFileChange = (index: number, event: any) => {
    const files: any = event.target.files;
    if (!files || files.length === 0) {
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        Swal.fire({
          icon: "error",
          title: "Archivo inválido",
          text: "La extensión del archivo no está permitida",
          confirmButtonColor: "#0A3A7A",
        });
        return;
      }
      if (file.size > 2000000) {
        Swal.fire({
          icon: "error",
          title: "Archivo inválido",
          text: "El tamaño del archivo debe ser menor o igual a 2MB",
          confirmButtonColor: "#0A3A7A",
        });
        return;
      }
    }

    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles[index] = files[0];
      return updatedFiles;
    });
  };

  const showEnlargedImage = (imageUrl: string) => {
    setEnlargedImageUrl(imageUrl);
    setIsEnlargedImageVisible(true);
  };

  const hideEnlargedImage = () => {
    setEnlargedImageUrl("");
    setIsEnlargedImageVisible(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleOpenSubareaDeleteModal = () => {
    setSubareaDeleteModalOpen(true);
  };

  const handleCloseSubareaDeleteModal = () => {
    setSubareaDeleteModalOpen(false);
  };
  console.log(formData);

  const formatNumber = (value: string) => {
    // Eliminar cualquier separador de miles antes de aplicar el nuevo valor
    const normalizedValue = (value || "").toString().replace(/,/g, "");
    // Aplicar el formato con separadores de miles
    return normalizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {t("advancedConfig")}
      </DialogTitle>
      {showAddInstallationSection ? (
        <div>
          <DialogContent sx={{ px: 10, pb: 0 }}>
            <form>
              <Card
                sx={{
                  mb: 2,
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  padding: "16px",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name={`installations.${currentTab}.description`}
                        control={control}
                        defaultValue={formData.description || ""}
                        render={({ field: { onChange, value } }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel>{t("description")}</InputLabel>
                            <Input
                              size="small"
                              value={formData.description}
                              onChange={(e) => {
                                onChange(e.target.value);
                                handleInputChange(
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            {errors?.installations?.[currentTab]?.description
                              ?.message && (
                                <FormHelperText error>
                                  {
                                    errors?.installations?.[currentTab]
                                      ?.description?.message
                                  }
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={`installations.${currentTab}.status`}
                        control={control}
                        defaultValue={formData.status || 0}
                        render={({ field: { onChange, value } }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel>{t("state")}</InputLabel>
                            <Select
                              variant="standard"
                              value={formData.status}
                              onChange={(e) => {
                                onChange(e.target.value ? e.target.value : 0);
                                handleInputChange(
                                  "status",
                                  e.target.value ? e.target.value : 0
                                );
                              }}
                            >
                              <MenuItem value={0}>{t("active")}</MenuItem>
                              <MenuItem value={1}>{t("idle")}</MenuItem>
                            </Select>
                            {errors?.installations?.[currentTab]?.status
                              ?.message && (
                                <FormHelperText error>
                                  {
                                    errors?.installations?.[currentTab]?.status
                                      ?.message
                                  }
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={formData.hasCapacity ? 6 : 12}>
                      <Controller
                        name={`installations.${currentTab}.hasCapacity`}
                        control={control}
                        defaultValue={formData.status || false}
                        render={({ field: { onChange, value } }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel>{t("reservationbyquotas")}</InputLabel>
                            <Select
                              variant="standard"
                              value={formData.hasCapacity}
                              onChange={(e) => {
                                onChange(e.target.value);
                                const has_capacity =
                                  e.target.value === "true" ? true : false;
                                handleInputChange("hasCapacity", has_capacity);
                              }}
                            >
                              <MenuItem value={true.toString()}>
                                {t("yes")}
                              </MenuItem>
                              <MenuItem value={false.toString()}>
                                {t("no")}
                              </MenuItem>
                            </Select>
                            {errors?.installations?.[currentTab]?.hasCapacity
                              ?.message && (
                                <FormHelperText error>
                                  {
                                    errors?.installations?.[currentTab]
                                      ?.hasCapacity?.message
                                  }
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                    {formData.hasCapacity && (
                      <Grid item xs={6}>
                        <Controller
                          name={`installations.${currentTab}.capacity`}
                          control={control}
                          defaultValue={formData.capacity || null}
                          render={({ field: { onChange, value } }) => (
                            <FormControl fullWidth size="small">
                              <InputLabel>{t("ability")}</InputLabel>
                              <Input
                                size="small"
                                type="number"
                                value={value || ''}
                                onChange={(e) => {
                                  let inputValue = e.target.value.trim();
                                  inputValue = inputValue.replace(/[^0-9]/g, '');
                                  if (inputValue === '' || parseInt(inputValue) >= 0) {
                                    onChange(inputValue === '' ? null : parseInt(inputValue));
                                    handleInputChange("capacity", inputValue === '' ? null : parseInt(inputValue));
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === '-') {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errors?.installations?.[currentTab]?.capacity?.message && (
                                <FormHelperText error>
                                  {errors?.installations?.[currentTab]?.capacity?.message}
                                </FormHelperText>
                              )}
                            </FormControl>
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name={`installations.${currentTab}.approval`}
                        control={control}
                        defaultValue={formData.approval || false}
                        render={({ field: { onChange, value } }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel>{t("needsApproval")}</InputLabel>
                            <Select
                              variant="standard"
                              value={formData.approval}
                              onChange={(e) => {
                                onChange(e.target.value);
                                const approval =
                                  e.target.value === "true" ? true : false;
                                handleInputChange("approval", approval);
                              }}
                            >
                              <MenuItem value={true.toString()}>
                                {t("yes")}
                              </MenuItem>
                              <MenuItem value={false.toString()}>
                                {t("no")}
                              </MenuItem>
                            </Select>
                            {errors?.installations?.[currentTab]?.approval
                              ?.message && (
                                <FormHelperText error>
                                  {
                                    errors?.installations?.[currentTab]
                                      ?.approval?.message
                                  }
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={`installations.${currentTab}.calculatePrice`}
                        control={control}
                        defaultValue={formData.calculatePrice || false}
                        render={({ field: { onChange, value } }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel>{t("calculatePricePerHour")}</InputLabel>
                            <Select
                              variant="standard"
                              value={formData.calculatePrice}
                              onChange={(e) => {
                                onChange(e.target.value);
                                const calculatePrice =
                                  e.target.value === "true" ? true : false;
                                handleInputChange("calculatePrice", calculatePrice);
                              }}
                            >
                              <MenuItem value={true.toString()}>
                                {t("yes")}
                              </MenuItem>
                              <MenuItem value={false.toString()}>
                                {t("no")}
                              </MenuItem>
                            </Select>
                            {errors?.installations?.[currentTab]?.calculatePrice
                              ?.message && (
                                <FormHelperText error>
                                  {
                                    errors?.installations?.[currentTab]
                                      ?.calculatePrice?.message
                                  }
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Controller
                        name={`installations.${currentTab}.reservationType`}
                        control={control}
                        defaultValue={formData.reservationType || false}
                        render={({ field: { onChange, value } }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel>{t("reservationType")}</InputLabel>
                            <Select
                              variant="standard"
                              value={formData.reservationType}
                              onChange={(e) => {
                                onChange(e.target.value);
                                const reservationType =
                                  e.target.value === "true" ? true : false;
                                handleInputChange("reservationType", reservationType);
                              }}
                            >
                              <MenuItem value={true.toString()}>
                                {t("reserveByHoursAndDays")}
                              </MenuItem>
                              <MenuItem value={false.toString()}>
                                {t("reserveByHours")}
                              </MenuItem>
                            </Select>
                            {errors?.installations?.[currentTab]?.reservationType
                              ?.message && (
                                <FormHelperText error>
                                  {
                                    errors?.installations?.[currentTab]
                                      ?.reservationType?.message
                                  }
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ mb: 2, fontSize: "16px" }}
                  >
                    {t("hourRange")} ({t("weekly")})
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name={`installations.${currentTab}.weekdaysStartTime`}
                        control={control}
                        defaultValue={formData.weekdaysStartTime || ""}
                        render={({ field: { onChange, value } }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel>
                              {t("mondayToFridayStartTime")}{" "}
                            </InputLabel>
                            <Select
                              variant="standard"
                              value={formData.weekdaysStartTime}
                              onChange={(e) => {
                                onChange(e.target.value);
                                handleInputChange(
                                  "weekdaysStartTime",
                                  e.target.value
                                );
                              }}
                              input={<Input />}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                  },
                                },
                              }}
                            >
                              {hoursArray.map((hour) => (
                                <MenuItem key={hour.value} value={hour.value}>
                                  {militaryToStandardTime(hour.label)}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors?.installations?.[currentTab]
                              ?.weekdaysStartTime?.message && (
                                <FormHelperText error>
                                  {
                                    errors?.installations?.[currentTab]
                                      ?.weekdaysStartTime?.message
                                  }
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={`installations.${currentTab}.weekdaysEndTime`}
                        control={control}
                        defaultValue={formData.weekdaysEndTime || ""}
                        render={({ field: { onChange, value } }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel>
                              {t("mondayToFridayEndTime")}
                            </InputLabel>
                            <Select
                              variant="standard"
                              value={formData.weekdaysEndTime}
                              onChange={(e) => {
                                onChange(e.target.value);
                                handleInputChange(
                                  "weekdaysEndTime",
                                  e.target.value
                                );
                              }}
                              input={<Input />}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                  },
                                },
                              }}
                            >
                              {hoursArray.map((hour) => (
                                <MenuItem key={hour.value} value={hour.value}>
                                  {militaryToStandardTime(hour.label)}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors?.installations?.[currentTab]
                              ?.weekdaysEndTime?.message && (
                                <FormHelperText error>
                                  {
                                    errors?.installations?.[currentTab]
                                      ?.weekdaysEndTime?.message
                                  }
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name={`installations.${currentTab}.weekendsStartTime`}
                        control={control}
                        defaultValue={formData.weekendsStartTime || ""}
                        render={({ field: { onChange, value } }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel>
                              {t("saturdayToSundayStartTime")}
                            </InputLabel>
                            <Select
                              variant="standard"
                              value={formData.weekendsStartTime}
                              onChange={(e) => {
                                onChange(e.target.value);
                                handleInputChange(
                                  "weekendsStartTime",
                                  e.target.value
                                );
                              }}
                              input={<Input />}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                  },
                                },
                              }}
                            >
                              {hoursArray.map((hour) => (
                                <MenuItem key={hour.value} value={hour.value}>
                                  {militaryToStandardTime(hour.label)}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors?.installations?.[currentTab]
                              ?.weekendsStartTime?.message && (
                                <FormHelperText error>
                                  {
                                    errors?.installations?.[currentTab]
                                      ?.weekendsStartTime?.message
                                  }
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={`installations.${currentTab}.weekendsEndTime`}
                        control={control}
                        defaultValue={formData.weekendsEndTime || ""}
                        render={({ field: { onChange, value } }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel>
                              {t("saturdayToSundayEndTime")}
                            </InputLabel>
                            <Select
                              variant="standard"
                              value={formData.weekendsEndTime}
                              onChange={(e) => {
                                onChange(e.target.value);
                                handleInputChange(
                                  "weekendsEndTime",
                                  e.target.value
                                );
                              }}
                              input={<Input />}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                  },
                                },
                              }}
                            >
                              {hoursArray.map((hour) => (
                                <MenuItem key={hour.value} value={hour.value}>
                                  {militaryToStandardTime(hour.label)}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors?.installations?.[currentTab]
                              ?.weekendsEndTime?.message && (
                                <FormHelperText error>
                                  {
                                    errors?.installations?.[currentTab]
                                      ?.weekendsEndTime?.message
                                  }
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ mb: 2, fontSize: "16px" }}
                  >
                    {t("prices")}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name={`installations.${currentTab}.publicPrice`}
                        control={control}
                        defaultValue={formData.publicPrice || ""}
                        render={({ field: { onChange, value } }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel>{t("singlePublicHourDayPrice")}</InputLabel>
                            <Input
                              size="small"
                              type="text"
                              value={formatNumber(value)}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(/[^0-9.,]/g, "");
                                onChange(newValue);
                                handleInputChange(
                                  "publicPrice",
                                  parseInt(newValue || "1")
                                );
                              }}
                              startAdornment={<InputAdornment position="start">{companyInfo?.currencies != null && companyInfo?.currencies[0]?.symbol ? companyInfo.currencies[0].symbol : ''}</InputAdornment>}
                            />
                            {errors?.installations?.[currentTab]?.publicPrice?.message && (
                              <FormHelperText error>
                                {errors?.installations?.[currentTab]?.publicPrice?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={`installations.${currentTab}.colegiadoPrice`}
                        control={control}
                        defaultValue={formData.colegiadoPrice || ""}
                        render={({ field: { onChange, value } }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel>{t("singleCollegiateHourDayPrice")}</InputLabel>
                            <Input
                              size="small"
                              type="text"
                              value={formatNumber(value)}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(/[^0-9.,]/g, "");
                                onChange(newValue);
                                handleInputChange(
                                  "colegiadoPrice",
                                  parseInt(newValue || "1")
                                );
                              }}
                              startAdornment={<InputAdornment position="start">{companyInfo?.currencies != null && companyInfo?.currencies[0]?.symbol ? companyInfo.currencies[0].symbol : ''}</InputAdornment>}
                            />
                            {errors?.installations?.[currentTab]?.colegiadoPrice?.message && (
                              <FormHelperText error>
                                {errors?.installations?.[currentTab]?.colegiadoPrice?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Controller
                    name={`installations.${currentTab}.areaDetails`}
                    control={control}
                    defaultValue={formData.areaDetails || ""}
                    render={({ field: { onChange, value } }) => (
                      <FormControl fullWidth size="small">
                        <>
                          <InputLabel>{t("detailsOfAreaOrConfig")}</InputLabel>
                          <Input
                            size="small"
                            multiline
                            rows={1}
                            value={formData.areaDetails}
                            onChange={(e) => {
                              onChange(e.target.value);
                              handleInputChange("areaDetails", e.target.value);
                            }}
                          />
                          {errors?.installations?.[currentTab]?.areaDetails
                            ?.message && (
                              <FormHelperText error>
                                {
                                  errors?.installations?.[currentTab]?.areaDetails
                                    ?.message
                                }
                              </FormHelperText>
                            )}
                        </>
                      </FormControl>
                    )}
                  />
                  <br />
                  <br />
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ fontSize: "16px", ml: 0.5 }}
                    >
                      {t("instPhotos")}
                    </Typography>
                    {files &&
                      files.map((url: any, photoIndex: any) => (
                        <div
                          key={photoIndex}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "8px",
                          }}
                        >
                          <Controller
                            name={`installations.${currentTab}.documents.${photoIndex}`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <FormControl>
                                <Button
                                  variant="outlined"
                                  component="label"
                                  htmlFor={`installation-photo-upload-${photoIndex}`}
                                  sx={{
                                    width: "150px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {t("selectImage")}
                                </Button>
                                <Input
                                  id={`installation-photo-upload-${photoIndex}`}
                                  type="file"
                                  onChange={(event) =>
                                    handleFileChange(photoIndex, event)
                                  }
                                  inputProps={{
                                    accept: "image/png, image/jpeg, image/jpg",
                                  }}
                                  style={{ display: "none" }}
                                />
                              </FormControl>
                            )}
                          />
                          {files[photoIndex] && (
                            <div
                              style={{
                                marginLeft: "30px",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {files[photoIndex].name}
                            </div>
                          )}
                          {files[photoIndex] && (
                            <div>
                              <IconButton
                                color="secondary"
                                onClick={() =>
                                  removeInstallationImageUrl2(
                                    currentTab,
                                    photoIndex
                                  )
                                }
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </div>
                          )}
                        </div>
                      ))}
                    {files && files.length < maxPhotos && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <IconButton
                          color="primary"
                          onClick={addImageUrl}
                          disabled={files && files.length >= maxPhotos}
                        >
                          <AddIcon fontSize="small" />
                        </IconButton>
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "15px", ml: 0.5 }}
                        >
                          {t("addImage")}
                        </Typography>
                      </div>
                    )}
                  </FormControl>
                </CardContent>
              </Card>
            </form>
          </DialogContent>

          <Actions sx={{ pb: 4, px: 4 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                mt: 2,
                mr: 2,
              }}
              onClick={handleSubmit(handleAcceptInstallation)}
            >
              {t("save")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                mt: 2,
                mr: 2,
              }}
              onClick={() => {
                setFormData(initForm);
                setFiles([]);
                setCurrentTab(0);
                setShowAddInstallationSection(false);
              }}
            >
              {t("cancel")}
            </Button>
          </Actions>
        </div>
      ) : (
        <>
          <IconButton
            color="primary"
            sx={{ ml: "auto", mb: 2 }}
            onClick={handleAddInstallation}
          >
            <AddIcon fontSize="small" />
            <Typography variant="body2" sx={{ fontSize: "15px", ml: 0.5 }}>
              {t("addNewSubinstall")}
            </Typography>
          </IconButton>
          <Tabs
            value={currentTab}
            onChange={(_, newValue) => setCurrentTab(newValue)}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ px: 10, mb: 1 }}
          >
            {installationsFields.length > 0 &&
              installationsFields.map((installation, index) => (
                <Tab
                  key={index}
                  label={`${installation.description}`}
                  sx={{
                    color: currentTab === index ? "#00aae4" : "#000",
                    "&:hover": {
                      color: "#00aae4",
                      textDecoration: "underline",
                    },
                  }}
                />
              ))}
          </Tabs>
          <Dialog
            open={isSubareaDeleteModalOpen}
            onClose={handleCloseSubareaDeleteModal}
            fullWidth
          >
            <DeleteModal
              //@ts-ignore
              textChildren={modalData?.modalObject?.username}
              actionButton={
                <>
                  {" "}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      mt: 2,
                      mr: 2,
                    }}
                    onClick={() => {
                      handleRemoveInstallation(currentTab);
                      handleCloseSubareaDeleteModal();
                    }}
                  >
                    {t("ok")}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      mt: 2,
                      mr: 2,
                    }}
                    onClick={() => {
                      handleCloseSubareaDeleteModal();
                    }}
                    autoFocus
                  >
                    {t("cancel")}
                  </Button>{" "}
                </>
              }
            />
          </Dialog>
          <IconButton
            color="error"
            sx={{ ml: "auto", mb: 2 }}
            onClick={handleOpenSubareaDeleteModal}
          >
            <DeleteIcon fontSize="small" />
            <Typography variant="body2" sx={{ fontSize: "15px", ml: 0.5 }}>
              {t("deleteInstallation")}
            </Typography>
          </IconButton>

          {installationsFields.map((installation, index) => (
            <div
              key={index}
              style={{ display: currentTab === index ? "block" : "none" }}
            >
              <DialogContent sx={{ px: 10, pb: 0 }}>
                <form>
                  <Card
                    sx={{
                      mb: 2,
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      padding: "16px",
                    }}
                  >
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Controller
                            key={index}
                            name={`installations.${index}.description`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <FormControl fullWidth size="small">
                                <InputLabel htmlFor={`description-${index}`}>
                                  {t("description")}
                                </InputLabel>
                                <Input
                                  size="small"
                                  value={value}
                                  onChange={onChange}
                                />
                                {errors?.installations && (
                                  <FormHelperText>
                                    {
                                      errors?.installations?.[index]
                                        ?.description?.message
                                    }
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name={`installations.${index}.status`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <FormControl fullWidth size="small">
                                <InputLabel htmlFor={`status-${index}`}>
                                  {t("state")}
                                </InputLabel>
                                <Select
                                  variant="standard"
                                  value={value}
                                  onChange={onChange}
                                >
                                  <MenuItem value={0}>{t("active")}</MenuItem>
                                  <MenuItem value={1}>{t("idle")}</MenuItem>
                                </Select>
                                {errors?.installations && (
                                  <FormHelperText>
                                    {
                                      errors?.installations?.[index]?.status
                                        ?.message
                                    }
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={watchHasCapacity[index].hasCapacity ? 6 : 12}
                        >
                          <Controller
                            name={`installations.${index}.hasCapacity`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <FormControl fullWidth size="small">
                                <InputLabel htmlFor={`has-capacity-${index}`}>
                                  {t("reservationbyquotas")}
                                </InputLabel>
                                <Select
                                  variant="standard"
                                  value={value}
                                  onChange={(e) => {
                                    const newValue =
                                      e.target.value === "true" ? true : false;
                                    onChange(newValue);
                                  }}
                                >
                                  <MenuItem value={true.toString()}>
                                    {t("yes")}
                                  </MenuItem>
                                  <MenuItem value={false.toString()}>
                                    {t("no")}
                                  </MenuItem>
                                </Select>
                                {errors?.installations && (
                                  <FormHelperText>
                                    {
                                      errors?.installations?.[index]
                                        ?.hasCapacity?.message
                                    }
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        </Grid>
                        {watchHasCapacity[index].hasCapacity && (
                          <Grid item xs={6}>
                            <Controller
                              key={index}
                              name={`installations.${index}.capacity`}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <FormControl fullWidth size="small">
                                  <InputLabel htmlFor={`capacity-${index}`}>
                                    {t("ability")}
                                  </InputLabel>
                                  <Input
                                    size="small"
                                    type="number"
                                    value={value === null ? '' : value}
                                    onChange={(e) => {
                                      let newValue = e.target.value.trim();
                                      newValue = newValue.replace(/[^0-9]/g, '');
                                      onChange(newValue === '' ? null : parseInt(newValue));
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === '-') {
                                        e.preventDefault();
                                      }
                                    }}
                                    inputProps={{
                                      min: "0",
                                    }}
                                  />
                                  {errors?.installations && (
                                    <FormHelperText>
                                      {errors?.installations?.[index]?.capacity?.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              )}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <br />
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Controller
                            name={`installations.${index}.approval`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <FormControl fullWidth size="small">
                                <InputLabel htmlFor={`approval-${index}`}>
                                  {t("needsApproval")}
                                </InputLabel>
                                <Select
                                  variant="standard"
                                  value={value}
                                  onChange={(e) => {
                                    const newValue =
                                      e.target.value === "true" ? true : false;
                                    onChange(newValue);
                                  }}
                                >
                                  <MenuItem value={true.toString()}>
                                    {t("yes")}
                                  </MenuItem>
                                  <MenuItem value={false.toString()}>
                                    {t("no")}
                                  </MenuItem>
                                </Select>
                                {errors?.installations && (
                                  <FormHelperText>
                                    {
                                      errors?.installations?.[index]
                                        ?.approval?.message
                                    }
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name={`installations.${index}.calculatePrice`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <FormControl fullWidth size="small">
                                <InputLabel htmlFor={`calculatePrice-${index}`}>
                                  {t("calculatePricePerHour")}
                                </InputLabel>
                                <Select
                                  variant="standard"
                                  value={value}
                                  onChange={(e) => {
                                    const newValue =
                                      e.target.value === "true" ? true : false;
                                    onChange(newValue);
                                  }}
                                >
                                  <MenuItem value={true.toString()}>
                                    {t("yes")}
                                  </MenuItem>
                                  <MenuItem value={false.toString()}>
                                    {t("no")}
                                  </MenuItem>
                                </Select>
                                {errors?.installations && (
                                  <FormHelperText>
                                    {
                                      errors?.installations?.[index]
                                        ?.calculatePrice?.message
                                    }
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Controller
                            name={`installations.${index}.reservationType`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <FormControl fullWidth size="small">
                                <InputLabel htmlFor={`reservationType-${index}`}>
                                  {t("reservationType")}
                                </InputLabel>
                                <Select
                                  variant="standard"
                                  value={value}
                                  onChange={(e) => {
                                    const newValue =
                                      e.target.value === "true" ? true : false;
                                    onChange(newValue);
                                  }}
                                >
                                  <MenuItem value={true.toString()}>
                                    {t("reserveByHoursAndDays")}
                                  </MenuItem>
                                  <MenuItem value={false.toString()}>
                                    {t("reserveByHours")}
                                  </MenuItem>
                                </Select>
                                {errors?.installations && (
                                  <FormHelperText>
                                    {
                                      errors?.installations?.[index]
                                        ?.reservationType?.message
                                    }
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        </Grid>
                      </Grid>
                      <br />
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ mb: 2, fontSize: "16px" }}
                      >
                        {t("hourRange")} ({t("weekly")})
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Controller
                            name={`installations.${index}.weekdaysStartTime`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <FormControl fullWidth size="small">
                                <InputLabel htmlFor={`weekdays-start-${index}`}>
                                  {t("mondayToFridayStartTime")}
                                </InputLabel>
                                <Select
                                  variant="standard"
                                  value={value}
                                  onChange={onChange}
                                  input={<Input />}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        maxHeight: 200,
                                      },
                                    },
                                  }}
                                >
                                  {hoursArray.map((hour) => (
                                    <MenuItem
                                      key={hour.value}
                                      value={hour.value}
                                    >
                                      {militaryToStandardTime(hour.label)}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors?.installations && (
                                  <FormHelperText>
                                    {
                                      errors?.installations?.[index]
                                        ?.weekdaysStartTime?.message
                                    }
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name={`installations.${index}.weekdaysEndTime`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <FormControl fullWidth size="small">
                                <InputLabel htmlFor={`weekdays-end-${index}`}>
                                  {t("mondayToFridayEndTime")}
                                </InputLabel>
                                <Select
                                  variant="standard"
                                  value={value}
                                  onChange={onChange}
                                  input={<Input />}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        maxHeight: 200,
                                      },
                                    },
                                  }}
                                >
                                  {hoursArray.map((hour) => (
                                    <MenuItem
                                      key={hour.value}
                                      value={hour.value}
                                    >
                                      {militaryToStandardTime(hour.label)}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors?.installations && (
                                  <FormHelperText>
                                    {
                                      errors?.installations?.[index]
                                        ?.weekdaysEndTime?.message
                                    }
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Controller
                            name={`installations.${index}.weekendsStartTime`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <FormControl fullWidth size="small">
                                <InputLabel htmlFor={`weekends-start-${index}`}>
                                  {t("saturdayToSundayStartTime")}
                                </InputLabel>
                                <Select
                                  variant="standard"
                                  value={value}
                                  onChange={onChange}
                                  input={<Input />}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        maxHeight: 200,
                                      },
                                    },
                                  }}
                                >
                                  {hoursArray.map((hour) => (
                                    <MenuItem
                                      key={hour.value}
                                      value={hour.value}
                                    >
                                      {militaryToStandardTime(hour.label)}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors?.installations && (
                                  <FormHelperText>
                                    {
                                      errors?.installations?.[index]
                                        ?.weekendsStartTime?.message
                                    }
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name={`installations.${index}.weekendsEndTime`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <FormControl fullWidth size="small">
                                <InputLabel htmlFor={`weekends-end-${index}`}>
                                  {t("saturdayToSundayEndTime")}
                                </InputLabel>
                                <Select
                                  variant="standard"
                                  value={value}
                                  onChange={onChange}
                                  input={<Input />}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        maxHeight: 200,
                                      },
                                    },
                                  }}
                                >
                                  {hoursArray.map((hour) => (
                                    <MenuItem
                                      key={hour.value}
                                      value={hour.value}
                                    >
                                      {militaryToStandardTime(hour.label)}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors?.installations && (
                                  <FormHelperText>
                                    {
                                      errors?.installations?.[index]
                                        ?.weekendsEndTime?.message
                                    }
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        </Grid>
                      </Grid>
                      <br />
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ mb: 2, fontSize: "16px" }}
                      >
                        {t("prices")}
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Controller
                            name={`installations.${index}.publicPrice`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <FormControl fullWidth size="small">
                                <InputLabel htmlFor={`public-price-${index}`}>
                                  {t("singlePublicHourDayPrice")}
                                </InputLabel>
                                <Input
                                  size="small"
                                  type="text"
                                  value={formatNumber(value)}
                                  onChange={(e) => {
                                    const newValue = e.target.value.replace(/[^0-9.,]/g, "");
                                    onChange(newValue);
                                  }}
                                  startAdornment={<InputAdornment position="start">{companyInfo?.currencies != null && companyInfo?.currencies[0]?.symbol ? companyInfo.currencies[0].symbol : ''}</InputAdornment>}

                                />
                                {errors?.installations && (
                                  <FormHelperText>
                                    {errors?.installations?.[index]?.publicPrice?.message}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name={`installations.${index}.colegiadoPrice`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <FormControl fullWidth size="small">
                                <InputLabel htmlFor={`colegiado-price-${index}`}>
                                  {t("singleCollegiateHourDayPrice")}
                                </InputLabel>
                                <Input
                                  size="small"
                                  type="text"
                                  value={formatNumber(value)}
                                  onChange={(e) => {
                                    const newValue = e.target.value.replace(/[^0-9.,]/g, "");
                                    onChange(newValue);
                                  }}
                                  startAdornment={<InputAdornment position="start">{companyInfo?.currencies != null && companyInfo?.currencies[0]?.symbol ? companyInfo.currencies[0].symbol : ''}</InputAdornment>}

                                />
                                {errors?.installations && (
                                  <FormHelperText>
                                    {errors?.installations?.[index]?.colegiadoPrice?.message}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        </Grid>
                      </Grid>
                      <br />
                      <FormControl fullWidth size="small">
                        <Controller
                          name={`installations.${index}.areaDetails`}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <InputLabel htmlFor={`area-details-${index}`}>
                                {t("detailsOfAreaOrConfig")}
                              </InputLabel>
                              <Input
                                size="small"
                                multiline
                                rows={1}
                                value={value}
                                onChange={onChange}
                              />
                              {errors?.installations && (
                                <FormHelperText>
                                  {
                                    errors?.installations?.[index]?.areaDetails
                                      ?.message
                                  }
                                </FormHelperText>
                              )}
                            </>
                          )}
                        />
                      </FormControl>
                      <br />
                      <br />
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ fontSize: "16px", ml: 0.5 }}
                        >
                          {t("instPhotos")}
                        </Typography>
                        {logosByArea[installation.id] &&
                          Array.isArray(logosByArea[installation.id]) &&
                          logosByArea[installation.id].map(
                            (url: any, photoIndex: any) => (
                              <div
                                key={photoIndex}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "8px",
                                  alignContent: "space-between",
                                  width: "auto",
                                }}
                              >
                                <Controller
                                  name={`installations.${currentTab}.documents.${photoIndex}`}
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <FormControl>
                                      <Button
                                        variant="outlined"
                                        component="label"
                                        htmlFor={`installation-photo-upload-${photoIndex}`}
                                        sx={{
                                          width: "150px",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {t("selectImage")}
                                      </Button>
                                      <Input
                                        id={`installation-photo-upload-${photoIndex}`}
                                        type="file"
                                        onChange={(event) =>
                                          handleInstallationFileChange(
                                            currentTab,
                                            photoIndex,
                                            event,
                                            installation,
                                            url
                                          )
                                        }
                                        inputProps={{
                                          accept:
                                            "image/png, image/jpeg, image/jpg",
                                        }}
                                        style={{ display: "none" }}
                                      />
                                    </FormControl>
                                  )}
                                />
                                {url && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginLeft: "20px",
                                      width: "150px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <Typography
                                      textOverflow={"clip"}
                                      variant="body2"
                                      sx={{
                                        maxWidth: 150,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        fontSize: "15px",
                                      }}
                                    >
                                      {url.originalName}
                                    </Typography>
                                  </div>
                                )}
                                {url && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginLeft: "8px",
                                      flex: 1,
                                    }}
                                  >
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        showEnlargedImage(
                                          `https://dispatcher.spacemanager.io/v1/documents/documents/view/${url.id}?originalName=${url.originalName}`
                                        );
                                      }}
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginRight: "-5px",
                                      }}
                                    >
                                      {t("viewImage")}
                                    </a>
                                  </div>
                                )}
                                {url && (
                                  <div>
                                    <Dialog
                                      open={isDeleteModalOpen}
                                      onClose={handleCloseDeleteModal}
                                      fullWidth
                                    >
                                      <DeleteModal
                                        //@ts-ignore
                                        textChildren={
                                          modalData?.modalObject?.username
                                        }
                                        actionButton={
                                          <>
                                            {" "}
                                            <Button
                                              type="submit"
                                              variant="contained"
                                              color="primary"
                                              sx={{
                                                mt: 2,
                                                mr: 2,
                                              }}
                                              onClick={() => {
                                                removeInstallationImageUrl(
                                                  currentTab,
                                                  photoIndex,
                                                  installationsFields[
                                                  currentTab
                                                  ]
                                                );
                                                handleCloseDeleteModal();
                                              }}
                                            >
                                              {t("delete")}
                                            </Button>
                                            <Button
                                              variant="contained"
                                              color="secondary"
                                              sx={{
                                                mt: 2,
                                                mr: 2,
                                              }}
                                              onClick={() => {
                                                handleCloseDeleteModal();
                                              }}
                                              autoFocus
                                            >
                                              {t("cancel")}
                                            </Button>{" "}
                                          </>
                                        }
                                      />
                                    </Dialog>
                                    <IconButton
                                      color="secondary"
                                      style={{ marginRight: "20px" }}
                                      onClick={handleOpenDeleteModal}
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  </div>
                                )}
                              </div>
                            )
                          )}
                        {logosByArea[installation.id] &&
                          logosByArea[installation.id].length < maxPhotos && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "8px",
                              }}
                            >
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  addInstallationImageUrl(
                                    currentTab,
                                    installation
                                  )
                                }
                                disabled={
                                  logosByArea[installation.id] &&
                                  logosByArea[installation.id].length >=
                                  maxPhotos
                                }
                              >
                                <AddIcon fontSize="small" />
                              </IconButton>
                              <Typography
                                variant="body2"
                                sx={{ fontSize: "15px", ml: 0.5 }}
                              >
                                {t("addImage")}
                              </Typography>
                            </div>
                          )}
                      </FormControl>
                    </CardContent>
                  </Card>
                </form>
              </DialogContent>
            </div>
          ))}
          {isEnlargedImageVisible && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                zIndex: 9999,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={hideEnlargedImage}
            >
              <img
                src={enlargedImageUrl}
                alt="Enlarged"
                style={{
                  width: "100%",
                  height: "auto",
                  display: "block",
                  margin: "auto",
                  maxWidth: "600px",
                  maxHeight: "400px",
                }}
              />
            </div>
          )}

          <Actions sx={{ pb: 4, px: 4 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                mt: 2,
                mr: 2,
              }}
              onClick={handleSubmit(onSubmit)}
            >
              {t("save")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                mt: 2,
                mr: 2,
              }}
              onClick={cancelModal}
            >
              {t("cancel")}
            </Button>
          </Actions>
        </>
      )}
    </>
  );
};

export default UpdateBookingModal;
