import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Autocomplete,
  styled,
  debounce,
  FormControl,
  MenuItem,
  Select,
  InputLabel
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import {
  getRequestUser
} from "../../services/users/UserService";
import { useApp } from "../../hooks/useApp";
import InputLabelToForm from "../InputLabelToForm";
import { useTranslation } from "react-i18next";

// Define un tipo para cada elemento dentro de `apartmentsData`
// interface ApartmentData {
//   name: string;
//   status: string;
//   user: any;
//   [key: string]: string;
// }

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdateApartment {
  id?: number;
  company: number;
  name: string;
  status: string;
  user: {
    id?: number;
    name?: string;
  };
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  company: number;
}

enum ApartmentField {
  Id = "id",
  Name = "name",
  Status = "status",
  User = "user",
}

const AddOrUpdateApartmentsModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  company
}: DialogProps) => {
  const { t } = useTranslation();
  // Validación de datos

  const [userData, setUsertData] = useState<any>([]);  
  const [inputUser,setInputUser] = useState("");
  const [timeoutUser,setTimeoutUser] = useState<any>(null);
  const { setLoading, setErrorMsg, isAdmin, setSuccessMsg } = useApp();

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "");
    };
    dataInit();
  }, [company]);
  

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (
    currentPage?: number,
    filter?: string,
    applyRole?: any
  ) => {
    setUsertData([]);
    try {
      //setPage(currentPage);
      let users = await getRequestUser(currentPage,filter,company);
      if (users) {
          setUsertData(users);
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };


  const validation = Yup.object().shape({
    status: Yup.string().required(t("fieldRequired")),
    name: Yup.string().required(t("fieldRequired")),
  });

  // Obtener nombre o código de área según lo ingresado por el usuario
  const getApartmentData = (value: string, field: string) => {
    return  '';
  };

  // Obtener valores predeterminados
  const defaultValues = {
    id: data ? data.id : 0,
    status: data ? data.status : 0,
    name: data ? data.name : "",
    company: company,
    user: data ? data.user : null
  };

  // Inicializar useForm con validación y valores predeterminados
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateApartment>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  // Actualizar el valor del nombre o el código de área según el otro campo
  useEffect(() => {
    const handleUpdateField = (fieldName: ApartmentField, value: string) => {
      if (!value) return;
      const newValue = getApartmentData(value, fieldName);
      // if (newValue) setValue(fieldName, newValue);
    };

    // Actualizar el nombre si se ingresa el código de área y viceversa
    handleUpdateField(ApartmentField.User, defaultValues.user);
    handleUpdateField(ApartmentField.Name, defaultValues.name);
    handleUpdateField(ApartmentField.Status, defaultValues.status);

  }, [defaultValues.status, defaultValues.name, defaultValues.user, setValue]);

  const onInputChange = (e:any, value: any) => {
    setInputUser(value);
  }

  useEffect(() => {
    // se valida si existe un timeout para eliminarlo si se digita nuevamente 
    if(timeoutUser)
      clearTimeout(timeoutUser);

    // se crea un timeout de 1 segundo y medio para esperar a que digiten mas caracteres
    const timer = setTimeout(() => {
      handleFetchData(0,inputUser); 
    }, 1000);

    // Se actualiza el timeout
    setTimeoutUser(timer);
  }, [inputUser]);
  

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "update"
          ? t("updateApartment")
          : t("addApartment")}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={'name'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={t("name")}
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='name'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('name')}
                    error={errors.name && Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>               
              <Controller
                    name={"status"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl variant="standard"  fullWidth>
                        <InputLabel id="simple-select-standard-label-apartment">{t("state")}</InputLabel>
                        <Select
                          labelId="simple-select-standard-label-apartment"
                          label={t("state")}
                          variant="standard"
                          value={value}
                          {...register("status")}
                        >
                          <MenuItem value="0">{t("free")}</MenuItem>
                          <MenuItem value="1">{t("withOwner")}</MenuItem>
                          <MenuItem value="2">{t("withTenant")}</MenuItem>
                          <MenuItem value="3">{t("inConstruction")}</MenuItem>
                        </Select>
                      </FormControl>
                    )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name='user'
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    value={value}
                    inputValue={inputUser}
                    onInputChange={onInputChange}
                    options={userData.content ? userData.content : [] }
                    getOptionLabel={(option: any) => (option.firstName?option.firstName:'')+' '+(option.lastName?option.lastName:'') || ''}
                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                    //@ts-ignore
                    renderInput={params => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = 'new-password';
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label={t("user")}
                          variant='standard'
                          sx={{ '& input': { pl: '0!important' } }}
                          error={!!errors.user}
                          helperText={errors.user && 'Seleccionar usuario!'}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          {t("save")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          {t("cancel")}
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateApartmentsModal;
