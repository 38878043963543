import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { deleteDocuments } from "../../services/booking/BookingService";
import { properties } from "../../utils/Properties_es";
import DeleteModal from "./DeleteModal";
import { useTranslation } from "react-i18next";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "14px",
  display: "flex",
  justifyContent: "space-between",
  paddingRight: "20px",
  marginBottom: "10px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
  paddingBottom: "2",
}));

interface AddOrUpdateBooking {
  id?: number;
  name: string;
  description: string;
  capacity: number;
  bookingTime: number;
  status?: {
    id?: number;
    name?: string;
  };
  logo?: File[];
  documents?: File[];
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  filteredPhotos: any;
  logosByArea: any;
  refreshData: any;
  setFilteredPhotos: any;
  cancelModal: any;
  modalType?: any;
}

const AddOrUpdateBookingModal = ({
  actionButton,
  data,
  onSubmit,
  filteredPhotos,
  logosByArea,
  refreshData,
  setFilteredPhotos,
  cancelModal,
  modalType,
}: DialogProps) => {
  const { t } = useTranslation();
  const validation = Yup.object().shape({
    description: Yup.string(),
    bookingTime: Yup.mixed() // Cambiado a mixed para permitir flexibilidad en el tipo de dato
    .transform((value, originalValue) => {
      // Si es una cadena vacía, conviértela en null
      return originalValue === '' ? null : value;
    })
    .nullable()
    .typeError("El campo debe ser numérico") // Agregamos el typeError aquí
    .required("Campo es requerido"),
    status: Yup.object().shape({
      id: Yup.number()
        .required("Campo es requerido")
        .typeError("El campo debe ser numérico"),
    }),
  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    name: data.name ? data.name : "",
    description: data.description ? data.description : "",
    capacity: data.capacity ? data.capacity : 0,
    bookingTime: data.bookingTime ? data.bookingTime : 0,
    status: data
      ? { id: data.status, name: data.status === 0 ? "Activo" : "Inactivo" }
      : { id: 0, name: "Activo" },
    logo: [] as File[],
    documents: [] as File[],
  };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateBooking>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });
  const { authInfo, setLoading, setErrorMsg, modalData, setSuccessMsg } =
    useApp();

  const [files, setFiles] = useState<File[]>(
    filteredPhotos ? [...filteredPhotos] : []
  );
  //const [clonedFilteredPhotos, setClonedFilteredPhotos] = useState([...filteredPhotos]);
  const [enlargedImageUrl, setEnlargedImageUrl] = useState<string>("");
  const [isEnlargedImageVisible, setIsEnlargedImageVisible] =
    useState<boolean>(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isLogoDeleteModalOpen, setLogoDeleteModalOpen] = useState(false);
  const [logoFileName, setLogoFileName] = useState("");
  const [isLogoSelected, setIsLogoSelected] = useState(false);
  const allowedExtensions = ["jpg", "jpeg", "png", "bmp"];

  const maxPhotos = 4;

  const addImageUrl = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/png, image/jpeg, image/jpg";
    input.onchange = (event) => handleFileChange(files.length, event);
    input.click();
  };

  const removeImageUrl = async (index: number) => {
    if (modalType === "create") {
      setFiles((prevFiles) => {
        const updatedFiles = [...prevFiles];
        updatedFiles.splice(index, 1);
        return updatedFiles;
      });
    } else {
      //loading
      setLoading && setLoading(true);
      try {
        //call service
        const deletePhoto = await deleteDocuments(
          filteredPhotos[index].id,
          authInfo.username
        );
        if (!deletePhoto) {
          setErrorMsg && setErrorMsg(t("ErrorRemovingInstallation"));
          setLoading && setLoading(false);
          return;
        }
        setLoading && setLoading(false);
        setSuccessMsg && setSuccessMsg(t("RecordDeleted"));
        refreshData(0, "");
      } catch (error: any) {
        setErrorMsg && setErrorMsg(error.message);
        setLoading && setLoading(false);
      }
    }
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const removeImageUrlLogo = async (index: any) => {
    //loading
    setLoading && setLoading(true);
    try {
      //call service
      const deletePhoto = await deleteDocuments(index, authInfo.username);
      if (!deletePhoto) {
        setErrorMsg && setErrorMsg(t("ErrorRemovingInstallation"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(t("RecordDeleted"));
      refreshData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleFileChange = (index: number, event: any) => {
    const files: any = event.target.files;
    if (!files || files.length === 0) {
      return;
    }

    console.log(files);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        Swal.fire({
          icon: "error",
          title: `${t("invalidFile")}`,
          text: `${t("FileExtNotAllowed")}`,
          confirmButtonColor: "#0A3A7A",
        });
        return;
      }

      if (file.size > 2000000) {
        Swal.fire({
          icon: "error",
          title: `${t("invalidFile")}`,
          text: `${t("FileSizeLess2MB")}`,
          confirmButtonColor: "#0A3A7A",
        });
        return;
      }
    }

    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles[index] = files[0];
      return updatedFiles;
    });

    setValue(`documents.${index}`, files[0]);
  };



  const handleFileChange2 = (index: number, event: any) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }

    const fileExtension = files[0].name.split(".").pop()?.toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      Swal.fire({
        icon: "error",
        title: `${t("invalidFile")}`,
        text: `${t("FileExtNotAllowed")}`,
        confirmButtonColor: "#0A3A7A",
      });
      return;
    }

    if (files[0].size > 2000000) {
      Swal.fire({
        icon: "error",
        title: `${t("invalidFile")}`,
        text: `${t("FileSizeLess2MB")}`,
        confirmButtonColor: "#0A3A7A",
      });
      return;
    }

    const updatedFiles = [...files];
    updatedFiles[index] = files[0];
    setLogoFileName(files[0].name);

    //setFiles(updatedFiles);
    setValue(`logo.${index}`, files[0]);
    setIsLogoSelected(true);
  };

  const showEnlargedImage = (imageUrl: string) => {
    setEnlargedImageUrl(imageUrl);
    setIsEnlargedImageVisible(true);
  };

  const hideEnlargedImage = () => {
    setEnlargedImageUrl("");
    setIsEnlargedImageVisible(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleOpenLogoDeleteModal = () => {
    setLogoDeleteModalOpen(true);
  };

  const handleCloseLogoDeleteModal = () => {
    setLogoDeleteModalOpen(false);
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "update" ? t("editInstallation") : t("addInstallation")}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0, maxWidth: "sm" }}>
        <form>
          <Grid container spacing={4} maxWidth="sm" sx={{ pt: 2, pb: 4 }}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                component="div"
                sx={{ mb: 2, fontSize: "16px" }}
              >
                {t("name")}
              </Typography>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("name")}
                    error={errors.name && Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                component="div"
                sx={{ mb: 2, fontSize: "16px" }}
              >
                {t("description")}
              </Typography>
              <Controller
                name={"description"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="description"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("description")}
                    error={errors.description && Boolean(errors.description)}
                    helperText={
                      errors.description && errors.description.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                component="div"
                sx={{ mb: 2, fontSize: "16px" }}
              >
                {t("state")}
              </Typography>
              <Controller
                control={control}
                name="status"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={[
                      { id: 0, name: t("active") },
                      { id: 1, name: t("idle") },
                    ]}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.status}
                          helperText={errors.status && errors.status.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                component="div"
                sx={{ mb: 2, fontSize: "16px" }}
              >
                {t("ability")}
              </Typography>
              <Controller
                name={"capacity"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="capacity"
                    sx={{ "& input": { pl: "0!important" } }}
                    onChange={(e) => {
                      let newValue = e.target.value.trim();
                      newValue = newValue.replace(/[^0-9]/g, '');
                      onChange(newValue);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                    error={errors.capacity && Boolean(errors.capacity)}
                    helperText={errors.capacity && errors.capacity.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                component="div"
                sx={{ mb: 2, fontSize: "16px" }}
              >
                {t("bookingTime")}
              </Typography>
              <Controller
                name={"bookingTime"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="bookingTime"
                    sx={{ "& input": { pl: "0!important" } }}
                    onChange={(e) => {
                      let newValue = e.target.value.trim();
                      newValue = newValue.replace(/[^0-9]/g, '');
                      onChange(newValue);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                    error={errors.bookingTime && Boolean(errors.bookingTime)}
                    helperText={errors.bookingTime && errors.bookingTime.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                size="small"
                sx={{ marginTop: "8px", marginBottom: "8px" }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ mb: 2, fontSize: "16px" }}
                >
                  {t("logo")}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                    alignContent: "space-between",
                    width: "auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "8px",
                      width: "180px",
                    }}
                  >
                    <input
                      id={`logo-upload`}
                      type="file"
                      onChange={(event) => handleFileChange2(0, event)}
                      accept="image/png, image/jpeg, image/jpg"
                      style={{ display: "none" }}
                    />
                    <Button
                      variant="outlined"
                      component="label"
                      htmlFor={`logo-upload`}
                      sx={{
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {t("selectLogo")}
                    </Button>
                  </div>
                  {!isLogoSelected &&
                    logosByArea &&
                    !!logosByArea[data.id]?.length && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "8px",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "15px", mt: 1 }}
                        >
                          {logosByArea[data.id][0].originalName}
                        </Typography>
                      </div>
                    )}
                  {!isLogoSelected &&
                    logosByArea &&
                    !!logosByArea[data.id]?.length &&
                    modalType !== "create" && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "8px",
                          flex: 1,
                        }}
                      >
                        <div style={{ marginRight: "8px" }}>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              showEnlargedImage(
                                `https://dispatcher.spacemanager.io/v1/documents/documents/view/${logosByArea[data.id][0].id
                                }?originalName=${logosByArea[data.id][0].originalName
                                }`
                              );
                            }}
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {t("viewImage")}
                          </a>
                        </div>
                      </div>
                    )}
                  {!isLogoSelected &&
                    logosByArea &&
                    !!logosByArea[data.id]?.length && (
                      <div style={{ marginRight: "-50px" }}>
                        <Dialog
                          open={isLogoDeleteModalOpen}
                          onClose={handleCloseLogoDeleteModal}
                          fullWidth
                        >
                          <DeleteModal
                            //@ts-ignore
                            textChildren={modalData?.modalObject?.username}
                            actionButton={
                              <>
                                {" "}
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  sx={{
                                    mt: 2,
                                    mr: 2,
                                  }}
                                  onClick={() => {
                                    handleCloseLogoDeleteModal();
                                    removeImageUrlLogo(
                                      logosByArea[data.id][0].id
                                    );
                                  }}
                                >
                                  {t("delete")}
                                </Button>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  sx={{
                                    mt: 2,
                                    mr: 2,
                                  }}
                                  onClick={() => {
                                    handleCloseLogoDeleteModal();
                                  }}
                                  autoFocus
                                >
                                  {t("cancel")}
                                </Button>{" "}
                              </>
                            }
                          />
                        </Dialog>
                        <IconButton
                          color="secondary"
                          onClick={handleOpenLogoDeleteModal}
                          style={{ marginLeft: "0px" }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </div>
                    )}
                  {logoFileName && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "8px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "15px", mt: 1 }}
                      >
                        {logoFileName}
                      </Typography>
                    </div>
                  )}
                </div>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                size="small"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ fontSize: "16px", ml: 0.5, mb: 2 }}
                >
                  {t("instPhotos")}
                </Typography>
                {files.map((file: any, index: any) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                      alignContent: "space-between",
                      width: "auto",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "8px",
                        width: "180px",
                      }}
                    >
                      <input
                        id={`photo-upload-${index}`}
                        type="file"
                        onChange={(event) => handleFileChange(index, event)}
                        accept="image/png, image/jpeg, image/jpg"
                        style={{ display: "none" }}
                      />
                      <Button
                        variant="outlined"
                        component="label"
                        htmlFor={`photo-upload-${index}`}
                        sx={{
                          width: "100%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {t("selectImage")}
                      </Button>
                    </div>
                    {file && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "8px",
                          width: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            maxWidth: 150,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize: "15px",
                          }}
                        >
                          {file.originalName}
                        </Typography>
                      </div>
                    )}
                    {file.id && modalType !== "create" && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "30px",
                          flex: 1,
                        }}
                      >
                        <div style={{ marginRight: "8px" }}>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              showEnlargedImage(
                                `https://dispatcher.spacemanager.io/v1/documents/documents/view/${file.id}?originalName=${file.originalName}`
                              );
                            }}
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {t("viewImage")}
                          </a>
                        </div>
                      </div>
                    )}
                    {file.id && (
                      <div style={{ marginRight: "-50px" }}>
                        <Dialog
                          open={isDeleteModalOpen}
                          onClose={handleCloseDeleteModal}
                          fullWidth
                        >
                          <DeleteModal
                            //@ts-ignore
                            textChildren={modalData?.modalObject?.username}
                            actionButton={
                              <>
                                {" "}
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  sx={{
                                    mt: 2,
                                    mr: 2,
                                  }}
                                  onClick={() => {
                                    removeImageUrl(index);
                                    handleCloseDeleteModal();
                                  }}
                                >
                                  {t("ok")}
                                </Button>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  sx={{
                                    mt: 2,
                                    mr: 2,
                                  }}
                                  onClick={() => {
                                    handleCloseDeleteModal();
                                  }}
                                  autoFocus
                                >
                                  {t("cancel")}
                                </Button>{" "}
                              </>
                            }
                          />
                        </Dialog>
                        <IconButton
                          color="secondary"
                          onClick={handleOpenDeleteModal}
                          style={{ marginLeft: "0px" }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </div>
                    )}
                    {files[index] && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          marginLeft: "-72px",
                        }}
                      >
                        <Typography variant="body2" sx={{ fontSize: "15px" }}>
                          {files[index].name}
                        </Typography>
                      </div>
                    )}
                  </div>
                ))}
                {files.length < maxPhotos && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    <IconButton
                      color="primary"
                      onClick={addImageUrl}
                      disabled={files.length >= maxPhotos}
                    >
                      <AddIcon fontSize="small" />
                    </IconButton>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "15px", ml: 0.5 }}
                    >
                      {t("addImage")}
                    </Typography>
                  </div>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      {isEnlargedImageVisible && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={hideEnlargedImage}
        >
          <img
            src={enlargedImageUrl}
            alt="Enlarged"
            style={{
              width: "100%",
              height: "auto",
              display: "block",
              margin: "auto",
              maxWidth: "600px",
              maxHeight: "400px",
            }}
          />
        </div>
      )}
      <DialogActions sx={{ pb: 1, px: 1 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          {t("save")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          {t("cancel")}
        </Button>{" "}
      </DialogActions>
    </>
  );
};

export default AddOrUpdateBookingModal;
