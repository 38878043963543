export interface InputBookingApproval {
    id: number;
    message: string;
    user: string;
    status: boolean;
}

export interface BookingResponse {
    content: BookingDataContent[];
    pageable: Pageable;
    totalPages: number;
    totalElements: number;
    last: boolean;
    numberOfElements: number;
    size: number;
    number: number;
    sort: Sort[];
    first: boolean;
    empty: boolean;
}

export interface BookingDataContent {
    id: number;
    userCreated: string;
    creationDate: Date;
    userModified: null;
    modificationDate: null;
    companyId: number;
    associatedNumber: null;
    totalAmount: number;
    name: string;
    lastName: string;
    email: string;
    phone: string;
    payMethod: PayMethod;
    reference: null;
    approvedBy: null;
    expirationDate: string;
    cvv: null;
    status: Status;
    requestDate: Date;
    description: null;
    bookingDetailList: BookingDetailList[];
}

export interface BookingDetailList {
    id: number;
    userCreated: string;
    creationDate: Date;
    userModified: null;
    modificationDate: null;
    idBooking: number;
    idAreaDetail: number;
    areaAmount: number;
    totalHours: number;
    startTime: string;
    endTime: string;
    bookingDay: Date;
    fullDay: boolean;
    expirationDate: null;
    name: string;
}

export type PayMethod = "DEPOSITO";

export type Status = "PEN";

export interface Pageable {
    sort: Sort[];
    pageSize: number;
    pageNumber: number;
    offset: number;
    paged: boolean;
    unpaged: boolean;
}

export interface Sort {
    direction: string;
    property: string;
    ignoreCase: boolean;
    nullHandling: string;
    descending: boolean;
    ascending: boolean;
}

export enum StatusSearchBooking {
    PEN = 2,
    APR = 3,
    REJ = 4,
    EXP = 5,
}

export interface InputBookingSearch {
    status: StatusSearchBooking;
    filter?: string;
    area?: number | null;
}

export interface ResponseApproval {
    result: boolean;
    message: string;
}

export enum StatusSearchApartment {
    ALL = '',
    FREE = '0',
    WITH_OWNER = '1',
    WITH_TENANT = '2',
    UNDER_CONSTRUCTION = '3'
}

export enum StatusSearchApartmentPendings {
    PENDING = '0',
    VALIDATING = '1',
    APPLIED = '2'
}

export interface BookingPaymetReportRespose {
    content: BookingPaymetReport[];
    pageable: Pageable;
    totalPages: number;
    totalElements: number;
    last: boolean;
    numberOfElements: number;
    size: number;
    number: number;
    sort: Sort[];
    first: boolean;
    empty: boolean;
}
export interface BookingPaymetReport {
    id: number;
    amount:number;
    payMethod: string;
    installation: string;
    datePayment: Date;
}