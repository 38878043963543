import { Box, Paper, Typography, Divider, useTheme } from "@mui/material";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const FancyPaper = (props: any) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        p: {
          xs: 5,
          md: `${theme.spacing(12)} ${theme.spacing(12)} ${theme.spacing(6)}`,
        },
      }}
    >
      <Typography
        variant="caption"
        color="info.dark"
        onClick={() => navigate("/dashboard/post")}
        sx={{
          cursor: "pointer",
          "&:hover": {
            color: theme.palette.primary.main,
          },
        }}
      >
        {t("home")}{" "}
      </Typography>
      {props.prevPage && props.linkPrevPage && (
        <Typography
          variant="caption"
          color="info.dark"
          onClick={() => navigate(props.linkPrevPage)}
          sx={{
            cursor: "pointer",
            "&:hover": {
              color: theme.palette.primary.main,
            },
          }}
        >
          {"/"} {props.prevPage}
        </Typography>
      )}
      <Typography variant="caption" color="text.primary">
        {"/ "} {props.pagetitle}
      </Typography>

      <Divider />
      <Paper
        sx={{
          pt: 5,
        }}
        {...props}
      >
        {props.children}
      </Paper>
    </Box>
  );
};

export default FancyPaper;
