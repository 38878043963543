import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import { formatDate } from "../../utils/UtilsFunctions";
import UrlBase from "../../url/Urls";
import { newsResponse } from "./NewsInterface";

export const getAllNews = async (
  page: number,
  companyId: number,
  filter: string,
  status?: string
) => {
  try {
    const response = await privateFetch.get(
      URL.REQUEST_NEWS_FILTER(
        page,
        UrlBase.registration_x_page,
        companyId,
        filter,
        status
      )
    );
    const responseData = response.data;

    return responseData;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const addNews = async (option: string, data: newsResponse) => {
  let responseData = {};
  await privateFetch
    .post(URL.REQUEST_NEWS(option), data)
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const updateNews = async (option: string, data: newsResponse) => {
  console.log("dataUPD: ", data);
  try {
    const response = await privateFetch.put(URL.REQUEST_NEWS(option), data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || "Error desconocido";
    throw errorMessage;
  }
};

export const deleteNews = async (option: string) => {
  try {
    const response = await privateFetch.delete(URL.REQUEST_NEWS(option));
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || "Error desconocido";
    throw errorMessage;
  }
};
