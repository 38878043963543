/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FancyPaper from "../components/FancyPaper";
import ResourceAccess from "../components/security/ResourceAccess";
import {
  PenIcon,
  PlusIcon,
  TrashIcon
} from "../components/svgicons/SvgIcons";
import AddOrUpdateAssociatedModal from "../components/utils/AddOrUpdateAssociatedModal";
import DeleteModal from "../components/utils/DeleteModal";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import FancyTooltip from "../components/utils/FancyTooltip";
import { useApp } from "../hooks/useApp";
import { createAssociated, deleteAssociated, getAllAssociated, getAssociated, updateAssociated } from "../services/associated/AssociatedService";
import { properties } from "../utils/Properties_es";
import { useTranslation } from "react-i18next";

const AssociatedManagment = () => {
  const { t } = useTranslation();
  const {
    authInfo,
    company,
    isAdmin,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const theme = useTheme();
    const [associatedData, setAssociatedData] = useState<any>([]);
    const [associatedAllData, setAssociatedAllData] = useState<any>([]);
  const initForm = {
    id: 0,
    adminLogin: "",
    clientLogin: "",
    description: "",
    name: "",
  };
  const [formData, setFormData] = useState<any>(initForm);
  const [page, setPage] = useState(0);
  const [preFilter, setPreFilter] = useState<string | undefined>('');
  const { handleSubmit } = useForm();

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "");
      await handleAssociates();
    };
    dataInit();

    // eslint-disable-next-line
  }, [company]);

  /**
   * Método encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (
    currentPage: number,
    filter?: string,
    applyRole?: any
  ) => {
    setLoading && setLoading(true);
    setAssociatedData([]);
    try {
      //setPage(currentPage);

      let data = await getAllAssociated(currentPage,isAdmin && company ? company: authInfo.companyId);

      console.log(data)
      if (data) {
        setAssociatedData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const handleAssociates = async () => {
    setLoading && setLoading(true);
    setAssociatedAllData([]);
    try {
      //setPage(currentPage);

      let data = await getAssociated(isAdmin && company ? company: authInfo.companyId);

      console.log(data)
      if (data) {
        setAssociatedAllData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };
  /**
   * Método encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let createData = await createAssociated({
        ...data,
        company: isAdmin ? company: authInfo.companyId,
        status: data.status.id,
        userCreated: authInfo?.username,
      });

      if (!createData) {
        setErrorMsg && setErrorMsg(t("errorCreatingRecord"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
      setSuccessMsg(t("successfullyCreatedRecord"));
      //call fetch data
      await handleFetchData(0,"");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error);
      setLoading && setLoading(false);
    }
  };

  /**
   * Método encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    console.log("data = ", data)
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let updateData = await updateAssociated(
        data.id,
        {
        ...data,
        status: data.status.id,
        userModified: authInfo?.username,
      });

      if (!updateData) {
        setErrorMsg &&
        setErrorMsg(t("errorUpdatingRecord"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
      setSuccessMsg(t("recordUpdatedCorrectly"));
      //call fetch data
      await handleFetchData(0,"");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteAssociated(id);
      if (!deleteData) {
        setErrorMsg &&
        setErrorMsg(t("errorDeletingRecord"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
      setSuccessMsg(t("recordDeletedSuccessfully"));

      //reset page and call fetch data
      await handleFetchData(0,"");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);  
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");
    console.log("modalAction = ", modalAction);
    if (modalAction === "update") {
      object = associatedData.content.find((p: any) => p.id === parseInt(id));
    }

    if (modalAction === "delete") {
      object = associatedData.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  /**
   * @param event
   */
  const handleCancelModal = () => {
    setFormData(initForm);
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    const customPage = newPage - 1;
    setPage(customPage);
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
  };

  return (
    <>
      <FancyPaper pagetitle={`${t("maintenance")} / ${t("associates")}`}>
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={9} sm={6} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={"ROLE:WRITE"}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                data-name="create"
              >
                {t("addAssociate")}
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </ResourceAccess>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t("id")}</TableCell>
                <TableCell>{t("fullName")}</TableCell>
                <TableCell align="center">{t("identification")}</TableCell>
                <TableCell align="center">
                {t("associateNumber")}
                </TableCell>
                <TableCell align="center">{t("email")}</TableCell>
                <TableCell align="center">{t("phone")}</TableCell>
                <TableCell align="center">{t("country")}</TableCell>
                <TableCell align="center">{t("state")}</TableCell>
                <TableCell align="center">{t("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {associatedData &&
              associatedData.content &&
                associatedData.content.map((row: any, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell align="center">{row.identification}</TableCell>
                    <TableCell align="center">
                      {row.associatedNumber}
                    </TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">{row.phone}</TableCell>
                    <TableCell align="center">{row.countries && row.countries.name}</TableCell>
                    <TableCell align="center">{row.status === 0 ? t("active") : t("idle")}</TableCell>

                    <TableCell align="center">
                      <ResourceAccess isCode={true} pathOrCode={"ROLE:WRITE"}>
                        <FancyTooltip title={t("edit")} placement="top">
                          <IconButton
                            aria-label="edit"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            onClick={handleOpenModal}
                            data-name="update"
                            data-id={row.id}
                          >
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>

                        <FancyTooltip title={t("delete")} placement="top">
                          <IconButton
                            aria-label="trash"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            data-id={row.id}
                            onClick={handleOpenModal}
                            data-name="delete"
                          >
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip>
                      </ResourceAccess>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={associatedData?.content?.length > 0 ? associatedData?.content?.length : 0}
          rowsPerPage={associatedData.size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={associatedData.totalElements}
          totalPages={associatedData.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddOrUpdateAssociatedModal
            data={modalData?.modalObject}
            associate={associatedAllData}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                 {t("delete")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  {t("cancel")}
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default AssociatedManagment;
