import React, { useState } from 'react';
import { Button, TextField, Typography } from '@mui/material'; // Import MUI components
import {
  CreatePaymentLinkRequest,
  uploadVoucherPayment
} from "../../services/payment/PaymentService"; // Assuming you're using Axios for HTTP requests

interface DialogProps {
  paymentData: CreatePaymentLinkRequest;
  paymentMethodId: number;
  amount: string;
  handleFetchData: any;
}

const UploadVoucherComponent = ({paymentData, paymentMethodId, amount, handleFetchData}: DialogProps) => {
  const [referenceNumber, setReferenceNumber] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('idle'); // 'idle', 'uploading', 'success', 'error'

  const handleReferenceNumberChange = (event: any) => {
    setReferenceNumber(event.target.value);
  };

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };


  const handleUpload = async () => {
    if (!referenceNumber || !selectedFile) {
      console.error('El número de referencia y el archivo son requeridos.');
      return;
    }

    setUploadStatus('uploading');

    try {
      const formData = new FormData();

      formData.append('companyId', `${paymentData.companyId}`);
      formData.append('userId', `${paymentData.userId}`);
      formData.append('apartmentId', `${paymentData.apartmentId}`);
      formData.append('pendingsToPay', `${paymentData.pendingsToPay}`);
      formData.append('paymentMethodId', `${paymentMethodId}`);
      formData.append('amount', `${amount}`);
      formData.append('reference', referenceNumber);
      formData.append('voucherFile', selectedFile);

      const response = await uploadVoucherPayment(formData);

      if (response.status === 200) {
        console.log('Upload successful!');
        setUploadStatus('success');
      } else {
        console.error('Upload failed:', response.data);
        setUploadStatus('error');
      }
      handleFetchData(0,"");
    } catch (error) {
      console.error('Upload error:', error);
      setUploadStatus('error');
    }
  };

  return (
      <div>
        <Typography variant="body1" gutterBottom>
         Enviar comprobante y número de referencia
        </Typography>
        <TextField
            id="referenceNumber"
            label="Número de referencia"
            variant="outlined"
            value={referenceNumber}
            onChange={handleReferenceNumberChange}
            fullWidth
            margin="normal"
        />
        <Button
            variant="contained"
            component="label"
            onChange={handleFileChange}
            style={{
              border: '1px solid red',
              margin: '0 0 25px 0'
            }}
            fullWidth
        >
          Seleccionar archivo
          <input type="file" hidden />
        </Button>
        <Button variant="contained" disabled={uploadStatus === 'uploading'} onClick={handleUpload} fullWidth >
          {uploadStatus === 'uploading' ? 'Enviando...' : 'Enviar comprobante'}
        </Button>
        {uploadStatus === 'success' && (
            <Typography variant="body2" color="green">
              Envío exitoso.
            </Typography>
        )}
        {uploadStatus === 'error' && (
            <Typography variant="body2" color="error">
              Error al enviar comprobante, Intente nuevamente.
            </Typography>
        )}
      </div>
  );
};

export default UploadVoucherComponent;
