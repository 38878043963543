import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import youtube1 from "../../resources/images/guide-youtube/1-youtube.jpg";
import { useTranslation } from "react-i18next";

export interface DialogProps {
  cancelModal: any;
}

const Image = styled("img")(() => ({
  width: "100%",
}));

const GuideIDVideoYoutube = ({ cancelModal }: DialogProps) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle>Instrucciones para cargar video de youtube</DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <List>
          <ListItem>
            <ListItemText>
              1. Ir a la página de youtube, buscar el video a cargar.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              2. Una vez tengas el video, la ID es la que se muestra en la barra
              superior del navegador.
              <Image
                srcSet={`${youtube1}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                src={`${youtube1}?w=164&h=164&fit=crop&auto=format`}
                alt="Barra de url youtube"
                loading="lazy"
              />
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              3. Ese ID <b>jmyZ1VGbzL4</b> es el que vas a pegar en el campo del
              formulario para la carga del video.
            </ListItemText>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          {t("cancel")}
        </Button>{" "}
      </DialogActions>
    </>
  );
};

export default GuideIDVideoYoutube;
