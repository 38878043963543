import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AcceptOrRejectBookingInterface {
  id?: number;
  message?: string;
}

interface DialogProps {
  data: any;
  onSubmit: any;
  openModalReject: boolean;
  handleCancelModalReject: () => void;
}

const RejectBookingModal = ({
  data,
  onSubmit,
  openModalReject,
  handleCancelModalReject,
}: DialogProps) => {
  const { t } = useTranslation();
  // Validación de datos
  const validation = Yup.object().shape({
    message: Yup.string().required(t("fieldRequired")),
  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    bookingDetailList: data.bookingDetailList ? data.bookingDetailList : [],
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<AcceptOrRejectBookingInterface>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const onSubmitForm = (data: any) => {
    onSubmit({
      id: data.id,
      bookingDetailList: data.bookingDetailList,
      status: false,
      message: data.message,
    });
    setValue("message", "");
  };

  // Usar watch para acceder a los valores del formulario
  const messageValue = watch("message", "");

  // Función para obtener la longitud del valor del campo "message"
  const getMessageLength = () => {
    return messageValue?.length || 0;
  };

  return (
    <>
      <Dialog
        open={openModalReject}
        onClose={handleCancelModalReject}
        fullWidth
      >
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
            {t("rejectReservation")}
          </DialogTitle>
          <DialogContent sx={{ px: 4, pb: 0 }}>
            <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    textDecoration: "underline",
                    fontSize: "18px",
                  }}
                >
                  <b>{t("reservationData")}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  label={t("reasonForRejection")}
                  type="text"
                  variant="standard"
                  autoComplete="message"
                  inputProps={{ maxLength: 100 }} // Establecer longitud máxima
                  sx={{ "& input": { pl: "0!important" } }}
                  {...register("message")}
                  error={errors.message && Boolean(errors.message)}
                  helperText={errors.message && errors.message.message}
                />
                <Typography variant="caption" color="textSecondary">
                {t("currentLength")}: {getMessageLength()} /  {t("100Characters")} ({t("maximum100Characters")})
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <Actions sx={{ pb: 4, px: 4 }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                mt: 2,
                mr: 2,
              }}
            >
             {t("reject")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                mt: 2,
                mr: 2,
              }}
              onClick={handleCancelModalReject}
            >
              {t("cancel")}
            </Button>
          </Actions>
        </form>
      </Dialog>
    </>
  );
};

export default RejectBookingModal;
