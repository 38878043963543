import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router";
import {
  Grid,
  useTheme,
  Button,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import { useApp } from "../../hooks/useApp";
import { useForm } from "react-hook-form";
import FancyPaper from "../../components/FancyPaper";
import PostCard from "../../components/PostCard";
import ResourceAccess from "../../components/security/ResourceAccess";
import { post } from "./dataPost";
import { AddOrUpdatePostCardType, PostListType } from "../../types/Post";
import DeleteModal from "../../components/utils/DeleteModal";
import { useTranslation } from "react-i18next";
import { getAllNews } from "../../services/news/NewsServices";

const DashboardPost = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    setLoading,
    setErrorMsg,
    setSuccessMsg,
    authInfo,
    modalData,
    setModalData,
    company,
  } = useApp();
  const { handleSubmit } = useForm();
  const [page, setPage] = useState<number>(0);
  const [sort, setSort] = useState<string>("ASC");
  const [filter, setFilter] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [postData, setPostData] = useState<PostListType>(post);
  const [selectedPost, setSelectedPost] = useState<AddOrUpdatePostCardType | null>(null);

  useEffect(() => {
    const getData = async () => {
      if (company !== null) {
        await handleFetch(page, sort, company, filter, status);
      }
    };
    getData();
  }, [company]);

  const handleFetch = async (
    page: number,
    sort: string,
    companyId: number,
    filter: string,
    status?: string
  ) => {
    try {
      let data: any = await getAllNews(page, companyId, filter, status);
      if (data) {
        setPostData(data);
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error);
    }
  };

  const handleSelectPost = (id: number) => {
    const selected = postData?.content?.find((p: any) => p.id === id);
    setSelectedPost(selected || null);
  };

  const handleDeselectPost = () => {
    setSelectedPost(null);
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = post.filter((item: any) => item.id !== id);
      if (!deleteData) {
        setErrorMsg && setErrorMsg(t("errorDeletingNews"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(t("newsSuccessfullyDeleted"));

      //reset page and call fetch data
      setPostData(deleteData);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    console.log(data);

    switch (modalData?.modalType) {
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <FancyPaper pagetitle={t("blog")}>
        <Grid container spacing={8}>
          <ResourceAccess isCode={true} pathOrCode={"POST:READ"}>
            {selectedPost ? (
              <>
                <Grid item xs={12}>
                  <div style={{ marginBottom: 16, marginTop: 16, textAlign: 'left' }}>
                    <Button variant="contained" color="primary" onClick={handleDeselectPost}>
                      {t("back")}
                    </Button>
                  </div>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid item xs={12} sm={8}>
                    <PostCard
                      id={selectedPost.id}
                      date={selectedPost.date}
                      title={selectedPost.title}
                      description={selectedPost.description}
                      idImagen={selectedPost.idImagen}
                      id_video={selectedPost.id_video}
                      idVideo={selectedPost.idVideo}
                      //onClick={handleDeselectPost}
                      imageExpanded={true}
                      category={[]}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              postData?.content?.length > 0 ? (
                postData?.content?.map((item: AddOrUpdatePostCardType, i: number) => (
                  <Grid item xs={12} sm={6} key={i}>
                    <PostCard
                      id={item.id}
                      //author={item.author}
                      date={item.date}
                      title={item.title}
                      description={item.description}
                      idImagen={item.idImagen}
                      id_video={item.id_video}
                      idVideo={item.idVideo}
                      onClick={() => handleSelectPost(item.id)}
                      category={[]}
                    />
                    {/* <Button variant="outlined" onClick={() => handleSelectPost(item.id)}>
                      {t("view")}
                    </Button> */}
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ textAlign: "center", mt: 20 }}>
                    {t("noNews")}
                  </Typography>
                </Grid>
              )
            )}
          </ResourceAccess>
        </Grid>
      </FancyPaper>
      {modalData !== null && modalData?.modalType === "view" && (
        <Dialog
          open={modalData?.modalOpen ?? false}
          onClose={handleCancelModal}
          fullWidth
          scroll="paper"
        >
          <DialogContent dividers={true}>
            <PostCard
              id={modalData?.modalObject?.id}
              date={modalData?.modalObject?.date}
              title={modalData?.modalObject?.title}
              description={modalData?.modalObject?.description}
              idImagen={modalData?.modalObject?.idImagen}
              idVideo={modalData?.modalObject?.idVideo}
              category={[]}
            />
          </DialogContent>
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2, mr: 2 }}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("delete")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 2 }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  {t("cancel")}
                </Button>
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default DashboardPost;
