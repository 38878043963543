import {
  Button, DialogActions, DialogContent, DialogTitle, Grid,
  styled, IconButton, Table, TableBody, TableCell, TableHead,
  TableRow
} from "@mui/material";
import React, {useEffect, useState} from "react";
import theme from "../../theme/theme";
import {TrashIcon} from "../svgicons/SvgIcons";
import FancyTooltip from "./FancyTooltip";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import PaymentMethods from "../payments/PaymentMethods";

const Title = styled(DialogTitle)(({theme}) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({theme}) => ({
  borderTop: "1px solid #dee2e6",
}));

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  company: number;
  handleFetchData: any;
}

const PayApartmentPendingModal = ({
                                    data,
                                    onSubmit,
                                    cancelModal,
                                    company,
                                    handleFetchData
                                  }: DialogProps) => {
  const {t} = useTranslation();
  // Inicializar useForm con validación y valores predeterminados
  const {
    handleSubmit,
    formState: {errors},
  } = useForm<any>({
    mode: "onChange",
  });



  const [pendingData, setPendingData] = useState<any>([]);

  const removeFields = (index: number) => {
    let data = [...pendingData];
    data.splice(index, 1)
    setPendingData(data)
  }

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      setPendingData(data.pending);
    };
    dataInit();
  }, [company]);


  const formatNumber = (value: string) => {
    // Eliminar cualquier separador de miles antes de aplicar el nuevo valor
    const normalizedValue = (value || "").toString().replace(/,/g, "");
    // Aplicar el formato con separadores de miles
    return normalizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const formatterCurrency = (row: any) => {
    const symbolCurrency = row?.currency?.id && row?.currency?.symbol ? row?.currency?.symbol + ' ' : '';
    return `${symbolCurrency} ${formatNumber(row.amount)}`;
  };


  return (
      <>
        <Title id="alert-dialog-title" sx={{pt: 4, px: 4}}>
          {t("payPending")}
        </Title>
        <DialogContent sx={{px: 4, pb: 0}}>
          <form>
            <Grid container spacing={3} maxWidth="lg" sx={{pt: 4, pb: 8}}>
              <Grid item xs={12}>

                <Table sx={{minWidth: 450}} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"> {t("apartment")}</TableCell>
                      <TableCell align="center"> {t("title")}</TableCell>
                      <TableCell align="center"> {t("expirationDate")}</TableCell>
                      <TableCell align="center"> {t("amount")}</TableCell>
                      <TableCell align="center"> {t("actions")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pendingData && pendingData.map((row: any, i: number) => {
                      return (<TableRow>
                            <TableCell align="center">{row.apartment.name}</TableCell>
                            <TableCell align="center">{row.title}</TableCell>
                            <TableCell
                                align="center">{row.expirationDate.substring(0, 10)}</TableCell>
                            <TableCell align="center">{formatterCurrency(row)}</TableCell>
                            <TableCell align="center">
                              <FancyTooltip title={t("delete")} placement="top">

                                <IconButton
                                    aria-label="trash"
                                    component="label"
                                    color="secondary"
                                    sx={{
                                      "&:hover": {
                                        color: theme.palette.secondary.dark,
                                      },
                                    }}
                                    data-id={row.id}
                                    onClick={() => removeFields(i)}
                                    data-name="delete"
                                >
                                  <TrashIcon/>
                                </IconButton>
                              </FancyTooltip>
                            </TableCell>
                          </TableRow>
                      );
                    })}
                    <TableRow style={{
                      backgroundColor: '#e1e8ee',
                      color: '#3e627c',
                      fontFamily: 'D-dinCondesed',
                      fontWeight: '700'
                    }}>
                      <TableCell align="center" component="th" scope="row"
                                 colSpan={3}>Total</TableCell>
                      <TableCell
                          align="center">{formatNumber(pendingData.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.amount, 0))}</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={12}>
                <PaymentMethods  data={data} company={company} handleFetchData={handleFetchData} />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <Actions sx={{pb: 4, px: 4}}>
          <Button
              variant="contained"
              color="secondary"
              sx={{
                mt: 2,
                mr: 2,
              }}
              onClick={cancelModal}
              autoFocus
          >
            {t("cancel")}
          </Button>{" "}
        </Actions>
      </>
  );
};

export default PayApartmentPendingModal;
