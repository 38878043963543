import { createTheme  } from '@mui/material/styles'

let theme = createTheme()

interface customGrey {
  greyDue: {
    main: string
    secondary?: string
    light?: string
    dark?: string
  }
}

declare module '@mui/material/styles' {
  interface Palette extends customGrey {}
  interface PaletteOptions extends customGrey {}
}


theme = createTheme({
  palette: {
    primary: {
      main: '#0A3A7A', 
      light: '#00aae4', 
      dark: '#00aae4',
      contrastText: '#FFFFFF',   
    },
    secondary: {
      main: '#0A3A7A', 
      light: '#00aae4', 
      dark: '#00aae4',
      contrastText: '#ffffff',   
    },
    info: {
      main: '#43a491',
      light: '#FFFFFF',   
      dark: '##B5B2B2',
      contrastText: '#ffffff',   
    },
    greyDue: {
      main: '#0A3A7A',
      dark: '#212529',
      light: '#ABABAB',   
    },
    success: {
      main: '#d32f2f',
    },
    text: {
      primary: '#5C5D60',
      secondary: '#888b8e',     
    },    
    /* background: {
      default: '#f6f6f6',
    } */
  },
  typography: {
    fontFamily: 'D-din',
    h1: {
      fontSize: '3.5125rem',
      fontWeight: 700,
      fontFamily: 'D-din',
    },
    h2: {
      fontSize: '2.7125rem',
      fontWeight: 700,
      fontFamily: 'D-dinCondesed',
    },
    h3: {
      fontSize: '1.3125rem'
    },
    h5: {
      fontSize: '1.3125rem',
      fontWeight: 700,
      fontFamily: 'D-dinCondesed',
    },
    button: {
      textTransform: 'none',
      fontSize: '1rem',
    },
    subtitle1: {
      fontSize: '1rem',
    },
    body1: {
      //fontSize: '1rem',
      fontSize: '.8125rem',
    },
    caption: {
      fontSize: '.8125rem'
    }
  },
  spacing: 4,
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: theme.palette.primary.contrastText,
          color: '#5d5e60',
          
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#e4e8ed'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          boxShadow: 'none',
          borderRight: '1px solid #e4e8ed'
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1rem'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '14px',
          marginRight: '0.75rem'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: '0',
          marginBottom: '0',
          color: '#A2A2A2',            
          '& .MuiTypography-root': {
            fontFamily: 'D-dinExp',
            fontWeight: '400',      
          }  
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: '0',
          '&:hover': {
            backgroundColor: '#f8f9fa'  
          }              
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {         
          '& .MuiInput-underline:before': {
              borderBottom: "2px solid #CACACA",
          },
          '& label': {
            color: '#5d5e60',
          },
          '& .Mui-disabled': {
            paddingLeft: '0!important'
          }
        },        
      },
    },
    MuiInput:{
      styleOverrides: {
        root: {
          '& .MuiInput-underline:before': {
            borderBottom: "2px solid #0A3A7A",
          },
        },
        underline: {
          '&:before': {
            borderBottom: "2px solid #0A3A7A",
          },
        }
      }
    },
    MuiInputBase:{
      styleOverrides: {
        root: {    
          fontSize: "1rem",  
          '& .MuiInput-underline:before': {
            borderBottom: "2px solid #0A3A7A",
          },
          '& .MuiSelect-select': {
            paddingLeft: "0!important",
          },          
        },        
        input: {          
          padding: '6.765px 16px!important',
          '& .MuiInput-underline:before': {
            borderBottom: "2px solid #0A3A7A",
          },
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {  
          '&.Mui-focused': {
            '& fieldset': {
              borderWidth: '1px!important',
              borderColor: '#43a491!important'
            }            
          },
          '&:hover': {
            '& fieldset': {
              borderWidth: '1px',
              borderColor: '#43a491!important'
            }            
          },
        }  
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: { 
          '& .MuiOutlinedInput-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },
          '& .MuiInput-input': {
            paddingLeft: '0!important'
          }
        },
        endAdornment: {
          top: 'calc(50% - 10px)',          
        }, 
        popupIndicator: {          
          backgroundImage: 'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\'%3E%3Cpath fill=\'none\' stroke=\'%23343a40\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'2\' d=\'m2 5 6 6 6-6\'/%3E%3C/svg%3E")',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '16px 12px',
          '& svg': {
            opacity: 0
          }
        }     
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          color: '#0A3A7A'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {          
          //boxShadow: '0 0 .875rem 0 rgba(33,37,41,.05)',
          boxShadow: 'none',
          background: theme.palette.primary.contrastText,            
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          marginBottom: '1rem'
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child th, :last-child td': {
            borderBottom: '1px solid #dee2e6!important'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {          
          fontSize: '15px',
          //textAlign: 'center',
          color: '#86939e',
          fontFamily: 'D-din',
          padding: theme.spacing(1.5),
          borderBottom: '1px solid #dee2e6'
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#e1e8ee',  
          '& .MuiTableCell-head': {
            fontFamily: 'D-dinCondesed',
            color: '#3e627c',
            fontWeight: '700',
           
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '0px',
          color: '#d32f2f'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {        
        select: {          
          padding:' 4px 0 2px',          
          '& .MuiInput-underline:before': {
            borderBottom: "2px solid #CACACA",
          },
          '&.Mui-disabled': {
            WebkitTextFillColor: 'rgba(255, 255, 255, 0.8)',
          }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderWidth: '1px',
          borderRadius: '32px',
          '&.Mui-disabled': {
            color: 'inherit!important',
            opacity: '0.5'
          },
          '& .MuiTouchRipple-root': {
            display: 'none'
          }, 
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: { 
          borderRadius: '32px',     
          boxShadow: 'none!important', 
          fontWeight: 400,   
          '&.MuiButton-containedPrimary': {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: '#43a491', 
            padding: '1px 16px',
            '&:hover': {            
              background: '#00aae4',
              color: '#ffffff',
              borderColor: '#43a491'  
            }
          },  
          '&.MuiButton-containedSecondary': {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: '#BCBEC0', 
            padding: '1px 16px',
            '&:hover': {            
              //background: '#ffffff',
              //color: '#5C5D60',
              //borderColor: '#43a491'  
            }
          },
          
        }
      }    
    }, 
    MuiPaginationItem: {
      styleOverrides: {
        root: { 
          '&.Mui-selected:hover': {
            backgroundColor: '#00aae4'
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: "#e1e8ee",
          color: "#3e627c!important",
          fontFamily: "D-dinExp",
          fontWeight: 400,
          fontSize: "1.15rem",
          display: "flex",
          justifyContent: "flex-start",
          paddingRight: "20px",
          marginBottom: "10px",
          alignItems: "center",
          columnGap: "10px",
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          borderTop: "1px solid #dee2e6",
          paddingBottom: "2",
        }
      }
    }
  },
});

export default theme

