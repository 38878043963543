import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { TrashIcon } from "../../svgicons/SvgIcons";

const Text = styled(DialogContentText)(({ theme }) => ({
  color: theme.palette.greyDue.main,
  fontFamily: "D-dinExp",
  fontWeight: 400,
  textAlign: "center",
  fontSize: "17px",
  padding: theme.spacing(4),
}));

interface DialogProps {
  textTitle?: string;
  textChildren?: any;
  actionButton?: any;
  onSubmit?: any;
  cancelModal?: any;
}

const DeleteMessageModal = ({
  textTitle,
  actionButton,
  onSubmit,
  cancelModal,
}: DialogProps) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        <TrashIcon sx={{ fontSize: "1rem", width: 16, color: "#3e627c" }} />
        {t("deleteRecord")}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <Text id="alert-dialog-description">
          {t("areYouSureToDeleteMessage")}
        </Text>
      </DialogContent>
      <DialogActions sx={{ pb: 4, px: 4 }}>{actionButton}</DialogActions>
    </>
  );
};

export default DeleteMessageModal;
