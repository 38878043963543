import { privateFetch } from "../../utils/CustomFetch";
import { URL } from "../../utils/UrlConstants";
import { Content, MessageResponse } from "./MessagesInterface";


export const getAllMessages = async (
    page: number,
    size: number,
    companyId: number,
    adminType: boolean,
    filter: string,
    apartment?: string | null,
): Promise<MessageResponse> => {
    try {
        // await new Promise(resolve => setTimeout(resolve, 3000));
        let url = '';
        if (apartment) {
            url = URL.REQUEST_MESSAGES_LIST_BY_APARTMENT(page, size, companyId, adminType, '',apartment);
            console.log(apartment);
        } else {
            url = URL.REQUEST_MESSAGES_LIST(page, size, companyId, adminType, '');
            console.log(apartment);
        }
        const response = await privateFetch.get(url);

        const responseData: MessageResponse = response.data;

        return responseData;
    } catch (error) {
        console.error("Error al obtener la lista de mensajes:", error);
        throw new Error("No se pudo obtener la lista de mensajes. Por favor, inténtalo de nuevo más tarde.");
    }
};


export const getAddMessages = async (data: Content, companyId: number): Promise<string> => {
    try {
        const response = await privateFetch.post(URL.REQUEST_MESSAGE_ADD(companyId), data);
        console.log(response);

        return response.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || "Error desconocido";
        throw errorMessage;
    }
};

export const getUpdateMessages = async (id: number, data: Content, companyId: number): Promise<Content> => {
    try {
        const response = await privateFetch.put(URL.REQUEST_MESSAGE_UPDATE(id, companyId), data);
        console.log(response);

        return response.data;
    } catch (error) {
        console.error("Error al realizar la petición:", error);
        throw error;
    }
};

export const getDeleteMessages = async (id: number, companyId: number): Promise<string> => {
    try {
        const response = await privateFetch.delete(URL.REQUEST_MESSAGE_DELETE(id, companyId));
        return response.data;
    } catch (error) {
        console.error("Error al realizar la petición:", error);
        throw error;
    }
};

export const getReadMessages = async (id: number, companyId: number): Promise<string> => {
    try {
        const response = await privateFetch.put(URL.REQUEST_MESSAGE_READ(id, companyId));
        return response.data;
    } catch (error) {
        console.error("Error al realizar la petición:", error);
        throw error;
    }
}