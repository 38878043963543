import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from "yup";
import { useApp } from '../../../hooks/useApp';
import { getRequest, getRequestById } from '../../../services/apartment/ApartmentService';
import { Content } from '../../../services/messages/MessagesInterface';
import { getAddMessages, getAllMessages, getDeleteMessages, getReadMessages, getUpdateMessages } from '../../../services/messages/MessagesService';


interface MessagesContextType {
    handleOpenModal: (event: any) => void;
    handleCancelModal: any;
    messagesData: any;
    handleFetchData: () => Promise<void>;
    onSubmit: (event: any) => void;
    handleAdd: (event: any) => void;
    handleDelete: any;
    handleSubmit: any;
    defaultValues: Content;
    setDefaultValues: (value: any) => void;
    messageValidationSchema: any;
    apartmentDataById: any;
    apartmentDataAll: any;
    readMessage: any;
}

const MessagesContext = createContext<MessagesContextType | undefined>(undefined);

export const MessagesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { t } = useTranslation();

    const [messagesData, setMessagesData] = useState<any>([]);
    const { setLoading,
        setErrorMsg,
        company,
        authInfo,
        modalData,
        setSuccessMsg,
        isAdmin,
        setModalData,
    } = useApp();

    const initValues: Content =
    {
        id: null,
        sourceName: isAdmin ? 'ADMINISTRATOR' : '',
        targetName: '',
        sourceApartment: '',
        targetApartment: '',
        title: '',
        message: '',
        status: null,
        creationDate: '',
        userCreated: '',
        userModified: null,
        modificationDate: null,
    }

    const [defaultValues, setDefaultValues] = useState<Content>(initValues);
    const [apartmentDataById, setApartmentDataById] = useState<{ id: number; name: string }[]>([]);
    const [apartmentDataAll, setApartmentDataAll] = useState<{ id: number; name: string }[]>([]);

    const { handleSubmit } = useForm();

    const messageValidationSchema = isAdmin ?
        Yup.object().shape({
            // Validación para administradores
            // sourceApartment:  Yup.string().required("Campo es requerido"),
            targetApartment: Yup.string().required("Campo es requerido"),
            title: Yup.string().required("Campo es requerido"),
            message: Yup.string().required("Campo es requerido"),
        }) :
        Yup.object().shape({
            // Validación para no administradores
            sourceApartment: Yup.string().required("Campo es requerido"),
            targetApartment: Yup.string().required("Campo es requerido"),
            title: Yup.string().required("Campo es requerido"),
            message: Yup.string().required("Campo es requerido"),
        });


    useEffect(() => {
        fetchApartmentDataAll();
        if (!isAdmin) {
            fetchApartmentDataById();
            // handleFetchData();

        } else if (isAdmin) {
            handleFetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdmin, company])

    const fetchApartmentDataById = async (
    ) => {
        try {
            const data = await getRequestById(0, isAdmin && company ? company : authInfo.companyId, authInfo.id, 100);
            if (data && data.content && data.content.length > 0) {
                setApartmentDataById(data.content.map((apartment: any) => ({
                    id: apartment.id,
                    name: apartment.name
                })));
            }

            console.log(data);

            // Si es una acción de creación, establecer el apartamento con el primer valor de la lista
            if (apartmentDataById.length > 0 && !isAdmin) {
                setDefaultValues({ ...initValues, sourceApartment: apartmentDataById[0].name });
                handleFetchData(apartmentDataById[0].name);
            } else {
                setDefaultValues(initValues);
            }

        } catch (error) {
            console.error('Error fetching apartment data:', error);
        }
    };

    // Carga de todos los apartamentos para seleccionar (Enviar mensaje)
    const fetchApartmentDataAll = async () => {
        try {
            const data = await getRequest(0, isAdmin && company ? company : authInfo.companyId, '', '', '', 100);
            if (data && data.content && data.content.length > 0) {
                setApartmentDataAll(data.content.map((apartment: any) => ({
                    id: apartment.id,
                    name: apartment.name
                })));
            }
        } catch (error) {
            console.error('Error fetching apartment data:', error);
        }
    };

    // Carga de todos los mensajes
    const handleFetchData = async (apartment?: string | null) => {
        setLoading && setLoading(true);
        setMessagesData([]);
        try {
            const data = await getAllMessages(0, 10, isAdmin && company ? company : authInfo.companyId, isAdmin, '', apartment);
            if (data) {
                setMessagesData(data);
            }
            setLoading && setLoading(false);
        } catch (error: any) {
            setLoading && setLoading(false);
            setErrorMsg && setErrorMsg(error.message);
        }
    };

    // Carga de todos los mensajes
    const handleFetchDataNoLoading = async (apartment?: string | null) => {
        // setLoading && setLoading(true);
        // setMessagesData([]);
        try {
            const data = await getAllMessages(0, 10, isAdmin && company ? company : authInfo.companyId, isAdmin, '', apartment);
            if (data) {
                setMessagesData(data);
            }
            // setLoading && setLoading(false);
        } catch (error: any) {
            // setLoading && setLoading(false);
            // setErrorMsg && setErrorMsg(error.message);
        }
    };

    const handleOpenModal = async (event: any) => {
        const modalAction = event.currentTarget.getAttribute("data-name");
        let object = null;
        const id = event.currentTarget.getAttribute("data-id");

        if (modalAction === "update" || modalAction === "delete" || modalAction === "view") {
            object = messagesData.content.find((p: any) => p.id === parseInt(id));
            if (object) {
                setDefaultValues(object);
            }
        } else if (modalAction === "create" && !isAdmin) {
            setDefaultValues({ ...initValues, sourceApartment: apartmentDataById[0].name });
        }

        setModalData && setModalData({
            ...modalData,
            modalOpen: true,
            modalType: modalAction,
            modalObject: object,
        });
    };

    const handleCancelModal = () => {
        setDefaultValues(initValues)
        setModalData && setModalData({
            ...modalData,
            modalOpen: false,
            modalType: "",
            modalObject: null,
        });
    };

    const onSubmit = async (data: any) => {
        console.log(data);
        switch (modalData?.modalType) {
            case "create":
                await handleAdd(data);
                break;
            case "update":
                await handleUpdate(data);
                break;
            case "delete":
                await handleDelete(modalData?.modalObject?.id, isAdmin && company ? company : authInfo.companyId);
                break;
            default:
                break;
        }
    };

    const handleAdd = async (data: any) => {
        handleCancelModal();
        setLoading && setLoading(true);
        console.log(data);
        try {
            let createData = await getAddMessages({
                ...data,
                userCreated: authInfo?.username,
            },
                isAdmin && company ? company : authInfo.companyId,
            );

            if (!createData) {
                setErrorMsg && setErrorMsg(t("errorCreatingMessage"));
                setLoading && setLoading(false);
                return;
            }
            setLoading && setLoading(false);
            setSuccessMsg &&
                setSuccessMsg(t("successfullyCreatedMessage"));
            await handleFetchData(apartmentDataById[0].name ?? null);
        }
        catch (error: any) {
            setErrorMsg && setErrorMsg(error);
            setLoading && setLoading(false);
        }
    };

    const handleUpdate = async (data: any) => {
        console.log("data = ", data)
        handleCancelModal();
        setLoading && setLoading(true);
        try {
            let updateData = await getUpdateMessages(
                data.id,
                {
                    ...data,
                    userModified: authInfo?.username,
                },
                isAdmin && company ? company : authInfo.companyId,
            );

            if (!updateData) {
                setErrorMsg &&
                    setErrorMsg(t("errorUpdatingMessage"));
                setLoading && setLoading(false);
                return;
            }
            setLoading && setLoading(false);
            setSuccessMsg &&
                setSuccessMsg(t("messageUpdatedCorrectly"));
            await handleFetchData(apartmentDataById[0].name ?? null);

        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
            setLoading && setLoading(false);
        }
    };

    const handleDelete = async (id: number, countryId: number) => {
        handleCancelModal();
        setLoading && setLoading(true);
        try {
            let deleteData = await getDeleteMessages(id, countryId);
            if (deleteData) {
                setLoading && setLoading(false);
                setSuccessMsg && setSuccessMsg(t("recordDeletedSuccessfully"));
                await handleFetchData(apartmentDataById[0].name ?? null);
            }
        } catch (error: any) {
            setErrorMsg && setErrorMsg(t("errorDeletingRecord"));
            setLoading && setLoading(false);
            return;
        }
    }

    const readMessage = async (id: number) => {
        try {
            const data = await getReadMessages(id, authInfo.companyId);
            if (data) {
                // return data;
                await handleFetchDataNoLoading(apartmentDataById[0].name ?? null);

            }
        } catch (error) {
            console.error('Error fetching read message:', error);
        }
        return null;
    }

    const contextValue: MessagesContextType = {
        setDefaultValues,
        defaultValues,
        handleOpenModal,
        handleCancelModal,
        messagesData,
        handleFetchData,
        handleAdd,
        onSubmit,
        handleDelete,
        handleSubmit,
        messageValidationSchema,
        apartmentDataById,
        apartmentDataAll,
        readMessage,
    };

    return (
        <MessagesContext.Provider value={contextValue}>
            {children}
        </MessagesContext.Provider>
    );
};

export const useMessages = () => {
    const context = useContext(MessagesContext);
    if (!context) {
        throw new Error('useMessages must be used within a MessagesProvider');
    }
    return context;
};

