import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import countriesData from "../utils/countrySelector/countriesData.json";
import CountryFlag from "./countrySelector/CountryFlag";
import { useTranslation } from "react-i18next";
import {
  validateNameAvailability
} from "../../services/country/CountryService";

interface CountryData {
  name: string;
  areaCode: string;
  [key: string]: string;
}

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdateCountry {
  id?: number;
  areaCode: string;
  name: string;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

enum CountryField {
  Id = "id",
  AreaCode = "areaCode",
  Name = "name",
}

const AddOrUpdateCountriesModal = ({
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  const { t } = useTranslation();
  // Validación de datos
  const validation = Yup.object().shape({
    areaCode: Yup.string().required(t("fieldRequired")),
    name: Yup.string().required(t("fieldRequired")),
  });

  // Obtener nombre o código de área según lo ingresado por el usuario
  const getCountryData = (value: string, field: string) => {
    return (countriesData as CountryData[]).find(
      (country) =>
        country.name.toLowerCase() === value.toLowerCase() ||
        country.areaCode.toLowerCase() === value.toLowerCase()
    )?.[field] || '';
  };

  // Función para detectar si el país ingresado coincide con algún país en el JSON
  const getCorrectedCountryName = (value: string) => {
    const country = (countriesData as CountryData[]).find(
      (country) =>
        country.name.toLowerCase() === value.toLowerCase()
    );
    return country ? country.name : value;
  };

  // Obtener valores predeterminados
  const defaultValues = {
    id: data ? data.id : 0,
    areaCode: data ? data.areaCode : "+",
    name: data ? data.name : "",
  };

  // Inicializar useForm con validación y valores predeterminados
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateCountry>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  // Actualizar el valor del nombre o el código de área según el otro campo
  useEffect(() => {
    const handleUpdateField = (fieldName: CountryField, value: string) => {
      if (!value) return;
      const newValue = getCountryData(value, fieldName);
      if (newValue) setValue(fieldName, newValue);
    };

    // Actualizar el nombre si se ingresa el código de área y viceversa
    handleUpdateField(CountryField.Name, defaultValues.areaCode);
    handleUpdateField(CountryField.AreaCode, defaultValues.name);

  }, [defaultValues.areaCode, defaultValues.name, setValue]);


  const [isNameAvailable, setIsNameAvailable] = useState(null);
  const handleNameChange = async (name: string) => {
    setIsNameAvailable(null); // Clear previous availability status
    if (name.length > 0) {
      const available = await validateNameAvailability(name);
      setIsNameAvailable(available);
    }
  };
  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "update"
          ? t("updateCountry")
          : t("addCountry")}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("name")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name3"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("name", {validate: () => isNameAvailable === null || isNameAvailable})}
                    error={(errors.name && Boolean(errors.name)) || (isNameAvailable !== null && !isNameAvailable)}
                    helperText={errors.name && errors.name.message ? errors.name.message : isNameAvailable === false
                      ? t("name_unavailable") : ''}
                    onChange={(e) => {
                      onChange(e);
                      setValue("areaCode", getCountryData(e.target.value, "areaCode"));
                      handleNameChange(e.target.value)
                    }}
                    onBlur={(e) => {
                      const correctedValue = getCorrectedCountryName(value);
                      setValue("name", correctedValue.charAt(0).toUpperCase() + correctedValue.slice(1));
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="areaCode"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("countryCode")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="areaCode"
                    sx={{
                      "& input": { pl: "0!important" },
                      "& .MuiInput-startAdornment": { marginRight: "8px" },
                    }}
                    InputProps={{
                      startAdornment: value ? <CountryFlag areaCode={value} /> : null,
                    }}
                    {...register("areaCode")}
                    error={errors.areaCode && Boolean(errors.areaCode)}
                    helperText={errors.areaCode && errors.areaCode.message}
                    onChange={(e) => {
                      onChange(e);
                      setValue("name", getCountryData(e.target.value, "name"));
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          disabled={!isNameAvailable}
          onClick={handleSubmit(onSubmit)}
        >
          {t("save")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          {t("cancel")}
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateCountriesModal;
