import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { getRequest } from "../../services/role/RoleService";
import { activateUser, valideAvailableUser } from "../../services/users/UserService";
import { formSchemaOptionsName } from "../../types/Utils";
import { properties } from "../../utils/Properties_es";

interface ValidationResult {
  exists: boolean;
  // otras propiedades si las hay
}

interface AddOrUpdateUser {
  id?: number;
  username: string;
  firstName: string;
  lastName: string;
  password: string;
  phone: string;
  status: string;
  roleID: {
    id?: number;
    name?: string;
  }[];
  idCompany:
  | {
    id?: number;
    name?: string;
  }[]
  | [];
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  refreshData: any;
  cancelModal: any;
  modalType?: any;
  roleData?: any;
  companyData: any;
}

const AddOrUpdateUserModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  refreshData,
  modalType,
  roleData,
  companyData,
}: DialogProps) => {
  const { t } = useTranslation();
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [filteredRoles, setFilteredRoles] = useState<any>([]);
  const [availableEmail, setAvailableEmail] = useState(false);
  //Validación de datos
  const validation = Yup.object().shape({
    username: Yup.string()
      .required(t("fieldRequired"))
      .email(t("invalidEmail")),
    firstName: Yup.string().required(t("fieldRequired")),
    lastName: Yup.string().required(t("fieldRequired")),
    password: Yup.string().when(modalType, {
      is: () => {
        if (modalType === "create") return true;
      },
      then: Yup.string().required(t("fieldRequired")),
    }),
    phone: Yup.string().required(t("fieldRequired")),
    status: Yup.string(),
    roleID: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError(t("fieldRequired")),
    idCompany: Yup.lazy((value) => {
      // Validar solo si el usuario es administrador
      return isAdmin ? Yup.object().shape(formSchemaOptionsName).typeError(t("fieldRequired")) : Yup.mixed();
    }),
  });
  console.log(data);
  console.log(companyData)
  const { setLoading, setErrorMsg, isAdmin, setSuccessMsg, company } = useApp();

  const defaultValues = {
    id: data ? data.id : 0,
    username: data ? data.username : "",
    firstName: data ? data.firstName : "",
    lastName: data ? data.lastName : "",
    password: data ? data.password : "",
    phone: data ? data.phone : "",
    status: data ? data.status : "",
    /* roleID: data.roleID
      ? Role.userRole.find((f: any) => f.role_name === data.roleID)
      : "", */
    roleID: data ? roleData.find((f: any) => f.name === data.userRole) : [],
    activationCode: data ? data.activationCode : "",
    idCompany:
      data && data.idCompany
        ? {
          id: data.idCompany,
          name: companyData.find((f: any) => f.id === data.idCompany)
            ?.description,
        }
        : ([] as any),
    companyRelation: data ? data.companyRelation : "",
  };

  console.log(defaultValues)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateUser>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const handleActiveUser = async (data: any) => {
    setLoading && setLoading(true);
    try {
      let dataActiveUser = await activateUser(
        data.activationCode,
        data.username
      );
      if (!dataActiveUser) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_user_save_error);
        setLoading && setLoading(false);
        return;
      }
      // await handleFetchByID(formData.id);

      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(t("userHasBeenActivated"));
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
    refreshData(0, "");
    cancelModal();
  };

  const handleCompanyChange = async (selectedCompany: any) => {
    setFilteredRoles([])
    console.log(selectedCompany);
    setSelectedCompany(selectedCompany);

    if (selectedCompany) {
      let companyRoles = await getRequest(selectedCompany.id);
      setFilteredRoles(companyRoles.content);
    } else {
      setFilteredRoles([]);
    }

    setValue("roleID", []);
  };

  useEffect(() => {
    const fetchData = async () => {
      setFilteredRoles([])
      if (data && data.idCompany) {
        try {
          let companyRoles = await getRequest(data.idCompany); 
          setFilteredRoles(companyRoles.content);
        } catch (error) {
          console.error("Error al obtener los roles de la compañía:", error);
          
        }
      }
    };
  
    fetchData();
  
  }, [data, companyData]);

  const validateEmail = async (email: string) => {
    // Expresión regular para validar el formato de correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    try {
      // Verificar si el correo electrónico tiene el formato correcto
      if (!emailRegex.test(email)) {
        // El correo electrónico no tiene el formato correcto
        setAvailableEmail(false);
        return;
      }

      // El correo electrónico tiene el formato correcto, continuar con la validación
      const isAvailable: boolean = await valideAvailableUser(email);
      setAvailableEmail(isAvailable);
      console.log(isAvailable);
    } catch (error) {
      console.error("Error al validar el correo electrónico:", error);
    }
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "update"
          ? t("updateUser")
          : t("addUser")}
        {data && data.status === 6 && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleActiveUser(data)}
          >
            {t("activateUser")}
          </Button>
        )}
        {data && data.status === 2 && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleActiveUser(data)}
          >
            {t("activateUser")}
          </Button>
        )}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={"firstName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("name")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="firstName"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("firstName")}
                    error={errors.firstName && Boolean(errors.firstName)}
                    helperText={errors.firstName && errors.firstName.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"lastName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("lastName")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="lastName"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("lastName")}
                    error={errors.lastName && Boolean(errors.lastName)}
                    helperText={errors.lastName && errors.lastName.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name={"phone"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("phone")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="phone"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("phone")}
                    error={errors.phone && Boolean(errors.phone)}
                    helperText={errors.phone && errors.phone.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"username"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("email")}
                    type="email"
                    variant="standard"
                    value={value}
                    autoComplete="username3"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("username")}
                    onChange={(e) => {
                      setValue("username", e.target.value);
                      validateEmail(e.target.value);
                    }}
                    error={availableEmail || (errors.username && Boolean(errors.username))}
                    helperText={
                      (availableEmail && t("emailAlreadyExists")) ||
                      (errors.username && errors.username.message)
                    }
                  />
                )}
              />
            </Grid>
            {isAdmin && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="idCompany"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                        handleCompanyChange(newValue);
                      }}
                      options={companyData.map((c: any) => {
                        return { id: c.id, name: c.description };
                      })}
                      getOptionLabel={(option: any) => option.name || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      //@ts-ignore
                      value={value}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label={t("company")}
                            variant="standard"
                            sx={{ "& input": { pl: "0!important" } }}
                            error={!!errors.idCompany}
                            helperText={
                              errors.idCompany && errors.idCompany.message
                            }
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            {isAdmin && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="roleID"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={filteredRoles}
                      getOptionLabel={(option: any) => option.name || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      //@ts-ignore
                      value={value}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label={t("profile")}
                            variant="standard"
                            sx={{ "& input": { pl: "0!important" } }}
                            error={!!errors.roleID}
                            helperText={errors.roleID && errors.roleID.message}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            {!isAdmin && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="roleID"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={roleData}
                      getOptionLabel={(option: any) => option.name || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      //@ts-ignore
                      value={value}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label={t("profile")}
                            variant="standard"
                            sx={{ "& input": { pl: "0!important" } }}
                            error={!!errors.roleID}
                            helperText={errors.roleID && errors.roleID.message}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {modalType === "create" && (
              <Grid item xs={12}>
                <Controller
                  name={"password"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("password")}
                      type="password"
                      variant="standard"
                      value={value}
                      autoComplete="password"
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("password")}
                      error={!!errors.password}
                      helperText={errors.password && errors.password.message}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          {t("save")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          {t("cancel")}
        </Button>{" "}
      </DialogActions>
    </>
  );
};

export default AddOrUpdateUserModal;
