import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Typography,
    styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";

const Title = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: "#e1e8ee",
    color: "#3e627c!important",
    fontFamily: "D-dinExp",
    fontWeight: 400,
    fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
    borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdate {
    id?: number;
    name: string;
    account?: string;
    paymentMethod?: number;
}

interface DialogProps {
    actionButton?: any;
    data: any;
    onSubmit: any;
    cancelModal: any;
    modalType?: any;
    refreshData: any;
}

const AddOrUpdatePayMethodModal = ({
    data,
    onSubmit,
    cancelModal,
    modalType,
    refreshData,
}: DialogProps) => {
    const { t } = useTranslation();

    //Validación de datos
    const validation = Yup.object().shape({
        name: Yup.string().required(t("fieldRequired")),
        account: Yup.string().required(t("fieldRequired")),
        paymentMethod: Yup.number().required(t("fieldRequired")), 
    });

    const defaultValues = {
        id: data ? data.id : 0,
        name: data ? data.name : "",
        account: data ? data.account : "",
        paymentMethod: data ? data.type : 3,
    };

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        control,
        watch,
    } = useForm<AddOrUpdate>({
        defaultValues,
        resolver: yupResolver(validation),
        mode: "onChange",
    });

    const { isAdmin, currencies, company, authInfo, setLoading, setErrorMsg, setSuccessMsg, modalData } = useApp();

    const selectedPaymentMethod = watch("paymentMethod");

    return (
        <>
            <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
                {modalType === "update" ? t("updateAccount") : t("addAccount")}
            </Title>
            <DialogContent sx={{ px: 4, pb: 0 }}>
                <form>
                    <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
                        <Grid item xs={12}>
                            <Controller
                                name={"name"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label={selectedPaymentMethod  === 3 ? t("bankName") : t("sinpeName")}
                                        type="text"
                                        variant="standard"
                                        value={value}
                                        autoComplete="name"
                                        sx={{ "& input": { pl: "0!important" } }}
                                        {...register("name")}
                                        error={errors.name && Boolean(errors.name)}
                                        helperText={
                                            errors.name && errors.name.message
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name={"account"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label={selectedPaymentMethod  === 3 ? t("account") : t("sinpePhone")}
                                        type="text"
                                        variant="standard"
                                        value={value}
                                        autoComplete="name"
                                        sx={{ "& input": { pl: "0!important" } }}
                                        {...register("account")}
                                        error={errors.account && Boolean(errors.account)}
                                        helperText={
                                            errors.account && errors.account.message
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name={"paymentMethod"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControl fullWidth size="small" variant="standard">
                                        <Select
                                            value={value}
                                            onChange={onChange}
                                            displayEmpty
                                            inputProps={{ "aria-label": "Without label" }}
                                        >
                                            <MenuItem value={3}>{t("bankAccount")}</MenuItem>
                                            <MenuItem value={4}>Sinpe</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <Actions sx={{ pb: 4, px: 4 }}>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        mt: 2,
                        mr: 2,
                    }}
                    onClick={() => {
                        handleSubmit(onSubmit)();
                    }}
                >
                    {t("save")}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                        mt: 2,
                        mr: 2,
                    }}
                    onClick={cancelModal}
                    autoFocus
                >
                    {t("cancel")}
                </Button>{" "}
            </Actions>
        </>
    );
};

export default AddOrUpdatePayMethodModal;
