import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  Divider
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  downloadPaymentFile,
  updateAppliedPaymentStatus
} from "../../services/payment/PaymentService";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));


interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  company: number;
}

const ValidatePaymentModal = ({
                                         actionButton,
                                         data,
                                         onSubmit,
                                         cancelModal,
                                         modalType,
                                         company
                                       }: DialogProps) => {
  const { t } = useTranslation();


  const formatNumber = (value: any) => {
    // Eliminar cualquier separador de miles antes de aplicar el nuevo valor
    const normalizedValue = (value || "").toString().replace(/,/g, "");
    // Aplicar el formato con separadores de miles
    return normalizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleDownloadVoucher= () => {
    downloadPaymentFile(data.id)
  };

  const handleApproveVoucher= () => {
    updateAppliedPaymentStatus(data.id,'CONFIRMED').then(() => {
      cancelModal()
    })
  }

  const handleRejectVoucher= () => {
    updateAppliedPaymentStatus(data.id, 'REJECTED').then(() => {
      cancelModal()
    })
  }

  return (
      <>
        <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
          {t("validatePayment")}
        </Title>
        <DialogContent sx={{ px: 8, pb: 0 }}>
            <Grid container spacing={2} sx={{ pt: 4, pb: 10 }}>
              <Grid item xs={6}>
                Apartamento
              </Grid>
              <Grid item xs={6}>
                 {data.apartment.name}
              </Grid>
              <Grid item xs={6}>
                Medio de pago
              </Grid>
              <Grid item xs={6}>
                { t(data.paymentMethodType) } - {data.accountMethodName}
              </Grid>
              <Grid item xs={6}>
                Referencia
              </Grid>
              <Grid item xs={6}>
                {data.reference}
              </Grid>
              <Grid item xs={6}>
                {t("datePayment")}
              </Grid>
              <Grid item xs={6}>
                {data.registrationDate}
              </Grid>

              <Grid item xs={6}>
                Monto
              </Grid>
              <Grid item xs={6}>
                {data.currency + ' ' + formatNumber(data.amount)}
              </Grid>

            </Grid>
        </DialogContent>
        <Actions sx={{ pb: 4, px: 4 }}>
          <Button
              variant="contained"
              color="primary"
              sx={{
                mt: 2,
                mr: 2,
              }}
              onClick={handleDownloadVoucher}
              autoFocus
          >
            {t("downloadVoucher")}
          </Button>
          <Button
              variant="contained"
              color="info"
              sx={{
                mt: 2,
                mr: 2,
              }}
              onClick={handleApproveVoucher}
              autoFocus
          >
            {t("approve")}
          </Button>
          <Button
              variant="contained"
              color="error"
              sx={{
                mt: 2,
                mr: 2,
              }}
              onClick={handleRejectVoucher}
              autoFocus
          >
            {t("denied")}
          </Button>

          <Button
              variant="contained"
              color="secondary"
              sx={{
                mt: 2,
                mr: 2,
              }}
              onClick={cancelModal}
              autoFocus
          >
            {t("cancel")}
          </Button>
        </Actions>
      </>
  );
};

export default ValidatePaymentModal;
