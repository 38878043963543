import React, {useEffect, useState} from 'react';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Grid,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Box,
  Stack,
  CircularProgress
} from '@mui/material';
import PaymentsIcon from '@mui/icons-material/Payments';
import {getRequestPayMethod} from "../../services/payMethod/CardMethodService";
import {createPaymentLink, CreatePaymentLinkRequest} from "../../services/payment/PaymentService";
import {useApp} from "../../hooks/useApp";
import {useTranslation} from "react-i18next";
import PayPalButtonsComponent from "./PayPalButtonsComponent";
import UploadVoucherComponent from "./UploadVoucherComponent";

interface DialogProps {
  data: any;
  company: number;
  handleFetchData: any;
}

interface PaymentMethodOption {
  id: number;
  name: string;
  type: number;
  apiKey: string;
}

const PaymentMethods = ({
                          data,
                          company,
                          handleFetchData
                        }: DialogProps) => {

  const {
    authInfo,
    isAdmin
  } = useApp();

  console.log('data', data);
  console.log('company', company);
  console.log('authInfo', authInfo);

  const totalAmount = data.pending.reduce((acc: any, current: { amount: any; }) => acc + current.amount, 0);
  const currencyCode = data.pending[0].currency.symbol


  const [hasMethodPayData, setHasMethodPayData] = useState<boolean>(false);
  const [selectedMethod, setSelectedMethod] = useState<PaymentMethodOption | null>(null);
  const [selectedPaymentAccountMethod, setSelectedPaymentAccountMethod] = useState<number | null>(null);
  const [methodPayData, setMethodPayData] = useState<any>([]);
  const [filteredPayments, setFilteredPayments] = useState<any>([])
  const [paying, setPaying] = useState<boolean>(false);
  const {t} = useTranslation();

  const filterPaymentMethods = (methods: any) => {
    const {account, card, paypal} = methods
    let paymentOptions: PaymentMethodOption[] = []
    if (card && card.length > 0) {
      card.forEach((acc: any) => {
        paymentOptions.push({
          id: acc.id,
          name: acc.type == 1 ? 'Tarjeta - TiloPay' : 'Tarjeta - Stripe',
          type: acc.type,
          apiKey:""
        });
      })
    }
    if (account && account.length > 0) {
      account.forEach((acc: any) => {
        paymentOptions.push({
          id: acc.id,
          name: acc.type == 3 ? 'Depósito' : 'Sinpe Móvil',
          type: acc.type,
          apiKey:""
        });
      })
    }
    if (paypal && paypal.length > 0) {
      paypal.forEach((acc: any) => {
        paymentOptions.push({id: acc.id, name: 'Paypal', type: acc.type,  apiKey:acc.apiKey});
      })
    }
    setFilteredPayments(paymentOptions)
  }

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    let paymentMethods: any = getRequestPayMethod(isAdmin ? company : authInfo.companyId);
    paymentMethods.then((data: any) => {
      setMethodPayData(data);
      filterPaymentMethods(data)
      if (data.account.length > 0 || data.card.length > 0 || data.paypal != null) {
        setHasMethodPayData(true);
      }
    })
  }, [company]);


  const handleSelectMethod = (method: string) => {
    let filterElement = filteredPayments.filter((item: PaymentMethodOption) => item.id === Number.parseInt(method))[0];
    setSelectedMethod(filterElement);
    setSelectedPaymentAccountMethod(null);
    setPaying(false)
  };

  const handleSelectAccountMethod = (method: string) => {
    setSelectedPaymentAccountMethod(Number.parseInt(method));
    setPaying(false)
  };


  let paymentData: CreatePaymentLinkRequest = {
    companyId: company,
    userId: authInfo.id,
    customerEmail: authInfo.username,
    customerName: authInfo.name,
    apartmentId: data.pending[0].apartment.id,
    pendingsToPay: data.pending.map((item: any) => item.id),
    paymentMethodId : 0,
    reference: ""
  }

  const handlePay = async () => {
    setPaying(true)
    let requestData: CreatePaymentLinkRequest = { ...paymentData,
      paymentMethodId: selectedMethod == null ? 0 : selectedMethod.id
    }

    let response: any = await createPaymentLink(requestData);
    window.location.assign(response);

    setPaying(false)
  };

  return (
      <Grid container spacing={1} className="payment-methods">
        <Grid item xs={12}>
          <Typography variant="h3">Formas de Pago</Typography>
        </Grid>
        <Grid item xs={4}>
          {hasMethodPayData ?
              <RadioGroup aria-label="payment-method" name="payment-method"
                          value={selectedMethod?.id}
                          onChange={(e) => handleSelectMethod(e.target.value)}>
                {filteredPayments && filteredPayments.map((item: PaymentMethodOption, idx: number) => (
                    <FormControlLabel
                        key={idx}
                        value={item.id}
                        control={<Radio/>}
                        label={
                          <Grid container spacing={1} alignItems="center">
                            <Grid item>
                              <PaymentsIcon/>
                            </Grid>
                            <Grid item>
                              <Typography variant="body1">{item.name}</Typography>
                            </Grid>
                          </Grid>
                        }
                    />
                ))}
              </RadioGroup>
              : <> {t("payMethodNotConfigured")}</>}
        </Grid>
        <Grid item xs={8}>
          <TableContainer component={Paper}
                          hidden={selectedMethod == null || selectedMethod?.type != 4}>
            <RadioGroup aria-label="payment-account-method-4" name="payment-account-method-4"
                        value={selectedPaymentAccountMethod}
                        onChange={(e) => handleSelectAccountMethod(e.target.value)}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={10}></TableCell>
                  <TableCell>{t('name')}</TableCell>
                  <TableCell>{t('account')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {methodPayData && methodPayData.account && methodPayData.account
                .filter((row: any) => row.type === 4)
                .map((row: any) => (
                    <TableRow key={row.name}>
                      <TableCell width={10}>
                        <FormControlLabel
                            key={row.id}
                            value={row.id}
                            control={<Radio/>}
                            label= ''
                        />
                      </TableCell>
                      <TableCell width={200}>{row.name}</TableCell>
                      <TableCell>{row.account}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
            </RadioGroup>
          </TableContainer>

          <TableContainer component={Paper}
                          hidden={selectedMethod == null || selectedMethod?.type != 3}>
            <RadioGroup aria-label="payment-account-method" name="payment-account-method"
                        value={selectedPaymentAccountMethod}
                        onChange={(e) => handleSelectAccountMethod(e.target.value)}>
            <Table aria-label="Bank table">
              <TableHead>
                <TableRow>
                  <TableCell width={10}></TableCell>
                  <TableCell width={200}>{t('bank')}</TableCell>
                  <TableCell width={50} >{t('account')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>


                {methodPayData && methodPayData.account && methodPayData.account
                .filter((row: any) => row.type === 3)
                .map((row: any) => (
                    <TableRow key={row.id}>
                      <TableCell width={10}>
                        <FormControlLabel
                            key={row.id}
                            value={row.id}
                            control={<Radio/>}
                            label= ''
                        />
                      </TableCell>
                      <TableCell width={200}>{row.name}</TableCell>
                      <TableCell width={50}>{row.account}</TableCell>
                    </TableRow>
                ))}

              </TableBody>
            </Table>
            </RadioGroup>
          </TableContainer>

          <Box hidden={selectedMethod == null || selectedMethod?.type != 5}>
            <PayPalButtonsComponent client_id={selectedMethod?.apiKey || ''}
                                    paymentData={ paymentData }
                                    paymentMethodId = { selectedMethod?.id || 0 }
                                    amount={ totalAmount }
                                    currency={ currencyCode }
            ></PayPalButtonsComponent>
          </Box>

          <Box hidden={selectedMethod == null || (selectedMethod?.type != 3 && selectedMethod?.type != 4 )}>
            <UploadVoucherComponent paymentData={ paymentData }
                                    paymentMethodId = { selectedPaymentAccountMethod || 0 }
                                    amount={ totalAmount }
                                    handleFetchData={handleFetchData}
            ></UploadVoucherComponent>
          </Box>

        </Grid>
        <Grid item xs={12}
              hidden={paying || selectedMethod == null || (selectedMethod?.type != 1 && selectedMethod?.type != 2)}>
          <Button variant="contained" color="primary" onClick={handlePay}>
            Pagar
          </Button>
        </Grid>
        <Grid item xs={12} hidden={!paying}>
          <Stack spacing={2} direction="row" justifyContent="center">
            <CircularProgress color="primary" />
          </Stack>
        </Grid>
        <style>
          {`
          .payment-methods {
            margin: 2px auto;
            padding: 5px;
            border: 1px solid #ddd;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
        `}
        </style>
      </Grid>
  );
}

export default PaymentMethods;