import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Typography,
    styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";

const Title = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: "#e1e8ee",
    color: "#3e627c!important",
    fontFamily: "D-dinExp",
    fontWeight: 400,
    fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
    borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdate {
    id?: number;
    user?: string;
    password?: string;
    apiKey?: string;
    paymentMethod?: number;
}

interface DialogProps {
    actionButton?: any;
    data: any;
    onSubmit: any;
    cancelModal: any;
    modalType?: any;
    refreshData: any;
}

const AddOrUpdateCardMethodModal = ({
    data,
    onSubmit,
    cancelModal,
    modalType,
    refreshData,
}: DialogProps) => {
    const { t } = useTranslation();

    //Validación de datos
    const validation = Yup.object().shape({
        user: Yup.string().nullable().when('paymentMethod', {
            is: '1',  // Aquí se compara con la opción que deseas
            then: Yup.string().required(t("fieldRequired")).nullable(),
            otherwise: Yup.string().nullable(),
        }),
        password: Yup.string().nullable().when('paymentMethod', {
            is: '1',  // Aquí se compara con la opción que deseas
            then: Yup.string().required(t("fieldRequired")).nullable(),
            otherwise: Yup.string().nullable(),
        }),
        apiKey: Yup.string().required(t("fieldRequired")),
        paymentMethod: Yup.number().required(t("fieldRequired")),
    });

    const defaultValues = {
        id: data ? data.id : 0,
        user: data ? data.user : "",
        password: data ? data.password : "",
        apiKey: data ? data.apiKey : "",
        paymentMethod: data ? data.type : 1,
    };



    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        control,
        watch,
    } = useForm<AddOrUpdate>({
        defaultValues,
        resolver: yupResolver(validation),
        mode: "onChange",
    });

    const { isAdmin, currencies, company, authInfo, setLoading, setErrorMsg, setSuccessMsg, modalData } = useApp();

    const selectedPaymentMethod = watch("paymentMethod");

    return (
        <>
            <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
                {modalType === "update" ? t("updateCard") : t("addCard")}
            </Title>
            <DialogContent sx={{ px: 4, pb: 0 }}>
                <form>
                    <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
                        <Grid item xs={12}>
                            <Controller
                                name={"paymentMethod"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControl fullWidth size="small" variant="standard">
                                        <Select
                                            value={value}
                                            onChange={onChange}
                                            displayEmpty
                                            inputProps={{ "aria-label": "Without label" }}
                                        >
                                            <MenuItem value={1}>Tilopay</MenuItem>
                                            <MenuItem value={2}>Stripe</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}
                            />
                        </Grid>
                        {selectedPaymentMethod === 1 && (
                            <>
                                <Grid item xs={12}>
                                    <Controller
                                        name={"user"}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                fullWidth
                                                size="small"
                                                label={t("user")}
                                                type="text"
                                                variant="standard"
                                                value={value}
                                                autoComplete="name"
                                                sx={{ "& input": { pl: "0!important" } }}
                                                {...register("user")}
                                                error={errors.user && Boolean(errors.user)}
                                                helperText={
                                                    errors.user && errors.user.message
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name={"password"}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                fullWidth
                                                size="small"
                                                label={t("password")}
                                                type="text"
                                                variant="standard"
                                                value={value}
                                                autoComplete="name"
                                                sx={{ "& input": { pl: "0!important" } }}
                                                {...register("password")}
                                                error={errors.password && Boolean(errors.password)}
                                                helperText={
                                                    errors.password && errors.password.message
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <Controller
                                name={"apiKey"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label={t("Api Key")}
                                        type="text"
                                        variant="standard"
                                        value={value}
                                        autoComplete="name"
                                        sx={{ "& input": { pl: "0!important" } }}
                                        {...register("apiKey")}
                                        error={errors.apiKey && Boolean(errors.apiKey)}
                                        helperText={
                                            errors.apiKey && errors.apiKey.message
                                        }
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent >
            <Actions sx={{ pb: 4, px: 4 }}>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        mt: 2,
                        mr: 2,
                    }}
                    onClick={() => {
                        handleSubmit(onSubmit)();
                    }}
                >
                    {t("save")}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                        mt: 2,
                        mr: 2,
                    }}
                    onClick={cancelModal}
                    autoFocus
                >
                    {t("cancel")}
                </Button>{" "}
            </Actions>
        </>
    );
};

export default AddOrUpdateCardMethodModal;
