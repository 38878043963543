/* eslint-disable @typescript-eslint/no-unused-vars */
import { CheckCircleOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import FancyPaper from '../components/FancyPaper';
import ResourceAccess from '../components/security/ResourceAccess';
import { AngleRightIcon, CircleCheckIcon, PlusIcon } from '../components/svgicons/SvgIcons';
import AddAuthorityByRole from '../components/utils/AddAuthorityByRole';
import DeleteModal from '../components/utils/DeleteModal';
import FancyTablePagination from '../components/utils/FancyTablePagination';
import FancyTooltip from '../components/utils/FancyTooltip';
import { useApp } from '../hooks/useApp';
import {
  createRequest,
  deleteRequest,
  getRequest,
} from '../services/authority/AuthorityByRoleService';
import { Content } from '../services/authority/AuthorityInterfaces';
import { getRequestNoAdded } from '../services/authority/AuthorityService';
import { findByIdRequestById } from '../services/role/RoleService';
import { properties } from '../utils/Properties_es';
import { useTranslation } from 'react-i18next';

const SettingAuthorityByRoleManagement = () => {
  const { t } = useTranslation();
  const { setLoading, setErrorMsg, modalData, setModalData, setSuccessMsg, errorMsg, language } = useApp();
  const theme = useTheme();
  const { roleId } = useParams();
  const [preFilter, setPreFilter] = useState<any>('');
  const [page, setPage] = useState(0);
  const [authorityByRoleData, setAuthorityByRoleData] = useState<any>(null);
  const [role, setRole] = useState<any>(null);
  const [authorityDataProp, setAuthorityDataProp] = useState<Content[] | null>(null);
  const [authoritySelected, setAuthoritySelected] = useState<number[]>([]);

  const initForm = {
    id: 0,
    code: '',
    createdBy: '',
    description: '',
    path: 0,
  };
  const [formData, setFormData] = useState<any>(initForm);

  console.log(language)

  useEffect(() => {
    let dataInit;
    dataInit = async () => {
      if (roleId) {
        await handleFetchDataRole(roleId);
        await handleFetchData(roleId, 0, '');
      }
    };
    dataInit();
    // eslint-disable-next-line
  }, []);

  const handleFetchData = async (roleId: string, currentPage: number, filter: string) => {
    setLoading && setLoading(true);

    try {
      let data = await getRequest(roleId, currentPage, filter, 100);

      let dataPermisos = await getRequestNoAdded(Number(roleId), currentPage, filter, 100);
      if (dataPermisos) {
        setAuthorityDataProp(dataPermisos.content);
      }
      if (data) {
        setAuthorityByRoleData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const selectAuthority = async (r: any) => {

    if (r === "selectAll") {
      // Si se hace clic en "Seleccionar todos"
      if (authoritySelected.length === authorityByRoleData.content.length) {
        // Si todos los elementos están seleccionados, de seleccionar todos
        setAuthoritySelected([]);
      } else {
        // Si no todos los elementos están seleccionados, seleccionar todos
        const allAuthorityIds = authorityByRoleData.content.map((item: any) => item.authorityId);
        setAuthoritySelected(allAuthorityIds);
      }
    } else {
      // Si se hace clic en un elemento individual
      const isSelected = authoritySelected.includes(r.authorityId);
      if (isSelected) {
        // Si el elemento ya está seleccionado, lo quitamos
        setAuthoritySelected(authoritySelected.filter(id => id !== r.authorityId));
      } else {
        // Si el elemento no está seleccionado, lo agregamos
        setAuthoritySelected([...authoritySelected, r.authorityId]);
      }
    }
  };

  const handleFetchDataRole = async (id: string | null) => {
    setLoading && setLoading(true);
    try {
      let roleData: any = await findByIdRequestById(id);
      if (roleData) {
        setRole(roleData?.content);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleAdd = async (data: number[]) => {
    let error = false;
    let msgError = '';
    setLoading && setLoading(true);

    for (let i = 0; i < data.length; i++) {
      try {
        let createData = await createRequest({
          authorityId: data[i],
          roleId: roleId,
        });

        if (!createData) {
          error = true;
          msgError = t("errorSavingPermissions");
          return;
        }
      } catch (error: any) {
        const errorMessage = error.message;
        msgError = errorMessage;
      }
    }

    if (error) {
      setErrorMsg && setErrorMsg(msgError);
    } else {
      setSuccessMsg && setSuccessMsg(`${data.length > 1 ? t("assignedPermissions") : t("assignedPermission")} ${t("correctly")}`)
      setLoading && setLoading(false);
    }
    if (roleId) {
      await handleFetchData(roleId, 0, '');
    }
  };

  const handleDelete = async (data: number[]) => {

    setLoading && setLoading(true);
    handleCancelModal()

    try {
      for (let i = 0; i < data.length; i++) {
        let deleteData = await deleteRequest({
          authorityId: data[i],
          roleId: roleId,
        });
        if (!deleteData) {
          setErrorMsg && setErrorMsg(t("errorDeletingPermissions"));
          setLoading && setLoading(false);
          return;
        }
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(`${data.length > 1 ? t("deletedPermissions") : t("deletedPermission")} ${t("correctly")}`)
      if (roleId) {
        await handleFetchData(roleId, 0, '');
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
  };

  const handleApplyFilter = async () => {
    if (preFilter !== '' && roleId) {
      await handleFetchData(roleId, 0, preFilter);
    }
  };

  /** Validate errors in case of opening modal **/
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: '',
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute('data-name');
    let object = null;
    const id = event.currentTarget.getAttribute('data-id');
    if (modalAction === 'delete') {
      object = authorityByRoleData.content.find((p: any) => p.authorityId === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    //@ts-ignore
    if (modalData?.modalType !== 'delete') {
      setFormData(initForm);
    }
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: '',
        modalObject: null,
      });
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case 'create':
        await handleAdd(data);
        break;
      case 'delete':
        //@ts-ignore
        await handleDelete(data);
        break;
      default:
        break;
    }
    if (modalData?.modalType !== 'delete') {
      setFormData(initForm);
    }
  };

  console.log(authorityByRoleData)

  return (
    <>
      <FancyPaper pagetitle={`${t("permissionsByProfile")}`}>
        <Typography variant='subtitle1' color='text.primary' sx={{ mb: 2 }}>
          <AngleRightIcon
            sx={{
              marginRight: '0px',
              color: 'primary.main',
              transform: 'translateY(3px)',
              fontSize: '1rem',
            }}
          />{' '}
          {t("profile")}: {role?.name}
        </Typography>
        <Divider />
        <Grid container spacing={8} sx={{ pt: 8, pb: 4 }}>
          <Grid item md={9} sm={6} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={'AUTHORITIESXROLE:WRITE'}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleOpenModal}
                data-name='create'>
                {t("addPermissionPerProfile")}
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </ResourceAccess>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Stack direction='row' alignItems='center' justifyContent='flex-end'>
              <TextField
                placeholder={t("searchFilter")}
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                    borderRadius: '0.2rem 0 0 0.2rem',
                  },
                  '& fieldset': {
                    borderRightWidth: '0',
                  },
                }}
                value={preFilter}
                onChange={(e: any) => {
                  setPreFilter(e.target.value);
                  if (e.target.value === '' && roleId) {
                    handleFetchData(roleId, 0, '');
                  }
                }}
                onKeyDown={e => e.key === 'Enter' && handleApplyFilter()}
              />
              <Button
                variant='contained'
                color='secondary'
                onClick={handleApplyFilter}
                sx={{
                  borderRadius: '0 0.2rem 0.2rem 0!important',
                  padding: '1.5px 16px!important',
                }}>
                Buscar
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Grid item md={3} sm={6} sx={{ pb: 8 }}>
          <Stack sx={{ justifyContent: 'right' }}>
            <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
              <Button
                variant='contained'
                color='primary'
                sx={{
                  mt: 2,
                  mr: 2,
                  maxWidth: '50%',
                }}
                onClick={authoritySelected.length > 0 ? handleOpenModal : undefined} 
                data-name='delete'>
                {t("deleteSelected")}
              </Button>
            </Box>
            <Stack flexDirection="row" justifyContent="right" alignItems="center">
              <Typography sx={{ fontWeight: 500 }}>
              {t("selectAll")} / {t("clearSelect")}
              </Typography>
              <Checkbox
                checked={authoritySelected.length === (authorityByRoleData?.content.length ?? 0)}
                indeterminate={
                  authoritySelected.length > 0 &&
                  authoritySelected.length < (authorityByRoleData?.content.length ?? 0)
                }
                onChange={() => selectAuthority("selectAll")}
              />
            </Stack>
          </Stack>
        </Grid>


        <Divider />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell align='center'>{t("codes")}</TableCell>
                <TableCell align='center'>{t("permission")}</TableCell>
                <TableCell align='center'>{t("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {authorityByRoleData &&
                authorityByRoleData.content &&
                authorityByRoleData.content.slice(page * 10, page * 10 + 10).map((r: any) => (
                  <TableRow key={r.id}>
                    <TableCell align='center'>{r.authority.code}</TableCell>
                    <TableCell align='center'>{language === "es" ? r.authority.description: r.authority.descriptionEn}</TableCell>
                    <TableCell align='center'>
                      <ResourceAccess isCode={true} pathOrCode={'AUTHORITIESXROLE:WRITE'}>
                        <FancyTooltip title='Eliminar permiso' placement='top'>
                          <IconButton
                            aria-label='create'
                            component='label'
                            color='secondary'
                            sx={{
                              '&:hover': {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            onClick={

                              () => {
                                return selectAuthority(r);
                              }}
                            data-name='create'
                            data-id={r.authorityId}
                          >
                            {authoritySelected.includes(r.authorityId) ? (
                              <CircleCheckIcon sx={{ pointerEvents: 'none' }} />
                            ) : (
                              <CheckCircleOutline />
                            )}
                          </IconButton>
                        </FancyTooltip>


                        {/* <FancyTooltip title='Eliminar' placement='top'>
                          <IconButton
                            aria-label='trash'
                            component='label'
                            color='secondary'
                            sx={{
                              '&:hover': {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            data-id={r.authorityId}
                            onClick={handleOpenModal}
                            data-name='delete'>
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip> */}
                      </ResourceAccess>
                    </TableCell>
                  </TableRow>
                ))}
              {!authorityByRoleData ||
                !authorityByRoleData.content ||
                (authorityByRoleData.content?.length <= 0 && (
                  <TableRow>
                    <TableCell colSpan={4} align='center'>
                    {t("thereAreNoRecords")}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={authorityByRoleData ? authorityByRoleData?.content.length : 0}
          rowsPerPage={10}
          page={page}
          onPageChange={handleChangePage}
          totalElements={authorityByRoleData?.content.length}
          totalPages={Math.ceil(authorityByRoleData?.content.length / 10)}
        />
      </FancyPaper>
      {modalData?.modalType === 'create' && (
        <Dialog open={modalData.modalOpen} onClose={handleCancelModal} fullWidth maxWidth='md'>
          <AddAuthorityByRole
            data={roleId}
            onSubmit={onSubmit}
            refresh={handleFetchData}
            cancelModal={handleCancelModal}
            authorityDataProp={authorityDataProp}
          />
        </Dialog>
      )}
      {modalData?.modalType === 'delete' && (
        <Dialog open={modalData.modalOpen} onClose={handleCancelModal} fullWidth>
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.code}
            actionButton={
              <>
                <Button
                  variant='contained'
                  color='primary'
                  sx={{
                    mt: 2,
                    mr: 2,
                    maxWidth: '50%',
                  }}
                  onClick={() => handleDelete(authoritySelected)}
                  data-name='delete'>
                 {t("deleteSelected")}
                </Button>
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default SettingAuthorityByRoleManagement;
