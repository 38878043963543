import React from "react";
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    styled,
} from "@mui/material";
import CountryFlag from "./CountryFlag";
import { useTranslation } from "react-i18next";


interface CountryOption {
    id: number;
    name: string;
    areaCode: string;
    flag: string | null;
    userCreated: string;
    userModified: string;
}

interface Props {
    value: number | null;
    onChange: (...event: any[]) => void;
    options: { content: CountryOption[] } | null;
    error?: string;
    selectedCountry: CountryOption | null;
    setSelectedCountry: (country: CountryOption | null) => void;
}


const InputLabelForm = styled(InputLabel)(({ theme }) => ({
    transform: "translate(0px, 5px) scale(0.75)",
}));

const CountrySelector: React.FC<Props> = ({ value, onChange, options, error, selectedCountry, setSelectedCountry }) => {
    const { t } = useTranslation();
    const handleChange = (event: SelectChangeEvent<string | number>) => {
        const selectedValue = event.target.value;
        const parsedValue = typeof selectedValue === "string" ? parseInt(selectedValue) : selectedValue;

        // Buscar el país seleccionado en base al ID
        const country = options?.content.find(option => option.id === parsedValue);

        // Actualizar el estado selectedCountry con el país seleccionado
        setSelectedCountry(country || null);
        // Llamar a onChange con el ID del país seleccionado
        onChange(parsedValue);
    };

    if (!options || !options.content) {
        return <div>Loading countries...</div>;
    }

    return (
        <FormControl fullWidth error={!!error}>
            <InputLabelForm>{t("country")}</InputLabelForm>
            <Select
                variant="standard"
                value={value || ""}
                onChange={handleChange}
                displayEmpty
                renderValue={(selected) => {
                    const selectedCountry = options.content.find(option => option.id === selected);
                    return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                        {selectedCountry && (
                                <CountryFlag areaCode={selectedCountry.areaCode} size={20} />
                            )}
                            {selectedCountry
                                ? `${selectedCountry.name} (${selectedCountry.areaCode})`
                                : t("selectCountry")}
                        </div>
                    );
                }}
            >
                <MenuItem value="">{t("selectCountry")}</MenuItem>
                {options.content.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option && (
                            <CountryFlag areaCode={option.areaCode} size={20} />
                        )}
                        {option.name} ({option.areaCode})
                    </MenuItem>
                ))}
            </Select>
            {error && <FormHelperText>Campo es requerido</FormHelperText>}
        </FormControl>
    );
};

export default CountrySelector;
