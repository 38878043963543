import { yupResolver } from "@hookform/resolvers/yup";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    TextField,
    styled
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useGuests } from "../../../pages/maintenance/guests/GuestsContext";
import { Content } from "../../../services/guests/GuestInterface";
import { properties } from "../../../utils/Properties_es";
import CountrySelector from "../countrySelector/CountrySelector";
import { useTranslation } from "react-i18next";

const Title = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: "#e1e8ee",
    color: "#3e627c!important",
    fontFamily: "D-dinExp",
    fontWeight: 400,
    fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
    borderTop: "1px solid #dee2e6",
}));

interface DialogProps {
    actionButton?: any;
    data: Content;
    onSubmit: any;
    cancelModal: any;
    modalType?: any;
}

const AddOrUpdateGuestModal = ({
    onSubmit,
    cancelModal,
    modalType,
}: DialogProps) => {
    const { t } = useTranslation();

    const { countryData,
        apartmentDataById,
        selectedCountry,
        defaultValues,
        guestValidationSchema,
        setSelectedCountry,
        setDefaultValues,
    } = useGuests();

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<Content>({
        defaultValues: defaultValues,
        resolver: yupResolver(guestValidationSchema),
        mode: "onChange",
    });

    return (
        <>
            <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
                {modalType === "update"
                    ? t("updateGuest")
                    : t("addGuest")}
            </Title>
            <DialogContent sx={{ px: 4, pb: 0 }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                label={t("apartment")}
                                variant="standard"
                                autoComplete="apartment"    
                                value={defaultValues.apartment} 
                                {...register("apartment")}
                                onChange={(e) => setDefaultValues({ ...defaultValues, apartment: e.target.value })}
                                InputProps={{ readOnly: apartmentDataById.length === 1 }}
                            >
                                {apartmentDataById.map((apartment: any) => (
                                    <MenuItem key={apartment.name} value={apartment.name}>
                                        {apartment.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                label={t("name")}
                                type="text"
                                variant="standard"
                                autoComplete="name"
                                {...register("name")}
                                error={errors.name && Boolean(errors.name)}
                                helperText={errors.name && errors.name.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                label={t("lastName")}
                                type="text"
                                variant="standard"
                                autoComplete="lastName"
                                {...register("lastName")}
                                error={errors.lastName && Boolean(errors.lastName)}
                                helperText={errors.lastName && errors.lastName.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name={'country'}
                                control={control}
                                render={({ field: { onChange, value }
                                }) => (
                                    <CountrySelector
                                        value={value || null}
                                        selectedCountry={selectedCountry}
                                        onChange={onChange}
                                        setSelectedCountry={setSelectedCountry}
                                        error={errors.country?.message}
                                        options={countryData} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                label={t("identification1")}
                                type="text"
                                variant="standard"
                                autoComplete="identification"
                                {...register("identification")}
                                error={errors.identification && Boolean(errors.identification)}
                                helperText={errors.identification && errors.identification.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                label={t("vehicleLicensePlate")}
                                type="text"
                                variant="standard"
                                autoComplete="carPlate"
                                {...register("carPlate")}
                                error={errors.carPlate && Boolean(errors.carPlate)}
                                helperText={errors.carPlate && errors.carPlate.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                label={t("email")}
                                type="text"
                                variant="standard"
                                autoComplete="email"
                                {...register("email")}
                                error={errors.email && Boolean(errors.email)}
                                helperText={errors.email && errors.email.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                label={t("startDate")}
                                type="datetime-local"
                                variant="standard"
                                autoComplete="startDate"
                                {...register("startDate")}
                                error={errors.startDate && Boolean(errors.startDate)}
                                helperText={errors.startDate && errors.startDate.message}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    min: '1000-01-01T00:00',
                                    max: '9999-12-31T23:59',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                label={t("endDate")}
                                type="datetime-local"
                                variant="standard"
                                autoComplete="endDate"
                                {...register("endDate")}
                                error={errors.endDate && Boolean(errors.endDate)}
                                helperText={errors.endDate && errors.endDate.message}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    min: '1000-01-01T00:00',
                                    max: '9999-12-31T23:59',
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Actions sx={{ pb: 4, px: 4 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{
                                mt: 2,
                                mr: 2,
                            }}
                            onClick={handleSubmit(onSubmit)}
                        >
                            {t("save")}
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{
                                mt: 2,
                                mr: 2,
                            }}
                            onClick={cancelModal}
                            autoFocus
                        >
                            {t("cancel")}
                        </Button>
                    </Actions>
                </form>
            </DialogContent>
        </>
    );
};

export default AddOrUpdateGuestModal;
