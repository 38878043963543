import { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  TextField,
  Button,
  Stack,
  Dialog,
} from "@mui/material";
import {
  KeyIcon,
  TrashIcon,
  PlusIcon,
  PenIcon,
  ConfigIcon,
} from "../../../components/svgicons/SvgIcons";
import FancyTooltip from "../../../components/utils/FancyTooltip";
import FancyPaper from "../../../components/FancyPaper";
import { useForm } from "react-hook-form";
import {
  getRequestBooking,
  createRequest,
  uploadDocuments,
  updateRequest,
  updateInfoConfRequest,
  findDocuments,
  getUrlImage,
  deleteDocuments,
  deleteRequest,
} from "../../../services/booking/BookingService";
import { properties } from "../../../utils/Properties_es";
import { useApp } from "../../../hooks/useApp";
import AddOrUpdateBookingModal from "../../../components/utils/AddOrUpdateBookingModal";
import DeleteModal from "../../../components/utils/DeleteModal";
import UpdateBookingModal from "../../../components/utils/UpdateBookingModal";
import FancyTablePagination from "../../../components/utils/FancyTablePagination";
import ResourceAccess from "../../../components/security/ResourceAccess";
import { data } from "../../../data/data";
import { useTranslation } from "react-i18next";

interface AddOrUpdateBookingModal {
  id?: number;
  name: string;
  description: string;
  status: string;
  logo?: File | null;
  file?: File | null;
  oldFile?: File | null;
}

interface UpdateBookingModal {
  id?: number;
  description: string;
  status: string;
  weeklyHours: string;
  weekdaysStartTime: string;
  weekdaysEndTime: string;
  weekendsStartTime: string;
  weekendsEndTime: string;
  typeDescription: string;
  publicPrice: string;
  colegiadoPrice: string;
  areaDetails: string;
  file?: File | null;
}

const SettingBooking = () => {
  const { t } = useTranslation();
  const {
    authInfo,
    company,
    isAdmin,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const theme = useTheme();
  const [installationData, setInstallationData] = useState<any>([]);
  const [preFilter, setPreFilter] = useState<any>("");
  const [page, setPage] = useState(0);
  const [filteredPhotos, setFilteredPhotos] = useState<any>([]);
  const [logosByArea, setLogosByArea] = useState<any>({});
  const [images, setImages] = useState<any>({});
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const initForm = {
    id: 0,
    description: "",
    status: "",
  };

  const [formData, setFormData] = useState<any>(initForm);

  const { handleSubmit } = useForm();

  console.log(formData);

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "");
    };
    dataInit();

    // eslint-disable-next-line
  }, [company]);

  /**
   * Evento de aplicar filtro de busqueda
   */
  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(0, preFilter);
    }
  };

  const getPhotos = async (idArea: string, areaDetailId?: string) => {
    const images: any = await findDocuments(
      isAdmin ? company : authInfo.companyId,
      idArea,
      areaDetailId
    );

    return images;
  };

  useEffect(() => {
    const fetchLogos = async () => {
      const logos: any = {};
      const images: any = {}; // Nuevo objeto para almacenar los logos por área

      for (const area of installationData.content ?? []) {
        const areaId = area.id;
        const areaImages: any = await getPhotos(areaId); // Obtener todas las imágenes del área
      
        const filteredImages = areaImages.filter(
          (img: any) => img.idDocumentCategory.id === 2
        );

        images[areaId] = filteredImages; 

        const filteredPhotosIds = filteredImages.map((img: any) => img.id); // Obtiene los IDs de las imágenes filtradas

        const imageUrlPromises = filteredPhotosIds.map(async (id: string) => {
          try {
            const imageUrl = await getUrlImage(id); // Obtiene la URL de la imagen utilizando el ID de la imagen
            return imageUrl;
          } catch (error) {
            console.error(`Error fetching image for area ${areaId}:`, error);
            return null; // Si hay un error, devuelve null para esa imagen
          }
        });

        const imageUrlResults = await Promise.all(imageUrlPromises); // Espera a que se completen todas las promesas de URL de imágenes

        // Filtra las URL de las imágenes válidas (no null)
        const validImageUrls = imageUrlResults.filter((url: any) => url !== null);

        logos[areaId] = validImageUrls; // Almacena las URL de las imágenes en logos
      }

      setLogosByArea(logos); // Actualiza el estado con las URLs de las imágenes
      setImages(images); // Actualiza el estado con los logos por área
    };

    fetchLogos();
  }, [installationData]);

  /**
   *
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (
    currentPage: number,
    filter: string,
    id?: string
  ) => {
    setLoading && setLoading(true);
    setInstallationData([]);
    try {
      setPreFilter(filter);
      setPage(currentPage);

      let bookingData = await getRequestBooking(
        currentPage,
        filter,
        isAdmin ? company : authInfo.companyId
      );
      if (bookingData) {
        setInstallationData(bookingData);
      }

      if (id) {
        const images: any = await getPhotos(id);
        const filteredImages = images?.filter(
          (img: any) => img.idDocumentCategory.id === 1
        );
        setFilteredPhotos(filteredImages);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      let createData = await createRequest(
        {
          ...data,
          userCreated: authInfo.username,
          status: data.status.id,
        },
        isAdmin ? company : authInfo.companyId
      );

      const idArea = createData.id;

      if (!createData) {
        setErrorMsg &&
          setErrorMsg && setErrorMsg(t("errorCreatingInstallation"));
        setLoading && setLoading(false);
        return;
      }
      if (!!data.documents.length) {
        const formData = new FormData();
        formData.append("idDocumentCategory", "1");
        formData.append("idCompany", isAdmin ? company : authInfo.companyId);
        formData.append("idArea", idArea);
        for (let i = 0; i < data.documents.length; i++) {
          formData.append("files", data.documents[i]);
        }
        formData.append("user", authInfo.username);
        await uploadDocuments(formData);
      }

      if (!!data.logo.length) {
        const formData = new FormData();
        formData.append("idDocumentCategory", "2");
        formData.append("idCompany", isAdmin ? company : authInfo.companyId);
        formData.append("idArea", idArea);
        for (let i = 0; i < data.logo.length; i++) {
          formData.append("files", data.logo[i]);
        }
        formData.append("user", authInfo.username);
        await uploadDocuments(formData);
      }

      if (createData.code === "001") {
        setErrorMsg && setErrorMsg(createData.message);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(t("successfullyCreatedInstallation"));
      //call fetch data
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    console.log(data);
    console.log(filteredPhotos);
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      let updateData = await updateRequest(
        {
          ...data,
          modifierUser: authInfo?.username,
          status: data.status.id,
        },
        isAdmin ? company : authInfo.companyId
      );

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(t("errorUpdatingInstallation"));
        setLoading && setLoading(false);
        return;
      }

      if (data.documents.length > filteredPhotos.length) {
        for (let i = 0; i < data.documents.length; i++) {
          const element = data.documents[i];
          if (!!element) {
            const formData = new FormData();
            formData.append("idDocumentCategory", "1");
            formData.append(
              "idCompany",
              isAdmin ? company : authInfo.companyId
            );
            formData.append("idArea", data.id);
            formData.append("files", element);
            formData.append("user", authInfo.username);
            await uploadDocuments(formData);
          }
        }
      } else {
        for (let i = 0; i < data.documents.length; i++) {
          const element = data.documents[i];
          if (!!element) {
            const deletePhoto = await deleteDocuments(
              filteredPhotos[i].id,
              authInfo.username
            );
            console.log(deletePhoto);

            const formData = new FormData();
            formData.append("idDocumentCategory", "1");
            formData.append(
              "idCompany",
              isAdmin ? company : authInfo.companyId
            );
            formData.append("idArea", data.id);
            formData.append("files", element);
            formData.append("user", authInfo.username);
            await uploadDocuments(formData);
          }
        }
      }

      const logoUpdate = data.logo[0];

      if (!!logoUpdate) {
        if (images[data.id].length > 0) {
          // Iterar sobre los logos relacionados con el área
          images[data.id].forEach(async (logo: any) => {
            // Acceder al ID de cada logo y eliminarlo
            const deletePhoto = await deleteDocuments(
              logo.id,
              authInfo.username
            );
            console.log(deletePhoto);
          });
        }
        const formData = new FormData();
        formData.append("idDocumentCategory", "2");
        formData.append("idCompany", isAdmin ? company : authInfo.companyId);
        formData.append("idArea", data.id);
        formData.append("files", logoUpdate);
        formData.append("user", authInfo.username);
        await uploadDocuments(formData);
      }

      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(t("installationUpdatedCorrectly"));
      //call fetch data
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de actualizar los datos de las configuraciones
   * @param data
   */
  const handleInstallationUpdate = async (data: any, id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let hasError = false;
      let nameError = "";

      for (let i = 0; i < data.installations.length; i++) {
        let updateData = await updateInfoConfRequest(
          {
            ...data.installations[i],
            userModified: authInfo?.username,
            idArea: id,
          },
          isAdmin ? company : authInfo.companyId
        );

        if (updateData.code === "001") {
          hasError = true;
          nameError += `${data.installations[i].description}; `;
        }
      }

      if (hasError) {
        setErrorMsg && setErrorMsg(`${nameError} No se pudo actualizar`);
        setLoading && setLoading(false);
        return;
      }

      for (const installation of data.installations) {
        const documents = installation.documents;

        for (let i = 0; i < documents.length; i++) {
          const document = documents[i];
          if (!!document) {
            console.log(document);
          }
        }
      }

      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_booking_update_conf_sucess);

      //call fetch data
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      //call service
      let deleteData = await deleteRequest(
        isAdmin ? company : authInfo.companyId,
        id
      );
      if (!deleteData) {
        setErrorMsg &&
          setErrorMsg(t("errorDeletingRecord"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(t("recordDeletedSuccessfully"));
      //reset page and call fetch data
      setPage(0);
      await handleFetchData(0, preFilter);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");

    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "update" || modalAction === "updateInstallation") {
      object = installationData.content.find((p: any) => p.id === parseInt(id));
      setFormData(object);

      const images: any = await getPhotos(id);

      const filteredImages = images?.filter(
        (img: any) => img.idDocumentCategory.id === 1
      );

      setFilteredPhotos(filteredImages);
    }

    if (modalAction === "delete") {
      object = installationData.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        //@ts-ignore
        modalObject: object,
      });
  };

  /**
   * Evento de cierre de modal
   * @param event
   */
  const handleCancelModal = () => {
    //@ts-ignore
    if (modalData?.modalType !== "delete") {
      setFormData(initForm);
    }
    setFilteredPhotos([]);
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }

    /* if (customPage !== page) {
      await handleFetchData(customPage, filter);
    } */
    //await handleFetchData(0, filter);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "updateInstallation":
        //@ts-ignore
        await handleInstallationUpdate(data, modalData?.modalObject?.id);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
    if (modalData?.modalType !== "delete") {
      setFormData(formData);
    }
  };
  return (
    <>
      {/* <img src={Banner} width="100%" /> */}
      <FancyPaper
        pagetitle={`${t("maintenance")} / ${t(
          "facilities"
        )}`} /* pagetitle="Mantenimiento / Instalaciones" */
      >
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={9} sm={6} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={"USER:WRITE"}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                data-name="create"
              >
                {t("addInstallation")}
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </ResourceAccess>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t("name")}</TableCell>
                <TableCell align="center">{t("description")}</TableCell>
                <TableCell align="center">{t("state")}</TableCell>
                <TableCell align="center">{t("logo")}</TableCell>
                <TableCell align="center">{t("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {installationData &&
                installationData.content &&
                installationData.content.map((row: any, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.description}</TableCell>
                    <TableCell align="center">
                      {row.status === 0 ? t("active") : t("idle")}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ padding: "8px", maxWidth: "100px" }}
                    >
                      <img
                        src={logosByArea[row.id]?.[0]}
                        style={{ width: "27%", height: "auto" }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <ResourceAccess isCode={true} pathOrCode={"USER:WRITE"}>
                        <FancyTooltip title={t("edit")} placement="top">
                          <IconButton
                            aria-label="edit"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            onClick={handleOpenModal}
                            data-name="update"
                            data-id={row.id}
                          >
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip
                          title={t("confFacilities")}
                          placement="top"
                        >
                          <IconButton
                            aria-label="sorter"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            data-id={row.id}
                            onClick={handleOpenModal}
                            data-name="updateInstallation"
                          >
                            <ConfigIcon />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip title={t("delete")} placement="top">
                          <IconButton
                            aria-label="trash"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            data-id={row.id}
                            onClick={handleOpenModal}
                            data-name="delete"
                          >
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip>
                      </ResourceAccess>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            installationData?.content?.length > 0
              ? installationData?.content?.length
              : 0
          }
          rowsPerPage={installationData.size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={installationData.totalElements}
          totalPages={installationData.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
          <Dialog
            open={modalData.modalOpen}
            onClose={handleCancelModal}
            fullWidth
          >
            <AddOrUpdateBookingModal
              data={formData}
              filteredPhotos={filteredPhotos}
              refreshData={handleFetchData}
              setFilteredPhotos={setFilteredPhotos}
              logosByArea={images}
              onSubmit={onSubmit}
              cancelModal={handleCancelModal}
              modalType={modalData?.modalType}
            />
          </Dialog>
        )}
      {modalData?.modalType === "updateInstallation" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <UpdateBookingModal
            data={formData}
            filteredPhotos={filteredPhotos}
            refreshData={handleFetchData}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
            areaHasCapacity={formData?.capacity !== null && formData?.capacity !== 0}
            bookingTime={formData?.bookingTime}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("delete")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  {t("cancel")}
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default SettingBooking;
