import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import { formatDate } from "../../utils/UtilsFunctions";
import UrlBase from "../../url/Urls";
import { newsCategoryResponse } from "./NewsInterface";

export const getAllNewsCategory = async (
  page: number,
  companyId: number,
  size?:string,
  filter?: string
) => {
  
  let siz:any = size !== "" ? size : UrlBase.registration_x_page
  try {
    const response = await privateFetch.get(
      URL.REQUEST_CATEGORIES(
        page,
        siz,  
        companyId,      
        filter ?? ""
      )
    );
    const responseData = response.data;

    return responseData;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const addNewsCategory = async (option: string, data: any) => {
  console.log([data])
  let responseData = {};
  await privateFetch
    .post(URL.REQUEST_CATEGORIES_CRUD(option), [data])
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const updateNewsCategory = async (option: string, data: newsCategoryResponse|any) => {
  try {
    const response = await privateFetch.put(URL.REQUEST_CATEGORIES_CRUD(option), data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || "Error desconocido";
    throw errorMessage;
  }
};

export const deleteNewsCategory = async (option: string) => {
  try {
    const response = await privateFetch.delete(URL.REQUEST_CATEGORIES_CRUD(option));
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || "Error desconocido";
    throw errorMessage;
  }
};