import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdate {
  planDetail: PlanDetail;
}

interface PlanDetail {
  id: number;
  reservationAmount: number;
  price: number;
  initialDate: string;
  expirationDate: string;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  modalType?: any;
  refreshData: any;
}

const TabPlanCompany = ({
  data,
  modalType,
  refreshData,
}: DialogProps) => {
  const { t } = useTranslation();

  // Validación de datos
  const validationSchema = Yup.object().shape({
    planDetail: Yup.object().shape({
      reservationAmount: Yup.number()
        .required(t("fieldRequired"))
        .typeError(t("fieldNumeric")),
      price: Yup.number()
        .required(t("fieldRequired"))
        .typeError(t("fieldNumeric")),
      initialDate: Yup.date().required(t("fieldRequired")),
      expirationDate: Yup.date().required(t("fieldRequired")),
    }),
  });

  const formatDateString = (dateString: string) => {
    return dateString ? dateString.substring(0, 10) : '';
  };

  const defaultValues = {
    planDetail: data ? {
      ...data,
      initialDate: formatDateString(data.initialDate),
      expirationDate: formatDateString(data.expirationDate)
    } : {
      reservationAmount: 0,
      price: 0,
      initialDate: '',
      expirationDate: '',
    },
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AddOrUpdate>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "update" ? t("updatePlan") : t("addPlan")}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name="planDetail.reservationAmount"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("reservationAmount")}
                    type="text"
                    variant="standard"
                    value={value}
                    disabled
                    autoComplete="planDetail.reservationAmount"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("planDetail.reservationAmount")}
                    error={!!errors.planDetail?.reservationAmount}
                    helperText={errors.planDetail?.reservationAmount?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="planDetail.price"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("price")}
                    type="text"
                    variant="standard"
                    value={value}
                    disabled
                    autoComplete="planDetail.price"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("planDetail.price")}
                    error={!!errors.planDetail?.price}
                    helperText={errors.planDetail?.price?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="planDetail.initialDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("initialDate")}
                    type="date"
                    variant="standard"
                    value={value}
                    disabled
                    autoComplete="planDetail.initialDate"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("planDetail.initialDate")}
                    error={!!errors.planDetail?.initialDate}
                    helperText={errors.planDetail?.initialDate?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="planDetail.expirationDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("expirationDate")}
                    type="date"
                    variant="standard"
                    value={value}
                    disabled
                    autoComplete="planDetail.expirationDate"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("planDetail.expirationDate")}
                    error={!!errors.planDetail?.expirationDate}
                    helperText={errors.planDetail?.expirationDate?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </>
  );
};

export default TabPlanCompany;
