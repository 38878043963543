/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";
import FancyPaper from "../components/FancyPaper";
import {
    PenIcon,
    PlusIcon,
    TrashIcon
} from "../components/svgicons/SvgIcons";
import AddOrUpdateApartmentsModal from "../components/utils/AddOrUpdateApartmentModal";
import AddOrUpdateNotificationsModal from "../components/utils/AddOrUpdateNotificationsModal";

import DeleteModal from "../components/utils/DeleteModal";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import FancyTooltip from "../components/utils/FancyTooltip";
import { useApp } from "../hooks/useApp";
import {
    createRequest,
    updateRequest,
    deleteRequest,
    getRequest,
    getRequestByCompany,
} from "../services/notifications/NotificationService";
import { properties } from "../utils/Properties_es";
import ResourceAccess from "../components/security/ResourceAccess";
import { StatusSearchApartment } from "../types/ServiceTypes";



const SettingNotifications = () => {
    const { t } = useTranslation();
    const {
        authInfo,
        company,
        isAdmin,
        setLoading,
        setErrorMsg,
        modalData,
        setModalData,
        setSuccessMsg,
        errorMsg,
    } = useApp();
    const theme = useTheme();
    const [notificationData, setNotificationData] = useState<any>([]);
    const initForm = {
        id: 0,
        status: 0,
        name: "",
        company: 0,
        user: null
    };
    const [formData, setFormData] = useState<any>(initForm);
    const { handleSubmit } = useForm();
    const [page, setPage] = useState(0);
    const [preFilter, setPreFilter] = useState<string | undefined>('');

    console.log(notificationData)

    /**
     * Efecto inicial para carga de registros
     */
    useEffect(() => {
        const dataInit = async () => {
            await handleFetchData(0, "");
        };
        dataInit();
    }, [company]);


    /**
     * Metodo encargado de buscar registros
     * @param filter
     * @param currentPage
     */
    const handleFetchData = async (
        currentPage?: number,
        filter?: string,
        applyRole?: any
    ) => {
        setLoading && setLoading(true);
        setNotificationData([]);
        try {
            //setPage(currentPage);
            let data = await getRequestByCompany(currentPage, 10,isAdmin ? company : authInfo.companyId);
            if (data) {
                setNotificationData(data);
            }
            setLoading && setLoading(false);
        } catch (error: any) {
            setLoading && setLoading(false);
            setErrorMsg && setErrorMsg(error.message);
        }
    };

    /**
     * Metodo encargado de crear registro
     * @param data
     */
    const handleAdd = async (data: any) => {
        handleCancelModal();
        setLoading && setLoading(true);
        try {
            let createData = await createRequest({
                ...data,
                state: "0",
                userCreated: authInfo?.username,
            });

            if (!createData) {
                setErrorMsg && setErrorMsg(t("errorCreatingRecord"));
                setLoading && setLoading(false);
                return;
            }
            setLoading && setLoading(false);
            setSuccessMsg &&
                setSuccessMsg(t("successfullyCreatedRecord"));
            //call fetch data
            await handleFetchData(0, "");
        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
            setLoading && setLoading(false);
        }
    };

    /**
     * Metodo encargado de actualizar registro
     * @param data
     */
    const handleUpdate = async (data: any) => {
        console.log(data)
        handleCancelModal();
        try {
            let updateData = await updateRequest({
                ...data,
                state: "1",
                userModified: authInfo?.username,

            });

            if (!updateData) {
                setErrorMsg &&
                    setErrorMsg(t("errorUpdatingRecord"));
                // setLoading && setLoading(false);
                return;
            }
            // setLoading && setLoading(false);
            // setSuccessMsg &&
            //     setSuccessMsg(t("recordUpdatedCorrectly"));
            //call fetch data
            await handleFetchData();
        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
            // setLoading && setLoading(false);
        }
    };

    /**
     * Metodo encargado de eliminar registro
     * @param data
     */
    const handleDelete = async (id: any) => {
        handleCancelModal();
        setLoading && setLoading(true);
        try {
            let deleteData = await deleteRequest(id);
            if (!deleteData) {
                setErrorMsg &&
                    setErrorMsg(t("errorDeletingRecord"));
                setLoading && setLoading(false);
                return;
            }
            setLoading && setLoading(false);
            setSuccessMsg &&
                setSuccessMsg(t("recordDeletedSuccessfully"));

            //reset page and call fetch data
            await handleFetchData();
        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
            setLoading && setLoading(false);
        }
    };

    /**
     * Efecto para validar errores en caso de abrir modal
     */
    useEffect(() => {
        if (modalData && modalData?.modalOpen && errorMsg) {
            setModalData &&
                setModalData({
                    modalOpen: false,
                    modalType: "",
                    modalObject: null,
                });
        }
    }, [modalData, errorMsg, setModalData]);

    /**
     * Evento de apertura de modal
     */
    const handleOpenModal = async (event: any) => {
        event.preventDefault();
        const modalAction = event.currentTarget.getAttribute("data-name");
        let object = null;
        const id = event.currentTarget.getAttribute("data-id");
        if (modalAction === "update") {
            object = notificationData.content.find((p: any) => p.id === parseInt(id));
        }

        if (modalAction === "delete") {
            object = notificationData.content.find((p: any) => p.id === parseInt(id));
        }

        //open modal
        setModalData &&
            setModalData({
                ...modalData,
                modalOpen: true,
                modalType: modalAction,
                modalObject: object,
            });
    };

    /**
     * @param event
     */
    const handleCancelModal = () => {
        setFormData(initForm);
        setModalData &&
            setModalData({
                ...modalData,
                modalOpen: false,
                modalType: "",
                modalObject: null,
            });
    };

    const onSubmit = async (data: any) => {
        switch (modalData?.modalType) {
            case "create":
                await handleAdd(data);
                break;
            case "update":
                await handleUpdate(data);
                break;
            case "delete":
                //@ts-ignore
                await handleDelete(modalData?.modalObject?.id);
                break;
            default:
                break;
        }
    };

    const handleChangePage = async (event: unknown, newPage: number) => {
        const customPage = newPage - 1;
        setPage(customPage);
        if (customPage !== page) {
            await handleFetchData(customPage, preFilter);
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
    };

    /**
     * Evento de aplicar filtro de busqueda
     */
    const handleApplyFilter = async () => {
        if (preFilter !== "") {
            await handleFetchData(0, preFilter);
        }
    };

    // const onChangeOptions = (e: any) => {
    //   setStatus(e.target.value);
    // };

    return (
        <>
            <FancyPaper pagetitle={`${t("maintenance")} / ${t("notifications")}`}>
                <Grid container spacing={8} sx={{ pb: 8 }}>
                    <Grid item md={12} sm={6} xs={12}>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                spacing={2}
                            >
                                <TextField
                                    placeholder={t("searchFilter")}
                                    sx={{
                                        width: "100%",
                                        "& .MuiInputBase-root": {
                                            borderRadius: "0.2rem 0 0 0.2rem",
                                        },
                                        "& fieldset": {
                                            borderRightWidth: "0",
                                        },
                                    }}
                                    value={preFilter}
                                    onChange={(e: any) => {
                                        setPreFilter(e.target.value);
                                        if (e.target.value === "") {
                                            handleFetchData(0, "");
                                        }
                                    }}
                                    onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
                                />
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleApplyFilter}
                                    sx={{
                                        borderRadius: "0 0.2rem 0.2rem 0!important",
                                        padding: "1.5px 16px!important",
                                    }}
                                >
                                    {t("search")}
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">{t("id")}</TableCell>
                                <TableCell align="center">{t("name")}</TableCell>
                                <TableCell align="center">{t("lastName")}</TableCell>
                                <TableCell align="center">{t("email")}</TableCell>
                                <TableCell align="center">{t("phone")}</TableCell>
                                <TableCell align="center">{t("dateSent")}</TableCell>
                                <TableCell align="center">{t("state")}</TableCell>
                                <TableCell align="center">{t("dateRead")}</TableCell>
                                <TableCell align="center">{t("actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {notificationData &&
                                notificationData.content &&
                                notificationData.content.map((row: any, i: number) => (
                                    <TableRow
                                        key={i}
                                        sx={{
                                            "&:last-child td, &:last-child th": { border: 0 },
                                        }}
                                    >
                                        <TableCell align="center" component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell align="center">{row.name}</TableCell>
                                        <TableCell align="center">{row.lastName}</TableCell>
                                        <TableCell align="center">{row.mail}</TableCell>
                                        <TableCell align="center">{row.phone}</TableCell>
                                        <TableCell align="center">{row.creationDate.substring(0, 10)}</TableCell>
                                        <TableCell align="center">{row.state === "0" ? t("unread") : t("read")}</TableCell>
                                        <TableCell align="center">{row.modificationDate ? row.modificationDate.substring(0, 10) : t("notAvalible")}</TableCell>
                                        <TableCell align="center">
                                            <ResourceAccess isCode={true} pathOrCode={"NOTIFICATIONS:WRITE"}>
                                                <FancyTooltip title={t("view")} placement="top">
                                                    <IconButton
                                                        aria-label="edit"
                                                        component="label"
                                                        color="secondary"
                                                        sx={{
                                                            "&:hover": {
                                                                color: theme.palette.secondary.dark,
                                                            },
                                                        }}
                                                        onClick={handleOpenModal}
                                                        data-name="update"
                                                        data-id={row.id}
                                                    >
                                                        <VisibilityIcon sx={{ fontSize: "1.5rem" }}/>
                                                    </IconButton>
                                                </FancyTooltip>
                                                {/* <FancyTooltip title={t("delete")} placement="top">
                                                    <IconButton
                                                        aria-label="trash"
                                                        component="label"
                                                        color="secondary"
                                                        sx={{
                                                            "&:hover": {
                                                                color: theme.palette.secondary.dark,
                                                            },
                                                        }}
                                                        data-id={row.id}
                                                        onClick={handleOpenModal}
                                                        data-name="delete"
                                                    >
                                                        <TrashIcon />
                                                    </IconButton>
                                                </FancyTooltip> */}
                                            </ResourceAccess>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <FancyTablePagination
                    count={notificationData?.content?.length > 0 ? notificationData?.content?.length : 0}
                    rowsPerPage={notificationData.size}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    totalElements={notificationData.totalElements}
                    totalPages={notificationData.totalPages}
                />
            </FancyPaper>
            {(modalData?.modalType === "create" ||
                modalData?.modalType === "update") && (
                    <Dialog
                        open={modalData.modalOpen}
                        onClose={handleCancelModal}
                        fullWidth
                    >
                        <AddOrUpdateNotificationsModal
                            data={modalData?.modalObject}
                            onSubmit={onSubmit}
                            cancelModal={handleCancelModal}
                            modalType={modalData?.modalType}
                        //company={company ? company : authInfo.companyId}
                        />
                    </Dialog>
                )}
            {modalData?.modalType === "delete" && (
                <Dialog
                    open={modalData.modalOpen}
                    onClose={handleCancelModal}
                    fullWidth
                >
                    <DeleteModal
                        //@ts-ignore
                        textChildren={modalData?.modalObject?.username}
                        actionButton={
                            <>
                                {" "}
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        mt: 2,
                                        mr: 2,
                                    }}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    {t("delete")}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{
                                        mt: 2,
                                    }}
                                    onClick={handleCancelModal}
                                    autoFocus
                                >
                                    {t("cancel")}
                                </Button>{" "}
                            </>
                        }
                    />
                </Dialog>
            )}
        </>
    );
};

export default SettingNotifications;
