import { yupResolver } from "@hookform/resolvers/yup";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    TextField,
    styled
} from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useApp } from "../../../hooks/useApp";
import { useMessages } from "../../../pages/maintenance/messages/MessagesContext";
import { Content } from "../../../services/messages/MessagesInterface";

const Title = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: "#e1e8ee",
    color: "#3e627c!important",
    fontFamily: "D-dinExp",
    fontWeight: 400,
    fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
    borderTop: "1px solid #dee2e6",
}));

interface DialogProps {
    actionButton?: any;
    data: Content;
    onSubmit: any;
    cancelModal: any;
    modalType?: any;
    isView?: boolean;
}
const AddOrUpdateMessageModal = ({
    onSubmit,
    data,
    cancelModal,
    modalType,
    isView,
}: DialogProps) => {
    const { t } = useTranslation();

    const {
        defaultValues,
        messageValidationSchema,
        setDefaultValues,
        apartmentDataById,
        apartmentDataAll,
        readMessage,
    } = useMessages();

    const {
        isAdmin,
    } = useApp();

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<Content>({
        defaultValues: defaultValues,
        resolver: yupResolver(messageValidationSchema),
        mode: "onChange",
    });

    useEffect(() => {

        if (modalType === "view") {
            readMessage(data.id)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <>
            <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
                {modalType === 'view'
                    ? t("viewMessage")
                    : modalType === 'update'
                        ? t("updateMessage")
                        : t("sendMessage")
                }
            </Title>
            <DialogContent sx={{ px: 4, pb: 0 }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
                        {
                            !isAdmin && modalType !== 'view' && (
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        select
                                        size="small"
                                        label={t("apartment")}
                                        variant="standard"
                                        autoComplete="sourceApartment"
                                        value={defaultValues?.sourceApartment || ''}
                                        {...register("sourceApartment")}
                                        error={errors.sourceApartment && Boolean(errors.sourceApartment)}
                                        helperText={errors.sourceApartment && errors.sourceApartment.message}
                                        onChange={(e) => setDefaultValues({ ...defaultValues, sourceApartment: e.target.value })}
                                        InputProps={{ readOnly: apartmentDataById.length === 1 || isView }}
                                    >
                                        {apartmentDataById.map((sourceApartment: any) => (
                                            <MenuItem key={sourceApartment.name} value={sourceApartment.name}>
                                                {sourceApartment.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>)
                        }
                        {
                            modalType === 'view' &&
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    select
                                    size="small"
                                    label={t("apartment")}
                                    variant="standard"
                                    autoComplete={(data.sourceApartment !== null && data.sourceApartment !== '') ? data.sourceApartment : (data.sourceName !== null ? data.sourceName : '')}
                                    value={data.sourceApartment !== '' ? data.sourceApartment : data.sourceName}
                                    InputProps={{ readOnly: isView }}
                                >
                                    <MenuItem
                                        key={(data.sourceApartment !== null && data.sourceApartment !== '') ? data.sourceApartment : (data.sourceName !== null ? data.sourceName : '')}
                                        value={(data.sourceApartment !== null && data.sourceApartment !== '') ? data.sourceApartment : (data.sourceName !== null ? data.sourceName : '')}
                                    >
                                        {data.sourceApartment !== '' ? data.sourceApartment : data.sourceName}
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        }
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                label={t("addressedTo")}
                                variant="standard"
                                autoComplete="targetApartment"
                                value={defaultValues?.targetApartment || ''}
                                {...register("targetApartment")}
                                onChange={(e) => setDefaultValues({ ...defaultValues, targetApartment: e.target.value })}
                                error={errors.targetApartment && Boolean(errors.targetApartment)}
                                helperText={errors.targetApartment && errors.targetApartment.message}
                                InputProps={{ readOnly: apartmentDataAll.length === 1 || isView }}
                            >
                                {apartmentDataAll.map((targetApartment: any) => (
                                    <MenuItem key={targetApartment.name} value={targetApartment.name}>
                                        {targetApartment.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                label={t("title")}
                                type="text"
                                variant="standard"
                                autoComplete="title"
                                {...register("title")}
                                error={errors.title && Boolean(errors.title)}
                                helperText={errors.title && errors.title.message}
                                InputProps={{ readOnly: isView }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                size="small"
                                label={t("description")}
                                type="text"
                                variant="outlined"
                                autoComplete="message"
                                {...register("message")}
                                error={errors.message && Boolean(errors.message)}
                                helperText={errors.message && errors.message.message}
                                InputProps={{ readOnly: isView }}
                            />
                        </Grid>
                    </Grid>
                    <Actions sx={{ pb: 4, px: 4 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{
                                mt: 2,
                                mr: 2,
                            }}
                            onClick={modalType === 'view' ? cancelModal : handleSubmit(onSubmit)}
                        >
                            {modalType === 'view' ? t("ok") : modalType === 'update' ? t('save') : t("send")}
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{
                                mt: 2,
                                mr: 2,
                            }}
                            onClick={cancelModal}
                            autoFocus
                        >
                            {t("cancel")}
                        </Button>
                    </Actions>
                </form>
            </DialogContent>
        </>
    );
};

export default AddOrUpdateMessageModal;
