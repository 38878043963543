import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  useTheme,
  TextField,
  Button,
  Stack,
  Paper,
  IconButton,
  styled,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { rememberPasword } from "../../types/RememberPassword";
import Footer from "../../components/Footer";
import { SpaceLogo } from "../../components/svgicons/SvgIcons";
import { SVRecoveryPassRequest } from "../../services/security/SecurityService";
import { properties } from "../../utils/Properties_es";
import MessageManager from "../../components/utils/MessageManager";
import Loading from "../../components/utils/Loading";
import { CONSTANT } from "../../utils/Constants";
import { useTranslation } from "react-i18next";

const Content = styled(Stack)(({ theme }) => ({
  minHeight: "100vh",
  padding: theme.spacing(0, 2),
}));

const Span = styled("span")(({ theme }) => ({
  color: "#00aae4",
}));

const LinkTo = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: "none",
}));

const RememberPassword: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    authInfo,
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    successMsg,
    setSuccessMsg,
    errorMsg,
    loginProvider,
    resetErrorMsg,
    resetSuccessMsg,
    language,
    handleLangChange,
  } = useApp();

  const validation = Yup.object().shape({
    username: Yup.string()
      .required("Email es requerido")
      .email("Email es invalido"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<rememberPasword>({
    resolver: yupResolver(validation),
  });

  const revoveryData: any = {
    // JSON data here
  };
  const onSubmit = async (data: any) => {
    try {
      setLoading && setLoading(true);

      //Authenticate user
      const revoveryData: any = await SVRecoveryPassRequest(data);
      setSuccessMsg && setSuccessMsg(t("recoveryEmailSend"));

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg &&
        setErrorMsg(
          error.message
            ? t("errorSendingEmail")
            : properties.com_parval_label_login_general_error
        );
    }
  };

  return (
    <>
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=""
          callback={resetErrorMsg}
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details=""
          callback={resetSuccessMsg}
        />
      )}
      {isLoading && <Loading />}

      <Content
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mt: 0, py: 4 }}
      >
        <SpaceLogo />
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          sx={{
            width: "430px",
            maxWidth: "100%",
            position: "relative",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
              fontSize: "1.50rem",
              color: theme.palette.info.dark,
              mt: `${theme.spacing(2)}!important`,
              mb: `${theme.spacing(2)}!important`,
            }}
          >
            {t("recoverPass")}
          </Typography>
          <FormControl
            sx={{ m: 1, minWidth: 60, position: "absolute", right: 0 }}
          >
            <Select
              value={language}
              autoWidth
              sx={{
                paddingLeft: theme.spacing(4),
                "& .MuiSelect-select": {
                  minWidth: {
                    xs: "30px!important",
                    md: "90px!important",
                  },
                },
              }}
              onChange={(e: any) =>
                handleLangChange && handleLangChange(e.target.value)
              }
            >
              <MenuItem value="en">{t("english")}</MenuItem>
              <MenuItem value="es">{t("spanish")}</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Paper
          sx={{
            width: "430px",
            maxWidth: "100%",
            borderRadius: "25px",
            background: "#F8F5F3",
            marginBottom: `${theme.spacing(3)}!important`,
            padding: {
              xs: theme.spacing(8),
              md: `${theme.spacing(8)} ${theme.spacing(12)}`,
            },
          }}
        >
          <form>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "center",
                    fontSize: "1.75rem",
                    color: theme.palette.primary.main,
                    mt: `${theme.spacing(2)}!important`,
                    mb: `${theme.spacing(2)}!important`,
                  }}
                >
                  {t("recoverPass")}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name={"username"}
                  control={control}
                  render={() => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("email")}
                      type="email"
                      variant="standard"
                      autoComplete="username"
                      {...register("username")}
                      error={errors.username && Boolean(errors.username)}
                      helperText={errors.username && errors.username.message}
                      sx={{
                        "& .MuiInput-root:before": {
                          borderBottomColor: "#8bc8bc!important",
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  data-name="update"
                  name="update"
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                  sx={{
                    borderRadius: "0.25rem",
                    mt: 3,
                  }}
                >
                  {t("send")}
                </Button>
              </Grid>
            </Grid>
          </form>
          <Typography align="center" sx={{ mt: 6 }} variant="body1">
            <LinkTo to="/">{t("returnToLogin")}</LinkTo>
          </Typography>
        </Paper>
        <Footer />
      </Content>
    </>
  );
};

export default RememberPassword;
