import { SecurityOutlined } from "@mui/icons-material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import {
  FromBracketIcon,
  GearIcon,
  HouseIcon,
  TableIcon,
} from "../components/svgicons/SvgIcons";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { MenuItem } from "../types/Menu";

export const MENUITEMS: MenuItem[] = [
  {
    label: "Inicio",
    icon: (
      <HouseIcon
        sx={{ transform: "translateY(-1px)", fontSize: "0.9225rem" }}
      />
    ),
    to: "home",
    permissions: ["is_superuser", "DASHBOARDCHART:WRITE", "is_user"],
    isViewAdmin: true,
  },
  {
    label: "Inicio",
    icon: (
      <HouseIcon
        sx={{ transform: "translateY(-1px)", fontSize: "0.9225rem" }}
      />
    ),
    to: "customerHome",
    permissions: ["is_superuser", "DASHBOARDCHART:READ", "is_user"],
  },
  {
    label: "Noticias",
    icon: (
      <DynamicFeedIcon
        sx={{ transform: "translateY(-1px)", fontSize: "0.9225rem" }}
      />
    ),
    to: "post",
    permissions: ["is_superuser", "POST:READ", "is_user"],
  },
  {
    //label: `${i18next.t("bookings")}`,
    label: "Reservas",
    icon: <TableIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: "Sistema de reservas",
        to: "booking-system",
        permissions: ["is_superuser", "BOOKINGSYSTEM:READ"],
      },
      {
        label: "Listado de reservas",
        to: "booking-list",
        permissions: ["is_superuser", "BOOKINGLIST:READ"],
      },
    ],
    permissions: ["is_superuser", "BOOKING:READ"],
  },

  {
    label: "Mantenimiento",
    icon: <PermDataSettingIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: "Instalaciones",
        to: "settingbooking",
        permissions: ["is_superuser", "INSTALLATION:READ"],
      },
      {
        label: "Asociados",
        to: "associates",
        permissions: ["is_superuser", "ASSOCIATES:READ"],
      },
      {
        label: "Países",
        to: "settingcountries",
        permissions: ["is_superuser", "COUNTRY:READ"],
      },
      {
        label: "Noticias",
        to: "news",
        permissions: ["is_superuser", "NEWS:READ"],
      },
      {
        label: "Categorías de noticias",
        to: "categories",
        permissions: ["is_superuser", "POSTCATEGORIES:READ"],
      },
      {
        label: "Apartamentos",
        to: "apartments",
        permissions: ["is_superuser", "APARTMENT:READ"],
      },
      {
        label: "Notificaciones",
        to: "settingNotifications",
        permissions: ["is_superuser", "NOTIFICATIONS:READ"],
      },
    ],

    permissions: ["is_superuser", "MAINTENANCEMENU:READ"],
  },
  {
    label: "Seguridad",
    icon: <SecurityOutlined />,
    to: null,
    expand: true,
    children: [
      {
        label: "Invitados",
        to: "guests",
        permissions: ["is_superuser", "GUESTS:READ", "is_user"],
      },
      {
        label: "Mensajes",
        to: "message",
        permissions: ["is_superuser", "MESSAGE:READ", "is_user"],
      },
    ],

    permissions: ["is_superuser", "SECURITYMENU:READ"],
  },
  {
    label: "Finanzas",
    icon: <AccountBalanceIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: "Pendientes",
        to: "apartmentspendings",
        permissions: ["is_superuser", "APARTMENTPENDING:READ"],
      },
      {
        label: "Pagados",
        to: "apartmentsPaid",
        permissions: ["is_superuser", "APARTMENTSPAID:READ"],
      },
      {
        label: "Estados financieros",
        to: "settingFinancialStatements",
        permissions: ["is_superuser", "FINANCIALSTATEMENTS:READ"],
      },
    ],

    permissions: ["is_superuser", "FINANCEMENU:READ"],
  },
  {
    label: "Reportes",
    icon: <TextSnippetIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: "Pago de Reservas",
        to: "reportbookingpayment",
        permissions: ["is_superuser", "REPORTBOOKINGPAYMENT:READ"],
      },
      {
        label: "Capacidad de Reservas",
        to: "reportbookingcapacity",
        permissions: ["is_superuser", "REPORTBOOKINGCAPACITY:READ"],
      },
    ],

    permissions: ["is_superuser", "REPORTMENU:READ"],
  },

  {
    label: "Configuración",
    icon: (
      <GearIcon sx={{ transform: "translateY(-1px)", fontSize: "1.025rem" }} />
    ),
    to: null,
    expand: true,
    children: [
      {
        label: "Usuarios",
        to: "settinguser",
        permissions: ["is_superuser", "USER:READ"],
        isViewAdmin: false,
      },
      {
        label: "Perfiles",
        to: "settingroles",
        permissions: ["is_superuser", "ROLE:READ"],
        isViewAdmin: false,
      },
      {
        label: "Permisos",
        to: "settingauthority",
        permissions: ["is_superuser", "AUTHORITY:READ"],
        isViewAdmin: true,
      },
      {
        label: "Métodos de pago",
        to: "settingPayMethod",
        permissions: ["is_superuser", "	PAYMETHOD:READ"],
        isViewAdmin: false,
      },
      {
        label: "Compañías",
        to: "settingcompanies",
        permissions: ["is_superuser", "COMPANY:READ"],
        isViewAdmin: false,
      },
    ],
    permissions: ["is_superuser", "SETTINGSMENU:READ"],
  },
  {
    label: "Cerrar sesión",
    icon: <FromBracketIcon />,
    to: null,

    permissions: ["is_superuser", "is_user"],
  },
];

export const topBarHeight = 64;
export const sideNavWidth = 260;
export const slcProvince = [
  {
    id: "1",
    label: "San José",
  },
  {
    id: "2",
    label: "Alajuela",
  },
  {
    id: "3",
    label: "Cartago",
  },
  {
    id: "4",
    label: "Heredia",
  },
  {
    id: "5",
    label: "Guanacaste",
  },
  {
    id: "6",
    label: "Puntarenas",
  },
  {
    id: "7",
    label: "Limón",
  },
];
export const typeAddCourse = [
  {
    id: "o",
    label: "Opcional",
  },
  {
    id: "s",
    label: "Obligatorio",
  },
];
export const typeStatus = [
  {
    id: "A",
    label: "Activo",
  },
  {
    id: "D",
    label: "Desactivado",
  },
];
export const statusArea = [
  {
    id: "ACTIVE",
    label: "Activo",
    type: "STATUS",
  },
  {
    id: "INACTIVE",
    label: "Inactivo",
    type: "STATUS",
  },
];
export const hourInitEnd = [
  {
    id: "00:00",
    label: "12:00 am",
  },
  {
    id: "01:00",
    label: "01:00 am",
  },
  {
    id: "02:00",
    label: "02:00 am",
  },
  {
    id: "03:00",
    label: "03:00 am",
  },
  {
    id: "04:00",
    label: "04:00 am",
  },
  {
    id: "05:00",
    label: "05:00 am",
  },
  {
    id: "06:00",
    label: "06:00 am",
  },
  {
    id: "07:00",
    label: "07:00 am",
  },
  {
    id: "08:00",
    label: "08:00 am",
  },
  {
    id: "09:00",
    label: "09:00 am",
  },
  {
    id: "10:00",
    label: "10:00 am",
  },
  {
    id: "11:00",
    label: "11:00 am",
  },
  {
    id: "12:00",
    label: "12:00 pm",
  },
  {
    id: "13:00",
    label: "13:00 pm",
  },
  {
    id: "14:00",
    label: "14:00 pm",
  },
  {
    id: "15:00",
    label: "15:00 pm",
  },
  {
    id: "16:00",
    label: "16:00 pm",
  },
  {
    id: "17:00",
    label: "17:00 pm",
  },
  {
    id: "18:00",
    label: "18:00 pm",
  },
  {
    id: "19:00",
    label: "19:00 pm",
  },
  {
    id: "20:00",
    label: "20:00 pm",
  },
  {
    id: "21:00",
    label: "21:00 pm",
  },
  {
    id: "22:00",
    label: "22:00 pm",
  },
  {
    id: "23:00",
    label: "23:00 pm",
  },
];
export const typeAreaSelect = [
  {
    id: 1,
    label: "Reserva por horas y por dias",
  },
  {
    id: 2,
    label: "Reserva mismo precio horas/dias",
  },
  {
    id: 3,
    label: "Reserva solo por horas",
  },
];
export const searchBooking = [
  {
    id: "PEN",
    label: "Reservas pendientes de aprobación",
  },
  {
    id: "EXP",
    label: "Reservas expiradas (2 días)",
  },
  {
    id: "APR",
    label: "Reservas aprobadas",
  },
  {
    id: "REJ",
    label: "Reservas rechazadas",
  },
];
