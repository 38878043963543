import { UrlBase } from "../../url/Urls";
import { CONSTANT } from "../../utils/Constants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { URL } from "../../utils/UrlConstants";
import { ApartmentResponse } from "./ApartmentInterfaces";

export const getRequest = async (page: any,  companyId: number, name: string, status: string, user: string,userId?: number,size?:any): Promise<ApartmentResponse> => {

  try {
    const response = await privateFetch.get(URL.REQUEST_APARTMENT(page, size ?? UrlBase.registration_x_page, companyId, name, status, user, userId ?? 0));
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestById = async (page: any, companyId: number, userId: number, size?:any): Promise<ApartmentResponse> => {

  try {
    const response = await privateFetch.get(URL.REQUEST_APARTMENT_BY_ID(page, size ?? UrlBase.registration_x_page, companyId, userId));
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const createRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    name: data.name,
    status: data.status,
    company: data.company,
    userCreated: data.userCreated
  };
  await privateFetch

    .post(URL.REQUEST_APARTMENT_CREATE(data.user ? data.user.id : 0), requestBody, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      if (error.response?.data?.code && error.response?.data?.code === '002'
        && error.response?.data?.message && error.response?.data?.message !== '')
        throw new Error(error.response.data.message);
      throw new Error(properties.com_spage_label_apartment_button_add_error);
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    name: data.name,
    status: data.status,
    company: data.company,
    userCreated: '',
    userModified: data.userModified,
  };
  await privateFetch
    .put(URL.REQUEST_APARTMENT_UPDATE(data.id, data.user ? data.user.id : 0), requestBody, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      if (error.response?.data?.code && error.response?.data?.code === '002'
        && error.response?.data?.message && error.response?.data?.message !== '')
        throw new Error(error.response.data.message);
      throw new Error(properties.com_spage_label_apartment_button_update_error);
    });
  return responseData;
};


export const deleteRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_APARTMENT_DELETE(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(
        properties.com_parval_label_booking_delete_error
      );
    });
  return responseData;
};