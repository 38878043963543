import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from "yup";
import { useApp } from '../../../hooks/useApp';
import { getRequestById } from '../../../services/apartment/ApartmentService';
import { getRequest } from '../../../services/country/CountryService';
import { Content } from '../../../services/guests/GuestInterface';
import { getAddGuest, getAllGuests, getDeleteGuest, getUpdateGuest } from '../../../services/guests/GuestsService';
import { properties } from '../../../utils/Properties_es';
import { useTranslation } from 'react-i18next';


interface GuestsContextType {
    handleOpenModal: (event: any) => void;
    handleCancelModal: any;
    guestsData: any;
    handleFetchData: () => Promise<void>;
    handleCountriesData: () => Promise<void>;
    countryData: any;
    setSelectedCountry: (value: any) => void;
    selectedCountry: any;
    onSubmit: (event: any) => void;
    handleAdd: (event: any) => void;
    handleDelete: any;
    handleSubmit: any;
    defaultValues: Content;
    setDefaultValues: (value: any) => void;
    guestValidationSchema: any;
    apartmentDataById: any;
}

const GuestsContext = createContext<GuestsContextType | undefined>(undefined);

export const GuestsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { t } = useTranslation();

    const initValues: Content =
    {
        id: null,
        name: '',
        lastName: '',
        country: null,
        identification: '',
        carPlate: '',
        email: '',
        userCreated: '',
        userModified: '',
        apartment: '',
        status: null,
        startDate: '',
        endDate: '',
    }

    const [defaultValues, setDefaultValues] = useState<Content>(initValues);

    const [guestsData, setGuestsData] = useState<any>([]);
    const [countryData, setCountryData] = useState<any>();
    const [selectedCountry, setSelectedCountry] = useState<any>();
    const [apartmentDataById, setApartmentDataById] = useState<{ id: number; name: string }[]>([]);
    const { handleSubmit } = useForm();

    const { setLoading,
        setErrorMsg,
        company,
        authInfo,
        modalData,
        setSuccessMsg,
        isAdmin,
        setModalData,
    } = useApp();

    const guestValidationSchema = Yup.object().shape({
        name: Yup.string().required("Campo es requerido"),
        lastName: Yup.string().required("Campo es requerido"),
        identification: Yup.string().required("Campo es requerido"),
        country: Yup.string().required("Campo es requerido"),
        startDate: Yup.string().test('date', 'La fecha no puede ser anterior a la actual', function(value) {
            if (value === undefined) return false; 
            return new Date(value) > new Date(); 
        }).required("Campo es requerido"),
        endDate: Yup.string().test('date', 'La fecha no puede ser anterior a la actual', function(value) {
            if (value === undefined) return false; 
            return new Date(value) > new Date(); 
        }).required("Campo es requerido"),
    });

    useEffect(() => {
        handleFetchData();
        handleCountriesData();
        fetchApartmentDataById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company])

    const fetchApartmentDataById = async () => {
        try {
            const data = await getRequestById(0, isAdmin && company ? company : authInfo.companyId, authInfo.id); // Modify with your API endpoint
            if (data && data.content && data.content.length > 0) {
                setApartmentDataById(data.content.map((apartment: any) => ({
                    id: apartment.id,
                    name: apartment.name
                })));
            }
        } catch (error) {
            console.error('Error fetching apartment data:', error);
        }
    };

    const handleFetchData = async () => {
        setLoading && setLoading(true);
        setGuestsData([]);
        try {
            const data = await getAllGuests(0, 10, isAdmin && company ? company : authInfo.companyId);
            if (data) {
                setGuestsData(data);
            }
            setLoading && setLoading(false);
        } catch (error: any) {
            setLoading && setLoading(false);
            setErrorMsg && setErrorMsg(error.message);
        }
    };

    const handleOpenModal = async (event: any) => {
        const modalAction = event.currentTarget.getAttribute("data-name");
        let object = null;
        const id = event.currentTarget.getAttribute("data-id");
    
        if (modalAction === "update" || modalAction === "delete") {
            object = guestsData.content.find((p: any) => p.id === parseInt(id));    
            // Si el objeto no es null y el apartamento no es null, establecer defaultValues con el objeto recibido
            if (object) {
                setDefaultValues(object);
            } 
        } else {
            // Si es una acción de creación, establecer el apartamento con el primer valor de la lista
            if (apartmentDataById.length > 0) {
                setDefaultValues({ ...initValues, apartment: apartmentDataById[0].name });
            } else {
                setDefaultValues(initValues);
            }
        }
    
        setModalData && setModalData({
            ...modalData,
            modalOpen: true,
            modalType: modalAction,
            modalObject: object,
        });
    };
    

    const handleCancelModal = () => {
        setDefaultValues(initValues)
        setModalData &&
            setModalData({
                ...modalData,
                modalOpen: false,
                modalType: "",
                modalObject: null,
            });
    };

    const onSubmit = async (data: any) => {
        switch (modalData?.modalType) {
            case "create":
                await handleAdd(data);
                break;
            case "update":
                await handleUpdate(data);
                break;
            case "delete":
                await handleDelete(modalData?.modalObject?.id, isAdmin && company ? company : authInfo.companyId);
                break;
            default:
                break;
        }
    };

    const handleAdd = async (data: any) => {
        handleCancelModal();
        setLoading && setLoading(true);
        try {
            let createData = await getAddGuest({
                ...data,
                userCreated: authInfo?.username,
            },
                isAdmin && company ? company : authInfo.companyId,
            );

            if (!createData) {
                setErrorMsg && setErrorMsg(t("errorCreatingGuest"));
                setLoading && setLoading(false);
                return;
            }
            setLoading && setLoading(false);
            setSuccessMsg &&
                setSuccessMsg(t("successfullyCreatedGuest"));
            await handleFetchData();
        } catch (error: any) {
            setErrorMsg && setErrorMsg(error);
            setLoading && setLoading(false);
        }
    };

    const handleUpdate = async (data: any) => {
        handleCancelModal();
        setLoading && setLoading(true);
        try {
            let updateData = await getUpdateGuest(
                data.id,
                {
                    ...data,
                    userModified: authInfo?.username,
                },
                isAdmin && company ? company : authInfo.companyId,
            );

            if (!updateData) {
                setErrorMsg &&
                setErrorMsg(t("errorUpdatingGuest"));
                setLoading && setLoading(false);
                return;
            }
            setLoading && setLoading(false);
            setSuccessMsg &&
            setSuccessMsg(t("guestUpdatedCorrectly"));
            await handleFetchData();

        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
            setLoading && setLoading(false);
        }
    };

    const handleDelete = async (id: number, countryId: number) => {
        handleCancelModal();
        setLoading && setLoading(true);
        try {
            let deleteData = await getDeleteGuest(id, countryId);
            if (deleteData) {
                setLoading && setLoading(false);
                setSuccessMsg &&  setSuccessMsg(t("recordDeletedSuccessfully"));
                
            }
            await handleFetchData();
        } catch (error: any) {
            setErrorMsg && setErrorMsg(t("errorDeletingRecord"));
            setLoading && setLoading(false);
            return;
        }
    }

    const handleCountriesData = async (
        currentPage?: number) => {
        setLoading && setLoading(true);
        setCountryData([]);
        try {
            let data = await getRequest(0);
            if (data) {
                setCountryData(data);
            }
            setLoading && setLoading(false);
        } catch (error: any) {
            setLoading && setLoading(false);
            setErrorMsg && setErrorMsg(error.message);
        }
    };

    const contextValue: GuestsContextType = {
        setDefaultValues,
        defaultValues,
        handleOpenModal,
        handleCancelModal,
        guestsData,
        handleFetchData,
        handleAdd,
        onSubmit,
        handleCountriesData,
        countryData,
        setSelectedCountry,
        selectedCountry,
        handleDelete,
        handleSubmit,
        guestValidationSchema,
        apartmentDataById,
    };

    return (
        <GuestsContext.Provider value={contextValue}>
            {children}
        </GuestsContext.Provider>
    );
};

export const useGuests = () => {
    const context = useContext(GuestsContext);
    if (!context) {
        throw new Error('useGuests must be used within a GuestsProvider');
    }
    return context;
};