import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router";
import FancyPaper from "../../components/FancyPaper";
import {
  Grid,
  useTheme,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Button,
  IconButton,
  Dialog,
  DialogContent,
} from "@mui/material";
import FancyTooltip from "../../components/utils/FancyTooltip";
import { TrashIcon, PenIcon } from "../../components/svgicons/SvgIcons";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useApp } from "../../hooks/useApp";
import PostCard from "../../components/PostCard";
import {
  AddOrUpdatePostCardType,
  PostListType,
  PostCategoryListType,
} from "../../types/Post";
import { post } from "../post/dataPost";
import { useForm } from "react-hook-form";
import DeleteModal from "../../components/utils/DeleteModal";
import { PlusIcon } from "../../components/svgicons/SvgIcons";
import { useTranslation } from "react-i18next";
import { getAllNews, deleteNews } from "../../services/news/NewsServices";
import { getAllNewsCategory } from "../../services/news/PostCategoryService";
import FindCategoryPost from "./FindCategoryPost";

export const findCategory = ({ id, postCategory }: any) => {
  let findCat: any = "";
  if (postCategory !== null) {
    findCat = postCategory.content?.find((item: any) => item.id === id)?.name;
  }
  return <>{findCat}</>;
};

const News = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    setLoading,
    setErrorMsg,
    setSuccessMsg,
    authInfo,
    modalData,
    setModalData,
    postCategory,
    setPostCategory,
    company,
    handleFetchNews,
    postData,
    setPostData,
  } = useApp();
  const { handleSubmit } = useForm();
  const [page, setPage] = useState<number>(0);
  const [sort, setSort] = useState<string>("ASC");
  const [filter, setFilter] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  // const [postData, setPostData] = useState<PostListType>(post);

  useEffect(() => {
    const getData = async () => {
      if (company !== null) {
        handleFetchNews && handleFetchNews(page, company, filter, status);
        await handleFetchCategory(0, company, "100", "");
      }
    };
    getData();
  }, [company]);

  /*   const handleFetch = async (
    page: number,
    sort: string,
    companyId: number,
    filter: string,
    status?: string
  ) => {
    try {
      let data: any = await getAllNews(page, sort, companyId, filter, status);
      if (data) {
        setPostData(data);
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error);
    }
  }; */

  const handleFetchCategory = async (
    page: number,
    companyId: number,
    size?: string,
    filter?: string
  ) => {
    try {
      let data: PostCategoryListType | null = await getAllNewsCategory(
        page,
        companyId,
        size,
        filter
      );
      if (data) {
        setPostCategory && setPostCategory(data);
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error);
    }
  };

  const handleOpenModal = async (event: any) => {
    event.preventDefault();

    const modalAction = event.currentTarget.getAttribute("data-name");
    const id = event.currentTarget.getAttribute("data-id");

    let object: AddOrUpdatePostCardType | any = null;
    if (modalAction === "view") {
      object = postData?.content.find((p: any) => p.id === parseInt(id));
    }

    if (modalAction === "delete") {
      object = postData?.content.find((p: any) => p.id === parseInt(id));
    }

    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteNews(id);
      if (!deleteData) {
        setErrorMsg && setErrorMsg(t("errorDeletingNews"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(t("newsSuccessfullyDeleted"));

      //reset page and call fetch data
      handleFetchNews && handleFetchNews(page, company, filter, status);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleNavigateSinglePost = async (data: AddOrUpdatePostCardType) => {
    console.log(data);
    setModalData &&
      setModalData({
        modalOpen: false,
        modalType: "",
        modalObject: data,
      });
    navigate(`/dashboard/news/${data.id}/${data.title}`);
    /*  setTimeout(() => {
      modalData &&
        modalData.modalObject !== null &&
        navigate(`/dashboard/news/${data.id}/${data.title}`);
    }, 300); */
  };

  const onSubmit = async (data: any) => {
    console.log(data);

    switch (modalData?.modalType) {
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <FancyPaper pagetitle={t("news")}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(`/dashboard/news/create`)}
              sx={{ mb: 4 }}
            >
              {t("addNew")}
              <PlusIcon sx={{ ml: 1 }} />
            </Button>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell align="left">{t("title")}</TableCell>
                    <TableCell align="left">{t("category")}</TableCell>
                    <TableCell align="center">{t("actions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {postData?.content?.map(
                    (row: AddOrUpdatePostCardType, i: number) => (
                      <TableRow key={i}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell align="left">{row.title}</TableCell>
                        <TableCell align="left">
                          {row.category !== "" &&
                            JSON.parse(row.category).map(
                              (item: any, i: number) => (
                                <>
                                  <FindCategoryPost
                                    key={i}
                                    id={item}
                                    postCategory={postCategory}
                                  />
                                  {i < JSON.parse(row.category).length - 1 &&
                                    ", "}
                                </>
                              )
                            )}
                        </TableCell>
                        <TableCell align="center">
                          <FancyTooltip title={t("view")} placement="top">
                            <IconButton
                              aria-label="edit"
                              component="label"
                              color="secondary"
                              sx={{
                                "&:hover": {
                                  color: theme.palette.secondary.dark,
                                },
                              }}
                              onClick={handleOpenModal}
                              data-name="view"
                              data-id={row.id}
                            >
                              <VisibilityIcon sx={{ fontSize: "1.5rem" }} />
                            </IconButton>
                          </FancyTooltip>
                          <FancyTooltip title={t("edit")} placement="top">
                            <IconButton
                              aria-label="edit"
                              component="label"
                              color="secondary"
                              sx={{
                                "&:hover": {
                                  color: theme.palette.secondary.dark,
                                },
                              }}
                              /* onClick={() =>
                                navigate(
                                  `/dashboard/news/${row.id}/${row.title}`
                                )
                              } */
                              onClick={() => handleNavigateSinglePost(row)}
                              data-name="update"
                              data-id={row.id}
                            >
                              <PenIcon />
                            </IconButton>
                          </FancyTooltip>
                          <FancyTooltip title={t("delete")} placement="top">
                            <IconButton
                              aria-label="trash"
                              component="label"
                              color="secondary"
                              sx={{
                                "&:hover": {
                                  color: theme.palette.secondary.dark,
                                },
                              }}
                              data-id={row.id}
                              onClick={handleOpenModal}
                              data-name="delete"
                            >
                              <TrashIcon />
                            </IconButton>
                          </FancyTooltip>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </FancyPaper>
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("delete")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  {t("cancel")}
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
      {modalData !== null && modalData?.modalType === "view" && (
        <Dialog
          open={modalData?.modalOpen ?? false}
          onClose={handleCancelModal}
          fullWidth
          scroll="paper"
        >
          <DialogContent dividers={true}>
            <PostCard
              id={modalData?.modalObject?.id}
              title={modalData?.modalObject?.title}
              description={modalData?.modalObject?.description}
              category={[]}
              id_video={modalData?.modalObject?.id_video}
              idImagen={modalData?.modalObject?.idImagen}
              idVideo={modalData?.modalObject?.idVideo}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default News;
