/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Dialog,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useApp } from "../../hooks/useApp";
import { reportBookingPayment, reportBookingPaymentDownload } from "../../services/booking/BookingService";
import FancyPaper from "../../components/FancyPaper";
import { BookingPaymetReport, BookingPaymetReportRespose } from "../../types/ServiceTypes";
import dayjs from 'dayjs';
import VisibilityIcon from "@mui/icons-material/Visibility";


import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import FancyTooltip from "../../components/utils/FancyTooltip";
import { ConfigIcon, PenIcon, TrashIcon } from "../../components/svgicons/SvgIcons";
import AddOrUpdateBookingPayment from "../../components/utils/AddOrUpdateBookingPayment";


const ReportBookingPayment = () => {
  const { t } = useTranslation();
  const {
    authInfo,
    company,
    companyInfo,
    isAdmin,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();

  const theme = useTheme();
  const [data, setData] = useState<BookingPaymetReport[]>([]);
  const [startDate, setStartDate] = useState<Dayjs>(dayjs(new Date()));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs(new Date()));
  const [maxDate, setMaxDate] = useState<Dayjs>(dayjs(new Date()));
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const initForm = {
    id: 0,
    adminLogin: "",
    clientLogin: "",
    description: "",
    name: "",
  };
  const [formData, setFormData] = useState<any>(initForm);

  const options = [`${t("download")} PDF`, `${t("download")} XLSX`];

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      // await handleFetchData(0, preFilter);
    };
    dataInit();
  }, [company]);

  /**
   * Efecto al actualizar fecha inicio
   */
  useEffect(() => {
    const dataInit = async () => {
      const date = startDate;
      setMaxDate(date.add(1, 'month'));
    };
    dataInit();
  }, [startDate]);


  /**
   * Metodo encargado de buscar registros
   * @param startDate fecha inicio de reporte
   * @param endDate fecha final de reporte
   */
  const handleFetchData = async (
    startDate: string,
    endDate: string
  ) => {
    setLoading && setLoading(true);
    setData([]);
    try {
      let data = await reportBookingPayment(company ? company : authInfo.companyId, 3, startDate, endDate);
      if (data) {
        setData(data.content);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };



  /**
   * Evento de aplicar filtro de busqueda
   */
  const handleApplyFilter = async () => {
    await handleFetchData(dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD'));
  };

  const handleDownload = () => {
    if (selectedIndex == 0)
      reportBookingPaymentDownload(company ? company : authInfo.companyId, 3, dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD'), 'pdf');
    if (selectedIndex == 1)
      reportBookingPaymentDownload(company ? company : authInfo.companyId, 3, dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD'), 'xlsx');
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };


  const formatterCurrency = (row: BookingPaymetReport) => {
    // const symbolCurrency = row?.currency?.id && row?.currency?.symbol ? row?.currency?.symbol + ' ' : '';
    const symbolCurrency = companyInfo?.currencies != null && companyInfo?.currencies[0]?.symbol ? companyInfo.currencies[0].symbol + ' ' : '';
    return `${formatNumber(row.amount.toFixed(2) + '')} ${symbolCurrency}`;
  };

  const formatterCurrencyNumber = (amount: number) => {
    const symbolCurrency = companyInfo?.currencies != null && companyInfo?.currencies[0]?.symbol ? companyInfo.currencies[0].symbol + ' ' : '';
    return `${formatNumber(amount.toFixed(2) + '')} ${symbolCurrency}`;
  };

  const formatNumber = (value: string) => {
    // Eliminar cualquier separador de miles antes de aplicar el nuevo valor
    const normalizedValue = (value || "").toString().replace(/,/g, "");
    // Aplicar el formato con separadores de miles
    return normalizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");
    console.log("modalAction = ", modalAction);
    if (modalAction === "update") {
      object = data.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setFormData(initForm);
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  return (
    <>
      <FancyPaper pagetitle={`${t("reports")} / ${t("bookingPayment")}`}>
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={6} sm={6} xs={12}>
            <ButtonGroup
              variant="contained"
              ref={anchorRef}
              aria-label="Download"
            >
              <Button onClick={handleDownload}>{options[selectedIndex]}</Button>
              <Button
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {options.map((option, index) => (
                          <MenuItem
                            key={option}
                            disabled={index === 2}
                            selected={index === selectedIndex}
                            onClick={(event) => handleMenuItemClick(event, index)}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("startDate")}
                  value={startDate}
                  onChange={(newValue: any) => {
                    setStartDate(newValue);
                    setEndDate(newValue);
                  }}
                  format="YYYY-MM-DD"
                />
                <DatePicker
                  label={t("endDate")}
                  value={endDate}
                  onChange={(newValue: any) => {
                    setEndDate(newValue);
                  }}
                  minDate={dayjs(startDate)}
                  maxDate={dayjs(maxDate)}
                  format="YYYY-MM-DD"
                />
              </LocalizationProvider>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleApplyFilter}
                sx={{
                  borderRadius: "0 0.2rem 0.2rem 0!important",
                  padding: "1.5px 16px!important",
                }}
              >
                {t("search")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t("reservationN°")}</TableCell>
                <TableCell align="center">{t("installation")}</TableCell>
                <TableCell align="center">{t("paymentMethod")}</TableCell>
                <TableCell align="center">{t("paymentDate")}</TableCell>
                <TableCell align="center">{t("amount")}</TableCell>
                <TableCell align="center">{t("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row: any, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="center">{row.installation}</TableCell>
                    <TableCell align="center">{row.payMethod}</TableCell>
                    <TableCell align="center">{row.datePayment.substring(0, 10)}</TableCell>
                    <TableCell align="center">{formatterCurrency(row)}</TableCell>
                    <TableCell align="center">
                      {/* <ResourceAccess isCode={true} pathOrCode={"USER:WRITE"}> */}
                      <FancyTooltip title={t("view")} placement="top">
                        <IconButton
                          aria-label="edit"
                          component="label"
                          color="secondary"
                          sx={{
                            "&:hover": {
                              color: theme.palette.secondary.dark,
                            },
                          }}
                          onClick={handleOpenModal}
                          data-name="update"
                          data-id={row.id}
                        >
                          <VisibilityIcon sx={{ fontSize: "1.5rem" }} />
                        </IconButton>
                      </FancyTooltip>
                      {/* </ResourceAccess> */}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"><b>Total</b></TableCell>
                <TableCell align="center">{formatterCurrencyNumber(data.length > 0 ? data.map(a => a.amount).reduce(function (a, b) { return a + b; }) : 0)}</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </FancyPaper>
      {(modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddOrUpdateBookingPayment
            data={modalData?.modalObject}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
    </>
  );
};

export default ReportBookingPayment;
