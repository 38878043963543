import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { RoleResponse } from "./CountryInterface";
import { UrlBase } from "../../url/Urls";

export const getRequest = async (page: any) : Promise<RoleResponse> => {
  console.log(page);
  
  try {
    const response = await privateFetch.get(URL.REQUEST_COUNTRY(page,UrlBase.registration_x_page));
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};


export const createRequest = async (data:any) => {
  let responseData = {};
  
  const requestBody = {
    name: data.name,
    areaCode: data.areaCode,
    userCreated: data.userCreated
  };
  await privateFetch
    
    .post(URL.REQUEST_COUNTRY_CREATE, [requestBody], {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
   
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_save_error);
    });
  return responseData;
};

export const updateRequest = async (data:any) => {
  let responseData = {};  
  const requestBody = {
    id: data.id,
    name: data.name,
    areaCode: data.areaCode,
    userModified: data.userModified,
  };
  await privateFetch
    .put(URL.REQUEST_COUNTRY_UPDATE(data.id), requestBody,{
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_update_error);
    });
  return responseData;
};


export const deleteRequest = async (id:any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_COUNTRY_DELETE(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(
        properties.com_parval_label_booking_delete_error
      );
    });
  return responseData;
};

export const validateNameAvailability =  async  (name: string) => {
  try {
    const response = await privateFetch.get(URL.REQUEST_COUNTRY_AVAILABLE(name));
    return response.data;
  } catch (error) {
    console.error('Error validating name availability:', error);
    return false; // Handle errors gracefully, assuming unavailable on error
  }
}
