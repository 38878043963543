/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FancyPaper from "../components/FancyPaper";
import {
  PenIcon,
  PlusIcon,
  TrashIcon
} from "../components/svgicons/SvgIcons";
import AddOrUpdateApartmentsModal from "../components/utils/AddOrUpdateApartmentModal";
import DeleteModal from "../components/utils/DeleteModal";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import FancyTooltip from "../components/utils/FancyTooltip";
import { useApp } from "../hooks/useApp";
import {
  createRequest,
  deleteRequest,
  getRequest,
  updateRequest,
} from "../services/apartment/ApartmentService";
import { properties } from "../utils/Properties_es";
import ResourceAccess from "../components/security/ResourceAccess";
import { StatusSearchApartment } from "../types/ServiceTypes";



const SettingApartments = () => {
  const { t } = useTranslation();
  const {
    authInfo,
    company,
    isAdmin,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const theme = useTheme();
  const [apartmentData, setApartmentData] = useState<any>([]);
  const initForm = {
    id: 0,
    status: 0,
    name: "",
    company: 0,
    user: null
  };
  const typeApartment = [
    { title: t("free"), id: 0 },
    { title: t("withOwner"), id: 1 },
    { title: t("withTenant"), id: 2 },
    { title: t("inConstruction"), id: 3 },
  ]
  const [formData, setFormData] = useState<any>(initForm);
  const { handleSubmit } = useForm();
  const [page, setPage] = useState(0);
  const [preFilter, setPreFilter] = useState<string | undefined>('');

  const [statusOptions, setStatusOptions] = useState<any>([
    {
      id: StatusSearchApartment.ALL,
      name: t("selectAll"),
    },
    {
      id: StatusSearchApartment.FREE,
      name: t("free"),
    },
    {
      id: StatusSearchApartment.WITH_OWNER,
      name: t("withOwner"),
    },
    {
      id: StatusSearchApartment.WITH_TENANT,
      name: t("withTenant"),
    },
    {
      id: StatusSearchApartment.UNDER_CONSTRUCTION,
      name: t("inConstruction"),
    }]);
  
  const [selectStatus, setSelectStatus] = useState<{
    id: StatusSearchApartment;
    name: string;
  } | null>(null);

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    setSelectStatus(statusOptions[0]);
    const dataInit = async () => {
      await handleFetchData(0, preFilter);
    };
    dataInit();
  }, [company]);

  /**
   * Efecto al cambiar filtro de estado
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, preFilter);
    };
    dataInit();
  }, [selectStatus]);

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (
    currentPage?: number,
    filter?: string,
    applyRole?: any
  ) => {
    setLoading && setLoading(true);
    setApartmentData([]);
    try {
      //setPage(currentPage);
      const filterString = filter ? filter: '';
      const statusString = selectStatus?.id ? selectStatus.id  : '';
      let data = await getRequest(currentPage, isAdmin && company ? company : authInfo.companyId, filterString, statusString, filterString);
      if (data) {
        setApartmentData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let createData = await createRequest({
        ...data,
        userCreated: authInfo?.username,
      });

      if (!createData) {
        setErrorMsg && setErrorMsg(t("errorCreatingRecord"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
      setSuccessMsg(t("successfullyCreatedRecord"));
      //call fetch data
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let updateData = await updateRequest({
        ...data,
        userModified: authInfo?.username,

      });

      if (!updateData) {
        setErrorMsg &&
        setErrorMsg(t("errorUpdatingRecord"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
      setSuccessMsg(t("recordUpdatedCorrectly"));
      //call fetch data
      await handleFetchData();
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteRequest(id);
      if (!deleteData) {
        setErrorMsg &&
        setErrorMsg(t("errorDeletingRecord"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
      setSuccessMsg(t("recordDeletedSuccessfully"));

      //reset page and call fetch data
      await handleFetchData();
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");
    if (modalAction === "update") {
      object = apartmentData.content.find((p: any) => p.id === parseInt(id));
    }

    if (modalAction === "delete") {
      object = apartmentData.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  /**
   * @param event
   */
  const handleCancelModal = () => {
    setFormData(initForm);
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    const customPage = newPage - 1;
    setPage(customPage);
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
  };

  /**
   * Evento de aplicar filtro de busqueda
   */
  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(0, preFilter);
    }
  };

  // const onChangeOptions = (e: any) => {
  //   setStatus(e.target.value);
  // };

  return (
    <>
      <FancyPaper pagetitle={`${t("maintenance")} / ${t("apartments")}`}>
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={12} sm={6} xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Box
                display="flex"
                alignContent="center"
                alignItems="center"
                gap={4}
              >
                
                <ResourceAccess isCode={true} pathOrCode={"USER:WRITE"}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenModal}
                    data-name="create"
                  >
                    {t("addApartments")}
                    <PlusIcon sx={{ ml: 1 }} />
                  </Button>
                </ResourceAccess>
              </Box>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
              >

                <Autocomplete
                  value={selectStatus}
                  onChange={(event: any, newValue: any) => {
                    setSelectStatus(newValue);
                  }}
                  options={statusOptions}
                  getOptionLabel={(option: any) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("state")}
                      variant="standard"
                      fullWidth
                      sx={{
                        marginTop: -5,
                        width: 200,
                        "& fieldset": {
                          borderColor: "blue",
                        },
                        "& svg": {
                          color: "blue",
                        },
                      }}
                    />
                  )}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >

                  <TextField
                    placeholder={t("searchFilter")}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderRadius: "0.2rem 0 0 0.2rem",
                      },
                      "& fieldset": {
                        borderRightWidth: "0",
                      },
                    }}
                    value={preFilter}
                    onChange={(e: any) => {
                      setPreFilter(e.target.value);
                      if (e.target.value === "") {
                        handleFetchData(0, "");
                      }
                    }}
                    onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleApplyFilter}
                    sx={{
                      borderRadius: "0 0.2rem 0.2rem 0!important",
                      padding: "1.5px 16px!important",
                    }}
                  >
                    {t("search")}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t("id")}</TableCell>
                <TableCell align="center">{t("apartment")}</TableCell>
                <TableCell align="center">{t("user")}</TableCell>
                <TableCell align="center">{t("state")}</TableCell>
                <TableCell align="center">{t("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {apartmentData &&
                apartmentData.content &&
                apartmentData.content.map((row: any, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.user ? (row.user.firstName ? row.user.firstName : '') + ' ' + (row.user.lastName ? row.user.lastName : '') : ''}</TableCell>
                    <TableCell align="center">{typeApartment[row.status].title}</TableCell>
                    <TableCell align="center">
                      <ResourceAccess isCode={true} pathOrCode={"ROLE:WRITE"}>
                        <FancyTooltip title={t("edit")} placement="top">
                          <IconButton
                            aria-label="edit"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            onClick={handleOpenModal}
                            data-name="update"
                            data-id={row.id}
                          >
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip title={t("delete")} placement="top">
                          <IconButton
                            aria-label="trash"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            data-id={row.id}
                            onClick={handleOpenModal}
                            data-name="delete"
                          >
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip>
                      </ResourceAccess>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={apartmentData?.content?.length > 0 ? apartmentData?.content?.length : 0}
          rowsPerPage={apartmentData.size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={apartmentData.totalElements}
          totalPages={apartmentData.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
          <Dialog
            open={modalData.modalOpen}
            onClose={handleCancelModal}
            fullWidth
          >
            <AddOrUpdateApartmentsModal
              data={modalData?.modalObject}
              onSubmit={onSubmit}
              cancelModal={handleCancelModal}
              modalType={modalData?.modalType}
              company={company ? company : authInfo.companyId}
            />
          </Dialog>
        )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("delete")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  {t("cancel")}
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default SettingApartments;
