import UrlBase from "../../url/Urls";
import { CONSTANT } from "../../utils/Constants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { URL } from "../../utils/UrlConstants";

export const getRequest = async (page:any,size:any) => {
  let responseData = {};  
  await privateFetch
    .get(
      URL.REQUEST_NOTIFICATIONS_LIST(
        page, size ?? UrlBase.registration_x_page
      ),  
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const getRequestByCompany = async (page:any,size:any,company:any) => {
  let responseData = {};  
  await privateFetch
    .get(
      URL.REQUEST_NOTIFICATIONS_LIST_BY_COMPANY(
        page, size ?? UrlBase.registration_x_page,company
      ),  
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};


export const createRequest = async (data:any) => {
  let responseData: any = {};
  await privateFetch
    .post(URL.REQUEST_NOTIFICATIONS_CREATE, JSON.stringify(data), {
        headers: {
            ...CONSTANT.HEADER_TYPE,
        },
      })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
        throw new Error(error.response.data.message);
    });
  return responseData;
};


export const updateRequest = async (data:any) => {
  let responseData = {};  
  try {
    const response = await privateFetch.put(URL.REQUEST_NOTIFICATIONS_UPDATE(data.id), JSON.stringify(data),{
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    });
    responseData = response.data;
  } catch (error) {
    throw new Error(properties.com_parval_label_role_update_error);
  }
  return responseData;
};

export const deleteRequest = async (id:any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_NOTIFICATIONS_DELETE(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      //throw new Error(properties.com_parval_label_role_delete_error);
      console.log(error)
    });
  return responseData;
};
