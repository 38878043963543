import {
    Button,
    Dialog,
    Grid,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Tab,
    TextField,
    useTheme,
    Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import FancyPaper from "../components/FancyPaper";
import { PenIcon, PlusIcon, TrashIcon } from "../components/svgicons/SvgIcons";
import FancyTooltip from "../components/utils/FancyTooltip";
import { useForm } from "react-hook-form";
import AddOrUpdatePayMethodModal from "../components/utils/AddOrUpdatePayMethodModal";
import DeleteModal from "../components/utils/DeleteModal";
import { useApp } from "../hooks/useApp";
import {
    getRequestCompany,
    getRequestOneCompany,
} from "../services/company/CompanyService";
import {
    createRequest,
    updateRequest,
    deleteRequest,
    getRequestPayMethod
} from "../services/payMethod/PayMethodService";
import { useTranslation } from "react-i18next";
import ResourceAccess from "../components/security/ResourceAccess";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import { ContentCurrency } from "../services/currency/CurrencyInterface";
import { deleteDocuments, findDocuments, uploadDocuments } from "../services/booking/BookingService";
import TabCard1Form from "../pages/tabsPayMethod/TabCard1Form";
import TabCard2Form from "../pages/tabsPayMethod/TabCard2Form";
import TabCard3Form from "../pages/tabsPayMethod/TabCard3Form";
import { AnyCnameRecord } from "dns";

const SettingPayMethod = () => {
    const { t } = useTranslation();
    const {
        authInfo,
        setLoading,
        setErrorMsg,
        modalData,
        setModalData,
        setSuccessMsg,
        errorMsg,
        isAdmin,
        handleDataCompanies,
        company,
    } = useApp();

    const theme = useTheme();
    const [companyData, setCompanyData] = useState<any>([]);
    const [preFilter, setPreFilter] = useState<any>("");
    const [page, setPage] = useState(0);
    const [tabIndex, setTabIndex] = useState(0);

    const initForm = {
        id: 0,
        company: "",
        subCompany: "",
        numberInquiries: "",
        addSubCompanies: []
    };
    const [formData, setFormData] = useState<any>(initForm);
    const { handleSubmit, register } = useForm();

    useEffect(() => {
        const dataInit = async () => {
            await handleFetchData(0, "");
        };
        dataInit();
    }, [company]);

    const handleApplyFilter = async () => {
        if (preFilter !== "") {
            await handleFetchData(0, preFilter);
        }
    };

    const handleFetchData = async (currentPage: number, filter?: string) => {
        setLoading && setLoading(true);
        try {
            setPreFilter(filter);
            setPage(currentPage);
            let data: any = await getRequestPayMethod(isAdmin ? company : authInfo.companyId);
            if (data) {
                setCompanyData(data);
            }
            setLoading && setLoading(false);
        } catch (error: any) {
            setLoading && setLoading(false);
            setErrorMsg && setErrorMsg(error.message);
        }
    };

    console.log(companyData)

    const onSubmit = async (data: any) => {

        switch (modalData?.modalType) {
            case "create":
                await handleAdd(data);
                break;
            case "update":
                await handleUpdate(data);
                break;
            case "delete":
                await handleDelete(modalData?.modalObject?.id);
                break;
            default:
                break;
        }
    };

    const handleAdd = async (data: any) => {
        console.log(data)
        handleCancelModal();
        setLoading && setLoading(true);
        try {
            let createData = await createRequest({
                ...data,
                name: data.name,
                account: data.account,
                type: data.paymentMethod,
                companyId: isAdmin ? company : authInfo.companyId,
                userCreated: authInfo?.username
            });
            if (!createData) {
                setErrorMsg && setErrorMsg(t("errorCreatingAccount"));
                setLoading && setLoading(false);
                return;
            }
            setSuccessMsg && setSuccessMsg(t("successfullyCreatedAccount"));
            setLoading && setLoading(false);
            setSuccessMsg && await handleFetchData(0, "");
        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
            setLoading && setLoading(false);
        }
    };

    const handleUpdate = async (data: any) => {
        handleCancelModal();
        setLoading && setLoading(true);
        try {
            let updateData = await updateRequest({
                ...data,
                name: data.name,
                account: data.account,
                type: data.paymentMethod,
                companyId: isAdmin ? company : authInfo.companyId,
                userModified: authInfo?.username,
                
            });
            if (!updateData) {
                setErrorMsg && setErrorMsg(t("errorUpdatingAccount"));
                setLoading && setLoading(false);
                return;
            }
            setLoading && setLoading(false);
            setSuccessMsg && setSuccessMsg(t("accountUpdatedCorrectly"));
            await handleFetchData(0, "");
        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
            setLoading && setLoading(false);
        }
    };

    const handleDelete = async (id: any) => {
        handleCancelModal();
        setLoading && setLoading(true);
        try {
            let deleteData = await deleteRequest(id);
            if (!deleteData) {
                setLoading && setLoading(false);
                return;
            }
            setLoading && setLoading(false);
            await handleFetchData(0, "");
        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
            setLoading && setLoading(false);
        }
    };

    const handleOpenModal = async (event: any) => {
        event.preventDefault();
        const modalAction = event.currentTarget.getAttribute("data-name");
        let object = null;
        const id = event.currentTarget.getAttribute("data-id");
        if (modalAction === "update" || modalAction === "delete") {
            object = companyData.account.find((p: any) => p.id === parseInt(id));
        }
        setModalData &&
            setModalData({
                ...modalData,
                modalOpen: true,
                modalType: modalAction,
                modalObject: object,
            });
    };

    const handleCancelModal = () => {
        setFormData(initForm);
        setModalData &&
            setModalData({
                ...modalData,
                modalOpen: false,
                modalType: "",
                modalObject: null,
            });
    };

    const handleChangePage = async (event: unknown, newPage: number) => {
        const customPage = newPage - 1;
        setPage(customPage);
        if (customPage !== page) {
            await handleFetchData(customPage, preFilter);
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    };

    return (
        <>
            <FancyPaper pagetitle={`${t("setting")} / ${t("payMethod")}`}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        sx={{ '& .MuiTabs-flexContainer': { justifyContent: 'center' } }}
                    >
                        <Tab label="Tarjeta" />
                        <Tab label="PayPal" />
                        <Tab label="Cuentas Bancarias" />
                    </Tabs>
                </Box>
                {tabIndex === 0 &&
                    <TabCard1Form
                        data={companyData.card}
                        refreshData={handleFetchData} />}
                {tabIndex === 1 &&
                    <TabCard2Form
                        data={companyData.paypal}
                        refreshData={handleFetchData}/>}
                {tabIndex === 2 && (
                    <>
                        <Grid container spacing={8} sx={{ pb: 8 }}>
                            <Grid item md={9} sm={6} xs={12}>
                                <ResourceAccess isCode={true} pathOrCode={"PAYMETHOD:WRITE"}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleOpenModal}
                                            data-name="create"
                                        >
                                            {t("addAccount")}
                                            <PlusIcon sx={{ ml: 1 }} />
                                        </Button>
                                </ResourceAccess>
                            </Grid>
                            {/* <Grid item md={3} sm={6} xs={12}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                >
                                    <TextField
                                        placeholder={t("searchFilter")}
                                        sx={{
                                            width: "100%",
                                            "& .MuiInputBase-root": {
                                                borderRadius: "0.2rem 0 0 0.2rem",
                                            },
                                            "& fieldset": {
                                                borderRightWidth: "0",
                                            },
                                        }}
                                        value={preFilter}
                                        onChange={(e: any) => {
                                            setPreFilter(e.target.value);
                                            if (e.target.value === "") {
                                                handleFetchData(0, "");
                                            }
                                        }}
                                        onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
                                    />
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleApplyFilter}
                                        sx={{
                                            borderRadius: "0 0.2rem 0.2rem 0!important",
                                            padding: "1.5px 16px!important",
                                        }}
                                    >
                                        Buscar
                                    </Button>
                                </Stack>
                            </Grid> */}
                        </Grid>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">{t("id")}</TableCell>
                                        <TableCell align="center">{t("name")}</TableCell>
                                        <TableCell align="center">{t("account")}</TableCell>
                                        <TableCell align="center">{t("type")}</TableCell>
                                        <TableCell align="center">{t("actions")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {companyData &&
                                        companyData.account &&
                                        companyData.account.map((row: any, i: number) => (
                                            <TableRow
                                                key={i}
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                }}
                                            >
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.id}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.account}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.type === 3 ? t("bankAccount") : t("Sinpe")}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <ResourceAccess isCode={true} pathOrCode={"PAYMETHOD:WRITE"}>
                                                        <FancyTooltip title={t("edit")} placement="top">
                                                            <IconButton
                                                                aria-label="edit"
                                                                component="label"
                                                                color="secondary"
                                                                sx={{
                                                                    "&:hover": {
                                                                        color: theme.palette.secondary.dark,
                                                                    },
                                                                }}
                                                                onClick={handleOpenModal}
                                                                data-name="update"
                                                                data-id={row.id}
                                                            >
                                                                <PenIcon />
                                                            </IconButton>
                                                        </FancyTooltip>
                                                        <FancyTooltip title="Eliminar" placement="top">
                                                            <IconButton
                                                                aria-label="trash"
                                                                component="label"
                                                                color="secondary"
                                                                sx={{
                                                                    "&:hover": {
                                                                        color: theme.palette.secondary.dark,
                                                                    },
                                                                }}
                                                                data-id={row.id}
                                                                onClick={handleOpenModal}
                                                                data-name="delete"
                                                            >
                                                                <TrashIcon />
                                                            </IconButton>
                                                        </FancyTooltip>
                                                    </ResourceAccess>
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <FancyTablePagination
                            count={companyData?.content?.length > 0 ? companyData?.content?.length : 0}
                            rowsPerPage={companyData.size}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            totalElements={companyData.totalElements}
                            totalPages={companyData.totalPages}
                        /> */}

                        {(modalData?.modalType === "update" || modalData?.modalType === "create") && (
                            <Dialog
                                open={modalData.modalOpen}
                                onClose={handleCancelModal}
                                fullWidth
                            >
                                <AddOrUpdatePayMethodModal
                                    data={modalData.modalObject}
                                    onSubmit={onSubmit}
                                    refreshData={handleFetchData}
                                    cancelModal={handleCancelModal}
                                    modalType={modalData?.modalType}
                                />
                            </Dialog>
                        )}
                        {modalData?.modalType === "delete" && (
                            <Dialog
                                open={modalData.modalOpen}
                                onClose={handleCancelModal}
                                fullWidth
                            >
                                <DeleteModal
                                    textChildren={modalData?.modalObject?.nombre}
                                    actionButton={
                                        <>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                sx={{
                                                    mt: 2,
                                                    mr: 2,
                                                }}
                                                onClick={handleSubmit(onSubmit)}
                                            >
                                                {t("save")}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                sx={{
                                                    mt: 2,
                                                }}
                                                onClick={handleCancelModal}
                                                autoFocus
                                            >
                                                {t("cancel")}
                                            </Button>
                                        </>
                                    }
                                />
                            </Dialog>
                        )}
                    </>

                )}
            </FancyPaper >
        </>
    );
};

export default SettingPayMethod;
