import { privateFetch } from "../../utils/CustomFetch.js";
import { URL } from "../../utils/UrlConstants";
import { CurrencyResponse } from "./CurrencyInterface.js";

export const getRequestCurrency = async (page: number, size: number): Promise<CurrencyResponse> => {
    try {
        const response = await privateFetch.get(URL.REQUEST_CURRENCIES(page, size));
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};