/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Button,
    Dialog,
    Grid,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    useTheme
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FancyPaper from "../components/FancyPaper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import FancyTablePagination from "../components/utils/FancyTablePagination";
import FancyTooltip from "../components/utils/FancyTooltip";
import { useApp } from "../hooks/useApp";
import {
    createRequest,
    deleteRequest,
    updateRequest,
} from "../services/apartmentPending/ApartmentPendingService";

import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";
import ResourceAccess from "../components/security/ResourceAccess";
import AddOrUpdateApartmentPaidModal from "../components/utils/AddOrUpdateApartmentPaidModal";
import {getRequestPayments} from "../services/payment/PaymentService";
import ValidatePaymentModal from "../components/utils/ValidatePaymentModal";



const SettingApartmentsPaid = () => {
    const { t } = useTranslation();
    const {
        authInfo,
        company,
        isAdmin,
        setLoading,
        setErrorMsg,
        modalData,
        setModalData,
        setSuccessMsg,
        errorMsg,
    } = useApp();
    const theme = useTheme();
    const [apartmentData, setApartmentData] = useState<any>([]);
    const initForm = {
        id: 0,
        amount: 0,
        apartment: null,
        company: 0,
        expirationDate: new Date(),
        registrationDate: new Date(),
        state: 0,
        title: ''
    };
    const typePending = [
        { title: t("pending"), id: 0 },
        { title: t("validating"), id: 1 },
        { title: t("applied"), id: 2 }
    ]
    const [formData, setFormData] = useState<any>(initForm);
    const { handleSubmit } = useForm();
    const [page, setPage] = useState(0);
    const [preFilter, setPreFilter] = useState<string | undefined>('');

    /**
     * Efecto inicial para carga de registros
     */
    useEffect(() => {
        const dataInit = async () => {
            await handleFetchData(0, "");
        };
        dataInit();
    }, [company]);

    /**
     * Metodo encargado de buscar registros
     * @param currentPage
     */
    const handleFetchData = async (
        currentPage?: number,
        filter?: string
    ) => {
        setLoading && setLoading(true);
        setApartmentData([]);
        setSelected([]);
        try {
            let data = await getRequestPayments(currentPage, company ? company : authInfo.companyId);
            if (data) {
                setApartmentData(data);
            }
            setLoading && setLoading(false);
        } catch (error: any) {
            setLoading && setLoading(false);
            setErrorMsg && setErrorMsg(error.message);
            console.log(error);
        }
    };

    /**
     * Metodo encargado de crear registro
     * @param data
     */
    const handleAdd = async (data: any) => {
        handleCancelModal();
        setLoading && setLoading(true);
        try {
            let createData = await createRequest({
                ...data,
                userCreated: authInfo?.username,
            });

            if (!createData) {
                setErrorMsg && setErrorMsg(t("errorCreatingRecord"));
                setLoading && setLoading(false);
                return;
            }
            setLoading && setLoading(false);
            setSuccessMsg &&
                setSuccessMsg(t("successfullyCreatedRecord"));
            //call fetch data
            await handleFetchData(0, "");
        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
            setLoading && setLoading(false);
            console.log(error);
        }
    };

    /**
     * Metodo encargado de actualizar registro
     * @param data
     */
    const handleUpdate = async (data: any) => {
        handleCancelModal();
        setLoading && setLoading(true);
        try {
            let updateData = await updateRequest({
                ...data,
                userModified: authInfo?.username,

            });

            if (!updateData) {
                setErrorMsg &&
                    setErrorMsg(t("errorUpdatingRecord"));
                setLoading && setLoading(false);
                return;
            }
            setLoading && setLoading(false);
            setSuccessMsg &&
                setSuccessMsg(t("recordUpdatedCorrectly"));
            //call fetch data
            await handleFetchData();
        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
            setLoading && setLoading(false);
        }
    };

    /**
     * Metodo encargado de eliminar registro
     * @param data
     */
    const handleDelete = async (id: any) => {
        handleCancelModal();
        setLoading && setLoading(true);
        try {
            let deleteData = await deleteRequest(id);
            if (!deleteData) {
                setErrorMsg &&
                    setErrorMsg(t("errorDeletingRecord"));
                setLoading && setLoading(false);
                return;
            }
            setLoading && setLoading(false);
            setSuccessMsg &&
                setSuccessMsg(t("recordDeletedSuccessfully"));

            //reset page and call fetch data
            await handleFetchData();
        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
            setLoading && setLoading(false);
        }
    };

    /**
     * Efecto para validar errores en caso de abrir modal
     */
    useEffect(() => {
        if (modalData && modalData?.modalOpen && errorMsg) {
            setModalData &&
                setModalData({
                    modalOpen: false,
                    modalType: "",
                    modalObject: null,
                });
        }
    }, [modalData, errorMsg, setModalData]);

    /**
     * Evento de apertura de modal
     */
    const handleOpenModal = async (event: any) => {
        event.preventDefault();
        const modalAction = event.currentTarget.getAttribute("data-name");
        let object = null;
        const id = event.currentTarget.getAttribute("data-id");
        if (modalAction === "update") {
            const pending = apartmentData?.content.find((p: any) => p.id === parseInt(id));
            object = {
                company: pending?.company ? pending.company : 0,
                apartment: { id: pending.apartmentId, name: pending.apartmentName },
                list: [{
                    id: pending?.id ? pending.id : 0,
                    amount: pending?.amount ? pending.amount : 0,
                    expirationDate: pending?.expirationDate ? pending.expirationDate : null,
                    registrationDate: pending?.registrationDate ? pending.registrationDate : null,
                    title: pending?.title ? pending.title : '',
                    currency: pending?.currency ? pending.currency : null
                }]
            }
        }

        if (modalAction === "validate") {
            const payment = apartmentData?.content.find((p: any) => p.id === parseInt(id));
            object = {
                company: payment?.company ? payment.company : 0,
                apartment: { id: payment.apartmentId, name: payment.apartmentName },
                id: payment?.id ? payment.id : 0,
                amount: payment?.amount ? payment.amount : 0,
                expirationDate: payment?.expirationDate ? payment.expirationDate : null,
                registrationDate: payment?.registrationDate ? payment.registrationDate : null,
                title: payment?.title ? payment.title : '',
                currency: payment?.currency ? payment.currency : null,
                paymentMethodType: payment?.paymentMethodType ? payment.paymentMethodType : null,
                reference: payment?.reference ? payment.reference : null,
                accountMethodName: payment?.accountMethodName ? payment.accountMethodName : null
            }

        }

        setModalData &&
            setModalData({
                ...modalData,
                modalOpen: true,
                modalType: modalAction,
                modalObject: object,
            });
    };


    /**
     * @param event
     */
    const handleCancelModal = () => {
        setFormData(initForm);
        setModalData &&
            setModalData({
                ...modalData,
                modalOpen: false,
                modalType: "",
                modalObject: null,
            });
    };

    const onSubmit = async (data: any) => {
        if (modalData?.modalType !== "delete") {
            if (data?.list && data?.list?.length > 0)
                data?.list.forEach((element: any) => {
                    const amount: string = element?.amount ? element.amount.toString().replace(',', '') : '0'
                    const object = {
                        id: element?.id ? element.id : 0,
                        amount: parseFloat(amount),
                        apartment: data?.apartment ? data.apartment : null,
                        company: company ? company : authInfo.companyId,
                        expirationDate: element?.expirationDate ? element.expirationDate : null,
                        registrationDate: element?.registrationDate ? element.registrationDate : null,
                        state: 0,
                        title: element?.title ? element.title : '',
                        currency: element?.currency ? element.currency : null
                    }

                    if (object?.expirationDate)
                        object.expirationDate = dayjs(object.expirationDate).format('YYYY-MM-DD') + 'T00:00:00.000Z'

                    if (object?.registrationDate)
                        object.registrationDate = dayjs(object.registrationDate).format('YYYY-MM-DD') + 'T00:00:00.000Z'

                    if (!object?.id || object?.id === 0) {
                        handleAdd(object);
                    } else {
                        handleUpdate(object);
                    }
                });

        } else {
            await handleDelete(modalData?.modalObject?.id);
        }
    };

    const handleChangePage = async (event: unknown, newPage: number) => {
        const customPage = newPage - 1;
        setPage(customPage);
        if (customPage !== page) {
            await handleFetchData(customPage, preFilter);
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
    };

    const formatterCurrency = (row: any) => {
        const symbolCurrency = row?.currency ? row?.currency : '';
        return `${formatNumber(row.amount)} ${symbolCurrency}`;
    };

    const formatNumber = (value: string) => {
        // Eliminar cualquier separador de miles antes de aplicar el nuevo valor
        const normalizedValue = (value || "").toString().replace(/,/g, "");
        // Aplicar el formato con separadores de miles
        return normalizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    /**
     * Evento de aplicar filtro de busqueda
     */
    const handleApplyFilter = async () => {
        if (preFilter !== "") {
            await handleFetchData(0, preFilter);
        }
    };

    /**
     * Seleccionar pendientes de la tabla
     */

    const [selected, setSelected] = useState<any[]>([]);

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = apartmentData?.content.map((n: any) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };



    const isSelected = (name: any) => selected.indexOf(name) !== -1;



    //////////////////////////////// HEADER /////////////////////////

    function EnhancedTableHead(props: any) {

        return (
            <TableHead>
                <TableRow>
                    <TableCell align="center"> {t("id")}</TableCell>
                    <TableCell align="center"> {t("apartment")}</TableCell>
                    <TableCell align="center"> {t("user")}</TableCell>
                    <TableCell align="center"> {t("title")}</TableCell>
                    <TableCell align="center"> {t("paymentDate")}</TableCell>
                    <TableCell align="center"> {t("expirationDate")}</TableCell>
                    <TableCell align="center"> {t("paymentMethod")}</TableCell>
                    <TableCell align="center"> {t("amount")}</TableCell>
                    <TableCell align="center"> {t("state")}</TableCell>
                    <TableCell align="center"> {t("actions")}</TableCell>
                </TableRow>
            </TableHead>
        );
    }

    return (
        <>
            <FancyPaper pagetitle={`${t("finance")} / ${t("paid")}`}>
                <Grid container spacing={8} sx={{ pb: 8 }}>
                    <Grid item md={12} sm={6} xs={12}>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                spacing={2}
                            >
                                <TextField
                                    placeholder={t("searchFilter")}
                                    sx={{
                                        width: "100%",
                                        "& .MuiInputBase-root": {
                                            borderRadius: "0.2rem 0 0 0.2rem",
                                        },
                                        "& fieldset": {
                                            borderRightWidth: "0",
                                        },
                                    }}
                                    value={preFilter}
                                    onChange={(e: any) => {
                                        setPreFilter(e.target.value);
                                        if (e.target.value === "") {
                                            handleFetchData(0, "");
                                        }
                                    }}
                                    onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
                                />
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleApplyFilter}
                                    sx={{
                                        borderRadius: "0 0.2rem 0.2rem 0!important",
                                        padding: "1.5px 16px!important",
                                    }}
                                >
                                    {t("search")}
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">

                        <EnhancedTableHead
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={apartmentData?.content?.length}
                        />
                        <TableBody>
                            {apartmentData &&
                                apartmentData.content &&
                                apartmentData.content.map((row: any, i: number) => {

                                    const isItemSelected = isSelected(row.id);
                                    return (<TableRow
                                            hover
                                            role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        sx={{
                                            "&:last-child td, &:last-child th": { border: 0 },
                                        }}
                                    >
                                        <TableCell align="center" component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell align="center">{row.apartmentName}</TableCell>
                                        <TableCell align="center">{row.firstName + ' ' + row.lastName}</TableCell>
                                        <TableCell align="center">{row.title}</TableCell>
                                        <TableCell align="center">{row.registrationDate}</TableCell>
                                        <TableCell align="center">{row.expirationDate}</TableCell>
                                        <TableCell align="center">{t(row.paymentMethodType)}</TableCell>
                                        <TableCell align="center">{formatterCurrency(row)}</TableCell>
                                        <TableCell align="center">{t(row.status)}</TableCell>
                                        <TableCell align="right">
                                            <ResourceAccess isCode={true} pathOrCode={"ROLE:WRITE"}>
                                                { row.status == 'VALIDATING' && <FancyTooltip title={t("validate")} placement="top" >
                                                    <IconButton
                                                        aria-label="validate"
                                                        component="label"
                                                        color="primary"
                                                        sx={{
                                                            "&:hover": {
                                                                color: theme.palette.secondary.dark,
                                                            },
                                                        }}
                                                        onClick={handleOpenModal}
                                                        data-name="validate"
                                                        data-id={row.id}
                                                    >
                                                        <RuleFolderIcon sx={{ fontSize: "1.5rem" }} />
                                                    </IconButton>
                                                </FancyTooltip>
                                                }
                                                <FancyTooltip title={t("view")} placement="top">
                                                    <IconButton
                                                        aria-label="edit"
                                                        component="label"
                                                        color="secondary"
                                                        sx={{
                                                            "&:hover": {
                                                                color: theme.palette.secondary.dark,
                                                            },
                                                        }}
                                                        onClick={handleOpenModal}
                                                        data-name="update"
                                                        data-id={row.id}
                                                    >
                                                        <VisibilityIcon sx={{ fontSize: "1.5rem" }} />
                                                    </IconButton>
                                                </FancyTooltip>
                                            </ResourceAccess>
                                        </TableCell>
                                    </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <FancyTablePagination
                    count={apartmentData?.content?.length > 0 ? apartmentData?.content?.length : 0}
                    rowsPerPage={apartmentData.size}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    totalElements={apartmentData.totalElements}
                    totalPages={apartmentData.totalPages}
                />
            </FancyPaper>
            {modalData?.modalType === "update" && (
                    <Dialog
                        open={modalData.modalOpen}
                        onClose={handleCancelModal}
                        fullWidth
                        maxWidth='md'
                    >
                        <AddOrUpdateApartmentPaidModal
                            data={modalData?.modalObject}
                            onSubmit={onSubmit}
                            cancelModal={handleCancelModal}
                            modalType={modalData?.modalType}
                            company={company ? company : authInfo.companyId}
                        />
                    </Dialog>
                )}
            {modalData?.modalType === "validate" && (
                <Dialog
                    open={modalData.modalOpen}
                    onClose={handleCancelModal}
                    fullWidth
                    maxWidth='md'
                >
                    <ValidatePaymentModal
                        data={modalData?.modalObject}
                        onSubmit={onSubmit}
                        cancelModal={handleCancelModal}
                        modalType={modalData?.modalType}
                        company={company ? company : authInfo.companyId}
                    />
                </Dialog>
            )}
        </>
    );
};

export default SettingApartmentsPaid;
