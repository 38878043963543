import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router";
import FancyPaper from "../../components/FancyPaper";
import {
  Grid,
  useTheme,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
  Stack,
} from "@mui/material";
import FancyTooltip from "../../components/utils/FancyTooltip";
import { TrashIcon, PenIcon } from "../../components/svgicons/SvgIcons";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useApp } from "../../hooks/useApp";
import { useForm } from "react-hook-form";
import DeleteModal from "../../components/utils/DeleteModal";
import { PlusIcon } from "../../components/svgicons/SvgIcons";
import { useTranslation } from "react-i18next";
import { PostCategoryListType, newsCategoryResponse } from "../../types/Post";
import {
  getAllNewsCategory,
  addNewsCategory,
  updateNewsCategory,
  deleteNewsCategory,
} from "../../services/news/PostCategoryService";
import AddOrUpdateCategoryModal from "../../components/utils/AddOrUpdateCtagoryModal";
import ResourceAccess from "../../components/security/ResourceAccess";
import FancyTablePagination from "../../components/utils/FancyTablePagination";

const PostCategories = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    setLoading,
    setErrorMsg,
    setSuccessMsg,
    authInfo,
    modalData,
    setModalData,
    company,
  } = useApp();
  const { handleSubmit } = useForm();
  const [postCategory, setPostCategory] = useState<PostCategoryListType | null>(
    null
  );
  const [page, setPage] = useState<number>(0);
  const [filter, setFilter] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const getData = async () => {
      await handleFetch(page, 1, "", filter);
    };
    getData();
  }, [company]);

  const handleFetch = async (
    page: number,
    companyId: number,
    size?: string,
    filter?: string
  ) => {
    try {
      let data: PostCategoryListType | null = await getAllNewsCategory(
        page,
        companyId,
        size,
        filter
      );
      if (data) {
        setPostCategory(data);
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error);
    }
  };

  const handleOpenModal = async (event: any) => {
    event.preventDefault();

    const modalAction = event.currentTarget.getAttribute("data-name");
    const id = event.currentTarget.getAttribute("data-id");

    let object: newsCategoryResponse | any = null;
    if (modalAction === "update") {
      object = postCategory?.content.find((p: any) => p.id === parseInt(id));
    }

    if (modalAction === "delete") {
      object = postCategory?.content.find((p: any) => p.id === parseInt(id));
    }

    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleAdd = async (data: newsCategoryResponse) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      let createData = await addNewsCategory("create", {
        ...data,
        idCompany: data.idCompany,
        userCreated: authInfo?.username,
      });

      if (!createData) {
        setErrorMsg && setErrorMsg(t("errorCreatingCategory"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(t("successfullyCreatedCategory"));
      //call fetch data
      await handleFetch(page, 1, "", filter);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleUpdate = async (data: newsCategoryResponse) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      let updateData = await updateNewsCategory(JSON.stringify(data.id), {
        ...data,
        userModified: authInfo?.username,
      });

      if (!updateData) {
        setErrorMsg && setErrorMsg(t("errorUpdatingCategory"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(t("categoryUpdatedCorrectly"));
      //call fetch data
      await handleFetch(page, 1, "", filter);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteNewsCategory(id);
      if (!deleteData) {
        setErrorMsg && setErrorMsg(t("errorDeletingCategory"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(t("categorySuccessfullyRemoved"));

      //reset page and call fetch data
      await handleFetch(page, 1, "", filter);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetch(customPage, company, "", filter);
    }

    /* if (customPage !== page) {
      await handleFetchData(customPage, filter);
    } */
    //await handleFetchData(0, filter);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleApplyFilter = async () => {
    if (filter !== "") {
      await handleFetch(page, company, "", filter);
    }
  };

  const onSubmit = async (data: any) => {
    console.log(data);

    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <FancyPaper pagetitle={t("news")}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Grid container spacing={8} sx={{ pb: 8 }}>
              <Grid item md={9} sm={6} xs={12}>
                <ResourceAccess
                  isCode={true}
                  pathOrCode={"POSTCATEGORIES:WRITE"}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenModal}
                    data-name="create"
                    sx={{ mb: 4 }}
                  >
                    {t("addCategory")}
                    <PlusIcon sx={{ ml: 1 }} />
                  </Button>
                </ResourceAccess>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <TextField
                    placeholder={t("searchFilter")}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderRadius: "0.2rem 0 0 0.2rem",
                      },
                      "& fieldset": {
                        borderRightWidth: "0",
                      },
                    }}
                    value={filter}
                    onChange={(e: any) => {
                      setFilter(e.target.value);
                      if (e.target.value === "") {
                        handleFetch(page, company, "", "");
                      }
                    }}
                    onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleApplyFilter}
                    sx={{
                      borderRadius: "0 0.2rem 0.2rem 0!important",
                      padding: "1.5px 16px!important",
                    }}
                  >
                    {t("search")}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell align="left">{t("title")}</TableCell>
                    <TableCell align="center">{t("actions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {postCategory?.content?.map(
                    (row: newsCategoryResponse, i: number) => (
                      <TableRow key={i}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="center">
                          <ResourceAccess
                            isCode={true}
                            pathOrCode={"POSTCATEGORIES:WRITE"}
                          >
                            <FancyTooltip title={t("edit")} placement="top">
                              <IconButton
                                aria-label="edit"
                                component="label"
                                color="secondary"
                                sx={{
                                  "&:hover": {
                                    color: theme.palette.secondary.dark,
                                  },
                                }}
                                onClick={handleOpenModal}
                                data-name="update"
                                data-id={row.id}
                              >
                                <PenIcon />
                              </IconButton>
                            </FancyTooltip>
                            <FancyTooltip title={t("delete")} placement="top">
                              <IconButton
                                aria-label="trash"
                                component="label"
                                color="secondary"
                                sx={{
                                  "&:hover": {
                                    color: theme.palette.secondary.dark,
                                  },
                                }}
                                data-id={row.id}
                                onClick={handleOpenModal}
                                data-name="delete"
                              >
                                <TrashIcon />
                              </IconButton>
                            </FancyTooltip>
                          </ResourceAccess>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <FancyTablePagination
              count={
                postCategory !== null && postCategory?.content?.length > 0
                  ? postCategory?.content?.length
                  : 0
              }
              rowsPerPage={postCategory?.size ?? rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              totalElements={postCategory?.totalElements}
              totalPages={postCategory?.totalPages}
            />
          </Grid>
        </Grid>
      </FancyPaper>
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("delete")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  {t("cancel")}
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData?.modalOpen ?? false}
          onClose={handleCancelModal}
          fullWidth
          scroll="paper"
        >
          <AddOrUpdateCategoryModal
            data={modalData?.modalObject}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
    </>
  );
};

export default PostCategories;
