import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Grid, Box, Typography, Divider, useTheme } from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import BusinessIcon from "@mui/icons-material/Business";
import EventIcon from "@mui/icons-material/Event";
import ComparativeGraph from "../components/ComparativeGraph";
import CardDashboard from "../components/CardDashboard";
import PieChartDashboard from "../components/PieChartDashboard";
import { useApp } from "../hooks/useApp";
import FancyPaper from "../components/FancyPaper";
import {
  getRequestTotalUser,
  getRequestTotalCompany,
  getRequestCompanyByCategory,
  getRequestBookingByMonth,
  getRequestBookingByArea,
  getRequestBookingApproved,
} from "../services/DashboardServices";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { StringLiteral } from "typescript";

const AdminDashboard = () => {
  const { t } = useTranslation();
  const { setLoading, setErrorMsg, company } = useApp();
  const [usersTotal, setUsersTotal] = useState<any>(null);
  const [companiesTotal, setCompaniesTotal] = useState<any>(null);
  const [companyByCategory, setCompanyByCategory] = useState<any>(null);
  const [bookingByMonth, setBookingByMonth] = useState<any>(null);
  const [bookingByArea, setBookingByArea] = useState<any>(null);
  const [bookingApproved, setBookingApproved] = useState<any>(null);
  const { handleSubmit } = useForm();

  useEffect(() => {
    console.log("company: ", company);
    const dataInit = async () => {
      if (company !== null) {
        await handleFetch(company);
      }
    };
    dataInit();
    // eslint-disable-next-line
  }, [company]);

  const handleFetch = async (id: any) => {
    setLoading && setLoading(true);
    try {
      let dataTotalUser = await getRequestTotalUser(id);
      let dataTotalCompany = await getRequestTotalCompany();
      let dataCompanyByCategory = await getRequestCompanyByCategory();
      let dataBookingByMonth = await getRequestBookingByMonth(id);
      let dataBookingByArea = await getRequestBookingByArea(id);
      let dataBookingApproved = await getRequestBookingApproved(id);

      if (dataTotalUser) {
        setUsersTotal(dataTotalUser);
      }
      if (dataTotalCompany) {
        setCompaniesTotal(dataTotalCompany);
      }
      if (dataCompanyByCategory) {
        setCompanyByCategory(dataCompanyByCategory);
      }
      if (dataBookingByMonth) {
        setBookingByMonth(dataBookingByMonth);
      }
      if (dataBookingByArea) {
        setBookingByArea(dataBookingByArea);
      }
      if (dataBookingApproved) {
        setBookingApproved(dataBookingApproved);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };
  const handleFetchCompanyByCategory = async () => {
    setLoading && setLoading(true);
    try {
      let dataCompanyByCategory = await getRequestCompanyByCategory();
      if (dataCompanyByCategory) {
        setCompanyByCategory(dataCompanyByCategory);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleFetchBookingByMonth = async (id: string) => {
    setLoading && setLoading(true);
    try {
      let dataBookingByMonth = await getRequestBookingByMonth(id);
      if (dataBookingByMonth) {
        setBookingByMonth(dataBookingByMonth);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleFetchBookingByArea = async (id: string) => {
    setLoading && setLoading(true);
    try {
      let dataBookingByArea = await getRequestBookingByArea(id);
      if (dataBookingByArea) {
        setBookingByArea(dataBookingByArea);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    const dataName = e.target.name;
    switch (dataName) {
      case "companyByCategory":
        await handleFetchCompanyByCategory();
        break;
      case "bookingByMonth":
        await handleFetchBookingByMonth(company);
        break;
      case "bookingByArea":
        await handleFetchBookingByArea(company);
        break;
      default:
        break;
    }
  };

  return (
    <FancyPaper pagetitle={t("home")}>
      <Grid container spacing={6} my={1}>
        <Grid item sm={3} xs={12}>
          <CardDashboard
            positive
            value={
              usersTotal !== null && usersTotal[0].total
                ? usersTotal[0].total
                : 0
            }
            title="Total usuarios"
            subtitle=""
            colorIcon="#0A3A7A"
            iconCard={<PersonAddAlt1Icon />}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <CardDashboard
            positive
            value={
              companiesTotal !== null && companiesTotal[0].total
                ? companiesTotal[0].total
                : 0
            }
            title="Total compañías"
            subtitle=""
            colorIcon="#00aae4"
            iconCard={<BusinessIcon />}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <CardDashboard
            positive
            value={
              bookingApproved !== null && bookingApproved[0].total
                ? bookingApproved[0].total
                : 0
            }
            title="Reservas aprobadas"
            subtitle=""
            colorIcon="#0A3A7A"
            iconCard={<EventIcon />}
          />
        </Grid>
        <Grid item sm={8} xs={12}>
          {bookingByMonth !== null && (
            <ComparativeGraph
              title="Reservas por mes"
              chartSeries={bookingByMonth}
              name={"bookingByMonth"}
              handleClick={handleSubmit(onSubmit)}
            />
          )}
        </Grid>
        <Grid item sm={4} xs={12}>
          {companyByCategory !== null && (
            <PieChartDashboard
              title="Compañías por categoría"
              data={companyByCategory}
              titleChart=" "
              name={"companyByCategory"}
              handleClick={handleSubmit(onSubmit)}
            />
          )}
        </Grid>
      </Grid>
    </FancyPaper>
  );
};

export default AdminDashboard;
