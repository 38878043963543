import { useState, useEffect, createContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { requestTypes } from "../types/Context";
import { setAuthData, setItem, getItem } from "../utils/LocalStorageManager";
import jwt_decode from "jwt-decode";
import { SVSessionValidateRequest } from "../services/security/SecurityService";
import { number } from "yup";
import {
  getRequestCompany,
  getRequestOneCompany,
} from "../services/company/CompanyService";
import { getAllNews } from "../services/news/NewsServices";
import i18n from "i18next";
import { getRequestCurrency } from "../services/currency/CurrencyService";
import { PostCategoryListType, PostListType } from "../types/Post";
import { getRequestPlanList } from "../services/planList/PlanListService";

const initialState = {
  isLoading: false,
  errorMsg: "",
  successMsg: "",
  language: "es",
  postCategory: null,
  postData: null,
};

const AppContext = createContext<requestTypes>(initialState);
const AppProvider = ({ children, authData }: any) => {
  const [authInfo, setAuthInfo] = useState(authData);
  //una sola compañía
  const [company, setCompany] = useState<null | number>(null);
  //todas las compañías
  const [companies, setCompanies] = useState<any>([]);
  const [currencies, setCurrencies] = useState<any>([]);
  const [planList, setPlanList] = useState<any>([]);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(initialState.isLoading);
  const [errorMsg, setErrorMsg] = useState(initialState.errorMsg);
  const [companyInfo, setCompanyInfo] = useState<any>(null);
  const [successMsg, setSuccessMsg] = useState(initialState.successMsg);
  const [modalData, setModalData] = useState({
    modalOpen: false,
    modalType: "",
    modalObject: null,
  });
  const [language, setLanguage] = useState(initialState.language);

  const [authenticate, setAuthenticate] = useState(authData !== null);
  const [postCategory, setPostCategory] = useState<PostCategoryListType | null>(
    null
  );
  const [postData, setPostData] = useState<PostListType | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      if ((authInfo && authInfo.companyId) || company) {
        try {
          const companyInfoResponse: any = await getRequestOneCompany(
            0,
            "",
            isAdmin ? company : authInfo.companyId
          );
          if (companyInfoResponse) {
            const selectedCompany = companyInfoResponse.content[0];
            if (selectedCompany && selectedCompany.language) {
              handleLangChange(selectedCompany.language);
            }
            setCompanyInfo(selectedCompany);
          }
        } catch (error) {
          console.error("Error fetching company information:", error);
        }
      }
    };

    fetchCompanyInfo();
  }, [isAdmin, authInfo, company]);

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const currenciesResponse: any = await getRequestCurrency(0, 25);
        if (currenciesResponse) {
          setCurrencies(currenciesResponse.content);
        }
      } catch (error) {
        console.error("Error fetching currencies:", error);
      }
    };
    fetchCurrencies();
  }, []);

  useEffect(() => {
    const fetchPlanList = async () => {
      try {
        const planListResponse: any = await getRequestPlanList(0, 25);
        if (planListResponse) {
          setPlanList(planListResponse.content);
        }
      } catch (error) {
        console.error("Error fetching plan List:", error);
      }
    };
    fetchPlanList();
  }, []);

  
  // useEffect(() => {
  //   if (companies.length > 0 && authInfo?.companyId !== null && authInfo?.companyId !== undefined) {
  //     console.log("entra acá");
  //     const selectedCompany = companies.find((company: any) => company.id === authInfo.companyId);
  //     if (selectedCompany && selectedCompany.language) {
  //       console.log("entra acá");
  //       console.log(selectedCompany);
  //       handleLangChange(selectedCompany.language);
  //     }
  //   }
  // }, [isAdmin, authInfo, companies]);

  // const fetchCompanyInfo = async (companyId: any) => {
  //   if (companies.length > 0 && companyId !== null && companyId !== undefined) {
  //     const selectedCompany = companies.find((company: any) => company.id === companyId);
  //     if (selectedCompany && selectedCompany.language) {
  //       handleLangChange(selectedCompany.language);
  //     }
  //   }
  // };

  const loginProvider = async (loginData: any) => {
    try {
      const sessionCode = loginData.authCode;
      const email = loginData.username;
      if (sessionCode && email) {
        const loginDataValidate = await SVSessionValidateRequest(
          email,
          sessionCode
        );
        //save login data in storage
        const authData = await setAuthData(
          "authMappRiskAdmin",
          loginDataValidate
        );
        if (!authData) {
          setAuthenticate(false);
          return;
        }
        const decodeToken: any = await jwt_decode(
          authData?.tokenInfo?.access_token
        );
        const expiresAt = await decodeToken?.exp;
        let valid = new Date().getTime() / 1000 < expiresAt;
        if (valid) {
          setAuthenticate(valid);
          setAuthInfo(authData);
          setIsAdmin(authData.userRole.id === 9);
          authData.userRole.id === 9
            ? navigate("/dashboard/home")
            : navigate("/dashboard/customerHome");
          return;
        }
      }
    } catch (error) {
      console.log(error);
    }
    setAuthenticate(false);
  };

  useEffect(() => {
    if (authInfo) {
      setIsAdmin(authInfo.userRole.id === 9);
    }
  }, [isAdmin]);

  useEffect(() => {
    console.log(authenticate);
  }, [authenticate]);
  const logoutProvider = async (e: any) => {
    //e.preventDefault();
    console.log("sale provider");
    await setAuthData("authMappRiskAdmin", null);
    setAuthenticate(false);
    navigate("/");
    //navigate("/", { replace: true });
  };

  // useEffect(() => {
  //   let time = 300000;
  //   let timeOutTimer = setTimeout(logoutProvider, time);
  //   const checkInactivity = () => {
  //     window.onclick = () => checkInactivity();
  //     window.onkeypress = () => checkInactivity();
  //     window.addEventListener("mousemove", checkInactivity);
  //     clearTimeout(timeOutTimer);
  //     timeOutTimer = setTimeout(logoutProvider, time);
  //   };
  //   checkInactivity();
  // }, []);

  /**
   * resetErrorMsg
   */
  const resetErrorMsg = () => {
    setErrorMsg("");
  };

  /**
   * resetSuccessMsg
   */
  const resetSuccessMsg = () => {
    setSuccessMsg("");
  };

  const handleDataCompanies = async () => {
    setLoading && setLoading(true);
    try {
      let data: any = await getRequestCompany(0, "", 100);
      if (data) {
        setCompanies(data.content);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const handleLangChange = async (lang: string) => {
    console.log(lang);
    setLanguage(lang);
    await setItem("lang", lang);
    const localLang: any = await getItem("lang");
    i18n.changeLanguage(localLang);
  };

  const handleFetchNews = async (
    page: number,
    companyId: number,
    filter: string,
    status?: string
  ) => {
    try {
      let data: any = await getAllNews(page, companyId, filter, status);
      if (data) {
        setPostData(data);
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error);
    }
  };

  console.log(companyInfo);

  const formContextValues: requestTypes = useMemo(
    () => ({
      authInfo,
      authenticate,
      isLoading,
      setLoading,
      errorMsg,
      setErrorMsg,
      successMsg,
      setSuccessMsg,
      modalData,
      setModalData,
      loginProvider,
      logoutProvider,
      resetErrorMsg,
      resetSuccessMsg,
      company,
      setCompany,
      companies,
      setCompanies,
      isAdmin,
      setIsAdmin,
      handleDataCompanies,
      language,
      setLanguage,
      handleLangChange,
      companyInfo,
      currencies,
      planList,
      postCategory,
      setPostCategory,
      postData,
      setPostData,
      handleFetchNews,
    }),
    // eslint-disable-next-line
    [
      authInfo,
      authenticate,
      isLoading,
      errorMsg,
      successMsg,
      modalData,
      company,
      companies,
      isAdmin,
      language,
      companyInfo,
      postCategory,
      postData,
      currencies,
      planList,
    ]
  );

  return (
    <AppContext.Provider value={formContextValues}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider };

export default AppContext;
