import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useApp } from '../../hooks/useApp';
import {
  getRequest
} from "../../services/country/CountryService";
import CountrySelector from './countrySelector/CountrySelector';
import { useTranslation } from 'react-i18next';
import { BookingPaymetReport } from '../../types/ServiceTypes';

const Title = styled(DialogTitle)(() => ({
  backgroundColor: '#e1e8ee',
  color: '#3e627c!important',
  fontFamily: 'D-dinExp',
  fontWeight: 400,
  fontSize: '17.5px',
}));

const Actions = styled(DialogActions)(() => ({
  borderTop: '1px solid #dee2e6',
}));

interface AddOrUpdatePayment {
  id?: number;
  amount: string;
  datePayment?: string;
  installation?: string;
  payMethod?: string;
  cardNumber?: string;
  cardName?: string;
  reference?: string;
}


interface DialogProps {
  data: any;
  cancelModal: any;
  modalType?: any;
}

const AddOrUpdateBookingPayment = ({
  data,
  cancelModal,
  modalType,
}: DialogProps) => {
  const { t } = useTranslation();

  const {
    setLoading,
    setErrorMsg,
    companyInfo,
  } = useApp();

  //Validación de datos
  const validation = Yup.object().shape({
    amount: Yup.string().required(t("fieldRequired")),
    datePayment: Yup.string().required(t("fieldRequired")),
    installation: Yup.string().required(t("fieldRequired")),
    payMethod: Yup.string().required(t("fieldRequired")),
    cardNumber: Yup.string().required(t("fieldRequired")),
    cardName: Yup.string().required(t("fieldRequired")),
    reference: Yup.string().required(t("fieldRequired")),
  });

  const formatterCurrency = (row: any) => {
    // const symbolCurrency = row?.currency?.id && row?.currency?.symbol ? row?.currency?.symbol + ' ' : '';
    const symbolCurrency = companyInfo?.currencies != null && companyInfo?.currencies[0]?.symbol ? companyInfo.currencies[0].symbol + ' ' : '';
    return `${formatNumber(row.toFixed(2) + '')} ${symbolCurrency}`;
  };

  const formatNumber = (value: string) => {
    // Eliminar cualquier separador de miles antes de aplicar el nuevo valor
    const normalizedValue = (value || "").toString().replace(/,/g, "");
    // Aplicar el formato con separadores de miles
    return normalizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const defaultValues = {
    id: data ? data.id : 0,
    amount: data ? formatterCurrency(data.amount) : '',
    datePayment: data ? data.datePayment.substring(0, 10) : '',
    installation: data ? data.installation : '',
    payMethod: data ? data.payMethod : '',
    cardNumber: data ? data.cardNumber : '',
    cardName: data ? data.cardName : '',
    reference: data ? data.reference : '',
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<AddOrUpdatePayment>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: 'onChange',
  });


//   useEffect(() => {
//     console.log(selectedCountry);
//     console.log(data);
//     selectedCountry != null && setValue("countries", selectedCountry)

//   }, [selectedCountry])

//   const validateAssociatedNumber = (value: string) => {
//     // Iterar sobre los elementos del objeto associate
//     for (const key in associate) {
//         if (associate.hasOwnProperty(key)) {
//             const item = associate[key];
//             // Verificar si el número de asociado ya existe en algún elemento
//             if (item.associatedNumber === value) {
//                 setAssociatedNumberError('El número de asociado ya existe');
//                 return; // Salir de la función una vez que se encuentra una coincidencia
//             }
//         }
//     }
//     // Si no se encuentra ninguna coincidencia, limpiar el mensaje de error
//     setAssociatedNumberError('');
// };


  return (
    <>
      <Title id='alert-dialog-title' sx={{ pt: 4, px: 4 }}>
        {t("payDetail")}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth='lg' sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={'installation'}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={t("installation")}
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='installation'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('installation')}
                    error={errors.installation && Boolean(errors.installation)}
                    helperText={errors.installation && errors.installation.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'payMethod'}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={t("paymentMethod")}
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='payMethod'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('payMethod')}
                    error={errors.payMethod && Boolean(errors.payMethod)}
                    helperText={errors.payMethod && errors.payMethod.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'datePayment'}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={t("paymentDate")}
                    type='email'
                    variant='standard'
                    value={value}
                    autoComplete='datePayment'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('datePayment')}
                    error={errors.datePayment && Boolean(errors.datePayment)}
                    helperText={errors.datePayment && errors.datePayment.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'amount'}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={t("amount")}
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='amount'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('amount')}
                    error={errors.amount && Boolean(errors.amount)}
                    helperText={errors.amount && errors.amount.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'cardNumber'}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={t("cardNumber")}
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='cardNumber'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('cardNumber')}
                    error={errors.cardNumber && Boolean(errors.cardNumber)}
                    helperText={errors.cardNumber && errors.cardNumber.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'cardName'}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={t("cardName")}
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='cardName'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('cardName')}
                    error={errors.cardName && Boolean(errors.cardName)}
                    helperText={errors.cardName && errors.cardName.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'reference'}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={t("reference")}
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='reference'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('reference')}
                    error={errors.reference && Boolean(errors.reference)}
                    helperText={errors.reference && errors.reference.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        {/* <Button
          variant='contained'
          color='primary'
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}>
          {t("save")}
        </Button> */}
        <Button
          variant='contained'
          color='secondary'
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus>
          {t("cancel")}
        </Button>{' '}
      </Actions>
    </>
  );
};

export default AddOrUpdateBookingPayment;
