import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { AssociatedResponse } from "./AssociatedTypes";
import { UrlBase } from "../../url/Urls";

export const getAllAssociated = async (page: number,companyId: any, filter?: string): Promise<AssociatedResponse> => {
  try {
    const response = await privateFetch.get<AssociatedResponse>(
      URL.REQUEST_ASSOCIATED_LIST(page, UrlBase.registration_x_page, companyId, filter)
    );

    return response.data;
  } catch (error) {
    throw new Error(`Error fetching associated list: ${error instanceof Error ? error.message : String(error)}`);
  }
};

export const getAssociated = async (companyId: any) => {
  try {
    const response = await privateFetch.get(URL.REQUEST_ALLASSOCIATED_LIST(companyId))

    return response.data;
  } catch (error) {
    throw new Error(`Error fetching associated list: ${error instanceof Error ? error.message : String(error)}`);
  }
};

export const createAssociated = async (data: any): Promise<any> => {
  try {
    const response = await privateFetch.post(URL.REQUEST_ASSOCIATED_CREATE, data);

    return response.data;
  } catch(error:any) {
    throw new Error(error.response.data.message);
  }
}

export const updateAssociated = async (id: number, data: any): Promise<any> => {
  try {
    const response = await privateFetch.put(URL.REQUEST_ASSOCIATED_UPDATE(id), data);

    return response.data;
  } catch (error) {
    throw new Error(`Error updating associated: ${error instanceof Error ? error.message : String(error)}`);
  }
}

export const deleteAssociated = async (id: number): Promise<any> => {
  try {
    const response = await privateFetch.delete(URL.REQUEST_ASSOCIATED_DELETE(id));

    return response.data;
  } catch (error) {
    throw new Error(`Error deleting associated: ${error instanceof Error ? error.message : String(error)}`);
  }
}
