/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    FormControl,
    FormHelperText,
    Grid,
    Grow,
    InputLabel,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next"; 
import { useApp } from "../../hooks/useApp";
import { getRequestBooking, reportBookingCapacity, reportBookingCapacityDownload, reportBookingPayment, reportBookingPaymentDownload } from "../../services/booking/BookingService";
import FancyPaper from "../../components/FancyPaper";
import { BookingPaymetReport } from "../../types/ServiceTypes";
import dayjs from 'dayjs';


import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
  
  const ReportBookingPayment = () => {
    const { t } = useTranslation();
    const {
      authInfo,
      company,
      companyInfo,
      isAdmin,
      setLoading,
      setErrorMsg
    } = useApp();

    const [data, setData] = useState<any[]>([]);
    const [date, setDate] = useState<Dayjs>(dayjs(new Date()));
    const [area, setArea] = useState<number>(0);
    const [areaDetail, setAreaDetail] = useState<number>(0);
    const [areaDetailRequired, setAreaDetailRequired] = useState<boolean>(false);
    const [areaArray, setAreaArray] = useState<any>([]);
    const [areaDetailArray, setAreaDetailArray] = useState<any>([]);
    const [areaError, setAreaError] = useState<string>('');
    const [areaDetailError, setAreaDetailError] = useState<string>('');
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] =useState(1);

    const options = [`${t("download")} PDF`, `${t("download")} XLSX`];

  const handleFetchBooking = async () => {
    setLoading && setLoading(true);
    setAreaArray([]);
    setAreaDetailArray([]);
    try {
      let bookingDataResponse = await getRequestBooking(0, '', isAdmin && company ? company : authInfo.companyId);
      if(bookingDataResponse?.content){
        setAreaArray(bookingDataResponse.content);
        if(area && area!==0)
            onChangeArea(area);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };
  
    /**
     * Efecto inicial para carga de registros
     */
    useEffect(() => {
        handleFetchBooking();
    }, [company]);
    
  
    /**
     * Metodo encargado de buscar registros
     * @param startDate fecha inicio de reporte
     * @param endDate fecha final de reporte
     */
    const handleFetchData = async (
      date: string,
      area_id: number,
      area_detail_id: number
    ) => {
      setLoading && setLoading(true);
      setData([]);
      try {
        let data = await reportBookingCapacity(company ? company : authInfo.companyId, date, area_id, area_detail_id);
        if (data) {
            setData(data.content);
        }
        setLoading && setLoading(false);
      } catch (error: any) {
        setLoading && setLoading(false);
        setErrorMsg && setErrorMsg(error.message);
      }
    };

    /**
     * Evento de aplicar filtro de busqueda
     */
    const handleApplyFilter = async () => {
        if(!area || area === 0 ){
            setAreaError(t('fieldRequired'));
            return;
        }
        if((!areaDetail || areaDetail ===0) && areaDetailRequired){
            setAreaDetailError(t('fieldRequired'));
            return;
        }
        await handleFetchData(dayjs(date).format('YYYY-MM-DD'),area, areaDetail);
    };

    const handleDownload = () => {
      if(selectedIndex==0)
        reportBookingCapacityDownload(company ? company : authInfo.companyId, dayjs(date).format('YYYY-MM-DD'), area, 'pdf', areaDetail);
      if(selectedIndex==1)
        reportBookingCapacityDownload(company ? company : authInfo.companyId, dayjs(date).format('YYYY-MM-DD'), area, 'xlsx', areaDetail);
    };

    const handleMenuItemClick = (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>,
      index: number,
    ) => {
      setSelectedIndex(index);
      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }

      setOpen(false);
    };
    
    const onChangeArea = (areaId: number) => {
        if(areaId && areaId!==0){
            const bookingArea = areaArray.find((areaObj: any) => areaObj.id === areaId);
            setArea(areaId);
            setAreaDetail(0);
            setAreaDetailArray([]);
            setAreaError('');
            setAreaDetailError('');
            if(bookingArea && bookingArea.id && bookingArea.id !== 0){
                setAreaDetailRequired(!!!bookingArea.capacity)
                setAreaDetailArray(bookingArea.areaDetailList);
            }
        }
    };
    
    const onChangeAreaDetail = (areaDetailId: number) => {
        if(areaDetailId && areaDetailId!==0){
            setAreaDetail(areaDetailId);
            setAreaDetailError('');
        }
    };
  
    return (
      <>
        <FancyPaper pagetitle={`${t("reports")} / ${t("bookingCapacity")}`}>
          <Grid container spacing={8} sx={{ pb: 8 }}>
            <Grid item md={6} sm={6} xs={12}>
              <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="Download"
              >
                <Button onClick={handleDownload}>{options[selectedIndex]}</Button>
                <Button
                  size="small"
                  aria-controls={open ? 'split-button-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {options.map((option, index) => (
                            <MenuItem
                              key={option}
                              disabled={index === 2}
                              selected={index === selectedIndex}
                              onClick={(event) => handleMenuItemClick(event, index)}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
  
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={4}
                >
                    <Stack>
                        <FormControl>
                            <InputLabel id="installation-simple-select-label">{t("installation")}</InputLabel>
                            <Select
                            labelId='installation-simple-select-label'
                            id='installation-simple-select'
                            label={t("installation")}
                            value={area}
                            onChange={(e: any) => {
                                if(e?.target?.value)
                                    onChangeArea(e.target.value);
                            }}
                            sx={{
                                width: '200px',
                                paddingLeft: '15px',
                                '& .MuiInputBase-root': {
                                    marginLeft: '5px',
                                },
                            }}>
                            {areaArray && areaArray.map((item: any, idx: number) => {
                                return <MenuItem value={item.id} key={idx}>{item.name}</MenuItem>;
                            })}
                            </Select>
                            <FormHelperText id="component-installation-error-text" sx={{paddingLeft: '15px'}}>{areaError}</FormHelperText>
                        </FormControl>
                    </Stack>
                    {areaDetailArray.length > 0 && areaDetailRequired && (
                        <Stack>
                            <FormControl >
                            <InputLabel id="subinstallation-simple-select-label">{t("subinstallation")}</InputLabel>
                                <Select
                                    labelId='subinstallation-simple-select-label'
                                    id='subinstallation-simple-select'
                                    label={t("subinstallation")}
                                    onChange={(e: any) => {
                                        if(e?.target?.value)
                                            onChangeAreaDetail(e.target.value);
                                    }}
                                    value={areaDetail}
                                    sx={{
                                    marginLeft: '10px',
                                    width: '200px',
                                    paddingLeft: '15px',
                                    '& .MuiInputBase-root': {
                                        marginLeft: '5px',
                                    },
                                    }}>
                                    {areaDetailArray && areaDetailArray.map((item: any) => {
                                    return <MenuItem value={item.id}>{item.description}</MenuItem>;
                                    })}
                                </Select>
                                <FormHelperText id="component-subinstallation-error-text" sx={{paddingLeft: '15px'}}>{areaDetailError}</FormHelperText>
                            </FormControl>
                        </Stack>
                    )}

                    <Stack>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={t("date")}
                                value={date}
                                onChange={(newValue: any) => {
                                    setDate(newValue);
                                }}
                                format="YYYY-MM-DD"
                                sx={{
                                    width: '150px'
                                }}
                            /> 
                        </LocalizationProvider>
                    </Stack>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleApplyFilter}
                      sx={{
                        borderRadius: "0 0.2rem 0.2rem 0!important",
                        padding: "1.5px 16px!important",
                      }}
                    >
                      {t("search")}
                    </Button>
                </Stack>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{t("schedule")}</TableCell>
                  <TableCell align="center">{t("installation")}</TableCell>
                  { data.length>0 && data[0].areaDetail &&
                  <TableCell align="center">{t("subinstallation")}</TableCell>
                  }
                  <TableCell align="center">{t("occupiedCapacity")}</TableCell>
                  <TableCell align="center">{t("availableCapacity")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map((row: any, i: number) => (
                    <TableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center" component="th" scope="row">
                        {row.hourStart}
                      </TableCell>
                      <TableCell align="center">{row.area?.name}</TableCell>
                      { row.areaDetail &&
                      <TableCell align="center">{row.areaDetail?.description}</TableCell>
                      }
                      <TableCell align="center">{row.occupiedCapacity}</TableCell>
                      <TableCell align="center">{row.availableCapacity}</TableCell>
                    </TableRow>                      
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </FancyPaper>
      </>
    );
  };
  
  export default ReportBookingPayment;
