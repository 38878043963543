import { privateFetch } from "../../utils/CustomFetch.js";
import { URL } from "../../utils/UrlConstants";
import { PlanListResponse } from "./PlanListInterface.js";

export const getRequestPlanList = async (page: number, size: number): Promise<PlanListResponse> => {
    try {
        const response = await privateFetch.get(URL.REQUEST_PLAN_LIST_LIST(page, size));
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};