import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Typography,
    styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";

const Title = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: "#e1e8ee",
    color: "#3e627c!important",
    fontFamily: "D-dinExp",
    fontWeight: 400,
    fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
    borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdate {
    id?: number;
    user?: string;
    password?: string;
    apiKey?: string;
    paymentMethod?: number;
}

interface DialogProps {
    actionButton?: any;
    data: any;
    onSubmit: any;
    cancelModal: any;
    modalType?: any;
    refreshData: any;
}

const AddOrUpdatePayPalMethodModal = ({
    data,
    onSubmit,
    cancelModal,
    modalType,
    refreshData,
}: DialogProps) => {
    const { t } = useTranslation();

    //Validación de datos
    const validation = Yup.object().shape({
        apiKey: Yup.string().required(t("fieldRequired")),
    });

    const defaultValues = {
        id: data ? data.id : 0,
        apiKey: data ? data.apiKey : "",
    };



    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        control,
        watch,
    } = useForm<AddOrUpdate>({
        defaultValues,
        resolver: yupResolver(validation),
        mode: "onChange",
    });

    const { isAdmin, currencies, company, authInfo, setLoading, setErrorMsg, setSuccessMsg, modalData } = useApp();

    const selectedPaymentMethod = watch("paymentMethod");

    return (
        <>
            <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
                {modalType === "update" ? t("updateCard") : t("addCard")}
            </Title>
            <DialogContent sx={{ px: 4, pb: 0 }}>
                <form>
                    <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
                        <Grid item xs={12}>
                            <Controller
                                name={"apiKey"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label={t("Api Key")}
                                        type="text"
                                        variant="standard"
                                        value={value}
                                        autoComplete="name"
                                        sx={{ "& input": { pl: "0!important" } }}
                                        {...register("apiKey")}
                                        error={errors.apiKey && Boolean(errors.apiKey)}
                                        helperText={
                                            errors.apiKey && errors.apiKey.message
                                        }
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent >
            <Actions sx={{ pb: 4, px: 4 }}>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        mt: 2,
                        mr: 2,
                    }}
                    onClick={() => {
                        handleSubmit(onSubmit)();
                    }}
                >
                    {t("save")}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                        mt: 2,
                        mr: 2,
                    }}
                    onClick={cancelModal}
                    autoFocus
                >
                    {t("cancel")}
                </Button>{" "}
            </Actions>
        </>
    );
};

export default AddOrUpdatePayPalMethodModal;
