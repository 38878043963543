import { CssBaseline, ThemeProvider } from "@mui/material";
import ReactDOM from "react-dom/client";
import theme from "./theme/theme";
import reportWebVitals from "./reportWebVitals";
import "./resources/custom.css";
import "./resources/fonts.css";
import { RouterProvider } from "react-router-dom";
import { appRoutes } from "./routes/AppRoutes";
import "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <RouterProvider router={appRoutes} />
  </ThemeProvider>
);

reportWebVitals();
