import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { arrayToStringWithCommas } from "../../utils/UtilsFunctions";


export const createRequest = async (data: any, company_id: any) => {
    let responseData: any = {};
    const requestBody = {
        description: data.description,
        status: data.status,
        availableDays: 7,
        generalDayAmount: 1500,
        associatedDayAmount: 1000,
        capacity: data.capacity,
        hasCapacity: data.hasCapacity,
        approval: data.approval,
        reservationType: data.reservationType,
        calculatePrice: data.calculatePrice,
        startTime: data.weekdaysStartTime,
        endTime: data.weekdaysEndTime,
        startTimeWeekend: data.weekendsStartTime,
        endTimeWeekend: data.weekendsEndTime,
        publicAmount: data.publicPrice,
        associatedAmount: data.colegiadoPrice,
        details: data.areaDetails,
        idArea: data.idArea,
        userCreated: data.userCreated

    };
    await privateFetch
        .post(URL.REQUEST_AREA_CREATE(company_id), JSON.stringify(requestBody), {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            },
        })
        .then(async (response) => {
            responseData = await response.data;
        })
        .catch(async (error) => {
            throw new Error(error.response.data.message);
        });
    return responseData;
};

export const updateRequest = async (data: any, company_id: any) => {
    let responseData = {};
    const requestBody = {
        id: data.id,
        idArea: 1,
        description: data.description,
        availableDays: data.availableDays,
        startTime: data.startTime,
        endTime: data.endTime,
        startTimeWeekend: data.startTimeWeekend,
        endTimeWeekend: data.endTimeWeekend,
        publicAmount: data.publicAmount,
        associatedAmount: data.associatedAmount,
        photo: arrayToStringWithCommas(data.photo),
        status: data.status,
        generalDayAmount: data.generalDayAmount,
        associatedDayAmount: data.associatedDayAmount,
        type: 1,
        capacity: data.capacity,
        details: data.details
    };
    await privateFetch
        .put(URL.REQUEST_AREA_UPDATE(data.id, company_id), JSON.stringify(requestBody), {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            },
        })
        .then(async (response) => {
            responseData = await response.data;
        })
        .catch(async (error) => {
            throw new Error(properties.com_parval_label_area_update_error);
        });
    return responseData;
};

export const deleteRequest = async (company_id: any, id: any) => {
    let responseData = {};
    await privateFetch
        .delete(URL.REQUEST_AREA_DELETE(company_id, id), {})
        .then(async (response) => {
            responseData = await response.data;
        })
        .catch(async (error) => {
            throw new Error(
                properties.com_parval_label_area_delete_error
            );
        });
    return responseData;
};