import React, { useState } from 'react';
import FancyPaper from '../components/FancyPaper';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, IconButton } from '@mui/material';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import theme from '../theme/theme';

const months = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
];

// Mock data for files inside each folder
const filesByMonth: { [key: string]: string[] } = {
    'Enero': ['file1.pdf', 'file2.docx'],
    'Febrero': ['file3.pdf', 'file4.docx'],
    // Add files for other months as needed
};

const SettingFinancialStatement = () => {
    const { t } = useTranslation();
    const [currentYear, setCurrentYear] = useState<number>(2024);
    const [selectedMonth, setSelectedMonth] = useState<string | null>(null);

    const handleFolderClick = (month: string) => {
        setSelectedMonth(month);
    };

    const handleBackClick = () => {
        setSelectedMonth(null);
    };

    return (
        <FancyPaper pagetitle={`${t("finance")} / ${t("financialStatement")}`}>
            <Box display="flex" flexDirection="column" height="100vh">
                {selectedMonth ? (
                    <Box flex={1} p={2}>
                        <IconButton onClick={handleBackClick} sx={{ mb: 2 }}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h5" gutterBottom>
                            {`Archivos de ${selectedMonth}`}
                        </Typography>
                        <Grid container spacing={5} justifyContent="flex-start">
                            {filesByMonth[selectedMonth]?.map((file) => (
                                <Grid item xs={12} sm={6} md={3} lg={3} key={file}>
                                    <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                                        <InsertDriveFileOutlinedIcon color="primary"
                                            sx={{
                                                "&:hover": {
                                                    color: theme.palette.secondary.dark,
                                                },
                                            }} style={{
                                                fontSize: 120
                                            }} />
                                        <Typography variant="h6">{file}</Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                ) : (
                    <Box flex={1} p={2}>
                        <Typography variant="h4" gutterBottom>ESTADOS FINANCIEROS</Typography>
                        <Grid container spacing={5} justifyContent="center">
                            {months.map((month) => (
                                <Grid item xs={12} sm={6} md={3} lg={3} key={month} onClick={() => handleFolderClick(month)}>
                                    <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                                        <FolderOutlinedIcon color="primary"
                                            sx={{
                                                "&:hover": {
                                                    color: theme.palette.secondary.dark,
                                                },
                                            }} style={{
                                                fontSize: 120
                                            }} />
                                        <Typography variant="h6">{month}</Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
            </Box>
        </FancyPaper>
    );
};

export default SettingFinancialStatement;
