import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import countriesData from "../utils/countrySelector/countriesData.json";
import CountryFlag from "./countrySelector/CountryFlag";
import { useTranslation } from "react-i18next";
import { validateNameAvailability } from "../../services/country/CountryService";
import { useApp } from "../../hooks/useApp";
import { newsCategoryResponse } from "../../types/Post";

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

const AddOrUpdateCategoryModal = ({
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  const { t } = useTranslation();
  const { company, authInfo } = useApp();
  // Validación de datos
  const validation = Yup.object().shape({
    id: Yup.number().required(t("fieldRequired")),
    idCompany: Yup.number().required(t("fieldRequired")),
    name: Yup.string().required(t("fieldRequired")),
  });

  const defaultValues = {
    id: data?.id ?? 0,
    idCompany: data?.idCompany ?? company,
    name: data?.name ?? "",
  };

  // Inicializar useForm con validación y valores predeterminados
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<newsCategoryResponse>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "update" ? t("updCategory") : t("addCategory")}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("name")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name3"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("name")}
                    error={errors.name && Boolean(errors.name)}
                    helperText={
                      errors.name && errors.name.message && errors.name.message
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          {t("save")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          {t("cancel")}
        </Button>{" "}
      </DialogActions>
    </>
  );
};

export default AddOrUpdateCategoryModal;
