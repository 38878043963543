import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import RejectBookingModal from "./RejectBookingModal";
import { useTranslation } from "react-i18next";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

const disabledBlack = {
  "& .MuiFormLabel-root.Mui-disabled": {
    color: "black", // Cambia el color de la etiqueta cuando está deshabilitado
  },
  "& .MuiInputBase-root.Mui-disabled": {
    "& input": {
      WebkitTextFillColor: "black", // Para navegadores basados en Webkit como Chrome y Safari
      color: "black", // Para otros navegadores
    },
  },
};

interface AcceptOrRejectBookingInterface {
  id?: number;
  name: string;
  creationDate?: string;
  associatedNumber?: string;
  phone?: string;
  email?: string;
  message?: string;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

const AcceptOrRejectBookingModal = ({
  data,
  onSubmit,
  cancelModal,
}: DialogProps) => {
  const { t } = useTranslation();
  //Validación de datos
  const validation = Yup.object().shape({
    message: Yup.string().required(t("fieldRequired")),
  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    name: data.name ? data.name : "",
    associatedNumber: data.associatedNumber ? data.associatedNumber : "",
    phone: data.phone ? data.phone : "",
    email: data.email ? data.email : "",
    creationDate: data.creationDate ? data.creationDate.substring(0, 10) : "",
    // status: data.status ? data.status : 0,
  };
  const [openModalReject, setOpenModalReject] = useState(false);

  const handleCancelModalReject = () => {
    setOpenModalReject(false);
  };

  const {
    register,
    formState: { errors },
    control,
  } = useForm<AcceptOrRejectBookingInterface>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  
const formatSingleHourTo12Hour = (time: string) => {
  const [hour, minutes] = time.split(':');

  let formattedHour = parseInt(hour, 10);
  let period = 'AM';

  if (formattedHour === 0) {
    formattedHour = 12;
  } else if (formattedHour === 12) {
    period = 'PM';
  } else if (formattedHour > 12) {
    formattedHour -= 12;
    period = 'PM';
  }

  return `${formattedHour}:${minutes} ${period}`;
};

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {t("bookings")}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  textDecoration: "underline",
                  fontSize: "18px",
                }}
              >
                <b>
                  {/* Subrayado */}
                  {t("reservationData")}
                </b>
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Controller
                name={"creationDate"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("areaRequestDate")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name3"
                    sx={disabledBlack}
                    {...register("creationDate")}
                    error={errors.creationDate && Boolean(errors.creationDate)}
                    helperText={
                      errors.creationDate && errors.creationDate.message
                    }
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name={"associatedNumber"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("associateNumber")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name3"
                    sx={disabledBlack}
                    {...register("associatedNumber")}
                    error={
                      errors.associatedNumber &&
                      Boolean(errors.associatedNumber)
                    }
                    helperText={
                      errors.associatedNumber && errors.associatedNumber.message
                    }
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name={"phone"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("phone")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name3"
                    sx={disabledBlack}
                    {...register("phone")}
                    error={errors.phone && Boolean(errors.phone)}
                    helperText={errors.phone && errors.phone.message}
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("name")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name3"
                    sx={disabledBlack}
                    {...register("name")}
                    error={errors.name && Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={"email"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("email")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name3"
                    sx={disabledBlack}
                    {...register("email")}
                    error={errors.email && Boolean(errors.email)}
                    helperText={errors.email && errors.email.message}
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  textDecoration: "underline",
                  fontSize: "18px",
                  marginBottom: "10px",
                }}
              >
                <b>
                  {t("reservationDetails")} {data.areaName}
                </b>
              </Typography>
            </Grid>
            <Grid container xs={12} spacing={4} paddingX="20px">
              {/* <Grid item sm={12} xs={12} sx={{ mt: 4 }}> */}
              <Grid item sm={6} xs={12}>
                <TextField
                  label={t("reservationDate")}
                  type="text"
                  variant="standard"
                  size="small"
                  fullWidth
                  sx={disabledBlack}
                  name="bookingDay"
                  value={data.bookingDay.substring(0, 10) || ""}
                  disabled
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label={t("totalHours")}
                  type="text"
                  variant="standard"
                  size="small"
                  fullWidth
                  name="totalHours"
                  sx={disabledBlack}
                  value={data.totalHours || ""}
                  disabled
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label={t("startTime")}
                  type="text"
                  variant="standard"
                  size="small"
                  fullWidth
                  name="startDate"
                  sx={disabledBlack}
                  value={formatSingleHourTo12Hour(data.startTime) || ""}
                  disabled
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label={t("endTime")}
                  type="text"
                  variant="standard"
                  size="small"
                  sx={disabledBlack}
                  fullWidth
                  name="endDate"
                  value={formatSingleHourTo12Hour(data.endTime) || ""}
                  disabled
                />
              </Grid>
              {data.payMethod !== "ADMIN" && (
                <Grid item sm={6} xs={12}>
                  <TextField
                    label={t("areaAmount")}
                    variant="standard"
                    size="small"
                    fullWidth
                    type="text"
                    name="areaAmount"
                    sx={disabledBlack}
                    value={data.areaAmount || ""}
                    disabled
                  />
                </Grid>
              )}
              {data.spaces && (
                <Grid item sm={6} xs={12}>
                  <TextField
                    label={t("reservedSpaces")}
                    variant="standard"
                    size="small"
                    fullWidth
                    type="text"
                    name="reservedSpaces"
                    sx={disabledBlack}
                    value={data.spaces || ""}
                    disabled
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          {/* </Grid> */}
        </form>
      </DialogContent>
      <DialogActions sx={{ pb: 4, px: 4 }}>
        {/* {data.status === "APR" || data.status === "REJ" ? (
          <Button
            variant="contained"
            color="secondary"
            sx={{
              mt: 2,
              mr: 2,
            }}
            onClick={cancelModal}
          >
            Cancelar
          </Button>
        ) : (
          <> */}
        {data.status !== 3 && (
          <Button
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              mr: 2,
            }}
            onClick={() =>
              onSubmit({
                id: data.id,
                bookingDetailList: data.bookingDetailList,
                status: true,
              })
            }
          >
            {t("approve")}
          </Button>
        )}

        {data.status !== 4 && (
          // {data.status !== "REJ" && (

          <Button
            variant="contained"
            color="secondary"
            sx={{
              mt: 2,
              mr: 2,
            }}
            onClick={() => setOpenModalReject(true)}
          >
            {t("reject")}
          </Button>
        )}

        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
        >
          {t("cancel")}
        </Button>
        {/* </>
        )} */}
      </DialogActions>
      <RejectBookingModal
        openModalReject={openModalReject}
        handleCancelModalReject={handleCancelModalReject}
        onSubmit={onSubmit}
        data={data}
      />
    </>
  );
};

export default AcceptOrRejectBookingModal;
