import {PayPalButton} from "react-paypal-button-v2";
import {
  createPaymentLink,
  CreatePaymentLinkRequest,
  UpdatePaymentRequest, updatePaymentStatus
} from "../../services/payment/PaymentService";
import {useApp} from "../../hooks/useApp";

interface DialogProps {
  client_id: string;
  paymentData: CreatePaymentLinkRequest;
  paymentMethodId: number;
  amount: string;
  currency: string;
}

const PayPalButtonsComponent = ({client_id, paymentData, paymentMethodId, amount, currency}: DialogProps) => {
  const {
    modalData,
    setModalData,
  } = useApp();

  if (client_id != null && client_id != "") {
    return (
        <PayPalButton
            createOrder={(data: any, actions: any) => {
              let order = actions.order.create({
                  purchase_units: [{
                    amount: {
                      currency_code: currency,
                      value: amount
                    }
                  }]
                })
              order.then((OrderId:any) => {
                  let requestData: CreatePaymentLinkRequest = {
                    ...paymentData,
                    paymentMethodId: paymentMethodId,
                    reference: OrderId
                  }
                  createPaymentLink(requestData);

              })
              return order;
            }}
            amount={amount}
            onError={(details: any)=>{
              console.log('details',details);
            }}
            onSuccess={(details: any, actions: any) => {

              let requestUpdateData: UpdatePaymentRequest = {
                transactionId: actions.orderID,
                status: "CONFIRMED"
              }
              updatePaymentStatus(requestUpdateData);
              setModalData && setModalData({
                ...modalData,
                modalOpen: true,
                modalType: 'paymentSuccess',
                modalObject: null,
              });
            }}
            options={{
              clientId: client_id,
              disableFunding: 'card'
            }}
        />
    );
  } else {
    return (<div>loading...</div>)
  }
}

export default PayPalButtonsComponent;