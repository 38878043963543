import {
    Button,
    Dialog,
    Grid,
    IconButton,
    Paper,
    Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow,
    useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import FancyPaper from '../../../components/FancyPaper';
import ResourceAccess from '../../../components/security/ResourceAccess';
import { PenIcon, PlusIcon, TrashIcon } from '../../../components/svgicons/SvgIcons';
import FancyTooltip from '../../../components/utils/FancyTooltip';
import AddOrUpdateGuestModal from '../../../components/utils/guests/AddOrUpdateGuestsModal';
import DeleteGuestModal from '../../../components/utils/guests/DeleteGuestModal';
import { useApp } from "../../../hooks/useApp";
import { Content } from '../../../services/guests/GuestInterface';
import { useGuests } from './GuestsContext';

const GuestsList: React.FC = () => {
    const { t } = useTranslation();
    const { guestsData,
        onSubmit,
        handleOpenModal,
        handleCancelModal,
        handleSubmit,
    } = useGuests();
    const {
        modalData,
    } = useApp();
    const theme = useTheme();


    // const { t } = useTranslation();

    return (
        <>
            <FancyPaper pagetitle={`${t("security")} / ${t("guests")}`}>

                <Grid container spacing={8} sx={{ pb: 8 }}>
                    <Grid item md={9} sm={6} xs={12}>
                        <ResourceAccess isCode={true} pathOrCode={"GUESTS:WRITE"}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleOpenModal
                                }
                                data-name="create"
                            >
                                {t("addGuest")}
                                <PlusIcon sx={{ ml: 1 }} />
                            </Button>
                        </ResourceAccess>
                    </Grid>
                </Grid>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'>{t("id")}</TableCell>
                                <TableCell align='center'>{t("apartment")}</TableCell>
                                <TableCell align='center'>{t("name")}</TableCell>
                                <TableCell align='center'>{t("lastName")}</TableCell>
                                <TableCell align='center'>{t("identification1")}</TableCell>
                                <TableCell align='center'>{t("country")}</TableCell>
                                <TableCell align='center'>{t("startDate")}</TableCell>
                                <TableCell align='center'>{t("startTime")}</TableCell>
                                <TableCell align='center'>{t("endDate")}</TableCell>
                                <TableCell align='center'>{t("endTime")}</TableCell>
                                <TableCell align='center'>{t("state")}</TableCell>
                                <TableCell align='center'>{t("actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {guestsData &&
                                guestsData.content &&
                                guestsData.content.map((row: Content, i: number) => (
                                    <TableRow
                                        key={i}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                        }}>
                                        <TableCell align='center'>{row.id}</TableCell>
                                        <TableCell align='center'>{row.apartment}</TableCell>
                                        <TableCell align='center'>{row.name}</TableCell>
                                        <TableCell align='center'>{row.lastName}</TableCell>
                                        <TableCell align='center'>{row.identification}</TableCell>
                                        <TableCell align='center'>{row.country}</TableCell>
                                        <TableCell align='center'>{row.startDate.substring(0, 10)}</TableCell>
                                        <TableCell align='center'>{format(new Date(row.startDate), 'hh:mm a')}</TableCell>
                                        <TableCell align='center'>{row.endDate.substring(0, 10)}</TableCell>
                                        <TableCell align='center'>{format(new Date(row.endDate), 'hh:mm a')}</TableCell>
                                        <TableCell align='center'>{row.status}</TableCell>
                                        <TableCell align='center'>
                                            <ResourceAccess isCode={true} pathOrCode={'ROLE:WRITE'}>
                                                <FancyTooltip title={t("edit")} placement='top'>
                                                    <IconButton
                                                        aria-label='edit'
                                                        component='label'
                                                        color='secondary'
                                                        sx={{
                                                            '&:hover': {
                                                                color: theme.palette.secondary.dark,
                                                            },
                                                        }}
                                                        onClick={handleOpenModal}
                                                        data-name='update'
                                                        data-id={row.id}>
                                                        <PenIcon />
                                                    </IconButton>
                                                </FancyTooltip>
                                                <FancyTooltip title={t("delete")} placement="top">
                                                    <IconButton
                                                        aria-label="trash"
                                                        component="label"
                                                        color="secondary"
                                                        sx={{
                                                            "&:hover": {
                                                                color: theme.palette.secondary.dark,
                                                            },
                                                        }}
                                                        data-id={row.id}
                                                        onClick={handleOpenModal}
                                                        data-name="delete"
                                                    >
                                                        <TrashIcon />
                                                    </IconButton>
                                                </FancyTooltip>
                                            </ResourceAccess>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </FancyPaper>
            {(modalData?.modalType === "create" ||
                modalData?.modalType === "update") && (
                    <Dialog
                        open={modalData.modalOpen}
                        onClose={handleCancelModal}
                        fullWidth
                    >
                        <AddOrUpdateGuestModal
                            data={modalData?.modalObject}
                            onSubmit={onSubmit}
                            cancelModal={handleCancelModal}
                            modalType={modalData?.modalType}
                        />
                    </Dialog>
                )}

            {modalData?.modalType === "delete" && (
                <Dialog
                    open={modalData.modalOpen}
                    onClose={handleCancelModal}
                    fullWidth
                >
                    <DeleteGuestModal
                        textChildren={'la invitación'}
                        actionButton={
                            <>
                                {" "}
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        mt: 2,
                                        mr: 2,
                                    }}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    {t("delete")}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{
                                        mt: 2,
                                    }}
                                    onClick={handleCancelModal}
                                    autoFocus
                                >
                                    {t("cancel")}
                                </Button>{" "}
                            </>
                        }
                    />
                </Dialog>
            )}

        </>
    );
};

export default GuestsList;
