import React from 'react';
import {Typography, Container, DialogContent, Button, DialogActions} from '@mui/material';
import {useTranslation} from "react-i18next";

interface DialogProps {
  cancelModal: any;
}

function PaymentFailure({cancelModal}: DialogProps) {

  const {t} = useTranslation();

  return (
      <>
      <DialogContent sx={{px: 4, pb: 0}}>
      <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h5" align="center">
          Pago no realizado
        </Typography>
        <Typography variant="body1" align="center">
          Tu pago no pudo ser procesado en este momento.
        </Typography>
      </Container>
      </DialogContent>
      <DialogActions sx={{pb: 4, px: 4}}>
        <Button
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              mr: 2,
            }}
            onClick={cancelModal}
            autoFocus
        >
          {t("ok")}
        </Button>
      </DialogActions>
      </>
  );
}

export default PaymentFailure;