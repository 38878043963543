/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FancyPaper from "../components/FancyPaper";
import {
  PenIcon,
  PlusIcon,
  TrashIcon
} from "../components/svgicons/SvgIcons";
import AddOrUpdateCountriesModal from "../components/utils/AddOrUpdateCountriesModal";
import DeleteModal from "../components/utils/DeleteModal";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import FancyTooltip from "../components/utils/FancyTooltip";
import CountryFlag from "../components/utils/countrySelector/CountryFlag";
import { useApp } from "../hooks/useApp";
import {
  createRequest,
  deleteRequest,
  getRequest,
  updateRequest,
} from "../services/country/CountryService";
import { properties } from "../utils/Properties_es";
import { useTranslation } from "react-i18next";

const SettingCountries = () => {
  const { t } = useTranslation();
  const {
    authInfo,
    company,
    isAdmin,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const theme = useTheme();
  const [countryData, setCountryData] = useState<any>([]);
  const initForm = {
    id: 0,
    areaCode: "",
    name: "",
  };
  const [formData, setFormData] = useState<any>(initForm);
  const { handleSubmit } = useForm();
  const [page, setPage] = useState(0);
  const [preFilter, setPreFilter] = useState<string | undefined>('');

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "");
    };
    dataInit();

    // eslint-disable-next-line
  }, [company]);

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (
    currentPage?: number,
    filter?: string,
    applyRole?: any
  ) => {
    setLoading && setLoading(true);
    setCountryData([]);
    try {
      //setPage(currentPage);

      let data = await getRequest(currentPage);
      if (data) {
        setCountryData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let createData = await createRequest({
        ...data,
        userCreated: authInfo?.username,
      });

      if (!createData) {
        setErrorMsg && setErrorMsg(t("errorCreatingCountry"));
        setLoading && setLoading(false);
        console.log("no se envia");
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(t("successfullyCreatedCountry"));
      console.log("se envio");
      //call fetch data
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    console.log("data = ", data)
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let updateData = await updateRequest({
        ...data,
        userModified: authInfo?.username,

      });

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(t("errorUpdatingCountry"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(t("countryUpdatedCorrectly"));
      //call fetch data
      await handleFetchData();
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteRequest(id);
      if (!deleteData) {
        setErrorMsg &&
          setErrorMsg(t("errorDeletingRecord"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(t("recordDeletedSuccessfully"));

      //reset page and call fetch data
      await handleFetchData();
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");
    console.log("modalAction = ", modalAction);
    if (modalAction === "update") {
      object = countryData.content.find((p: any) => p.id === parseInt(id));
    }

    if (modalAction === "delete") {
      object = countryData.content.find((p: any) => p.id === parseInt(id));
    }

    console.log("object = ", object);
    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  /**
   * @param event
   */
  const handleCancelModal = () => {
    setFormData(initForm);
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    const customPage = newPage - 1;
    setPage(customPage);
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
  };

  return (
    <>
      <FancyPaper pagetitle={`${t("maintenance")} / ${t("Countries")}`}>
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={9} sm={6} xs={12}>
            {/* <ResourceAccess isCode={true} pathOrCode={"ROLE:WRITE"}> */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
              data-name="create"
            >
              {t("addCountry")}
              <PlusIcon sx={{ ml: 1 }} />
            </Button>
            {/* </ResourceAccess> */}
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t("id")}</TableCell>
                <TableCell align="center">{t("flag")}</TableCell>
                <TableCell align="center">{t("name")}</TableCell>
                <TableCell align="center">{t("codes")}</TableCell>
                <TableCell align="center">{t("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {countryData &&
                countryData.content &&
                countryData.content.map((row: any, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="center">
                      <div style={{  alignItems: "center" }}>
                        <CountryFlag areaCode={row.areaCode} size={20} />
                      </div>
                    </TableCell>      
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.areaCode}</TableCell>           
                    <TableCell align="center">
                      {/* <ResourceAccess isCode={true} pathOrCode={"ROLE:WRITE"}> */}
                      <FancyTooltip title={t("edit")} placement="top">
                        <IconButton
                          aria-label="edit"
                          component="label"
                          color="secondary"
                          sx={{
                            "&:hover": {
                              color: theme.palette.secondary.dark,
                            },
                          }}
                          onClick={handleOpenModal}
                          data-name="update"
                          data-id={row.id}
                        >
                          <PenIcon />
                        </IconButton>
                      </FancyTooltip>
                      <FancyTooltip title={t("delete")} placement="top">
                        <IconButton
                          aria-label="trash"
                          component="label"
                          color="secondary"
                          sx={{
                            "&:hover": {
                              color: theme.palette.secondary.dark,
                            },
                          }}
                          data-id={row.id}
                          onClick={handleOpenModal}
                          data-name="delete"
                        >
                          <TrashIcon />
                        </IconButton>
                      </FancyTooltip>
                      {/* </ResourceAccess> */}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={countryData?.content?.length > 0 ? countryData?.content?.length : 0}
          rowsPerPage={countryData.size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={countryData.totalElements}
          totalPages={countryData.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
          <Dialog
            open={modalData.modalOpen}
            onClose={handleCancelModal}
            fullWidth
          >
            <AddOrUpdateCountriesModal
              data={modalData?.modalObject}
              onSubmit={onSubmit}
              cancelModal={handleCancelModal}
              modalType={modalData?.modalType}
            />
          </Dialog>
        )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                   {t("delete")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  {t("cancel")}
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default SettingCountries;
