import { yupResolver } from "@hookform/resolvers/yup";
import {
  Stack,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Autocomplete,
  styled,
  IconButton,
  Divider,
  InputAdornment,
  FormControl,
  InputLabel,
  Input,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import {
  getRequest
} from "../../services/apartment/ApartmentService";
import theme from "../../theme/theme";
import { PlusIcon, TrashIcon } from "../svgicons/SvgIcons";
import FancyTooltip from "./FancyTooltip";
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdateApartmentPending {
  apartment: {
    id?: number;
    name?: string;
    user?: {
      id?: number;
      fisrtName?: string;
      lastName?: string;
      userName?: string;
    };
  };
  company: number;
  list:any[];
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  company: number;
}

enum ApartmentField {
  Apartment = "apartment",
  Company = "company",
  List = "list"
}

const AddOrUpdateApartmentPaidModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  company
}: DialogProps) => {
  const { t } = useTranslation();
  
  const [apartmentData, setApartmentData] = useState<any>([]);  
  const [inputUser,setInputUser] = useState("");
  const [timeoutUser,setTimeoutUser] = useState<any>(null);
  const { companyInfo, setLoading, setErrorMsg, isAdmin, setSuccessMsg,authInfo} = useApp();
  const [nameUser,setNameUser] = useState("");


  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "");

      if(data){
        loadFields(data);
        if(data?.list?.length>0){
          setNameUser((data.apartment?.user?.firstName ? data.apartment?.user?.firstName : '')+' '+(data.apartment?.user?.lastName ? data.apartment?.user?.lastName : ''));
        }
      }
    };
    dataInit();
  }, [company]);


  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (
    currentPage?: number,
    filter?: string,
    applyRole?: any
  ) => {
    setApartmentData([]);
    try {
      //setPage(currentPage);
      const filterString : string = filter ? filter : '';
      let userId: number = 0;
      if(!isAdmin)
        userId=authInfo.id;
      let apartments = await getRequest(currentPage,company,filterString,'','',userId);
      if (apartments) {
        setApartmentData(apartments);
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };
  
  // Validación de datos
  const validation = Yup.object().shape({
    apartment: Yup.object().required("Campo es requerido, selecionar apartamento")
  });

  // Obtener valores predeterminados
  const defaultValues = {
    apartment: data?.apartment ? data.apartment : {id:0, name:''},
    company: data?.company ? data.company : 0,
    list: data?.list ? data.list : []
  };

  // Inicializar useForm con validación y valores predeterminados
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateApartmentPending>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });


  const onInputChange = (e:any, value: any) => {
    setInputUser(value);
  }

  useEffect(() => {
    // se valida si existe un timeout para eliminarlo si se digita nuevamente 
    if(timeoutUser)
      clearTimeout(timeoutUser);

    // se crea un timeout de 1 segundo y medio para esperar a que digiten mas caracteres
    const timer = setTimeout(() => {
      handleFetchData(0,inputUser); 
    }, 1000);

    // Se actualiza el timeout
    setTimeoutUser(timer);
  }, [inputUser]);


 
  
  const [formFields, setFormFields] = useState([
    { id: 0,amount: 0,expirationDate: new Date(),registrationDate: new Date(),title:'',currency: companyInfo?.currencies != null && companyInfo?.currencies[0]?.symbol ? companyInfo?.currencies[0] : null },
  ])

  const handleFormChange = (event: any, index: number) => {
    let data = [...formFields];
    const variable: string =  event.target.name;
    if(variable === 'amount')
      data[index]['amount'] = event.target.value;
    if(variable === 'expirationDate')
      data[index]['expirationDate'] = event.target.value;
    if(variable === 'registrationDate')
      data[index]['registrationDate'] = event.target.value;
    if(variable === 'title')
      data[index]['title'] = event.target.value;
    setFormFields(data);
  }

  const submit = (e: any) => {
    e.preventDefault();
  }

  const addFields = () => {
    let object = {
      id: 0,
      amount: 0,
      expirationDate: new Date(),
      registrationDate: new Date(),
      title:'',
      currency: companyInfo?.currencies != null && companyInfo?.currencies[0]?.symbol ? companyInfo?.currencies[0] : null
    }

    setFormFields([...formFields, object])

    data=[...formFields];
    
  }


  const loadFields = (dataLoad: any) => {

    setFormFields([]);
    if(formFields.length>0){
      let object: any = {};
      dataLoad?.list.forEach((r:any) => {
        let dateRegistration: Date = new Date(); 
        if(r?.registrationDate ) {
          const yearRegistration = r.registrationDate.substring(0, 4);
          const monthRegistration = r.registrationDate.substring(5, 7);
          const dayRegistration = r.registrationDate.substring(8,10);
          dateRegistration.setDate(dayRegistration);  
          const month : number = parseInt(monthRegistration)-1;
          dateRegistration.setMonth(month);  
          dateRegistration.setFullYear(yearRegistration);  
          dateRegistration.setHours(0);  
          dateRegistration.setMinutes(0);  
          dateRegistration.setSeconds(0);  
        }

        let dateExpiration: Date = new Date(); 
        if(r?.expirationDate ) {
          const yearExpiration = r.expirationDate.substring(0, 4);
          const monthExpiration = r.expirationDate.substring(5, 7);
          const dayExpiration = r.expirationDate.substring(8,10);
          dateExpiration.setDate(dayExpiration);  
          const month : number = parseInt(monthExpiration)-1;
          dateExpiration.setMonth(month);  
          dateExpiration.setFullYear(yearExpiration);  
          dateExpiration.setHours(0);  
          dateExpiration.setMinutes(0);  
          dateExpiration.setSeconds(0);  
        }
        
        object = {
          id: r?.id ? r.id : 0,
          amount: r?.amount ? r.amount : 0,
          expirationDate: dateExpiration,
          registrationDate: dateRegistration,
          title: r?.title ? r.title : '',
          currency: r?.currency ? r.currency : (companyInfo?.currencies != null && companyInfo?.currencies[0]?.symbol ? companyInfo?.currencies[0] : null),
        }
      });



      setFormFields([ object]);
      data = [...formFields];
    }    
  }
  
  const removeFields = (index: number) => {
    let data = [...formFields];
    data.splice(index, 1)
    setFormFields(data)
  }

  useEffect(() => {
    setValue('list',formFields);
  }, [formFields]);

  const formatNumber = (value: any) => {
    // Eliminar cualquier separador de miles antes de aplicar el nuevo valor
    const normalizedValue = (value || "").toString().replace(/,/g, "");
    // Aplicar el formato con separadores de miles
    return normalizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {t("record")}
      </Title>
      <DialogContent sx={{ px: 8, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 10 }}>           

            <Grid item xs={4}>
              <Controller
                control={control}
                name='apartment'
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    disabled={true}
                    value={value}
                    inputValue={inputUser}
                    onInputChange={onInputChange}
                    options={apartmentData.content ? apartmentData.content : [] }
                    getOptionLabel={(option: any) => option.name || ''}
                    isOptionEqualToValue={(option: any, value: any) => { 
                      if(option.id !== value.id)
                        return false;
                        setNameUser((option?.user?.firstName ? option?.user?.firstName : '')+' '+(option?.user?.lastName ? option?.user?.lastName : ''));
                      return true;
                    }}
                    //@ts-ignore
                    renderInput={params => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = 'apartment';
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label={t("apartment")}
                          variant='standard'
                          sx={{ '& input': { pl: '0!important' } }}
                          error={!!errors.apartment}
                          helperText={errors.apartment && errors.apartment.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={5}>
              {nameUser}
            </Grid>
            <Grid item xs={12}>
              
                  {formFields.map((form, index) => {
                    return (
                      <div key={index}>
                        <TextField
                          name='title'
                          label={t("title")}
                          variant="standard"
                          disabled={true}
                          onChange={event => handleFormChange(event, index)}
                          value={form.title}
                          sx={{ m: 1, minWidth: 120 }} 
                        />
                        <TextField
                          name='registrationDate'
                          label={t("registrationDate")}
                          type="date"
                          variant="standard"
                          disabled={true}
                          onChange={event => handleFormChange(event, index)}
                          value={dayjs(form.registrationDate).format("YYYY-MM-DD")}
                          sx={{ m: 1, minWidth: 120 }} 
                        />
                        
                        <TextField
                          name='expirationDate'
                          label={t("expirationDate")}
                          type="date"
                          variant="standard"
                          disabled={true}
                          onChange={event => handleFormChange(event, index)}
                          value={dayjs(form.expirationDate).format("YYYY-MM-DD")}
                          sx={{ m: 1, minWidth: 120 }} 
                        />
                        <TextField
                          name='amount'
                          label={t("amount")}
                          type="text"
                          variant="standard"
                          disabled={true}
                          onChange={event => handleFormChange(event, index)}
                          value={formatNumber(form.amount)}
                          sx={{ m: 1, minWidth: 120 }} 
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{companyInfo?.currencies != null && companyInfo?.currencies[0]?.symbol ? companyInfo.currencies[0].symbol : ''}</InputAdornment>,
                          }}
                        />
                        <Divider sx={{borderBottomWidth: 5}} />
                      </div>
                    )
                  })}

            </Grid>
            
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          {t("ok")}
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateApartmentPaidModal;
