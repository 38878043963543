import { useState, useEffect, useMemo, forwardRef, Fragment } from "react";
import {
  Link,
  LinkProps,
  useLocation,
  Outlet,
  useNavigate,
} from "react-router-dom";
import {
  Box,
  Drawer,
  Divider,
  IconButton,
  List,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  styled,
  CssBaseline,
  MenuItem as MenuItemMui,
  Stack,
  TextField,
  MenuItem,
  Autocomplete,
  Badge,
  FormControl,
  Select,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { MENUITEMS } from "../utils/Const";
import { MENUITEMS_EN } from "../utils/Const-en";
//import { MenuItem } from "../types/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import Logo from "../resources/images/logo_space_manager.svg";
import theme from "../theme/theme";
import { useApp } from "../hooks/useApp";
import { properties } from "../utils/Properties_es";
import MessageManager from "../components/utils/MessageManager";
import { CONSTANT } from "../utils/Constants";
import ResourceAccess from "../components/security/ResourceAccess";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import UrlBase from "../url/Urls";
import Loading from "../components/utils/Loading";
import { getRequestCompany } from "../services/company/CompanyService";
import { useTranslation } from "react-i18next";

const drawerWidth = 264;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const IconHamburger = styled("i")(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: "1px",
  content: '""',
  cursor: "pointer",
  display: "block",
  height: "3px",
  transition: "background .1s ease-in-out,color .1s ease-in-out",
  width: "24px",
  position: "relative",
  "&:before,&:after": {
    background: theme.palette.primary.main,
    borderRadius: "1px",
    content: '""',
    cursor: "pointer",
    display: "block",
    height: "3px",
    transition: "background .1s ease-in-out,color .1s ease-in-out",
    position: "absolute",
  },
  "&:before": {
    top: "-7.5px",
    width: "24px",
  },
  "&:after": {
    bottom: "-7.5px",
    width: "16px",
  },
}));

const IconNestedLink = styled(ListItemIcon)(({ theme }) => ({
  color: "inherit",
  "&:before": {
    content: '"→"',
    display: "inline-block",
    left: "0",
    position: "relative",
    transform: "translateX(0px)",
    transition: "all 0.1s ease 0s",
  },
}));

const ListItemTextNested = styled(ListItemText)(({ theme }) => ({
  "& span": {
    fontSize: "90%",
  },
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: 0,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up("md")]: {
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  boxShadow: "none",
  padding: "0",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up("md")]: {
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
}));

const Footer = styled("footer")(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.text.secondary}`,
  padding: `${theme.spacing(2)} ${theme.spacing(3.5)}`,
}));

function ListItemLink(props: any) {
  const { to } = props;

  const renderLink = useMemo(
    () =>
      forwardRef<any, Omit<LinkProps, "to">>((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} color="greyDue.main" />
      )),
    [to]
  );

  return <ListItem {...props} component={to ? renderLink : "button"} />;
}

const filterByPermissions = (items: any[], permission: string): any[] => {
  const elements = items.filter((item) =>
    item.permissions.includes(permission)
  );
  elements.forEach((el, index) => {
    if (el.expand) {
      elements[index] = {
        ...el,
        children: filterByPermissions(el.children, permission),
      };
    }
  });
  return elements;
};

const DashboardHome = () => {
  const { t } = useTranslation();
  const {
    authInfo,
    isAdmin,
    authenticate,
    errorMsg,
    successMsg,
    logoutProvider,
    resetErrorMsg,
    resetSuccessMsg,
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    company,
    setCompany,
    companies,
    setCompanies,
    handleDataCompanies,
    language,
    handleLangChange,
  } = useApp();
  const [mobileOpen, setMobileOpen] = useState(false);
  //const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const userCompanyId = authInfo?.companyId;
  const [defaultCompany, setDefaultCompany] = useState<any>(null);

  const location = useLocation();
  const user = {
    is_superuser: true,
    is_client: false,
    is_company: false,
  };
  const menuItems = getMenuItems();
  const [expanded, setExpanded] = useState<boolean[]>(
    new Array(menuItems.length).fill(false)
  );
  const [showAssociated, setShowAssociated] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const selectedMenuItemIndex = menuItems.findIndex(
      (item) =>
        !!item.expand &&
        !!item.children.find((child) => child.to === location.pathname)
    );
    if (selectedMenuItemIndex > -1) {
      setExpanded(
        expanded.map((el, i) => {
          if (i === selectedMenuItemIndex) {
            return !el;
          }
          return el;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getMenuItems() {
    let menuItems = language === "es" ? [...MENUITEMS] : [...MENUITEMS_EN];
    //let menuItems = [...MENUITEMS] [...MENUITEMS_EN];
    if (user.is_superuser) {
      menuItems = filterByPermissions(menuItems, "is_superuser");
    }
    if (user.is_company) {
      menuItems = filterByPermissions(menuItems, "is_company");
    }
    if (user.is_client) {
      menuItems = filterByPermissions(menuItems, "is_client");
    }
    if (!user.is_superuser && !user.is_company && !user.is_client) {
      menuItems = filterByPermissions(menuItems, "is_user");
    }
    return menuItems;
  }

  const handleClick = (index: number, item: any) => {
    if (item.expand) {
      setExpanded(
        expanded.map((o, i) => {
          if (i === index) {
            return !o;
          }
          return o;
        })
      );
    }
  };

  const handleSelected = (item: any) => {
    return item.to === location.pathname;
  };

  const searchShowAssociated = () => {
    const companyId = company ? company : Number(authInfo?.companyId);

    console.log("companyId", companyId);
    console.log("companies", companies);

    const show = companies.find(
      (companyDetail: any) => companyDetail.id === companyId
    ) as any;
    console.log("show", show);

    setShowAssociated(show?.associated ? show.associated : false);
  };

  useEffect(() => {
    searchShowAssociated();
  }, [companies]);


  const drawer = (
    <div>
      <br />
      <Toolbar sx={{ justifyContent: "center" }}>
        <img
          src="/space-managerlogo.png"
          alt="Space Area Administrativa"
          width="150"
          style={{
            marginTop: "-1.5rem",
          }}
        />
        {/* alt='Altio - Sociedad Administradora de Fondos de Inversión' width='150' /> */}
      </Toolbar>
      <br />
      <List sx={{ pt: 0, marginTop: "-3rem" }}>
        {menuItems.map((item, index) => (
          <Fragment key={index}>
            {item.label === "Logout" || item.label === "Cerrar sesión" ? (
              <ListItemLink
                button
                onClick={logoutProvider}
                selected={handleSelected(item)}
                to={item.to}
                sx={{
                  // borderBottom: `1px solid ${theme.palette.text.secondary}`,
                  // boxShadow: '0px 0px 0px 1px #E0E0E0',
                  padding: ".625rem 1.125rem .625rem 1.725rem",
                }}
              >
                <ListItemIcon
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: ".8125rem",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={t(item.label)} />
                {/* <ListItemText primary={item.label} /> */}
              </ListItemLink>
            ) : (
              <>
                {!item.isViewAdmin && (
                  <ResourceAccess
                    isCode={true}
                    pathOrCode={`${item.permissions[1]}`}
                  >
                    <ListItemLink
                      button
                      onClick={() => handleClick(index, item)}
                      selected={handleSelected(item)}
                      to={item.to}
                      sx={{
                        padding: ".625rem 1.125rem .625rem 1.725rem",
                      }}
                    >
                      <ListItemIcon
                        style={{
                          color: theme.palette.primary.main,
                          fontSize: ".8125rem",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.label} />

                      {item.expand ? (
                        expanded[index] ? (
                          <ExpandLess sx={{ color: "greyDue.main" }} />
                        ) : (
                          <ExpandMore sx={{ color: "greyDue.main" }} />
                        )
                      ) : null}
                    </ListItemLink>
                  </ResourceAccess>
                )}
                {item.isViewAdmin && isAdmin && (
                  <ResourceAccess
                    isCode={true}
                    pathOrCode={`${item.permissions[1]}`}
                  >
                    <ListItemLink
                      button
                      onClick={() => handleClick(index, item)}
                      selected={handleSelected(item)}
                      to={item.to}
                      sx={{
                        padding: ".625rem 1.125rem .625rem 1.725rem",
                      }}
                    >
                      <ListItemIcon
                        style={{
                          color: theme.palette.primary.main,
                          fontSize: ".8125rem",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.label} />

                      {item.expand ? (
                        expanded[index] ? (
                          <ExpandLess sx={{ color: "greyDue.main" }} />
                        ) : (
                          <ExpandMore sx={{ color: "greyDue.main" }} />
                        )
                      ) : null}
                    </ListItemLink>
                  </ResourceAccess>
                )}
                {item.expand &&  (
                  <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                      {item.children.map((child) => (
                        <Fragment key={child.label}>
                          {child.isViewAdmin && isAdmin && (
                            <ResourceAccess
                              pathOrCode={`/${child.to}`}
                              isCode={false}
                            >
                              <ListItemLink
                                button
                                //key={child.label}
                                to={child.to}
                                selected={child.to === location.pathname}
                                sx={{
                                  // borderBottom: `1px solid ${theme.palette.text.secondary}`,
                                  // boxShadow: '0px 0px 0px 1px #E0E0E0',
                                  padding: ".625rem 1.5rem .625rem 2.35rem",
                                }}
                              >
                                <IconNestedLink />
                                <ListItemTextNested primary={child.label} />
                              </ListItemLink>
                            </ResourceAccess>
                          )}
                          {!!!child.isViewAdmin && (
                            <ResourceAccess
                              pathOrCode={`/${child.to}`}
                              isCode={false}
                            >
                              <ListItemLink
                                button
                                to={child.to}
                                selected={child.to === location.pathname}
                                sx={{
                                  // borderBottom: `1px solid ${theme.palette.text.secondary}`,
                                  // boxShadow: '0px 0px 0px 1px #E0E0E0',
                                  padding: ".625rem 1.5rem .625rem 2.35rem",
                                }}
                              >
                                <IconNestedLink />
                                <ListItemTextNested primary={child.label} />
                              </ListItemLink>
                            </ResourceAccess>
                          )}
                          {/* {child.label === 'Asociados' && (isAdmin || showAssociated) && (
                            <ResourceAccess pathOrCode={`/${child.to}`} isCode={false}>
                              <ListItemLink
                                button
                                to={child.to}
                                selected={child.to === location.pathname}
                                sx={{
                                  // borderBottom: `1px solid ${theme.palette.text.secondary}`,
                                  // boxShadow: '0px 0px 0px 1px #E0E0E0',
                                  padding: '.625rem 1.5rem .625rem 2.35rem',
                                }}>
                                <IconNestedLink />
                                <ListItemTextNested primary={child.label} />
                              </ListItemLink>
                            </ResourceAccess>
                          )} */}
                        </Fragment>
                      ))}
                    </List>
                  </Collapse>
                )}
              </>
            )}
          </Fragment>
        ))}
      </List>
    </div>
  );

  //validate auth
  if (!authenticate) {
    console.log(authenticate);
    console.log("navigate es");
    navigate("/", { replace: true });
  }

  useEffect(() => {
    console.log(authenticate);
  }, [authenticate]);

  useEffect(() => {
    handleDataCompanies();
  }, []);

  const handleChange = (e: any, newValue: any) => {
    const selectedId = e.target.value;
    console.log(newValue);
    setSelectedCompany(newValue || defaultCompany);
    console.log(defaultCompany);

    setCompany &&
      setCompany(
        newValue ? newValue.id : defaultCompany ? defaultCompany.id : null
      );
  };

  useEffect(() => {
    if (isAdmin && authInfo?.companyId && companies.length > 0) {
      const userCompanyData: any = companies.find(
        (company: any) => Number(company.id) === Number(authInfo.companyId)
      );
      setSelectedCompany(userCompanyData);
      setDefaultCompany(userCompanyData);
    }
  }, [isAdmin, authInfo, companies]);

  useEffect(() => {
    if (defaultCompany) {
      setCompany && setCompany(defaultCompany ? defaultCompany.id : null);
    }
  }, [defaultCompany]);

  useEffect(() => {
    if (!isAdmin) {
      const userCompanyData: any = companies.find(
        (company: any) => Number(company.id) === Number(authInfo.companyId)
      );
      setDefaultCompany(userCompanyData);
    }
  }, [authInfo, companies]);

  console.log(company);

  return (
    <>
      {isLoading && <Loading />}
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=""
          callback={resetErrorMsg}
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details=""
          callback={resetSuccessMsg}
        />
      )}
      <Box sx={{ display: "flex", height: "100vh" }}>
        <CssBaseline />
        <AppBar position="fixed" open={!mobileOpen}>
          <Toolbar
            sx={{
              [theme.breakpoints.up("sm")]: {
                px: 6,
                minHeight: "54px",
              },
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <IconHamburger />
            </IconButton>
            <Typography
              color="inherit"
              sx={{ fontSize: "1rem", flexGrow: 1 }}
              noWrap
              component="div"
            >
              Space Manager
            </Typography>
            <Stack direction="row" spacing={4} alignItems={"center"}>
              {isAdmin && (
                <>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1rem", marginTop: "8px" }}
                  >
                    {t("company")}:
                  </Typography>
                  <Autocomplete
                    value={selectedCompany}
                    defaultValue={defaultCompany}
                    onChange={handleChange}
                    options={companies}
                    getOptionLabel={(option: any) => option.description}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        fullWidth
                        sx={{
                          width: 200,
                          "& fieldset": {
                            borderColor: "blue",
                          },
                          "& svg": {
                            color: "blue",
                          },
                        }}
                      />
                    )}
                  />
                </>
              )}
              {!isAdmin && (
                <>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1rem", marginTop: "8px" }}
                  >
                    {t("company")}:
                  </Typography>
                  <Autocomplete
                    value={defaultCompany}
                    disabled={!isAdmin}
                    onChange={handleChange}
                    options={companies}
                    getOptionLabel={(option: any) => option.description}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        fullWidth
                        sx={{
                          width: 200,
                          "& fieldset": {
                            borderColor: "blue",
                          },
                          "& svg": {
                            color: "blue",
                          },
                        }}
                      />
                    )}
                  />
                </>
              )}
              <FormControl sx={{ m: 1, minWidth: 60 }}>
                <Select
                  value={language}
                  autoWidth
                  sx={{
                    paddingLeft: theme.spacing(4),
                    "& .MuiSelect-select": {
                      minWidth: {
                        xs: "30px!important",
                        md: "90px!important",
                      },
                    },
                  }}
                  onChange={(e: any) =>
                    handleLangChange && handleLangChange(e.target.value)
                  }
                >
                  <MenuItem value="en">{t("english")}</MenuItem>
                  <MenuItem value="es">{t("spanish")}</MenuItem>
                </Select>
              </FormControl>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                //onClick={handleMenu}
                color="inherit"
                sx={{
                  "&:hover, &:focus": {
                    background: "transparent",
                  },
                  "& .MuiTouchRipple-root": {
                    display: "none",
                  },
                }}
              >
                <PersonIcon sx={{ fontSize: "24px" }} color="primary" />
                <Typography variant="body1" sx={{ fontSize: "1rem" }}>
                  {authInfo?.name}
                </Typography>
                {/* <ExpandMore /> */}
              </IconButton>

              {/* <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItemMui
                    onClick={logoutProvider}
                    sx={{ borderRadius: "0!important" }}
                  >
                    Cerrar sesión
                  </MenuItemMui>
                </Menu> */}
            </Stack>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="persistent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open={!mobileOpen}
          >
            {drawer}
          </Drawer>
        </Box>
        <Main open={!mobileOpen}>
          <Toolbar
            sx={{
              [theme.breakpoints.up("sm")]: {
                minHeight: "15px",
              },
            }}
          />
          <Box sx={{ flex: "1 1" }}>
            <Outlet />
          </Box>
          <Footer>
            <Typography variant="body2">
              Copyright 2024. {t("rightsReserved")}
            </Typography>
          </Footer>
        </Main>
      </Box>
    </>
  );
};

export default DashboardHome;
