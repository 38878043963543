import { Grid, styled } from "@mui/material";

const AnimationLoad = styled("div")(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0.9)",
  height: "100%",
  left: "0",
  position: "fixed",
  top: "0",
  width: "100%",
  zIndex: "10000",
}));
const Osahanloading = styled("div")(({ theme }) => ({
  "@keyframes loading": {
    "0%": { transform: "rotate(0deg)" },
    "50%": {
      background: "#3e627c none repeat scroll 0 0",
      transform: "rotate(180deg)",
    },
    "100%": { transform: "rotate(360deg)" },
  },
  animation: `1.5s linear 0s normal none infinite running loading`,
  background: "#43a491 none repeat scroll 0 0",
  borderRadius: "50px",
  height: "50px",
  left: "50%",
  marginLeft: "-25px",
  marginTop: "-25px",
  position: "absolute",
  top: "50%",
  width: "50px",
  "&:after": {
    animation:
      "1.5s linear 0s normal none infinite running osahanloading_after",
    borderColor: "#3e627c transparent",
    borderRadius: "80px",
    borderStyle: "solid",
    borderWidth: "10px",
    content: '""',
    height: "80px",
    left: "-15px",
    position: "absolute",
    top: "-15px",
    width: "80px",
  },
}));

const Loading = () => {
  return (
    <Grid container>
      <AnimationLoad>
        <Osahanloading />
      </AnimationLoad>
    </Grid>
  );
};

export default Loading;
