import { privateFetch } from "../../utils/CustomFetch.js";
import { URL } from "../../utils/UrlConstants";
import {CONSTANT} from "../../utils/Constants";
import {ApartmentPendingResponse} from "../apartmentPending/ApartmentPendingInterfaces";
import {UrlBase} from "../../url/Urls";


export type CreatePaymentLinkRequest = {
  companyId: number;
  userId: number;
  customerEmail: string;
  customerName: string;
  apartmentId: number;
  pendingsToPay: number[];
  paymentMethodId: number;
  reference: string;
}


export type UpdatePaymentRequest = {
  transactionId: string;
  status: string;
}


export const createPaymentLink = async (data: CreatePaymentLinkRequest) => {
  console.log('data create payment link', data);
  let responseData: any = {};
  await privateFetch
  .post(URL.REQUEST_PAYMENT_LINK, JSON.stringify(data), {
    headers: {
      ...CONSTANT.HEADER_TYPE,
    },
  })

  .then(async (response) => {
    responseData = await response.data;
  })
  .catch(async (error) => {
    throw new Error(error.response.data.message);
  });
  return responseData;
};

export const updatePaymentStatus = async (data:UpdatePaymentRequest) => {
  let responseData: any = {};
  await privateFetch
  .post(URL.REQUEST_PAYMENT_UPDATE, JSON.stringify(data), {
    headers: {
      ...CONSTANT.HEADER_TYPE,
    },
  })

  .then(async (response) => {
    responseData = await response.data;
  })
  .catch(async (error) => {
    throw new Error(error.response.data.code);
  });
  return responseData;
};

export const uploadVoucherPayment = async (data:FormData) => {
  let responseData: any = {};
  await privateFetch
  .post(URL.REQUEST_PAYMENT_UPLOAD_VOUCHER, data, {
    headers: {
      ...CONSTANT.HEADER_TYPE_DOCUMENTS,
    },
  })

  .then(async (response) => {
    responseData = response;
  })
  .catch(async (error) => {
    throw new Error(error.response.data);
  });
  return responseData;
};


export const getRequestPayments = async (page: any, companyId: number) : Promise<ApartmentPendingResponse> => {
  try {
    const response = await privateFetch.get(URL.REQUEST_PAYMENT_APPLIED(page,UrlBase.registration_x_page,companyId));
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const downloadPaymentFile = async (id:number) => {
  const response = await privateFetch.get(URL.REQUEST_PAYMENT_DOWNLOAD_VOUCHER(id), {
    responseType: 'blob'
  });
  // @ts-ignore
  const fileName = response.headers.get('Content-Disposition')
  .split('"')[1];

  const blob = response.data;
  const fileReader = new FileReader();
  fileReader.onload = (event) => {
    // @ts-ignore
    const fileUrl = event.target.result;
    const link = document.createElement('a');
    if (typeof fileUrl === "string") {
      link.href = fileUrl;
    }
    link.download = fileName;
    link.click();
  };
  fileReader.readAsDataURL(blob);
};

export const updateAppliedPaymentStatus = async (id: number, status:string) => {
  await privateFetch
  .post(URL.REQUEST_APPLIED_PAYMENT_UPDATE(id, status), {
    headers: {
      ...CONSTANT.HEADER_TYPE,
    },
  })

  .then(async (response) => {

  })
  .catch(async (error) => {
    throw new Error(error.response.data.code);
  });

};