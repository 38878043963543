import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useApp } from '../../hooks/useApp';
import {
  getRequest
} from "../../services/country/CountryService";
import CountrySelector from './countrySelector/CountrySelector';
import { useTranslation } from 'react-i18next';

const Title = styled(DialogTitle)(() => ({
  backgroundColor: '#e1e8ee',
  color: '#3e627c!important',
  fontFamily: 'D-dinExp',
  fontWeight: 400,
  fontSize: '17.5px',
}));

const Actions = styled(DialogActions)(() => ({
  borderTop: '1px solid #dee2e6',
}));

interface AddOrUpdateRole {
  id?: number;
  name: string;
  identification: string;
  phone: string;
  email: string;
  associatedNumber?: string;
  company?: string;
  status?: {
    id?: number;
    name?: string;
  };
  countries: CountryOption;
}

interface CountryOption {
  id: number;
  name: string;
  areaCode: string;
  flag: string | null;
  userCreated: string;
  userModified: string;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  associate: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

const AddOrUpdateAssociatedModal = ({
  data,
  associate,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  const { t } = useTranslation();

  const {
    setLoading,
    setErrorMsg,
  } = useApp();
  const [countryData, setCountryData] = useState<any>([]);
  const [selectedCountry, setSelectedCountry] = useState<CountryOption | null>(null);
  const [associatedNumberError, setAssociatedNumberError] = useState('');

  const handleFetchData = async (
    currentPage?: number) => {
    setLoading && setLoading(true);
    setCountryData([]);
    try {

      let data = await getRequest(0);
      if (data) {
        setCountryData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };


  useEffect(() => {
    handleFetchData()
  }, [])

  //Validación de datos
  const validation = Yup.object().shape({
    name: Yup.string().required(t("fieldRequired")),
    identification: Yup.string().required(t("fieldRequired")),
    phone: Yup.string().required(t("fieldRequired")),
    email: Yup.string().required(t("fieldRequired")),
    associatedNumber: Yup.string().required(t("fieldRequired")),
    status: Yup.object().shape({
      id: Yup.number()
        .required(t("fieldRequired"))
        .typeError(t("numberField")),
    }),
    // countries: Yup.object().shape({
    //   id: Yup.number().required('Campo es requerido'),
    //   name: Yup.string().required('Campo es requerido'),
    //   areaCode: Yup.string().required('Campo es requerido'),
    //   flag: Yup.string().nullable(),
    // }),  
  });

  const defaultValues = {
    id: data ? data.id : 0,
    name: data ? data.name : '',
    company: data ? data.company : 0,
    identification: data ? data.identification : '',
    phone: data ? data.phone : '',
    email: data ? data.email : '',
    associatedNumber: data ? data.associatedNumber : '',
    status: data ?
      { id: data.status, name: data.status === 0 ? t("active") : t("idle")} :
      { id: 0, name: t("active") },
    countries: data ? data.countries : null,
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateRole>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: 'onChange',
  });

  useEffect(() => {
    console.log(selectedCountry);
    console.log(data);
    selectedCountry != null && setValue("countries", selectedCountry)

  }, [selectedCountry])

  const validateAssociatedNumber = (value: string) => {
    // Iterar sobre los elementos del objeto associate
    for (const key in associate) {
        if (associate.hasOwnProperty(key)) {
            const item = associate[key];
            // Verificar si el número de asociado ya existe en algún elemento
            if (item.associatedNumber === value) {
                setAssociatedNumberError('El número de asociado ya existe');
                return; // Salir de la función una vez que se encuentra una coincidencia
            }
        }
    }
    // Si no se encuentra ninguna coincidencia, limpiar el mensaje de error
    setAssociatedNumberError('');
};


  return (
    <>
      <Title id='alert-dialog-title' sx={{ pt: 4, px: 4 }}>
        {modalType === 'update' ? t("updateAssociate") : t("addAssociate")}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth='lg' sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={'name'}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={t("fullName")}
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='name3'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('name')}
                    error={errors.name && Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'identification'}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={t("identification")}
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='identification'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('identification')}
                    error={errors.identification && Boolean(errors.identification)}
                    helperText={errors.identification && errors.identification.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'associatedNumber'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={t("associateNumber")}
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='identification'
                    sx={{ '& input': { pl: '0!important' } }}
                    onChange={(e) => {
                      onChange(e);
                      validateAssociatedNumber(e.target.value);
                    }}
                    error={errors.associatedNumber || associatedNumberError ? true : false}
                    helperText={errors.associatedNumber
                      ? errors.associatedNumber.message
                      : associatedNumberError}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'email'}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={t("email")}
                    type='email'
                    variant='standard'
                    value={value}
                    autoComplete='email'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('email')}
                    error={errors.email && Boolean(errors.email)}
                    helperText={errors.email && errors.email.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name={'countries'}
                control={control}
                render={({ field: { onChange, value }

                }) => (
                  <CountrySelector
                    value={value ? value.id : null}
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    onChange={onChange}
                    error={errors.countries?.message}
                    options={countryData} />
                )}

              />
            </Grid>
            <Grid item xs={8}>
              <Controller
                name={'phone'}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={t("phone")}
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='phone'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('phone')}
                    error={errors.phone && Boolean(errors.phone)}
                    helperText={errors.phone && errors.phone.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name='status'
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={[
                      { id: 0, name: t("active") },
                      { id: 1, name: t("idle") },
                    ]}
                    getOptionLabel={(option: any) => option.name || ''}
                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                    //@ts-ignore
                    value={value}
                    renderInput={params => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = 'new-password';
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label={t("state")}
                          variant='standard'
                          sx={{ '& input': { pl: '0!important' } }}
                          error={!!errors.status}
                          helperText={errors.status && errors.status.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant='contained'
          color='primary'
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}>
          {t("save")}
        </Button>
        <Button
          variant='contained'
          color='secondary'
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus>
          {t("cancel")}
        </Button>{' '}
      </Actions>
    </>
  );
};

export default AddOrUpdateAssociatedModal;
