import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Collapse,
  styled,
  TextField,
  Button,
  Stack,
  Box,
  CircularProgress,
} from "@mui/material";
import { IconButtonProps } from "@mui/material/IconButton";
import { PersonPin } from "@mui/icons-material";
import theme from "../theme/theme";
import { useForm, Controller } from "react-hook-form";
import Condominio from "../resources/images/condominio-residencial.jpg";
import { AddOrUpdatePostCardType } from "../types/Post";
import { useTranslation } from "react-i18next";
import { useApp } from "../hooks/useApp";
import { formatDate } from "../utils/UtilsFunctions";
import { getUrlImage, getUrlVideo } from "../services/booking/BookingService";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from 'react-material-ui-carousel';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}
interface validationForm {
  message: string;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const PostCard = ({
  title,
  description,
  id_video,
  idImagen,
  idVideo,
  onClick,
  imageExpanded,
}: AddOrUpdatePostCardType & { onClick?: () => void, imageExpanded?: boolean }) => {
  const { t } = useTranslation();
  const { authInfo, setErrorMsg } = useApp();
  const [expanded, setExpanded] = useState(false);
  const [loadImage, setLoadImage] = useState(false);
  const [loadVideo, setLoadVideo] = useState(false);

  const [file, setFile] = useState<string[]>([]);
  const [videoFile, setVideoFile] = useState("");

  const settings = {
    dots: true, // Mostrar puntos de navegación
    infinite: true, // Carrusel infinito
    speed: 500, // Velocidad de transición en milisegundos
    slidesToShow: 1, // Número de imágenes a mostrar a la vez
    slidesToScroll: 1, // Número de imágenes a desplazar a la vez
    autoplay: true, // Reproducción automática
    autoplaySpeed: 3000, // Intervalo de reproducción automática en milisegundos
  };


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const defaultValues: validationForm = {
    message: "",
  };

  useEffect(() => {
    const getImage = async () => {
      if (idImagen) {
        if (typeof idImagen === "string") {
          const imageIds = idImagen.split(",");
          await handleFetchImage(imageIds);
        }
      }
      if (typeof idVideo === "number") {
        await handleFetchVideo(idVideo);
      }
    };
    getImage();
    // eslint-disable-next-line
  }, []);

  const handleFetchImage = async (ids: string[]) => {
    setLoadImage(true);
    try {
      const imageUrls = await Promise.all(
        ids.map(async (id) => {
          const imageUrl = await getUrlImage(id);
          return imageUrl;
        })
      );
      setFile(imageUrls);
      setLoadImage(false);
      return imageUrls;
    } catch (error: any) {
      setLoadImage(false);
      console.log(error);
    }
  };

  const handleFetchVideo = async (id: string) => {
    setLoadVideo(true);
    try {
      const videoUrl = await getUrlVideo(id);
      setVideoFile(videoUrl);
      setLoadVideo(false);
      return videoUrl;
    } catch (error: any) {
      setLoadVideo(false);
      console.log(error);
    }
  };

  const { register, control, watch } = useForm({
    defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
  });

  console.log(imageExpanded)

  return (
    <Card
      sx={{
        boxShadow:
          "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;",
        height: "100%",
        cursor: "pointer", // Para mostrar que es clicable
      }}
      onClick={onClick} // Manejar el clic
    >
      {id_video !== "" && id_video !== null ? (
        <Box sx={{ height: "300px" }}>
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${id_video}?rel=0&amp;modestbranding=1&amp;hl=es`}
            data-src={`https://www.youtube.com/embed/${id_video}?rel=0&amp;modestbranding=1&amp;hl=es`}
            title={title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </Box>
      ) : idVideo ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          {loadVideo ? (
            <CircularProgress size={24} sx={{ mx: "auto" }} />
          ) : (
            <video width="100%" height="300" controls>
              <source src={videoFile} />
              Your browser does not support the video tag.
            </video>
          )}
        </Box>
      ) : file.length > 1 ? (
        <Box
          sx={{
            width: '100%',
            height: imageExpanded ? "600px" : "300px",
            margin: '0 auto',
            overflow: 'hidden',
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            //backgroundColor: '#f0f0f0',
          }}
        >
          <Carousel
            sx={{
              width: '100%',
              height: '100%',
              '& .carousel-root': {
                width: '100%',
                height: '100%',
              },
            }}
            indicators={false}
          >
            {file.map((img, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                }}
              >
                <Box
                  component="img"
                  src={img}
                  loading="lazy"
                  sx={{
                    maxHeight: imageExpanded ? '580px' : '280px',
                    maxWidth: '100%',
                    width: 'auto',
                    height: 'auto',
                    objectFit: 'contain',
                    objectPosition: 'center',
                    transition: 'max-height 0.3s ease',
                  }}
                  alt={`Imagen ${index + 1}`}
                />
              </Box>
            ))}
          </Carousel>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          {loadImage ? (
            <CircularProgress size={24} sx={{ mx: "auto" }} />
          ) : (
            <CardMedia
              component="img"
              height="300"
              //@ts-ignore
              image={file.length === 1 ? file[0] : Condominio}
              alt={title}
            />
          )}
        </Box>
      )}

      <CardContent>
        <Typography variant="h5" color="text.primary" sx={{ mb: 2 }}>
          {title}
        </Typography>

        <Typography variant="body1" color="text.primary">
          {description}
        </Typography>
      </CardContent>

      <CardActions disableSpacing>
        <Stack
          direction="row"
          useFlexGap
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <CardHeader
            avatar={
              <Avatar
                sx={{ bgcolor: theme.palette.grey[300] }}
                aria-label="recipe"
              >
                R
              </Avatar>
            }
            title={authInfo.username}
            subheader=""
          ///subheader={formatDate(new Date())}
          />
          {/* <Button onClick={handleExpandClick} aria-label="add comment">
            <PersonPin /> {t("addComment")}
          </Button> */}
        </Stack>
      </CardActions>
      {/* <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        sx={{ px: 4, pb: 8 }}
      >
        <Controller
          name={"message"}
          control={control}
          render={({ field: { value } }) => (
            <TextField
              fullWidth
              size="small"
              placeholder="Ingresar comentario"
              type="text"
              variant="standard"
              value={value}
              {...register("message")}
            />
          )}
        />
      </Collapse> */}
    </Card>
  );
};

export default PostCard;
