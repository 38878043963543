// src/components/TabCard1Form.tsx
import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    Grid,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    useTheme
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { PlusIcon, PenIcon, TrashIcon } from "../../components/svgicons/SvgIcons";
import FancyTooltip from "../../components/utils/FancyTooltip";
import AddOrUpdateCardMethodModal from "../../components/utils/AddOrUpdateCardMethodModal";
import DeleteModal from "../../components/utils/DeleteModal";
import { useApp } from "../../hooks/useApp";
import { createRequest, updateRequest, deleteRequest, getRequestPayMethod } from "../../services/payMethod/CardMethodService";
import ResourceAccess from "../../components/security/ResourceAccess";

interface TabCard1FormProps {
    data: any;
    refreshData: any;
}

const TabCard1Form: React.FC<TabCard1FormProps> = ({ data, refreshData }) => {
    const { t } = useTranslation();
    const { authInfo, setLoading, setErrorMsg, modalData, setModalData, setSuccessMsg, isAdmin, company } = useApp();
    const theme = useTheme();

    const [preFilter, setPreFilter] = useState<string>("");
    const [page, setPage] = useState(0);
    const { handleSubmit, register } = useForm();


    const onSubmit = async (formData: any) => {
        switch (modalData?.modalType) {
            case "create":
                await handleAdd(formData);
                break;
            case "update":
                await handleUpdate(formData);
                break;
            case "delete":
                await handleDelete(modalData?.modalObject?.id);
                break;
            default:
                break;
        }
    };

    const handleAdd = async (formData: any) => {
        handleCancelModal();
        setLoading && setLoading(true);
        try {
            const createData = await createRequest({
                ...formData,
                user: formData.paymentMethod === 1 ? formData.user : null,
                password: formData.paymentMethod === 1 ? formData.password : null,
                apiKey: formData.apiKey,
                type: formData.paymentMethod,
                companyId: isAdmin ? company : authInfo.companyId,
                userCreated: authInfo?.username
            });
            if (!createData) {
                setErrorMsg && setErrorMsg(t("errorCreatingCard"));
                setLoading && setLoading(false);
                return;
            }
            setSuccessMsg && setSuccessMsg(t("successfullyCreatedCard"));
            setLoading && setLoading(false);
            await refreshData(0, "");
        } catch (error: any) {
            setErrorMsg && setErrorMsg(t("errorCreatingCard"));
            setLoading && setLoading(false);
        }
    };

    const handleUpdate = async (formData: any) => {
        handleCancelModal();
        setLoading && setLoading(true);
        try {
            const updateData = await updateRequest({
                ...formData,
                user: formData.paymentMethod === 1 ? formData.user : null,
                password: formData.paymentMethod === 1 ? formData.password : null,
                apiKey: formData.apiKey,
                type: formData.paymentMethod,
                companyId: isAdmin ? company : authInfo.companyId,
                userModified: authInfo?.username,
            });
            if (!updateData) {
                setErrorMsg && setErrorMsg(t("errorUpdatingCard"));
                setLoading && setLoading(false);
                return;
            }
            setLoading && setLoading(false);
            setSuccessMsg && setSuccessMsg(t("cardUpdatedCorrectly"));
            await refreshData(0, "");
        } catch (error: any) {
            setErrorMsg && setErrorMsg(t("errorUpdatingCard"));
            setLoading && setLoading(false);
        }
    };

    const handleDelete = async (id: any) => {
        handleCancelModal();
        setLoading && setLoading(true);
        try {
            const deleteData = await deleteRequest(id);
            if (!deleteData) {
                setLoading && setLoading(false);
                return;
            }
            setLoading && setLoading(false);
            await refreshData(0, "");
        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
            setLoading && setLoading(false);
        }
    };

    const handleOpenModal = (event: any) => {
        event.preventDefault();
        const modalAction = event.currentTarget.getAttribute("data-name");
        const id = event.currentTarget.getAttribute("data-id");
        let object = null;
        if (modalAction === "update" || modalAction === "delete") {
            object = data.find((p: any) => p.id === parseInt(id));
        }
        setModalData && setModalData({
            modalOpen: true,
            modalType: modalAction,
            modalObject: object,
        });
    };

    const handleCancelModal = () => {
        setModalData && setModalData({
            modalOpen: false,
            modalType: "",
            modalObject: null,
        });
    };

    // const handleApplyFilter = async () => {
    //     if (preFilter !== "") {
    //         await handleFetchData(0, preFilter);
    //     }
    // };

    // const handleChangePage = async (event: unknown, newPage: number) => {
    //     const customPage = newPage - 1;
    //     setPage(customPage);
    //     if (customPage !== page) {
    //         await handleFetchData(customPage, preFilter);
    //     }
    // };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
    };

    const truncateText = (text: any, maxLength: any) => {
        if (text && text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    return (
        <>
            <Grid container spacing={8} sx={{ pb: 8 }}>
                <Grid item md={9} sm={6} xs={12}>
                    <ResourceAccess isCode={true} pathOrCode={"PAYMETHOD:WRITE"}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenModal}
                            data-name="create"
                        >
                            {t("addCard")}
                            <PlusIcon sx={{ ml: 1 }} />
                        </Button>
                    </ResourceAccess>
                </Grid>
                {/* <Grid item md={3} sm={6} xs={12}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <TextField
                            placeholder={t("searchFilter")}
                            sx={{
                                width: "100%",
                                "& .MuiInputBase-root": {
                                    borderRadius: "0.2rem 0 0 0.2rem",
                                },
                                "& fieldset": {
                                    borderRightWidth: "0",
                                },
                            }}
                            value={preFilter}
                            onChange={(e: any) => {
                                setPreFilter(e.target.value);
                                if (e.target.value === "") {
                                    handleFetchData(0, "");
                                }
                            }}
                            onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
                        />
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleApplyFilter}
                            sx={{
                                borderRadius: "0 0.2rem 0.2rem 0!important",
                                padding: "1.5px 16px!important",
                            }}
                        >
                            Buscar
                        </Button>
                    </Stack>
                </Grid> */}
            </Grid>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{t("id")}</TableCell>
                            <TableCell align="center">{t("type")}</TableCell>
                            <TableCell align="center">{t("user")}</TableCell>
                            <TableCell align="center">{t("password")}</TableCell>
                            <TableCell align="center">{t("Api Key")}</TableCell>
                            <TableCell align="center">{t("actions")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data &&
                            data.map((row: any, i: number) => (
                                <TableRow
                                    key={i}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                    }}
                                >
                                    <TableCell align="center" component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {row.type === 1 ? "Tilopay" : "Stripe"}

                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {truncateText(row.user, 50)}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {truncateText(row.password, 50)}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {truncateText(row.apiKey, 50)}
                                    </TableCell>
                                    <TableCell align="center">
                                        <ResourceAccess isCode={true} pathOrCode={"PAYMETHOD:WRITE"}>
                                            <FancyTooltip title={t("edit")} placement="top">
                                                <IconButton
                                                    aria-label="edit"
                                                    component="label"
                                                    color="secondary"
                                                    sx={{
                                                        "&:hover": {
                                                            color: theme.palette.secondary.dark,
                                                        },
                                                    }}
                                                    onClick={handleOpenModal}
                                                    data-name="update"
                                                    data-id={row.id}
                                                >
                                                    <PenIcon />
                                                </IconButton>
                                            </FancyTooltip>
                                            <FancyTooltip title="Eliminar" placement="top">
                                                <IconButton
                                                    aria-label="trash"
                                                    component="label"
                                                    color="secondary"
                                                    sx={{
                                                        "&:hover": {
                                                            color: theme.palette.secondary.dark,
                                                        },
                                                    }}
                                                    data-id={row.id}
                                                    onClick={handleOpenModal}
                                                    data-name="delete"
                                                >
                                                    <TrashIcon />
                                                </IconButton>
                                            </FancyTooltip>
                                        </ResourceAccess>
                                    </TableCell>

                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {(modalData?.modalType === "update" || modalData?.modalType === "create") && (
                <Dialog
                    open={modalData.modalOpen}
                    onClose={handleCancelModal}
                    fullWidth
                >
                    <AddOrUpdateCardMethodModal
                        data={modalData.modalObject}
                        onSubmit={onSubmit}
                        refreshData={"handleFetchData"}
                        cancelModal={handleCancelModal}
                        modalType={modalData?.modalType}
                    />
                </Dialog>
            )}
            {modalData?.modalType === "delete" && (
                <Dialog
                    open={modalData.modalOpen}
                    onClose={handleCancelModal}
                    fullWidth
                >
                    <DeleteModal
                        textChildren={modalData?.modalObject?.nombre}
                        actionButton={
                            <>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        mt: 2,
                                        mr: 2,
                                    }}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    {t("save")}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{
                                        mt: 2,
                                    }}
                                    onClick={handleCancelModal}
                                    autoFocus
                                >
                                    {t("cancel")}
                                </Button>
                            </>
                        }
                    />
                </Dialog>
            )}
        </>
    );
};

export default TabCard1Form;
