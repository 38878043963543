import { privateFetch } from "../../utils/CustomFetch";
import { URL } from "../../utils/UrlConstants";

import { Content, GuestResponse, MessageResponse } from "./GuestInterface";
export const getAllGuests = async (
    page: number,
    size: number,
    companyId: number
): Promise<GuestResponse> => {
    try {
        const response = await privateFetch.get(URL.REQUEST_GUESTS_LIST(page, size, companyId));
        const responseData: GuestResponse = response.data;

        return responseData;
    } catch (error) {
        console.error("Error al obtener la lista de invitados:", error);
        throw new Error("No se pudo obtener la lista de invitados. Por favor, inténtalo de nuevo más tarde.");
    }
};


export const getAddGuest = async (data: Content, companyId: number): Promise<MessageResponse> => {
    try {
        const response = await privateFetch.post(URL.REQUEST_GUESTS_ADD(companyId), data);
        console.log(response);

        return response.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || "Error desconocido";
        throw errorMessage;
    }
};

export const getUpdateGuest = async (id: number, data: Content, companyId: number): Promise<Content> => {
    try {
        const response = await privateFetch.put(URL.REQUEST_GUESTS_UPDATE(id, companyId), data);
        console.log(response);

        return response.data;
    } catch (error) {
        console.error("Error al realizar la petición:", error);
        throw error;
    }
};

export const getDeleteGuest = async (id: number, companyId: number): Promise<string> => {
    try {
        const response = await privateFetch.delete(URL.REQUEST_GUESTS_DELETE(id, companyId));
        return response.data;
    } catch (error) {
        console.error("Error al realizar la petición:", error);
        throw error;
    }
};