import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Button,
    Dialog,
    Grid,
    IconButton,
    Paper,
    Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow,
    useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import FancyPaper from '../../../components/FancyPaper';
import ResourceAccess from '../../../components/security/ResourceAccess';
import { PenIcon, PlusIcon, TrashIcon } from '../../../components/svgicons/SvgIcons';
import FancyTooltip from '../../../components/utils/FancyTooltip';
import AddOrUpdateMessageModal from '../../../components/utils/messages/AddOrUpdateMessageModal';
import DeleteMessageModal from '../../../components/utils/messages/DeleteMessageModal';
import { useApp } from "../../../hooks/useApp";
import { Content } from '../../../services/messages/MessagesInterface';
import { useMessages } from './MessagesContext';


const MessagesList: React.FC = () => {
    const { t } = useTranslation();
    const { messagesData,
        onSubmit,
        handleOpenModal,
        handleCancelModal,
        handleSubmit,
        apartmentDataById,
    } = useMessages();
    const {
        modalData,
        isAdmin,
    } = useApp();

    const theme = useTheme();

    return (
        <>
            <FancyPaper pagetitle={`${t("security")} / ${t("messageList")}`}>

                <Grid container spacing={8} sx={{ pb: 8 }}>
                    <Grid item md={9} sm={6} xs={12}>
                        <ResourceAccess isCode={true} pathOrCode={"MESSAGE:WRITE"}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleOpenModal
                                }
                                data-name="create"
                            >
                                {t("addMessage")}
                                <PlusIcon sx={{ ml: 1 }} />
                            </Button>
                        </ResourceAccess>
                    </Grid>
                </Grid>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'>{t("id")}</TableCell>
                                <TableCell align='center'>{t("apartment")}</TableCell>
                                <TableCell align='center'>{t("of1")}</TableCell>
                                <TableCell align='center'>{t("date")} / {t("hour")}</TableCell>
                                <TableCell align='center'>{t("title")}</TableCell>
                                <TableCell align='center'>{t("state")}</TableCell>
                                <TableCell align='center'>{t("actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {messagesData &&
                                messagesData.content &&
                                messagesData.content.map((row: Content, i: number) => (
                                    <TableRow
                                        key={i}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                        }}>
                                        <TableCell align='center'>{row.id}</TableCell>
                                        <TableCell align='center'>{row.sourceApartment}</TableCell>
                                        <TableCell align='center'>{row.sourceName === 'ADMINISTRATOR' ? t("administrator") : row.sourceName === 'SECURITY' ? t('security') : row.sourceName}</TableCell>
                                        <TableCell align='center'>  {row.creationDate ?
                                            `${dayjs(row.creationDate).format('YYYY-MM-DD')} ${format(new Date(row.creationDate), 'hh:mm a')}`
                                            : ''}</TableCell>
                                        <TableCell align='center'>{row.title}</TableCell>
                                        <TableCell align='center'>{row.status === 0 ? t("unread") : t("read")}</TableCell>
                                        <TableCell align='center'>
                                            {!isAdmin && apartmentDataById && (row.sourceApartment !== apartmentDataById[0].name) &&
                                                <FancyTooltip title={t("view")} placement='top'>
                                                    <IconButton
                                                        aria-label='edit'
                                                        component='label'
                                                        color='secondary'
                                                        sx={{
                                                            '&:hover': {
                                                                color: theme.palette.secondary.dark,
                                                            },
                                                        }}
                                                        onClick={handleOpenModal}
                                                        data-name='view'
                                                        data-id={row.id}>
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                </FancyTooltip>}
                                            <ResourceAccess isCode={true} pathOrCode={'MESSAGE:WRITE'}>
                                                {(isAdmin && row.sourceName === 'ADMINISTRATOR') || (apartmentDataById.length > 0 && row.sourceApartment === apartmentDataById[0].name) ? (
                                                    <>
                                                        <FancyTooltip title={t("edit")} placement='top'>
                                                            <IconButton
                                                                aria-label='edit'
                                                                component='label'
                                                                color='secondary'
                                                                sx={{
                                                                    '&:hover': {
                                                                        color: theme.palette.secondary.dark,
                                                                    },
                                                                }}
                                                                onClick={handleOpenModal}
                                                                data-name='update'
                                                                data-id={row.id}
                                                            >
                                                                <PenIcon />
                                                            </IconButton>
                                                        </FancyTooltip>
                                                        <FancyTooltip title={t("delete")} placement="top">
                                                            <IconButton
                                                                aria-label="trash"
                                                                component="label"
                                                                color="secondary"
                                                                sx={{
                                                                    "&:hover": {
                                                                        color: theme.palette.secondary.dark,
                                                                    },
                                                                }}
                                                                onClick={handleOpenModal}
                                                                data-id={row.id}
                                                                data-name="delete"
                                                            >
                                                                <TrashIcon />
                                                            </IconButton>
                                                        </FancyTooltip>
                                                    </>
                                                ) : null}
                                            </ResourceAccess>

                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </FancyPaper>
            {(modalData?.modalType === "create" ||
                modalData?.modalType === "update" ||
                modalData?.modalType === "view") && (
                    <Dialog
                        open={modalData.modalOpen}
                        onClose={handleCancelModal}
                        fullWidth
                    >
                        <AddOrUpdateMessageModal
                            data={modalData?.modalObject}
                            onSubmit={onSubmit}
                            cancelModal={handleCancelModal}
                            modalType={modalData?.modalType}
                            isView={modalData?.modalType === "view"}
                        />
                    </Dialog>
                )}

            {modalData?.modalType === "delete" && (
                <Dialog
                    open={modalData.modalOpen}
                    onClose={handleCancelModal}
                    fullWidth
                >
                    <DeleteMessageModal
                        actionButton={
                            <>
                                {" "}
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        mt: 2,
                                        mr: 2,
                                    }}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    {t("delete")}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{
                                        mt: 2,
                                    }}
                                    onClick={handleCancelModal}
                                    autoFocus
                                >
                                    {t("cancel")}
                                </Button>{" "}
                            </>
                        }
                    />
                </Dialog>
            )}

        </>
    );
};

export default MessagesList;
