import { useState, useEffect, useRef, useCallback } from "react";
import {
  Button,
  Grid,
  TextField,
  FormControl,
  Typography,
  MenuItem,
  Select,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  InputLabel,
  styled,
  Chip,
  Box,
  ListItemText,
  Dialog,
  CircularProgress,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PublishIcon from "@mui/icons-material/Publish";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InputLabelToForm from "../InputLabelToForm";
import FancyPaper from "../FancyPaper";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { AddOrUpdatePostCardType } from "../../types/Post";
import Condominio from "../../resources/images/condominio-residencial.jpg";
import placeholder from "../../resources/images/placeholder-image.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import { formatDate } from "../../utils/UtilsFunctions";
import { useTranslation } from "react-i18next";
import MessageManager from "./MessageManager";
import { CONSTANT } from "../../utils/Constants";
import { useApp } from "../../hooks/useApp";
import i18next from "i18next";
import { addNews, updateNews } from "../../services/news/NewsServices";
import { newsResponse } from "../../services/news/NewsInterface";
import { RichTextInput } from "@tonz/react-draft-wysiwyg-input";
import "@tonz/react-draft-wysiwyg-input/style.css";
import GuideIDVideoYoutube from "./GuideIDVideoYoutube";
import {
  getUrlImage,
  getUrlVideo,
  uploadDocuments,
  deleteDocuments,
} from "../../services/booking/BookingService";
import Swal from "sweetalert2";
import Carousel from "react-material-ui-carousel";

const LabelForm = styled(InputLabel)(() => ({
  left: "-14px",
}));

interface categoryType {
  id: number;
  description: string;
}

const dataCategory = [
  { id: 1, description: "Eventos" },
  { id: 2, description: "Mantenimientos" },
  { id: 3, description: "Otros" },
];

const post: any = {
  id: 0,
  title: "Suspensión Temporal del Suministro de Agua",
  description: `Buenas tardes Estimados Vecinos.
    Les informamos que, como parte de las labores de reparación anunciadas previamente, se llevará a cabo una suspensión programada del suministro de agua desde las 6:00 p.m. de hoy hasta las 6:00 a.m. de mañana.              
    
    Instamos a todos las personas a tomar las precauciones necesarias almacenando agua para cubrir sus necesidades durante este período de suspensión. Nos esforzaremos por completar las reparaciones de manera oportuna y restaurar el suministro de agua lo antes posible.
    
    Agradecemos su colaboración y paciencia mientras trabajamos para mejorar nuestros servicios.`,
  date: new Date(),
  tags: ["Servicios", "Eventos"],
  status: "Borrador",
  author: "Condominio Oasis de San Jose.",
  image: "https://definicion.de/wp-content/uploads/2009/02/condominio-1.jpg",
};

const AddOrUpdPostCard = () => {
  const { t } = useTranslation();
  const {
    resetErrorMsg,
    resetSuccessMsg,
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    authInfo,
    company,
    handleFetchNews,
    postData,
    postCategory,
    isAdmin,
  } = useApp();
  const { postID, postTitle } = useParams();
  const navigate = useNavigate();
  const editorRef = useRef<any | null>(null);
  const [singlePost, setSinglePost] = useState<AddOrUpdatePostCardType | null>(
    null
  );
  const [categories, setCategories] = useState<any>(null);
  const [openYoutubeVideo, setOpenYoutubeVideo] = useState(false);

  const requeridString = Yup.string().required(t("fieldRequired"));

  useEffect(() => {
    if (postID) {
      handleFindByID();
    }
    console.log("placeholder: ", placeholder);
  }, []);

  const handleFindByID = async () => {
    let data: any = postData?.content?.filter(
      (item: any) => Number(item.id) === Number(postID)
    );
    console.log("data", data[0]);
    setSinglePost(data[0]);
  };

  const handleCloseYoutubeVideoGuide = () => {
    setOpenYoutubeVideo(false);
  };

  const validation = Yup.object().shape({
    id: Yup.number(),
    title: requeridString,
    description: requeridString,
    date: Yup.date(),
    status: Yup.string(),
    id_company: Yup.string(),
    category: Yup.array(),
    id_video: Yup.string(),
  });

  const defaultValues = {
    id: singlePost?.id ?? 0,
    title: singlePost?.title ?? "",
    description: singlePost?.description ?? "",
    status: singlePost?.status ?? "0", //@ts-ignore
    category: singlePost?.category ? JSON.parse(singlePost?.category) : [],
    date: new Date(),
    id_company: company,
    id_video: singlePost?.id_video ?? "",
    idImagen: [] as File[],
    idVideo: [] as File[],
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm<AddOrUpdatePostCardType>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });
  const watch_title = watch("title");
  const watch_id_video = watch("id_video");
  const watch_image: any = watch("idImagen");
  const watch_video: any = watch("idVideo");
  const [loadImage, setLoadImage] = useState(false);

  const [file, setFile] = useState<string[]>([]);
  const allowedExtensions = ["jpg", "jpeg", "png", "bmp"];

  const [videoFile, setVideoFile] = useState("");
  const allowedVideoExtensions = ["mp4", "avi", "mkv"];
  const settings = {
    dots: true, // Mostrar puntos de navegación
    infinite: true, // Carrusel infinito
    speed: 500, // Velocidad de transición en milisegundos
    slidesToShow: 1, // Número de imágenes a mostrar a la vez
    slidesToScroll: 1, // Número de imágenes a desplazar a la vez
    autoplay: true, // Reproducción automática
    autoplaySpeed: 3000, // Intervalo de reproducción automática en milisegundos
  };

  console.log(videoFile)
  useEffect(() => {
    const getImage = async () => {
      if (singlePost && singlePost.idImagen) {
        if (typeof singlePost.idImagen === "string") {
          const imageIds = singlePost.idImagen.split(",");
          await handleFetchImage(imageIds);
        } else if (typeof singlePost.idImagen === "number") {
          await handleFetchImage([singlePost.idImagen.toString()]);
        }
      }
      if (typeof singlePost?.idVideo === "number") {
        await handleFetchVideo(singlePost?.idVideo);
      }
    };
    getImage();
    reset(defaultValues);
    // eslint-disable-next-line
  }, [singlePost]);

  const handleFetchImage = async (ids: string[]) => {
    setLoadImage(true);
    try {
      const imageUrls = await Promise.all(
        ids.map(async (id) => {
          const imageUrl = await getUrlImage(id);
          return imageUrl;
        })
      );
      setFile(imageUrls);
      setLoadImage(false);
      return imageUrls;
    } catch (error: any) {
      setLoadImage(false);
      setErrorMsg && setErrorMsg(error);
    }
  };

  const handleFetchVideo = async (id: string) => {
    setLoadImage(true);
    try {
      const videoUrl = await getUrlVideo(id);
      setVideoFile(videoUrl);
      setLoadImage(false);
      return videoUrl;
    } catch (error: any) {
      setLoadImage(false);
      setErrorMsg && setErrorMsg(error);
    }
  };


  const handleChangeCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log([event.target.name], event.target.checked);
    setCategories({
      ...categories,
      [event.target.name]: event.target.checked,
    });
  };

  // const handleAddNews = async (option: string, data: newsResponse) => {
  //   console.log(data)
  //   try {
  //     setLoading && setLoading(true);
  //     let addData: any = await addNews(option, {
  //       //...data,
  //       title: data.title,
  //       description: data.description,
  //       status: data.status,
  //       userCreated: authInfo.username,
  //       id_video: data.id_video,
  //       //@ts-ignore
  //       category: JSON.stringify(data.category),
  //       id_company: company,
  //     });
  //     if (addData) {
  //       if (data.idImagen) {
  //         const addImage = await handleUploadImage(data.idImagen);
  //         if (addImage) {
  //           const updPost = await updateNews(addData.id, {
  //             ...addData,
  //             idImagen: `${addImage.id}`,
  //           });
  //           setSinglePost(updPost);
  //         }
  //       } else {
  //         setSinglePost(addData);
  //       }

  //       if (data.idVideo) {
  //         const addVideo = await handleUploadVideo(data.idVideo);
  //         if (addVideo) {
  //           const updPost = await updateNews(addData.id, {
  //             ...addData,
  //             idVideo: `${addVideo.id}`,
  //           });
  //           setSinglePost(updPost);
  //         }
  //       }


  //     }
  //     if (!addData) {
  //       setErrorMsg && setErrorMsg(t("errorCreatingNews"));
  //       setLoading && setLoading(false);
  //       return;
  //     }

  //     handleFetchNews && handleFetchNews(0, company, "", "");
  //     setSuccessMsg && setSuccessMsg(t("newsCreatedSuccessfully"));
  //     setLoading && setLoading(false);
  //     navigate(`/dashboard/news/${addData.id}/${addData.title}`);
  //   } catch (error: any) {
  //     setErrorMsg && setErrorMsg(error.message);
  //     setLoading && setLoading(false);
  //   }
  // };

  const handleAddNews = async (option: string, data: newsResponse) => {
    try {
      setLoading && setLoading(true);
      let addData: any = await addNews(option, {
        title: data.title,
        description: data.description,
        status: data.status,
        userCreated: authInfo.username,
        id_video: data.id_video,
        //@ts-ignore
        category: JSON.stringify(data.category),
        id_company: company,
      });

      if (addData) {
        if (data.idImagen && data.idImagen.length > 0) {
          const addedImages = [];

          // Iterar sobre cada archivo en el FileList
          for (let i = 0; i < data.idImagen.length; i++) {
            const file = data.idImagen[i];
            if (file) {
              const addImage = await handleUploadImage([file]);
              if (addImage) {
                addedImages.push(addImage.id);
              }
            }
          }

          // Actualizar la publicación con los IDs de las imágenes agregadas
          if (addedImages.length > 0) {
            const updPost = await updateNews(addData.id, {
              ...addData,
              idImagen: addedImages.join(","),
            });
            setSinglePost(updPost);
          } else {
            setSinglePost(addData);
          }
        } else {
          setSinglePost(addData);
        }

        if (data.idVideo) {
          const addVideo = await handleUploadVideo(data.idVideo);
          if (addVideo) {
            const updPost = await updateNews(addData.id, {
              ...addData,
              idVideo: `${addVideo.id}`,
            });
            setSinglePost(updPost);
          }
        }
      }

      if (!addData) {
        setErrorMsg && setErrorMsg(t("errorCreatingNews"));
        setLoading && setLoading(false);
        return;
      }

      handleFetchNews && handleFetchNews(0, company, "", "");
      setSuccessMsg && setSuccessMsg(t("newsCreatedSuccessfully"));
      setLoading && setLoading(false);
      navigate(`/dashboard/news/${addData.id}/${addData.title}`);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleUpdNews = async (option: string, data: newsResponse) => {
    try {
      let addedImages = [];
      let addImage = null;
      let addVideo = null;
      setLoading && setLoading(true);
      // Eliminar las imágenes anteriores
      if (singlePost?.idImagen) {
        const imageIds = singlePost.idImagen.split(",");
        for (const imageId of imageIds) {
          //@ts-ignore
          await deleteDocuments(imageId, authInfo.username);
        }
      }

      // Cargar las nuevas imágenes
      if (data.idImagen && data.idImagen.length > 0) {
        for (let i = 0; i < data.idImagen.length; i++) {
          const file = data.idImagen[i];
          if (file) {
            addImage = await handleUploadImage([file]);
            if (addImage) {
              addedImages.push(addImage.id);
            }
          }
        }
      }

      //video
      if (typeof watch_video !== "number" && data.idVideo) {
        if (singlePost?.idVideo !== null) {
          //@ts-ignore
          await deleteDocuments(singlePost.idVideo, authInfo.username);
        }

        addVideo = await handleUploadVideo(data.idVideo);
      }

      let addData = await updateNews(option, {
        title: data.title,
        description: data.description,
        status: data.status,
        userCreated: data.userCreated,
        userModified: authInfo.username,
        id_video: data.id_video, //@ts-ignore
        idImagen: addedImages.length > 0 ? addedImages.join(",") : "",
        idVideo:
          addVideo !== null
            ? addVideo.id
            : typeof data.idVideo !== "object"
              ? `${data.idVideo}`
              : "",
        //@ts-ignore
        category: JSON.stringify(data.category),
        id_company: company,
      });

      if (!addData) {
        setErrorMsg && setErrorMsg(t("errorUpdatingNews"));
        setLoading && setLoading(false);
        return;
      }
      setSinglePost(addData);
      handleFetchNews && handleFetchNews(0, company, "", "");
      setSuccessMsg && setSuccessMsg(t("newsUpdatedSuccessfully"));
      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleFileChange = (e: any) => {
    const files = e.target.files;

    if (!files || files.length === 0) {
      return;
    }

    if (files.length > 5) {
      Swal.fire({
        icon: "error",
        title: t("invalidFile"),
        text: t("max5FilesAllowed"),
        confirmButtonColor: "#0A3A7A",
      });
      return;
    }

    const fileUrls = [];
    for (let i = 0; i < files.length; i++) {
      const fileExtension = files[i].name.split(".").pop()?.toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={`${t("invalidFile")}`}
          details={`${t("FileExtNotAllowed")}`}
          callback={resetErrorMsg}
        />;
        return;
      }

      if (files[i].size > 2000000) {
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={`${t("invalidFile")}`}
          details={`${t("FileSizeLess2MB")}`}
          callback={resetErrorMsg}
        />;
        return;
      }

      fileUrls.push(URL.createObjectURL(files[i]));
    }

    console.log(files)

    setValue("idImagen", files);
    setFile(fileUrls);

    // const files = e.target.files;

    // console.log(files)

    // if (!files || files.length === 0) {
    //   return;
    // }

    // const fileExtension = files[0].name.split(".").pop()?.toLowerCase();
    // if (!allowedExtensions.includes(fileExtension)) {
    //   <MessageManager
    //     type={CONSTANT.ERROR_MSG}
    //     msg={`${t("invalidFile")}`}
    //     details={`${t("FileExtNotAllowed")}`}
    //     callback={resetErrorMsg}
    //   />;
    //   return;
    // }

    // if (files[0].size > 2000000) {
    //   <MessageManager
    //     type={CONSTANT.ERROR_MSG}
    //     msg={`${t("invalidFile")}`}
    //     details={`${t("FileSizeLess2MB")}`}
    //     callback={resetErrorMsg}
    //   />;
    //   return;
    // }
    // setValue("idImagen", e.target.files);
    // setFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleUploadImage = async (acceptedFiles: File[]) => {
    let responseAddImage: any = null;
    try {
      setLoading && setLoading(true);
      if (acceptedFiles && acceptedFiles[0]?.name) {
        const formData = new FormData();
        formData.append("idDocumentCategory", "3");
        formData.append("idCompany", isAdmin ? company : authInfo.companyId);
        formData.append("files", acceptedFiles[0]);
        formData.append("user", authInfo.username);
        responseAddImage = await uploadDocuments(formData);
      }
      setLoading && setLoading(false);
      return responseAddImage;
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleVideoFileChange = (e: any) => {
    const files = e.target.files;

    if (!files || files.length === 0) {
      return;
    }

    console.log(files)

    const fileExtension = files[0].name.split(".").pop()?.toLowerCase();
    if (!allowedVideoExtensions.includes(fileExtension)) {
      Swal.fire({
        icon: "error",
        title: t("invalidFile"),
        text: t("FileExtNotAllowed"),
        confirmButtonColor: "#0A3A7A",
      });
      return;
    }

    if (files[0].size > 20000000) {
      Swal.fire({
        icon: "error",
        title: t("invalidFile"),
        text: t("FileSizeLess20MB"),
        confirmButtonColor: "#0A3A7A",
      });
      return;
    }
    setValue("idVideo", e.target.files);
    setVideoFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleUploadVideo = async (acceptedFiles: File[]) => {
    let responseAddVideo: any = null;
    try {
      setLoading && setLoading(true);
      if (acceptedFiles && acceptedFiles[0]?.name) {
        const formData = new FormData();
        formData.append("idDocumentCategory", "4");
        formData.append("idCompany", isAdmin ? company : authInfo.companyId);
        formData.append("files", acceptedFiles[0]);
        formData.append("user", authInfo.username);
        responseAddVideo = await uploadDocuments(formData);
      }
      setLoading && setLoading(false);
      return responseAddVideo;
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    const typeAction = e.target.name;
    switch (typeAction) {
      case "create":
        await handleAddNews("create", data);
        break;
      case "update":
        await handleUpdNews(data.id, data);
        break;
      default:
        break;
    }
  };

  return (
    <FancyPaper
      prevPage="Noticias"
      linkPrevPage="/dashboard/news"
      pagetitle={singlePost?.title}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate(`/dashboard/news`)}
      >
        <KeyboardBackspaceIcon />
        {t("back")}
      </Button>
      <Typography variant="h5" color="initial" sx={{ my: 5 }}>
        {postID ? t("updNew") : t("addNew")}
      </Typography>
      <form>
        <Grid container spacing={8} sx={{ pt: 4, pb: 8 }}>
          <Grid item xs={10}>
            <Grid container spacing={3} sx={{ pt: 4, pb: 8 }}>
              <Grid item xs={12}>
                <Controller
                  name={"title"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      variant="standard"
                      label={t("title")}
                      value={value}
                      autoComplete="title"
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("title")}
                      error={errors.title && Boolean(errors.title)}
                      helperText={errors.title && errors.title.message}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        placeholder: t("enterTitleHere"),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <Editor
                  defaultContentState={contentState}
                  editorState={editorState}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  onEditorStateChange={handleEditorStateChange}
                />  */}
                <Controller
                  name={"description"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      variant="standard"
                      label={t("description")}
                      value={value}
                      multiline
                      rows={6}
                      autoComplete="description"
                      sx={{
                        "& textarea": { pl: "0!important" },
                      }}
                      {...register("description")}
                      error={errors.description && Boolean(errors.description)}
                      helperText={
                        errors.description && errors.description.message
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        placeholder: t("enterDescriptionHere"),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container spacing={3} sx={{ pt: 4, pb: 8 }}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  name={postID ? "update" : "create"}
                  onClick={handleSubmit(onSubmit)}
                >
                  {postID ? t("update") : t("publish")}
                  <PublishIcon sx={{ ml: 4 }} />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={"date"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      variant="standard"
                      label={t("date")}
                      disabled
                      value={value}
                      autoComplete="date"
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("date")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 8, mb: 2 }}>
                <Controller
                  name={"status"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth className={"disabled-select"}>
                      <InputLabelToForm>{t("state")}</InputLabelToForm>
                      <Select
                        // disabled
                        variant="standard"
                        value={value}
                        {...register("status")}
                      >
                        <MenuItem value="0">{t("draft")}</MenuItem>
                        <MenuItem value="1">{t("published")}</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="category"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth>
                      <LabelForm>{t("category")}</LabelForm>
                      <Select
                        variant="standard"
                        value={value}
                        multiple
                        {...register("category")}
                        renderValue={(selected: any) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value: any) => (
                              <Chip
                                key={value}
                                label={
                                  postCategory?.content?.find(
                                    (item: any) => item.id === value
                                  )?.name
                                }
                                color="primary"
                                size="small"
                                sx={{ mb: "2px" }}
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {postCategory?.content?.map((item: any, i: any) => (
                          <MenuItem key={item.id} value={item.id}>
                            <Checkbox checked={value.indexOf(item.id) > -1} />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="idImagen"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Grid container spacing={2}>
                      {file.length !== 0 ? (
                        <Grid
                          item
                          xs={12}
                          style={{
                            marginBottom: "30px",
                            width: "300px",
                            height: "250px",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            //backgroundColor: '#f0f0f0', 
                            overflow: 'hidden',
                          }}
                        >
                          {file.length === 1 ? (
                            <div style={{
                              width: "100%",
                              height: "100%",
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                              <img
                                src={file[0]}
                                loading="lazy"
                                style={{
                                  width: "auto",
                                  height: "auto",
                                  maxWidth: "290px",
                                  maxHeight: "240px",
                                  objectFit: "contain",
                                  objectPosition: "center",
                                }}
                                alt={`Imagen 1`}
                              />
                            </div>
                          ) : (
                            <Carousel
                              sx={{
                                width: '100%',
                                height: '100%',
                              }}
                              indicators={false}
                            >
                              {file.map((img, index) => (
                                <div
                                  key={index}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <img
                                    src={img}
                                    loading="lazy"
                                    style={{
                                      width: "auto",
                                      height: "auto",
                                      maxWidth: "290px",
                                      maxHeight: "240px",
                                      objectFit: "contain",
                                      objectPosition: "center",
                                    }}
                                    alt={`Imagen ${index + 1}`}
                                  />
                                </div>
                              ))}
                            </Carousel>
                          )}
                        </Grid>
                      ) : (
                        <Grid item>
                          <img
                            src={placeholder}
                            loading="lazy"
                            style={{
                              maxWidth: "250px",
                              maxHeight: "200px",
                              objectFit: "contain"
                            }}
                            alt="Imagen de placeholder"
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "8px",
                    // marginTop: "5px",
                    width: "180px",
                  }}
                >
                  <input
                    id={`photo-upload`}
                    type="file"
                    multiple
                    onChange={(event) => handleFileChange(event)}
                    accept="image/png, image/jpeg, image/jpg"
                    style={{ display: "none" }}
                  />
                  <Button
                    variant="outlined"
                    component="label"
                    htmlFor={`photo-upload`}
                    startIcon={<CloudUploadIcon />}
                    sx={{
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {t("selectImage")}
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12}>
                {/* <Controller
                  name={"idVideo"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <video
                      controls
                      src={videoFile !== "" ? videoFile : placeholder}
                      style={{ width: "100%" }}
                    />
                  )}
                /> */}
                <Controller
                  name={"idVideo"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div style={{ width: "110%", paddingTop: "60%", position: "relative" }}>
                      <video
                        controls
                        src={videoFile !== "" ? videoFile : placeholder}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          objectFit: "contain"
                        }}
                      />
                    </div>
                  )}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "8px",
                    marginTop: "12px",
                    width: "180px",
                  }}
                >
                  <input
                    id={`video-upload`}
                    type="file"
                    onChange={(event) => handleVideoFileChange(event)}
                    accept="video/mp4, video/avi, video/mkv"
                    style={{ display: "none" }}
                  />
                  <Button
                    variant="outlined"
                    component="label"
                    htmlFor={`video-upload`}
                    startIcon={<CloudUploadIcon />}
                    sx={{
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {t("selectVideo")}
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => setOpenYoutubeVideo(true)}>
                  ¿Cómo buscar ID del video?
                </Button>
                <Controller
                  name={"id_video"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      variant="standard"
                      label={t("IDVideo")}
                      value={value}
                      autoComplete="date"
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("id_video")}
                    />
                  )}
                />
                {watch_id_video !== "" && (
                  <Box sx={{ my: 4 }}>
                    <iframe
                      width="100%"
                      height="100%"
                      src={`https://www.youtube.com/embed/${watch_id_video}?rel=0&amp;modestbranding=1&amp;hl=es`}
                      data-src={`https://www.youtube.com/embed/${watch_id_video}?rel=0&amp;modestbranding=1&amp;hl=es`}
                      title={watch_title}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={openYoutubeVideo}
        onClose={handleCloseYoutubeVideoGuide}
        fullWidth
      >
        <GuideIDVideoYoutube cancelModal={handleCloseYoutubeVideoGuide} />
      </Dialog>
    </FancyPaper>
  );
};

export default AddOrUpdPostCard;
