import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Grid, Box, Typography, Divider, useTheme } from "@mui/material";

import { useApp } from "../hooks/useApp";
import { useForm } from "react-hook-form";
import FancyPaper from "../components/FancyPaper";


const ConsumptionHistory = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setLoading, setErrorMsg } = useApp();




  return (
    <>
      <FancyPaper pagetitle="Auditoría / Historial de Consumo">
        <Typography variant="body1">
          Historial de Consumo
        </Typography>

      </FancyPaper>
    </>
  );
};

export default ConsumptionHistory;
